/* eslint-disable no-unused-vars */
/* global React */
import CellHOC from '@platform/react/hoc/list/cell'
import { useEffect, useRef, useState } from 'react'
import Autocomplete from 'ui/Component/Field/Autocomplete'
import PlatformEvent from 'lib/util/event'
import { getFirstItem } from 'lib/util'

/**
 * Returns the ListItemCellUserAdminRoleAtOrg element's current theme styles.
 *
 * @param {Object} theme    the application's current theme
 * @returns {Object}        the ListItemCellUserAdminRoleAtOrg component's styles
 */
const styles = theme => ({
  root: {
    width: '100%',
    marginRight: '5px',
  },
})

/**
 * Displays the atOrganisation input field for each users' role.
 *
 * @param {Object} classes  styling for the cell
 * @param {Object} props    props for the cell
 *
 * @return {*}
 * @constructor
 */
export const ListItemCellUserAdminRoleAtOrg = (classes, props = {}) => {
  const { index, label, atOrg, events, view } = props
  const parentRef = useRef(null)
  const [currentOrganisation, setCurrentOrganisation] = useState(null)
  const [disabled, setDisabled] = useState(false)

  useEffect(() => {
    (async () => {
      const event = new CustomEvent('create')
      const result = await events?.onAtOrgCreate(new PlatformEvent(event, { value: atOrg, index }))
      const organisation = getFirstItem(result) || {}

      const newCurrentOrganisation = currentOrganisation?.key !== organisation.key
        || currentOrganisation?.disabled !== organisation.disabled
        ? organisation
        : currentOrganisation

      setCurrentOrganisation(newCurrentOrganisation)
    })()
  })

  useEffect(() => {
    setDisabled(getFirstItem(currentOrganisation)?.disabled || false)
  }, [currentOrganisation])

  const Field = React.forwardRef(Autocomplete)

  return (
    <Field
      events={{
        onOpen: events?.onAtOrgOpen,
        onChange: events?.onAtOrgChange(parseInt(index, 10)),
        getLabel: events?.getLabel,
        onLinkChange: events?.onLinkChange,
      }}
      ref={parentRef}
      view={view}
      className={classes.input}
      label={label}
      value={currentOrganisation}
      freeText={true}
      oneShot={true}
      // debounced={500}
      disabled={disabled}
      openInNewTab={true}
    />
  )
}

export default CellHOC(ListItemCellUserAdminRoleAtOrg, styles)
