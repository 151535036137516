import CellHOC from '@platform/react/hoc/list/cell'
import LabeledHOC from '@platform/react/hoc/list/labeled'
import { ListItemCellUserEmail } from 'ui/Element/List/Item/Cell/Person/Email'

export default CellHOC(
  LabeledHOC(
    props => props.labels.contact,
    ListItemCellUserEmail,
  ),
)
