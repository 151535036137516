/* eslint-disable no-unused-vars,object-curly-newline */
/* global React */
import { useMemoRef, useStyles } from '@platform/react/hook'
import { Grid, LinearProgress } from '@mui/material'
import FileImage from 'ui/Element/Attachment/Item/File'
import { useState } from 'react'

/**
 * Returns the AttachmentListTemplateSmall element's current theme styles.
 *
 * @param {object} theme    the application's current theme
 * @param {number} spacing  padding for each image
 * @param {number} space
 * @param {number} gap
 * @returns {object}        the AttachmentListTemplateSmall component's styles
 */
const styles = (theme, { spacing, space, gap }) => ({
  root: {
    minHeight: 80,
    padding: spacing
      ? theme.spacing(spacing)
      : theme.spacing(space, gap),
    alignItems: 'stretch',
  },
  image: {
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'left',
    backgroundSize: 'cover',
  },
  progress: {
    top: '-100%',
    width: '100%',
    height: '100%',
    opacity: 0.4,
  },
  ...theme.custom.attachmentListTemplateSmall,
})

/**
 * AttachmentListTemplateSmall attachment component
 *
 * Displays a small representation of the file to be displayed in a grid.
 *
 * @param {object} attachment                 the attached file
 * @param {object} types                      the types for the {@link FileImage} element
 * @param {object} [classes]                  the element's style classes
 * @param {object} [props]                    additional element's properties
 * @param {boolean} [props.showProgress=true] whether to show a progress bar
 * @param {number} [props.size=4]             the number of grids used by the element
 */
const AttachmentListTemplateSmall = ({ attachment, types, classes: parentClasses, ...props }) => {
  const {
    showProgress = true,
    size = 4,
    events,
    download = true,
    spacing = 1,
    space = 1,
    gap = 1,
  } = props
  const classes = { ...useStyles(styles, { spacing, space, gap })(), ...parentClasses }
  const { value: item } = attachment
  const [attachmentState, setAttachmentState] = useState({})
  const { progress = 0 } = attachmentState || {}
  const isLoading = progress !== 0 && progress !== 100

  return (
    <Grid
      item
      className={`${classes.root} ${classes?.image ? classes.image : ''}`}
      title={item.name}
      md={size}
      lg={size}
      xl={size}
    >
      <FileImage
        types={types}
        download={download}
        attachment={attachment}
        events={{
          onClick: events?.onClick,
          onState: setAttachmentState,
        }}
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
      />
      {isLoading && showProgress && (
        <LinearProgress
          color={'inherit'}
          className={classes.progress}
          variant={'determinate'}
          value={progress}
        />
      )}
    </Grid>
  )
}
export default useMemoRef(AttachmentListTemplateSmall, props => [
  props.classes,
])
