import { Grid, Typography } from '@mui/material'
import getRelativeTime from 'lib/util/date'
import format from 'lib/util/string'
import CellHOC from '@platform/react/hoc/list/cell'
import { getThemeColor } from 'lib/util'

/**
 * Custom styles for the note text.
 *
 * @return {{cell: {lineHeight: number}}}
 */
const styles = theme => ({
  row: {
    marginBottom: '4px',
  },
  read: {
    fontWeight: 'normal',
    color: theme.palette.text.secondary,
  },
  unread: {
    fontWeight: 500,
    color: theme.palette.text.dark,
  },
  item: {
    fontSize: 12,
    fontWeight: 'normal',
    color: 'text.secondary',
  },
})

/**
 * Map the timestamp a message was created to
 * a human-readable representation.
 * @param ts
 * @param times
 * @returns {string}
 */
const mapTime = (ts, times) => {
  const relativeTime = getRelativeTime(ts)
  const u = Object.keys(relativeTime)[0]
  return format(times[u], { number: Math.round(relativeTime[u]) })
}

/**
 * Displays information about the message.
 *
 * @param {Object} classes  styling for the cell
 * @param {Object} props    props for the cell
 *
 * @returns {JSX.Element}
 */
const ListItemCellMessageDescription = (classes, props = {}) => {
  const {
    attachments,
    times,
    partner,
    submitTimestamp: timestamp,
    text,
    type,
    title,
    unreadMessages,
    events,
  } = props

  /**
   * Available system message types
   */
  const systemMessageTypes = events?.getSystemMessageTypes(null)

  /**
   * Maps the current message's type to the available
   * system message types
   *
   * @param currentType
   * @returns {*}
   */
  const mapSystemMessageType = currentType => systemMessageTypes.find(
    messageType => messageType.key === currentType,
  )

  const currentSystemType = mapSystemMessageType(type)?.value
  const readStateClass = unreadMessages ? classes.unread : classes.read

  return (
    <>
      <Typography
        className={`${classes.row} ${readStateClass}`}
      >
        {partner || '-'}
      </Typography>
      <Typography
        sx={{ fontSize: 16 }}
        className={`${classes.row} ${readStateClass}`}
      >
        {title || '-'}
      </Typography>
      <Typography
        sx={{
          fontSize: 14,
          fontWeight: 'normal',
        }}
        className={classes.row}
      >
        {
          text
              || (currentSystemType && <i>{currentSystemType}</i>)
              || (attachments && <i>{attachments}</i>)
              || '-'
        }
      </Typography>
      <Grid container spacing={1}>
        <Grid item>
          <Typography className={classes.item}>
            {mapTime(timestamp, times)}
          </Typography>
        </Grid>
      </Grid>
    </>
  )
}

export default CellHOC(ListItemCellMessageDescription, styles)
