import { Typography } from '@mui/material'
import { useContext } from 'react'
import ApplicationContext from '@platform/react/context/application'
import CellHOC from '@platform/react/hoc/list/cell'

/**
 * Displays the {@param props.submitTimestamp} of the note.
 *
 * @param {Object} classes  styling for the cell
 * @param {Object} props    props for the cell
 *
 * @returns {JSX.Element}
 */
const ListItemCellNoteTimestamp = (classes, props = {}) => {
  const { intl } = useContext(ApplicationContext)

  const { submitTimestamp, startDate } = props
  const localDate = intl.getLocaleDate(startDate || submitTimestamp, { month: 'short', day: '2-digit' })
  const localTime = !startDate ? intl.getLocaleDate(submitTimestamp, { hour: '2-digit', minute: '2-digit' }) : '-'

  return (
    <>
      <Typography
        variant={'caption'}
        color={'textSecondary'}
        sx={{
          fontWeight: 500,
          textAlign: 'center',
        }}
      >
        {`${localDate.toUpperCase()}`}
      </Typography>
      <Typography
        variant={'body1'}
        color={'black'}
        sx={{
          fontWeight: 500,
          textAlign: 'center',
        }}
      >
        {localTime}
      </Typography>
    </>
  )
}

export default CellHOC(ListItemCellNoteTimestamp)
