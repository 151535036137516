/* global React */
import { Grid, Skeleton, Fade } from '@mui/material'
import { useMemoRef } from '@platform/react/hook'

export const style = {
  maxWidth: '95%',
  maxHeight: '95%',
  backgroundColor: 'rgba(87, 87, 87, 0.11)',
}

/**
 * PlaceholderListItemCellBlock
 *
 * To be displayed while a list's cell element is being loaded.
 *
 * @param {number} size                               placeholder's grid width
 * @param {string|number|number[]} [width='90%']      block's width
 * @param {string|number|number[]} [blockWidth=width] block's width
 * @param {string|number|number[]} [blockHeight=40]   block's height
 * @returns {null|JSX.Element|JSX.Element[]}
 * @constructor
 */
const PlaceholderListItemCellBlock = useMemoRef(({ size, width = '90%', blockWidth = width, blockHeight = 40, fullWidth }) => (
  <Fade
    in={true}
    timeout={{
      enter: 1500,
      exit: 1500,
    }}
  >
    <Grid
      item
      xs={size}
    >
      <Skeleton
        variant={'rounded'}
        width={blockWidth}
        height={blockHeight}
        style={{
          ...style,
          ...fullWidth && {
            maxWidth: '100%',
            width: '100%',
          },
        }}
      />
    </Grid>
  </Fade>
), props => [props.blockWidth])

export default PlaceholderListItemCellBlock
