/* eslint-disable object-curly-newline,implicit-arrow-linebreak */
import { useEffect, useState, useTransition } from 'react'
import { useMemoRef, useStyles } from '@platform/react/hook'
import { Grid, Icon, Link } from '@mui/material'
import { getThemeColor } from 'lib/util'

const styles = (theme, { value, iconColor }) => ({
  root: {},
  link: {},
  iconLink: {
    ...iconColor && { color: `${getThemeColor(theme, iconColor)}!important` },
    verticalAlign: 'middle',
    ...value && { marginRight: '1rem' },
  },
  ...theme.custom.iconLink,
})

/**
 * IconLink UI Element.
 * Displays clickable/link icon with text.
 *
 * @param {object} forwardedRef             a reference to the root element
 * @param {string} [value]                  a string to be used as value
 * @param {boolean} [hidden]                whether the component should be hidden
 * @param {string} [prefix]                 a string to be used as value
 * @param {string} [icon]                   a string to be used as value
 * @param {string} [events]                 the events to be fired by the element
 * @param {boolean} [autoHide]              whether the element should be hidden if {@param value}
 *                                          is falsy
 * @param {object} [props]                  additional element properties
 * @param {string} [props.variant]          a text style variant to set for the element
 * @param {string} [props.color]            a color style to set for the element
 * @param {string} [props.disabled]         whether the element is not interactive
 * @param {object} props.events             events for the link
 * @param {Function} [props.events.onOpen]  onOpen handler
 * @param {Function} [props.events.onClick] onClick handler
 * @returns {JSX.Element}                   the new IconLink element
 * @constructor
 */
const IconLink = ({ forwardedRef, value, hidden, prefix, icon, events, autoHide, ...props }) => {
  const classes = useStyles(styles, { value, iconColor: props.iconColor })()
  const { onOpen, onClick } = events || {}
  const [, startTransition] = useTransition()
  const [href, setHref] = useState(() => onOpen?.(null))

  useEffect(() => {
    setHref(onOpen?.(null))
  }, [onOpen])

  const handleClick = event => startTransition(() => onClick?.(event))

  return (autoHide && !value) || hidden ? null : (
    <Grid
      ref={forwardedRef}
      className={props?.className || classes.root}
      container
      item
    >
      <Link
        href={href || `${prefix ? `${prefix}:` : 'http://'}${value}`}
        data-rel={'external'}
        target={props.target || '_blank'}
        rel="noopener"
        className={classes.link}
        variant={props.variant}
        color={props.color}
        tabIndex={props.disabled && -1}
        onClick={handleClick}
        style={
          props.disabled && {
            pointerEvents: 'none',
            cursor: 'default',
            '&:hover': {
              textDecoration: 'none',
            },
          }
        }
        underline="hover">
        <Icon
          className={classes.iconLink}
        >
          {icon}
        </Icon>
        {value}
      </Link>
    </Grid>
  )
}

export default useMemoRef(IconLink, props =>
  [props.value, props.events, props.color, props.variant, props.className])
