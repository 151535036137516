/* eslint-disable import/no-extraneous-dependencies, arrow-body-style,object-curly-newline */
/* global React */
import { forwardRef } from 'react'
import { Grid } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { isArr, isObj } from 'lib/util'
import mapClasses from '@platform/react/hook/mapClasses'
import ScrollGrid from 'ui/Component/Grid/ScrollGrid'
import ErrorBoundary from 'ui/Error'

const prelogin = {
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'row',
  flexBasis: 'auto',
  maxWidth: [[512], '!important'],
  margin: 'auto',
}

/**
 * Section style decorators.
 *
 * Intended to be applied selectively through the {@link Section} and {@link Block} classes prop.
 * A classes prop should contain the names of the classes to be applied in each specific case.
 *
 * @param {Object} theme  application's styling theme
 * @return {Object}       Section style decorators
 */
export const sectionStyles = makeStyles(theme => ({
  start: {
    justifyContent: 'flex-start!important',
  },
  end: {
    justifyContent: 'flex-end!important',
  },
  center: {
    justifyContent: 'center!important',
  },
  spaceBetween: {
    justifyContent: 'space-between',
  },
  spaceAround: {
    justifyContent: 'space-around',
  },
  spaceEvenly: {
    justifyContent: 'space-evenly',
  },
  selfStart: {
    alignSelf: 'flex-start!important',
  },
  selfEnd: {
    alignSelf: 'flex-end!important',
  },
  selfCenter: {
    alignSelf: 'center!important',
  },
  selfStretch: {
    alignSelf: 'stretch!important',
  },
  itemsStart: {
    alignItems: 'flex-start!important',
  },
  itemsEnd: {
    alignItems: 'flex-end!important',
  },
  itemsCenter: {
    alignItems: 'center!important',
  },
  itemsBaseline: {
    alignItems: 'baseline!important',
  },
  itemsStretch: {
    alignItems: 'stretch!important',
  },
  contentsCenter: {
    alignContent: 'center!important',
  },
  contentsSpaceEvenly: {
    alignContent: 'space-evenly!important',
  },
  contentsEnd: {
    alignContent: 'flex-end!important',
  },
  textCenter: {
    textAlign: 'center',
  },
  separator: {
    borderBottom: '3px solid blue',
  },
  column: {
    flexDirection: 'column',
  },
  nowrap: {
    flexWrap: 'nowrap',
  },
  xsWrap: {
    // down() is exclusive, sm not included!
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    },
  },
  reverse: {
    flexFlow: 'wrap-reverse',
  },
  fill: {
    flex: [[1, 1, '100%']],
  },
  login: prelogin,
  wideLogin: {
    maxWidth: [['55rem'], '!important'],
    '& *:not(input)': {
      textAlign: [['center'], '!important'],
    },
    '& > div > section > div > section > div': {
      maxWidth: '100%',
      justifyContent: 'center',
    },
    '& form': {
      marginTop: '1.2rem!important',
      flexBasis: '100%',
      [theme.breakpoints.up('md')]: {
        flexBasis: '58%',
      },
    },
    '& p': {
      width: '100%',
    },
  },
  secondaryLogin: {
    '& h6': {
      fontSize: '1.5rem',
      color: theme.palette.secondary.main,
    },
    '& button[type=submit]': {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  register: {
    ...prelogin,
    justifyContent: 'flex-start',
    maxWidth: [['50rem'], '!important'],
    marginTop: [['1vw'], '!important'],
  },
  invite: {
    justifyContent: 'center',
    maxWidth: '400px',
    margin: [['auto', 'auto']],
  },
  dashboard: {
    maxWidth: '560px',
  },
  divider: {
    paddingTop: 35,
    borderTop: [[1, 'solid', theme.palette.divider]],
  },
  widget: {
    maxWidth: '272px',
    [theme.breakpoints.down('md')]: {
      maxWidth: '100%',
    },
  },
  borderLeft: {
    [theme.breakpoints.up('md')]: {
      borderLeftWidth: '1px!important',
    },
  },
  borderRight: {
    [theme.breakpoints.up('md')]: {
      borderRightWidth: '1px!important',
    },
  },
  borderTop: {
    borderTop: [[1, 'solid', theme.palette.divider]],
  },
  borderBottom: {
    borderBottom: [[1, 'solid', theme.palette.divider]],
  },
  paddingLeft: {
    [theme.breakpoints.up('sm')]: {
      paddingLeft: 38,
    },
  },
  marginLeft: {
    [theme.breakpoints.up('sm')]: {
      marginLeft: 38,
    },
  },
  flex: {
    flex: [[1, 1, 0], '!important'],
    [theme.breakpoints.down('md')]: {
      flex: 'auto!important',
    },
  },
  wizard: {
    maxWidth: 900,
    [theme.breakpoints.down('md')]: {
      maxWidth: '100%',
    },
  },
  footer: {
    paddingLeft: theme.layout.navigationDrawerWidth,
    [theme.breakpoints.down('md')]: {
      paddingLeft: 0,
    },
    position: 'fixed!important',
    padding: theme.spacing(1),
    height: 60,
    // with absolute positioning, we can't use our regular borders
    borderTop: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.common.white,
    bottom: '0px',
    left: '0px',
    right: '0px',
  },
  stickyBottom: {
    position: 'absolute!important',
    // with absolute positioning, we can't use our regular borders
    borderTop: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.common.white,
    bottom: '0px',
    left: '0px',
    right: '0px',
  },
  stickyTop: {
    position: 'absolute!important',
    // with absolute positioning, we can't use our regular borders
    borderBottom: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.common.white,
    top: '0px',
    left: '0px',
    right: '0px',
  },
  preface: {
    [theme.breakpoints.up('md')]: {
      paddingTop: '10vh',
    },
  },
  profile: {
    maxWidth: theme.breakpoints.values.md,
  },
  // visual classes
  bgGray: {
    backgroundColor: theme.palette.background.content,
  },
  bgWhite: {
    backgroundColor: theme.palette.background.main,
  },
  radius1: {
    borderRadius: theme.spacing(1),
  },
  radius1_5: {
    borderRadius: theme.spacing(1.5),
  },
  radius2: {
    borderRadius: theme.spacing(2),
  },
  ...theme.custom.section,
}))

/**
 * @typedef {Object} SectionProps
 * @property {string} key               element's key
 * @property {string[]} classes         names of the styles among {@link styles} to be applied
 * @property {number} scroll            whether scroll is enabled
 * @property {number} scrollBottom      whether scroll is enabled and starts at the bottom
 * @property {number} borderWidth       element's border width
 * @property {string} borderColor       element's border color
 * @property {boolean} fullHeight       whether the element should have the parent's height
 * @property {boolean} fullWidth        whether the element should have the parent's width
 * @property {number} padding           size (spacing) of the padding area in all four sides
 * @property {number} paddingHorizontal left and right sizes (spacing) of the padding area
 * @property {number} paddingVertical   top and bottom sizes (spacing) of the padding area
 * @property {number} paddingTop        top size (spacing) of the padding area
 * @property {number} paddingBottom     bottom size (spacing) of the padding area
 * @property {number} paddingLeft       left size (spacing) of the padding area
 * @property {number} paddingRight      right size (spacing) of the padding area
 * @property {number} margin            size (spacing) of the margin area in all four sides
 * @property {number} marginHorizontal  left and right sizes (spacing) of the margin area
 * @property {number} marginVertical    top and bottom sizes (spacing) of the margin area
 * @property {number} marginTop         top size (spacing) of the margin area
 * @property {number} marginBottom      bottom size (spacing) of the margin area
 * @property {number} marginLeft        left size (spacing) of the margin area
 * @property {number} marginRight       right size (spacing) of the margin area
 * @property {number} spacing           amount of spacing around each child item
 * @property {number} space             amount of spacing above and below each child item
 * @property {number} gap               amount of spacing at the sides of each child item
 * @property {number} grow              factor by which to enlarge the item if there is space
 * @property {number} shrink            factor by which to shrink the item if needed
 * @property {number|'auto'} xs         the number of parent's columns used on xs width
 * @property {number|'auto'} sm         the number of parent's columns used on sm width
 * @property {number|'auto'} md         the number of parent's columns used on md width
 * @property {number|'auto'} lg         the number of parent's columns used on lg width
 * @property {number|'auto'} xl         the number of parent's columns used on xl width
 * @property {boolean} hidden           whether the element shouldn't be mounted at all
 */
const sectionProps = {
  key: '',
  classes: '',
  className: '',
  scroll: '',
  scrollBottom: '',
  borderWidth: 'border',
  borderColor: '',
  fullHeight: '',
  fullWidth: '',
  padding: '',
  paddingHorizontal: 'paddingX',
  paddingVertical: 'paddingY',
  paddingTop: '',
  paddingBottom: '',
  paddingLeft: '',
  paddingRight: '',
  margin: '',
  marginHorizontal: 'marginX',
  marginVertical: 'marginY',
  marginTop: '',
  marginBottom: '',
  marginLeft: '',
  marginRight: '',
  spacing: '',
  space: 'rowSpacing',
  gap: 'columnSpacing',
  grow: 'flexGrow',
  shrink: 'flexShrink',
  xs: '',
  sm: '',
  md: '',
  lg: '',
  xl: '',
  hidden: '',
}

/**
 * Searches for all {@link SectionProps} properties that can be found in {@param props} and groups
 * them together. Returns an array whose first element is an object containing all matching
 * properties and the second one contains the rest.
 *
 * If {@param map} is {@code true}, the first object of the returned array will have the names of
 * its properties mapped according to the values of the properties in {@link SectionProps}.
 *
 * @param {SectionProps & any} props    an object from which to extract {@link SectionProps}.
 * @param {boolean} [map]               whether to map the props names
 * @return {[{SectionProps}, {Object}]} an array containing a first object all props from
 *                                      {@param props} that match those from {@link SectionProps},
 *                                      and a second object with the rest
 */
const getItemProps = (props, map) => Object.keys(props).reduce(([itemProps, rest], key) => {
  const target = key in sectionProps ? itemProps : rest
  const targetKey = map ? sectionProps[key] || key : key
  target[targetKey] = props[key]
  return [itemProps, rest]
}, [{}, {}])

/**
 * Returns an array whose first object contains the properties from {@param props} that should be
 * applied to a child's wrapping {@link Block} and the second one the ones that should be applied to
 * the child item itself.
 *
 * By default, it attempts to create a new object with all properties from {@param props} that can
 * be found in {@link SectionProps} and returns it as the first item of the array and the rest in an
 * object as the second one.
 *
 * If there are any {@param props.children}, the child to whom the {@param props} belong is
 * considered to be a {@link Section} and then, in that case, the filtered {@link SectionProps} are
 * returned inside the second object of the array instead of the first one, so that the supposed
 * child {@link Section} receives them instead of its wrapping {@link Block}. This can be opted out
 * by passing {@param props.block} with {@code true}, in which case it will behave as it does by
 * default.
 *
 * If {@param props.block} is an object, this function will attempt to obtain the
 * {@link SectionProps} from it instead and consider that the rest of {@param props} belong to the
 * child item.
 *
 * @param {SectionProps & any} props          an object from which to extract {@link SectionProps}.
 * @param {SectionProps & any} [props.block]  an object from which to extract {@link SectionProps}.
 * @return {[{Object}, {Object}]}
 */
const getBlockProps = (props) => {
  const { block, children, ...restProps } = props
  const blockIsObject = isObj(block)
  const targetProps = blockIsObject ? block : restProps
  const [gridItemProps, rest] = getItemProps(targetProps)
  const restItemProps = blockIsObject ? restProps : rest

  // const isBlock = blockIsObject ? block.block : block
  const isContainer = !block && !!children?.length
  const { fullWidth, fullHeight } = gridItemProps
  const { scroll, scrollBottom, ...gridBlockProps } = gridItemProps
  const blockProps = isContainer ? {} : gridBlockProps
  const itemBlockProps = isContainer ? gridItemProps : {}

  const itemProps = {
    ...itemBlockProps,
    ...restItemProps,
    ...fullWidth && { fullWidth },
    ...fullHeight && { fullHeight },
    children,
  }

  return [blockProps, itemProps]
}

/**
 * Item element used by {@link Section} and {@link Block} that abstracts the handling of all their
 * shared props and functionalities.
 *
 * It maps most props according to the values of {@link SectionProps} and passes them to an
 * underlying {@link Grid} component.
 *
 * @type {React.ForwardRefExoticComponent}
 */
const Item = forwardRef(({ children, component, sx, hidden, ...props }, ref) => {
  const [itemProps] = hidden ? [] : getItemProps(props, true)
  const { scroll, scrollBottom, classes, fullWidth, fullHeight, ...rootProps } = itemProps

  const scrollable = scroll || scrollBottom
  const Root = scrollable ? ScrollGrid : Grid
  const scrollProps = scrollable && scrollBottom ? { scrollProps: { bottom: true } } : {}

  return hidden ? null : (
    <Root
      flexGrow={(scrollable && 1)}
      border={0}
      borderColor={'gray.900'}
      alignItems={'flex-start'}
      alignContent={'flex-start'}
      {...rootProps}
      {...scrollProps}
      container
      item
      className={[
        props.className,
        mapClasses(classes, sectionStyles),
      ].filter(item => !!item).join(' ')}
      ref={ref}
      component={component}
      noValidate={component === 'form'}
      sx={{
        ...fullWidth && { width: '100%' },
        ...fullHeight && { height: '100%' },
        ...sx,
      }}
    >
      {children}
    </Root>
  )
})

/**
 * Wraps the {@param child} element with a {@link Grid} element and sets the layout props
 * of the former and {@param options} as props of the latter.
 *
 * @param {object} child                  the child element
 * @param {object} child.key              child's identification key
 * @param {SectionProps} child.props      child component's props
 * @param {object} options                child element options
 * @param {number} [options.segments={}]  the number of spacings between around the item
 * @returns {*}
 */
const Block = ({ child, options }) => {
  const { segments = {} } = options
  const { ...item } = child // shallow copying child, so that we can rewrite its props
  const { key, props } = item
  const { hidden } = props
  const [blockProps, itemProps] = hidden ? [] : getBlockProps(props)
  const { scroll, scrollBottom } = itemProps || {}
  // setting child props after having filtered the ones that belong to its wrapper
  item.props = { ...itemProps, ...!segments && { segments } }

  return hidden ? null : (
    <ErrorBoundary key={key}>
      <Item
        {...blockProps}
        key={key}
        sx={{
          ...(scroll || scrollBottom) && { overflow: 'auto' },
        }}
      >
        {item}
      </Item>
    </ErrorBoundary>
  )
}

/**
 * Helper function that maps each item of {@param children} to new a {@link Template} element.
 *
 * @param {Object} children[] the items to wrap each with {@param options.Template}
 * @param {function} Template an element to be used to represent each item from {@param children}
 * @param {Object} options    properties from {@link Section} that should affect its children
 * @returns {*}
 */
export const decorateAsBlockGrid = (children, { Template, ...options } = {}) => {
  const items = isArr(children) ? children : [children]
  return items.map(child => Template({ child, options: { ...options } }))
}

/**
 * High-order section component designed to place and arrange other elements inside it, which will
 * be also automatically wrapped by another component to facilitate their placement. The arrangement
 * of these elements depends on their number and the width they occupy. This component's classes,
 * spacing, space, gap and width (xs, sm, md, lg and xl) properties offer additional possibilities.
 *
 * The padding set to its children by using the spacing, space and gap properties is corrected with
 * a higher width and negative margin on the container, so that the nesting of multiple instances of
 * this component should not be noticeable.
 *
 * @param {string} [component='section']  the type of HTMLElement to use for the component
 * @param {object[]} [children]           the children elements to be contained by the component
 * @param {function} [iterator]           the iterator used to loop through the children
 * @param {SectionProps} [props]          additional component's properties
 * @param {React.Ref} ref                 a reference object to the root element
 * @returns {JSX.Element}                 the new component
 * @constructor
 */
const Section = ({ component, children, iterator, ...props }, ref) => {
  const [itemProps] = getItemProps(props)

  const decorate = iterator || decorateAsBlockGrid
  const items = decorate(children, { Template: Block })

  return (
    <Item
      {...itemProps}
      ref={ref}
      component={component || 'section'}
    >
      {items}
    </Item>
  )
}

export default Section
