import { Typography } from '@mui/material'
import CellHOC from '@platform/react/hoc/list/cell'
import { useContext } from 'react'
import ApplicationContext from '@platform/react/context/application'

/**
 * Intended to display name and address of the organisation where a person belongs to.
 *
 * @param {Object} classes  styling for the cell
 * @param {Object} props    props for the cell
 *
 * @returns {JSX.Element}
 */
export const ListItemCellRequestStatus = (classes, props = {}) => {
  const { intl } = useContext(ApplicationContext)
  const {
    status: statusKey,
    statusReason: statusReasonKey,
    modify: { timestamp: modifyTimestamp = '' },
    events,
  } = props
  const [status] = events?.getStatus?.(statusKey || -1) || []
  const [statusReason] = events?.getStatusReason?.(statusReasonKey || -1) || []
  const statusLabel = status?.value
  const statusReasonLabel = statusReason?.value
  const label = (statusReasonLabel || statusLabel || '').toUpperCase()
  const modifyDate = modifyTimestamp
    ? intl.getLocaleDate(modifyTimestamp, { dateStyle: 'short' })
    : ''
  const statusLine = statusReasonLabel
    ? `${statusReasonLabel} - ${modifyDate}`
    : modifyDate

  // Mapping status to color
  let statusColor = 'primary'
  if (statusKey >= 60 && statusKey < 70) statusColor = 'warning'
  if (statusKey >= 80) statusColor = 'text'
  const statusColorType = status >= 80 ? 'secondary' : 'main'

  return (
    <>
      <Typography
        variant={'body1'}
        sx={{
          fontWeight: 500,
          lineHeight: 1.3,
          color: `${statusColor}.${statusColorType}`,
        }}
        className={classes.row}
      >
        {label}
      </Typography>
      <Typography
        variant={'caption'}
        color={'textSecondary'}
        className={classes.row}
      >
        {statusLine}
      </Typography>
    </>
  )
}

export default CellHOC(ListItemCellRequestStatus)
