/* eslint-disable no-undefined */
/* global G, React */
import { useContext, useRef, useState } from 'react'
import { Alert, Button, Snackbar as MuiSnackbar, Typography } from '@mui/material'
import { useEventCallback, useEventHandler, useStyles } from '@platform/react/hook/'
import ApplicationContext from '@platform/react/context/application'

/**
 * Styles for the Alert
 * @returns {{alert: {borderRadius: string}, message: {fontFamily: string}}}
 */
const styles = () => ({
  alert: {
    borderRadius: '3.5rem',
  },
})

/**
 * Default state for the Alert component.
 * @private
 */
const _defaultState = {
  open: false,
  duration: 6000,
  close: true,
  severity: undefined,
  color: undefined,
  variant: undefined,
  message: null,
  action: null,
  orientation: {
    vertical: 'bottom',
    horizontal: 'center',
  },
}

const SnackBar = () => {
  const classes = useStyles(styles)()
  const { eventBus } = useContext(ApplicationContext)
  const [state, setState] = useState(_defaultState)

  const handleClose = useEventCallback((e, r) => {
    if (r === 'clickaway') return
    setState({ ..._defaultState, open: false })
  })
  const _dataReadEventName = useRef(eventBus.type(G.DATA, G.UPDATE))
  const _eventHandler = useEventCallback(({ detail }) => {
    setState({
      ..._defaultState,
      open: true,
      ...detail,
    })
  })
  useEventHandler(eventBus, _dataReadEventName.current, _eventHandler)

  return (
    <MuiSnackbar
      open={state.open}
      onClose={handleClose}
      anchorOrigin={state.orientation}
      autoHideDuration={state.duration}
    >
      <Alert
        classes={{
          root: classes.alert,
        }}
        color={state.color}
        variant={state.variant}
        severity={state.severity}
        onClose={state.close ? handleClose : null}
        action={state.action && (
          <Button
            color='inherit'
            size='small'
            onClick={() => {
              state.action.handler({})
              handleClose()
            }}>
            {state.action.title}
          </Button>
        )}
      >
        <Typography variant={'14/bold'}>
          {state.message}
        </Typography>
      </Alert>
    </MuiSnackbar>
  )
}

export default SnackBar
