import CellHOC from '@platform/react/hoc/list/cell'
import LabeledHOC from '@platform/react/hoc/list/labeled'
import { ListItemCellTicketAssignee } from 'ui/Element/List/Item/Cell/Ticket/Assignee'

export default CellHOC(
  LabeledHOC(
    props => props.labels.assigned,
    ListItemCellTicketAssignee,
  ),
)
