/* global React */
/* eslint-disable object-curly-newline,implicit-arrow-linebreak */
import { useMemoRef, useStyles } from '@platform/react/hook'
import { Grid, Paper } from '@mui/material'
import { useContext, useRef } from 'react'
import ApplicationContext from '@platform/react/context/application'
import ChildrenHOC from '@platform/react/hoc/children'
import ErrorBoundary from 'ui/Error'
import { countToLabel } from 'ui/Component/Subbed/Widget/Narrow'
import PlatformEvent from 'lib/util/event'

const styles = theme => ({
  root: {
    minHeight: '80px',
    padding: '0.8rem 0.5rem',
    borderRadius: '10px',
    border: '1px solid rgb(0 0 0 / 5%)',
    boxShadow: '0px 4px 6px 0px rgb(0 0 0 / 5%)',
  },
  zero: {
    backgroundImage: 'linear-gradient(147deg, #f7fcff -4%, #dce2e6)',
  },
  textContainer: {
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  title: {
    margin: 0,
    fontWeight: 500,
  },
  body: {
    textTransform: 'uppercase',
    margin: 0,
    fontWeight: 500,
  },
  click: {
    cursor: 'pointer',
  },
  ...theme.custom.widgetSingle,
})

/**
 * Single Widget
 *
 * Take a look at {@code GaiaSubbedNarrowWidget} for
 * explanation of the different states.
 *
 * @param forwardedRef
 * @param value
 * @param title
 * @param icon
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const SubbedSingleWidget = ({ forwardedRef, value, title, icon, ...props }) => {
  const classes = useStyles(styles)()
  const {
    ZeroIcon,
    ZeroTitle,
    OneIcon,
    OneTitle,
    OneBody,
    OtherTitle,
    OtherBody,
  } = props.childrenObject
  const { events } = props
  const { socket: { ref: socket } } = useContext(ApplicationContext)
  const socketKey = useRef(null)
  const customEvent = new CustomEvent('load', { detail: { key: socketKey.current } })
  const platformEvent = new PlatformEvent(customEvent)
  const event = useRef(() => platformEvent)
  const [count, setCount] = React.useState(0)
  const [singleItem, setSingleItem] = React.useState(null)

  React.useLayoutEffect(() => {
    let isMounted = true;

    /**
     * Fetching of initial data
     */
    (async () => {
      socketKey.current = `${events.onOpen(null)}_${props?.documentType}`
      const result = isMounted && await props.events.onPublication(event.current())

      if (isMounted) {
        result.item && setSingleItem(result.item)
        setCount(result.total.count)
      }
    })()

    /**
     * Subscription based data
     * @returns {Promise<void>}
     */
    const listener = async () => {
      const result = isMounted && await props.events.onPublication(event.current())

      if (isMounted) {
        result.item && setSingleItem(result.item)
        setCount(result.total.count)
      }
    }

    socket.sub(socketKey.current, () => {
      socket.on(socketKey.current, listener)
    })

    return () => {
      isMounted = false
      socket.unsub(socketKey.current, () => {
        socket.off(socketKey.current, listener)
      })
    }
  }, [])
  return (
    <ErrorBoundary>
      <Grid
        ref={forwardedRef}
        component={Paper}
        className={`${classes.root} ${count ? `${classes[`${props.background}`]} ${classes.click}` : classes.zero}`}
        onClick={e => count && events.onClick(e, count === 1 ? singleItem.key : null)}
        container
        item
      >
        <Grid
          ref={forwardedRef}
          alignItems={'center'}
          justifyContent={'center'}
          container
          item
          xs={3}
          sm={3}
          md={3}
          lg={3}
          xl={3}
        >
          {count > 0
            ? <OneIcon />
            : <ZeroIcon />
          }
        </Grid>
        <Grid
          direction={'column'}
          className={classes.textContainer}
          container
          item
          xs={9}
          sm={9}
          md={9}
          lg={9}
          xl={9}
        >
          {count > 0
            ? <>
              {!singleItem
                ? <>
                  <OtherTitle
                    className={classes.title}
                    variant={'h5'}
                    value={count}
                  />
                  <OtherBody
                    className={classes.body}
                    variant={'caption'}
                    value={countToLabel('body', props.label, count)}
                  />
                </>
                : <>
                  <OneTitle
                    className={classes.title}
                    value={singleItem.title}
                  />
                  <OneBody
                    className={classes.body}
                    value={singleItem.body}
                  />
                </>}
            </>
            : <ZeroTitle
              className={classes.body}
              hidden={false}
              value={countToLabel('title', props.label, 0)}
            >
            </ZeroTitle>
          }
        </Grid>
      </Grid>
    </ErrorBoundary>
  )
}

export default useMemoRef(ChildrenHOC(SubbedSingleWidget), [])
