import { Typography } from '@mui/material'
import CellHOC from '@platform/react/hoc/list/cell'

/**
 * Displays the full name of the user's person.
 *
 * @param {Object} classes  styling for the cell
 * @param {Object} props    props for the cell
 *
 * @return {*}
 * @constructor
 */
export const ListItemCellUserEmail = (classes, props = {}) => {
  const { contactChannels = [] } = props
  const contactChannel = contactChannels.find(channel => channel.type === 'email')?.value
    || contactChannels[0]?.value
    || '-'

  return (
    <Typography
      color={'black'}
      variant={'body2'}
      className={classes.row}
      sx={{ fontWeight: 700 }}
    >
      {contactChannel}
    </Typography>
  )
}

export default CellHOC(ListItemCellUserEmail)
