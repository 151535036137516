/* eslint-disable object-curly-newline */
/* global React */
import { useEffect, useState, useTransition } from 'react'
import { FormGroup, FormControl, FormLabel, Grid, FormHelperText } from '@mui/material'
import PlatformEvent from 'lib/util/event'
import useMemoRef from '@platform/react/hook/useMemoRef'
import CheckBox from 'ui/Element/Field/Check'

/**
 * Displays a group of {@link CheckBox} items in the same way as the usual radio group, with th
 * main difference that they can be deselected.
 *
 * @param {object} forwardedRef             a reference object to the root element
 * @param {object} events                   an object containing an onChange event handler
 * @param {function} events.onChange        the handler to be called whenever the component state
 *                                          changes
 * @param {function} events.getSelection    a function triggered during the first render to
 *                                          alternatively obtain the options to display
 * @param {string} label                    a string to be used as the component's label
 * @param {string} value                    the value of the checked option
 * @param {boolean} row                     whether to display the group as a row
 * @param {object} props                    additional component's properties
 * @param {object[]} props.options          an array of options to configure each button
 * @param {string} props.options[].key      a string to be used as the checkbox key
 * @param {string} props.options[].label    a string to be used as the checkbox label
 * @param {any} props.options[].value       an item to be used as the checkbox value
 * @param {string} props.options[].checked  whether the checkbox is checked
 * @param {boolean} props.error             whether to display the component in error state
 * @param {string} props.helperText         an instructive string to be displayed below the
 *                                          component
 * @returns {JSX.Element}                   the new component
 * @constructor
 */
const CheckGroupField = ({ forwardedRef, events, label, value, row, ...props }) => {
  const [, startTransition] = useTransition()
  const [options, setOptions] = useState(props.options || [])

  const handleClick = (event) => {
    const { value: newValue, checked } = event.target
    startTransition(() => events?.onChange(new PlatformEvent(event, { value: newValue, checked })))
  }

  // Allowing options to be set through a getSelection handler
  useEffect(() => {
    (async () => {
      const newOptions = await events?.getSelection?.(null)
      newOptions?.length && setOptions(newOptions)
    })()
  }, [])

  return (
    <FormControl
      ref={forwardedRef}
      component='fieldset'
      error={props.error}
      fullWidth
    >
      <Grid
        item
        container
        flexWrap={row ? 'wrap' : 'nowrap'}
        direction={row ? 'row' : 'column'}
        justifyContent={row ? 'space-between' : 'flex-start'}
        alignItems={'flex-start'}
        spacing={1}
        xs
      >
        {!label ? null : (
          <Grid
            item
            xs={row ? 12 : 6}
          >
            <FormLabel
              component='legend'
            >
              {label}
            </FormLabel>
          </Grid>
        )}
        <Grid
          item
          xs={row ? true : 6}
        >
          <FormGroup
            row={row}
            aria-label={label}
          >
            {options.map(opt => (
              <CheckBox
                key={opt.key}
                label={opt.value}
                value={opt.key}
                checked={value === `${opt.key}`}
                // disabled={opt.disabled || (!!value && value !== `${opt.value}`)}
                onClick={handleClick}
              />
            ))}
          </FormGroup>
        </Grid>
      </Grid>
      {props.helperText && (
        <FormHelperText>
          {props.helperText}
        </FormHelperText>
      )}
    </FormControl>
  )
}

export default useMemoRef(CheckGroupField, p => [p.value, p.error, p.options, p.helperText])
