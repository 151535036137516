import { Typography } from '@mui/material'
import CellHOC from '@platform/react/hoc/list/cell'

/**
 * Displays the {@code requesterContactOrg} property in cell.
 *
 * @param {Object} classes  styling for the cell
 * @param {Object} props    props for the cell
 *
 * @returns {JSX.Element}
 */
export const ListItemCellRequestRecipient = (classes, props = {}) => {
  const { requesterContactOrg = { address: {} } } = props
  return (
    <>
      <Typography
        variant={'body2'}
        color={'black'}
        className={classes.row}
        sx={{ fontWeight: 700 }}
      >
        {requesterContactOrg.value.name}
      </Typography>
      <Typography
        variant={'body2'}
        className={classes.row}
        color={'textSecondary'}
      >
        {requesterContactOrg.value.address.city}
      </Typography>
    </>
  )
}

export default CellHOC(ListItemCellRequestRecipient)
