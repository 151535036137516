/* eslint-disable object-curly-newline, arrow-body-style, import/no-extraneous-dependencies */
import { Paper } from '@mui/material'
import { useMemoRef, useStyles } from '@platform/react/hook'
import Text from 'ui/Element/Text/Simple'

const styles = theme => ({
  container: {
    width: '100%',
    backgroundColor: theme.palette.common.white,
  },
  root: {
    whiteSpace: 'pre-line',
  },
  ...theme.custom.textBlock,
})

const TextBlock = ({ forwardedRef, value, ...props }) => {
  const classes = useStyles(styles)()

  return (
    <Paper
      elevation={0}
      component={'header'}
      className={props.className || classes.container}
    >
      <Text
        {...props}
        className={classes.root}
        forwardedRef={forwardedRef}
        value={value}
      />
    </Paper>
  )
}

export default useMemoRef(TextBlock, [])
