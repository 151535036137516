import { Typography } from '@mui/material'
import CellHOC from '@platform/react/hoc/list/cell'

/**
 * Custom styles for the note text.
 *
 * @return {{cell: {lineHeight: number}}}
 */
const styles = () => ({
  cell: {
    lineHeight: 1.3,
  },
})

/**
 * Displays the {@param props.text} and {@param props.submitter} of the note.
 *
 * @param {Object} classes  styling for the cell
 * @param {Object} props    props for the cell
 *
 * @returns {JSX.Element}
 */
const ListItemCellNoteTextAndSubmitter = (classes, props = {}) => {
  const { text, submitter: { refs: { person: [person] } } } = props

  return (
    <>
      <Typography
        variant={'body2'}
        align={'justify'}
        color={'textBlack'}
        className={classes.cell}
      >
        {text || '-'}
      </Typography>
      <Typography
        variant={'body2'}
        component={'p'}
        color={'textSecondary'}
        className={`${classes.row} ${classes.cell}`}
      >
        {person?.value?.name || '-'}
      </Typography>
    </>
  )
}

export default CellHOC(ListItemCellNoteTextAndSubmitter, styles)
