import { Typography } from '@mui/material'
import CellHOC from '@platform/react/hoc/list/cell'

/**
 * Intended to display a team's sortOrder
 *
 * @param {Object} classes  styling for the cell
 * @param {Object} props    props for the cell
 *
 * @return {*}
 * @constructor
 */
const ListItemCellTeamSortOrder = (classes, props) => {
  const { sortOrder = '-' } = props

  return (
    <Typography>
      {sortOrder}
    </Typography>
  )
}

export default CellHOC(ListItemCellTeamSortOrder)
