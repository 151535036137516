/* global React */
import { Grid } from '@mui/material'
import { useMemoRef, useStyles } from '@platform/react/hook'
import { format } from 'date-fns'
import Text from 'ui/Element/Text/Simple'

/**
 * Returns the Date element's current theme styles.
 *
 * @param {object} theme  the application's current theme
 * @returns {object}      the Date element's styles
 */
const styles = theme => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    padding: theme.spacing(2),
  },
  message: {},
  ...theme.custom.dateMessage,
})

/**
 * Date Message Item.
 *
 * Message item used to display a date.
 *
 * @param {string} value  the date value
 * @returns {JSX.Element} the new Date element
 * @constructor
 */
const DateMessage = ({ value }) => {
  const classes = useStyles(styles)()

  return (
    <Grid
      item
      className={classes.root}
    >
      <Text
        className={classes.message}
        variant={'body2'}
        color={'textSecondary'}
      >
        { value ? format(value, 'd.MM.yyyy.') : 'Loading...'}
      </Text>
    </Grid>
  )
}

export default useMemoRef(DateMessage, [])
