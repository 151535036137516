/* global React */
import { Grid, Link, useTheme, getLuminance } from '@mui/material'
import { withStyles } from '@mui/styles'
import AppContext from '@platform/react/context/application'

const styles = theme => ({
  root: {
    marginLeft: 'auto',
    marginRight: '5%',
    cursor: 'pointer',
    color: theme.palette.black.main,
  },
  ...theme.custom.toggleLink,
})

/**
 * Returns a link element configured to be displayed in the application's toolbar
 *
 * @param {string} href     the path the link should redirect to
 * @param {string} text     the text to be displayed as the link
 * @param {object} classes      the component's style classes
 * @returns {JSX.Element}
 * @constructor
 */
const AppBarLink = ({ href, text, classes }) => {
  const { palette } = useTheme()
  const { actionBar: actionBarColor = palette.primary.dark } = palette.appBar
  const isAppBarBright = getLuminance(actionBarColor) > 0.5

  return (
    <Grid
      item
      className={classes.root}
    >
      <Link
        href={href}
        underline={'hover'}
        color={isAppBarBright ? 'primary' : 'inherit'}
      >
        {text}
      </Link>
    </Grid>
  )
}

/**
 * Returns a link that redirects the user to the login screen.
 *
 * @param {object} classes      the component's style classes
 * @returns {JSX.Element}
 * @constructor
 */
const LoginLink = ({ classes }) => {
  const { intl } = React.useContext(AppContext)

  const text = intl.translate('button.loginLinkMD', {
    ns: 'guest',
    _key: 'button.loginLinkMD',
    defaultValue: 'Already have an account? **LOG IN**',
    interpolation: { escapeValue: false },
    md: true,
  })

  // TODO: replace href value with route configuration
  return (
    <AppBarLink
      text={text}
      classes={classes}
      href={'/#/pre/guest/index'}
    />
  )
}

/**
 * Returns a link that redirects the user to the register screen.
 *
 * @param {object} classes      the component's style classes
 * @returns {JSX.Element}
 * @constructor
 */
const RegisterLink = ({ classes }) => {
  const { intl } = React.useContext(AppContext)

  const text = intl.translate('guest.text.registerLinkMD', {
    ns: 'custom',
    _key: 'guest.text.registerLinkMD',
    defaultValue: 'Don\'t have an account? **REGISTER**',
    interpolation: { escapeValue: false },
    md: true,
  })

  // TODO: replace href value with route configuration
  return (
    <AppBarLink
      text={text}
      classes={classes}
      href={'/#/pre/registration/register'}
    />
  )
}

/**
 * ToggleLink element
 *
 * Returns either {@link LoginLink} or {@link RegisterLink}, depending on whether the current route
 * is the login screen.
 *
 * @param {'login'|'register'} link whether to show the login or the register link
 * @param {object} classes          the component's style classes
 * @returns {JSX.Element}           the new CreateButton component
 * @constructor
 */
const ToggleLink = ({ login, classes }) => (login
  ? <RegisterLink classes={classes} />
  : <LoginLink classes={classes} />)

export default withStyles(styles)(ToggleLink)
