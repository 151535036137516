/* eslint-disable no-unused-vars */
/* global React */
import { useMemoRef } from '@platform/react/hook'
import { Box, Grid, LinearProgress } from '@mui/material'
import { withStyles } from '@mui/styles'
import FileImage from 'ui/Element/Attachment/Item/File'
import { useState } from 'react'
import OverflowTooltip from 'ui/Element/Text/OverflowTooltip'

/**
 * Returns the AttachmentListTemplateAction element's current theme styles.
 *
 * @param {object} theme  the application's current theme
 * @returns {object}      the AttachmentListTemplateAction component's styles
 */
const styles = theme => ({
  root: {
    width: 0,
    justifyContent: 'inherit',

    '&:not(:last-child)': {
      marginBottom: theme.spacing(0.4),
    },
  },
  wrapper: {
    flexBasis: 0,
    position: 'relative',
    flexWrap: 'nowrap',
    alignItems: 'center',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.divider,
    borderRadius: 8,
    padding: theme.spacing(1, 1.5),
  },
  image: {
    height: '2rem',
    width: '2rem',
    marginRight: theme.spacing(1),
  },
  text: {
    maxWidth: '18.75rem', // 300px
    display: 'inline-block',
    whiteSpace: [['nowrap'], '!important'],
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  progress: {
    left: 0,
    position: 'absolute',
    width: '100%',
    height: '100%',
    opacity: 0.4,
    borderRadius: 8,
  },
  ...theme.custom.attachmentListTemplateAction,
})

/**
 * AttachmentListTemplateAction attachment component
 *
 * Displays a clickable representation of a file that would allow a specific action to be performed,
 * such as playing it in the case of a video or audio file, or downloading it.
 *
 * @param {object} attachment     the attached file
 * @param {object} types          the types for the {@link FileImage} element
 * @param {boolean} showProgress  whether to show a progress bar
 * @param {object} classes        styling
 */
const AttachmentListTemplateAction = ({ attachment, types, showProgress = true, classes, ...props }) => {
  const { events, download = true } = props
  const { value: item } = attachment
  const [attachmentState, setAttachmentState] = useState({})
  const { progress = 0 } = attachmentState || {}
  const isLoading = progress !== 0 && progress !== 100

  return (
    <Grid
      item
      container
      xs={12}
      sm={12}
      md={12}
      lg={12}
      xl={12}
      title={item.name}
      className={classes.root}
    >
      <Grid
        item
        container
        className={classes.wrapper}
      >
        <Box className={classes.image}>
          <FileImage
            types={types}
            download={download}
            attachment={attachment}
            events={{
              onClick: events?.onClick,
              onState: setAttachmentState,
            }}
            xs={true}
            sm={true}
            md={true}
            xl={true}
            lg={true}
          />
        </Box>
        <Grid
          item
          xs={'auto'}
          sm={'auto'}
          md={'auto'}
          lg={'auto'}
          xl={'auto'}
          color={'textPrimary'}
          className={classes.text}
        >
          <OverflowTooltip>
            {item.name}
          </OverflowTooltip>
        </Grid>
        {isLoading && showProgress && (
          <LinearProgress
            value={progress}
            color={'inherit'}
            variant={'determinate'}
            className={classes.progress}
          />
        )}
      </Grid>
    </Grid>
  )
}

export default useMemoRef(withStyles(styles)(AttachmentListTemplateAction), props => [
  props.classes,
])
