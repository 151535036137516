import { Typography } from '@mui/material'
import CellHOC from '@platform/react/hoc/list/cell'

/**
 * Intended to display the contact channels of the user
 *
 * @param {Object} classes  styling for the cell
 * @param {Object} props    props for the cell
 *
 * @returns {JSX.Element}
 */
const ListItemCellPersonContactChannels = (classes, props = {}) => {
  const { contactChannels } = props
  return contactChannels ? contactChannels.map((channel, index) => (
    <Typography
      key={index}
      component={'span'}
      variant={'caption'}
      title={channel.value}
      className={classes.row}
    >
      {channel.value}
    </Typography>
  )) : []
}

export default CellHOC(ListItemCellPersonContactChannels)
