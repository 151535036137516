import { Box } from '@mui/material'
import CellHOC from '@platform/react/hoc/list/cell'
import SimpleImage from 'ui/Element/Image/Simple'

const styles = theme => ({
  root: {
    width: theme.typography.pxToRem(theme.typography.fontSize * 3),
    height: theme.typography.pxToRem(theme.typography.fontSize * 3),
    display: 'flex',
  },
})

/**
 * Display the product image for a given device.
 *
 * @param {Object} classes  styling for the cell
 * @param {Object} props    props for the cell
 *
 * @returns {JSX.Element}
 */
const ListItemCellDeviceImage = (classes, props = {}) => {
  const { product, api } = props
  const ref = product?.key || null

  return (
    <Box
      className={classes.root}
    >
      <SimpleImage
        api={api}
        value={ref}
        fallback={'GenericDevice.png'}
      />
    </Box>
  )
}

export default CellHOC(ListItemCellDeviceImage, styles)
