/* global React */
/* eslint-disable object-curly-newline,implicit-arrow-linebreak */
import { useContext, useRef } from 'react'
import { Box, Grid, Paper } from '@mui/material'
import { ArrowForward as ArrowForwardIcon } from '@mui/icons-material'
import PlatformEvent from 'lib/util/event'
import { useMemoRef, useStyles } from '@platform/react/hook'
import ApplicationContext from '@platform/react/context/application'
import ChildrenHOC from '@platform/react/hoc/children'
import { countToLabel } from 'ui/Component/Subbed/Widget/Narrow'
import IconSvg from 'ui/Element/Icon/Svg'
import ErrorBoundary from 'ui/Error'

const styles = (theme, { variant }) => ({
  root: {
    height: '72px',
    minWidth: '272px',
    width: '100%',
    padding: '20px',
    borderRadius: '12px',
    border: `1px solid ${theme.palette.border.main}`,
    boxShadow: 'none',
    justifyContent: 'space-between',
    backgroundColor: variant === 'dark'
      ? theme.palette.primary.main
      : theme.palette.common.white,
  },
  icon: {
    backgroundColor: variant === 'dark'
      ? theme.palette.primary.main
      : theme.palette.background.light,
    padding: '4px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '4px',
    marginRight: '16px',
  },
  forward: {
    cursor: 'pointer',
    height: '24px',
    width: '24px',
    marginLeft: '28px',
    transition: 'transform 0.2s',
    '& .MuiSvgIcon-root': {
      color: theme.palette.common.black,
    },
    '&:hover': {
      transform: 'translate(5px)',
    },
  },
  side: {
    display: 'flex',
    alignItems: 'center',
  },
  body: {
    color: theme.palette.common.black,
    fontSize: '14px',
    fontWeight: 500,
  },
  count: {
    color: theme.palette.common.black,
    fontSize: '18px',
    fontWeight: 700,
  },
  ...theme.custom.widgetLink,
})

/**
 * Link Widget
 *
 * Take a look at {@code GaiaSubbedNarrowWidget} for
 * explanation of the different states.
 *
 * @param forwardedRef
 * @param {("light"|"dark")} variant  the variant of the widget
 * @param {String} icon               icon for thw widget
 * @param {Object} props              additional props
 * @returns {JSX.Element}
 * @constructor
 */
const SubbedLinkWidget = ({ forwardedRef, variant = 'light', icon = 'device', ...props }) => {
  const {
    Count,
    ZeroBody,
    OtherBody,
  } = props.childrenObject
  const { events } = props
  const classes = useStyles(styles)({ variant })
  const { socket: { ref: socket } } = useContext(ApplicationContext)
  const socketKey = useRef(null)
  const customEvent = new CustomEvent('load', { detail: { key: socketKey.current } })
  const platformEvent = new PlatformEvent(customEvent)
  const event = useRef(() => platformEvent)
  const [count, setCount] = React.useState(0)

  React.useLayoutEffect(() => {
    let isMounted = true;

    /**
     * Fetching of initial data
     */
    (async () => {
      socketKey.current = `${events.onOpen(null)}_${props?.documentType}`
      const result = isMounted && await props.events.onPublication(event.current())

      if (isMounted) {
        setCount(result.total.count)
      }
    })()

    /**
     * Subscription based data
     * @returns {Promise<void>}
     */
    const listener = async () => {
      const result = isMounted && await props.events.onPublication(event.current())

      if (isMounted) {
        setCount(result.total.count)
      }
    }

    socket.sub(socketKey.current, () => {
      socket.on(socketKey.current, listener)
    })

    return () => {
      isMounted = false
      socket.unsub(socketKey.current, () => {
        socket.off(socketKey.current, listener)
      })
    }
  }, [])
  return (
    <ErrorBoundary>
      <Grid
        ref={forwardedRef}
        component={Paper}
        className={classes.root}
        container
        item
      >
        <Grid
          className={classes.side}
          item
        >
          <Box className={classes.icon}>
            <IconSvg
              raw={false}
              icon={icon}
              variant={'outlined'}
              color={'common.black'}
            />
          </Box>
          {count > 0
            ? <>
              <OtherBody
                className={classes.body}
                value={countToLabel('body', props.label, count)}
              />
            </>
            : <ZeroBody
              className={classes.body}
              hidden={false}
              value={countToLabel('title', props.label, 0)}
            >
            </ZeroBody>
          }
        </Grid>
        <Grid
          className={classes.side}
          item
        >
          <Count
            className={classes.count}
            value={count}
          />
          <Box
            className={classes.forward}
            onClick={e => events?.onClick(e)}
          >
            <ArrowForwardIcon />
          </Box>
        </Grid>
      </Grid>
    </ErrorBoundary>
  )
}

export default useMemoRef(ChildrenHOC(SubbedLinkWidget), [])
