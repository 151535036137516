import { Typography } from '@mui/material'
import CellHOC from '@platform/react/hoc/list/cell'

/**
 * Intended to display the assignee of the ticket.
 *
 * @param {Object} classes  styling for the cell
 * @param {Object} props    props for the cell
 *
 * @returns {JSX.Element}
 */
export const ListItemCellTicketAssignee = (classes, props = {}) => {
  const {
    assignee: {
      refs: {
        person: [{
          value: {
            firstName = '',
            lastName = '',
          } = {},
        } = {}] = [],
      } = {},
    } = {},
    team: {
      value: {
        name: teamName = '-',
      } = {},
    } = {},
  } = props

  const assigneeName = firstName || lastName ? `${firstName} ${lastName}` : teamName
  const assigneeTeamName = lastName ? teamName : ''

  return (
    <>
      <Typography
        color={'black'}
        variant={'body2'}
        className={classes.row}
        sx={{ fontWeight: 700 }}
      >
        {assigneeName}
      </Typography>
      <Typography
        variant={'caption'}
        color={'textSecondary'}
        className={classes.row}
      >
        {assigneeTeamName}
      </Typography>
    </>
  )
}

export default CellHOC(ListItemCellTicketAssignee)
