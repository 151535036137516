import CellHOC from '@platform/react/hoc/list/cell'
import LabeledHOC from '@platform/react/hoc/list/labeled'
import { ListItemCellUserName } from 'ui/Element/List/Item/Cell/User/Name'

export default CellHOC(
  LabeledHOC(
    props => props.labels.name,
    ListItemCellUserName,
  ),
)
