/* eslint-disable object-curly-newline,no-undefined,no-nested-ternary,implicit-arrow-linebreak,
no-return-assign */
/* global React */
import { Suspense, useEffect, useMemo, useRef, useState, useTransition } from 'react'
import { Autocomplete, Box, Typography } from '@mui/material'
import { debounce, getFirstItem, isArr, isObj, isStr } from 'lib/util'
import PlatformEvent from 'lib/util/event'
import { preload, useStyles } from '@platform/react/hook'
import { getOptionLabel } from '@platform/react/hoc/autocomplete/option'
import BasicInput from 'ui/Component/Field/Autocomplete/BasicInput'
import TextFieldInput from 'ui/Component/Field/Autocomplete/TextFieldInput'
import ErrorBoundary from 'ui/Error'
import SearchInput from 'ui/Component/Field/Autocomplete/SearchInput'

const styles = theme => ({
  option: {
    justifyContent: [['space-between'], '!important'],
  },
  separator: {
    borderColor: theme.palette.divider,
    borderStyle: 'solid',
    borderWidth: [[0, 0, 1, 0]],
    display: 'flex',
    margin: [[4, 0]],
  },
  separatorLabel: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  link: {
    position: 'absolute',
    right: '0',
    width: 'initial',
  },
  ...theme.custom.autocompleteField,
})

/**
 * Returns the component's options by returning its {@param selection} and {@param value} together
 * as an array, depending on whether they are defined. It also filters duplicate entries.
 *
 * @param {object[]} selection  the defined component's static options
 * @param {object|Array} value        the current component's value
 * @returns {*[]}
 * @private
 */
const _getOptions = (selection, value) => (value && (
  // value could also be an array if we have multiple === true
  !isArr(value)
    ? !selection?.find(item => item.key === value.key)
    : !selection?.filter(item => value.map(x => x.key).includes(item.key))?.length)
  ? selection.concat(isArr(value) ? value : [value])
  : selection || (value ? [value] : []))

/**
 * Returns the proper {@param value} depending on whether {@param props.multiple} is enabled
 * or not.
 *
 * @param {Object[]|Object} value   the value to set the input field to
 * @param {Object} props            incoming props
 * @param {Boolean} props.multiple  whether the Autocomplete should accept multiple values
 * @return {*|*[]}
 * @private
 */
const _getParsedValue = (value, props) => (props.multiple
  ? (!value && []) || (isArr(value) && value) || [value]
  : getFirstItem(value))

/**
 * Function to render {@param Template} as an option.
 *
 * @param {Function} Template   component to render the option
 * @param {Object} events       events for the component
 * @param {Object} classes      styling for the component
 * @param {Object} labels       labels for each option
 * @param {string} noDataLabel  label to display if there are no options
 * @param {Object} optionProps  generic props for each option
 * @returns {function(*, *): null|JSX.Element}
 */
const createOption = ({ View: Template, events, classes, labels, noDataLabel, ...optionProps }) =>
  (rootProps, option) => (isArr(option) && !option?.length ? null : (
    <Box
      component={'li'}
      className={classes.option}
      {...rootProps} // contains MUIs option styles, needs to come after our className
      key={option.key} // needs to come after rootProps, otherwise value.name will be used as a key
      // Manually add/remove focused class on enter/leave, MUI autocomplete does not trigger this
      // when leaving the list box, so we need to do it manually
      onMouseLeave={(e) => { e.target.classList.remove('Mui-focused') }}
      onMouseEnter={(e) => { e.target.classList.add('Mui-focused') }}
    >
      {option.key === -1
        ? <Typography>{noDataLabel || 'No results'}</Typography>
        : <Template
          option={option}
          labels={labels}
          events={events}
          {...optionProps}
        />
      }
    </Box>
  ))

/**
 * Getting the correct clearedOption amongst the available options
 *
 * if {@param clearedOption} is a {@type String}, it's expected to be the {@code key} of the
 * correct option. In that case, search it in the available {@param options} and return it.
 * If it's an {@type Object} itself, it's expected to be the correct option, just return it.
 *
 * @param {Object[]} options            available options
 * @param {String|Object} clearedOption options to set the input to if cleared
 * @return {*}
 */
const _getClearedOption = (options, clearedOption) => (isObj(clearedOption)
  ? clearedOption
  : options.find(x => x.key === clearedOption))

/**
 * Simple Autocomplete UI Component
 *
 * Displays a dropdown field whose items are obtained by performing an asynchronous
 * {@param events.onOpen} call the first time it is clicked.
 *
 * {@param events.onOpen} must return an array of items with key and value properties.
 *
 * If the field has {@param value} set before the call to {@param events.onOpen} could be
 * made, {@param value} will also be used as the single dropdown element until the call is made.
 *
 * @param {object} events                   an object containing handlers for the events fired by
 *                                          the component
 * @param {function} [events.onOpen]        the function called the first time the component is
 *                                          opened
 * @param {function} [events.onChange]      the function called whenever the field value changes
 * @param {function} [events.getSelection]  a function called at on mount to alternatively obtain
 *                                          {@param props.selection}
 * @param {function} [events.onLinkChange]  function called when the input changes responsible for
 *                                          returning the new link in case {@param openInNewTab} is
 *                                          true
 * @param {object|array} [value]            a starting item to set for the component
 * @param {string} [value.key]              the key used to match the dropdown item
 * @param {object} [value.value]            the content's of the item
 * @param {string} [value.label]            the text to be displayed when the item is selected
 * @param {string} [value.value.name]       the text to be displayed when the item is selected
 * @param {string} helperText               the text to display when {@param props.error} is true
 * @param {boolean} oneShot                 whether the {@param events.onOpen} handler should be
 *                                          fired every time the dropdown is opened
 * @param {object} [parentClasses]          list of classes to overwrite the styling
 * @param {number} [maxOptions]             maximum number of options to display
 * @param {boolean} [basic]                 whether to use {@link InputBase} instead of
 *                                          {@link TextField}
 * @param {object} props                    additional component's properties
 * @param {boolean} [props.selection]       a static selection of items to prepend to the dynamic
 *                                          ones
 * @param {number} [props.clearedOption]    the option to automatically set whenever the field is
 *                                          cleared
 * @param {boolean} [valueIsOption=true]    whether the selected value should be explicitly added at
 *                                          all times to the list of selectable options
 * @param {boolean} [props.freeText]        whether it should allow the user to write anything
 * @param {boolean} [props.disabled]        whether it should allow interaction
 * @param {string} [props.label]            the label's text (shown above option)
 * @param {string} [props.subLabel]         the subLabel's text (shown below option)
 * @param {string} [props.noDataLabel]      label to display if there are no search results
 * @param {boolean} [props.error]           whether it should inform about an error
 * @param {boolean} [props.focused]         whether it should be focused from the start
 * @param {boolean} [props.autoComplete]    autofill field value
 * @param {Number} [props.debounced=500]    time in ms for the debounced input field
 * @param {Boolean} [props.multiple]        whether the Autocomplete should accept multiple values
 * @param {number} [props.limitTags]        limits the number of tags to be displayed. Is only
 *                                          applicable if {@param props.multiple} is {@code true}.
 * @param {Boolean} [props.openInNewTab]    whether a link icon end adornment should be shown that
 *                                          links to the selected item
 * @param {Boolean} [props.filterLocally]   whether to filter the options locally in addition to
 *                                          to the onOpen handler. Use this if the options we
 *                                          display are static
 * @param {Boolean} [props.sendSearchTerm]  whether to include the original search term as
 *                                          {@code term} in the {@code onChange} event
 * @param {string[]} [props.optionLabels]   list of label names to be used for each options
 * @param {Object} [props.optionProps]      generic props for each options. Useful if one template
 *                                          is used for multiple option types and a distinction
 *                                          needs to be made
 * @param {String} props.view               template to use for each option
 * @param {object} ref
 * @return {JSX.Element}
 * @constructor
 */
const AutocompleteField = (
  {
    events,
    value,
    helperText,
    oneShot,
    classes: parentClasses,
    maxOptions,
    inputVariant = 'default',
    ...props
  }, ref,
) => {
  const classes = parentClasses ?? useStyles(styles)()

  const {
    freeText,
    clearedOption,
    valueIsOption = true,
    openInNewTab = false,
    searchIcon = false,
    sendSearchTerm = false,
  } = props

  const [, startTransition] = useTransition()
  const [selection, setSelection] = useState(props.selection || [])
  const [parsedValue, setParsedValue] = useState(() => _getParsedValue(value, props))
  const [inputValue, setInputValue] = useState(null)
  const [options, setOptions] = useState(() => _getOptions(selection, valueIsOption && parsedValue))
  const [open, setOpen] = useState(false)
  const [loaded, setLoaded] = useState(false)
  const [link, setLink] = useState(false)
  const loading = open && !loaded
  const mouseRef = useRef({})

  /**
   * Grabbing each {@param props.optionLabels} from the props and pass them to the option as
   * {@code labels} to it.
   */
  const optionLabels = !props.optionLabels
    ? {}
    : Object.keys(props.optionLabels).reduce((acc, key) => ({
      ...acc, [key]: props[props.optionLabels[key]],
    }), {})

  const optionProps = props.optionProps ?? {}

  const View = useMemo(() => preload(props.view), [props.view])
  const renderOptionFn = createOption({
    View,
    events,
    classes,
    labels: optionLabels,
    noDataLabel: props.noDataLabel,
    ...optionProps,
  })

  /**
   * Handle clicking on an item in the list.
   *
   * @param {SyntheticInputEvent} event event that triggered this callback
   * @param {String} selectedItem       selected element
   * @return {Promise<void>}
   */
  const handleChange = async (event, selectedItem) => {
    const item = selectedItem === null && clearedOption
      ? _getClearedOption(options, clearedOption)
      : selectedItem

    // If item is a string, we have not selected an option from the options list, but
    // rather hit enter with the given search term
    if (item && isStr(item) && events?.onKey?.({ ...event, detail: { term: item.trim() } })) {
      return undefined
    }

    setParsedValue(item)
    openInNewTab && setLink(events?.onLinkChange?.(item ?? false))
    startTransition(() => events.onChange(new PlatformEvent(event, {
      item: item && isStr(item) ? item.trim() : item,
      options,
      ...sendSearchTerm && { term: inputValue?.trim() },
    })))
  }

  /**
   * Handle changes in the input field.
   *
   * @param {SyntheticInputEvent} event event that triggered this callback
   * @param {String} term               term typed into the field
   * @return {Promise<undefined|boolean>}
   */
  const handleInputChange = async (event, term) => {
    // We clicked on an item in the list, no need to research
    if (event?.type === 'click') return undefined

    // If the search term is the same as the initial value prop, we just rendered the component
    const defaultValue = getFirstItem(value)
    if (term === defaultValue?.value?.name || term === defaultValue) return undefined

    setInputValue(term)

    if (term === '') return undefined

    setLoaded(false)
    // Properly escape double quotes, otherwise server crashes.
    const parsedTerm = term.replace(/"/g, '\\"')
    const result = await events.onOpen(new PlatformEvent(event, { term: parsedTerm.trim() }))

    setOptions([
      ...selection,
      ...Object.keys(result).map(k => result[k]),
    ])
    setLoaded(true)

    return true
  }

  /**
   * Debouncing {@link handleInputChange} by {@param props.debounced}.
   *
   * @type {(function(): void)|*}
   */
  const debouncedInputChange = debounce(handleInputChange, props.debounced)

  selection?.length
  && clearedOption >= 0
  && parsedValue === null
  && setParsedValue(selection[clearedOption])

  useEffect(() => {
    (async () => {
      const newSelection = await events.getSelection?.(null)
      newSelection?.length && setSelection(newSelection)
      newSelection?.length && setOptions(_getOptions(newSelection, valueIsOption && parsedValue))
    })()
  }, [])

  /**
   * If {@param props.selection} changes (from the outside), we need to determine
   * the {@code options} and {@code selection} states again. This also includes
   * empty states. Hence, don't check for {@param props.selection.length}
   * and use {@code getFistItem(value)} instead of {@code parsedValue}.
   */
  useEffect(() => {
    (async () => {
      const newSelection = props.selection || []
      setSelection(newSelection)
      setOptions(_getOptions(newSelection, valueIsOption && getFirstItem(value)))
    })()
  }, [props.selection])

  useEffect(() => {
    let active = true

    if (!open || (oneShot && loaded)) {
      return undefined
    }

    setLoaded(false);

    (async (e) => {
      /**
       * If we fetch data continuously, we pass the current options to the event handler so that we
       * can decide there whether we need new data.
       */
      const result = events.onOpen && !oneShot
        ? await events.onOpen(e, options)
        : await events.onOpen(e)

      /**
       * If we deal with an array of objects, we need to filter the union of {@link selection} and
       * {@link result} to only include each object once.
       */
      active && result.length && setOptions(() => {
        const hasObjects = selection.every(i => typeof i === 'object')
        let concatenatedState = [
          ...selection,
          ...parsedValue && valueIsOption ? [parsedValue] : [],
          ...Object.keys(result).map(k => result[k]),
        ]

        /**
         * If we use multiple value, the default value (one element of {@link concatenatedState})
         * will be an array itself, we need to merge its value with the rest of the array to avoid
         * unique key errors.
         */
        concatenatedState = props.multiple
          ? concatenatedState.reduce((acc, option) => (isArr(option)
            ? acc.concat(option)
            : [...acc, option]), [])
          : concatenatedState

        const keys = {}
        return hasObjects
          ? concatenatedState.filter(({ key }) => (keys[key] ? false : keys[key] = true))
          : concatenatedState
      })

      // fallback to defaultValues
      parsedValue
      && !result.length
      && setOptions(_getOptions(selection, valueIsOption && parsedValue))
      // set default empty, if no results && no defaults
      !parsedValue && !result.length && setOptions([
        { key: -1, value: 'No results', disabled: true },
      ])

      setLoaded(true)
    })()

    return () => {
      active = false
    }
  }, [open])

  useEffect(() => {
    const newValue = _getParsedValue(value, props) || (freeText ? '' : null)
    setParsedValue(newValue)

    openInNewTab && setLink(events?.onLinkChange?.(newValue ?? false))

    newValue
    && !options?.length
    && setOptions(_getOptions(selection, valueIsOption && newValue))
  }, [value])

  useEffect(() => {
    if (inputValue === '') { setOptions(_getOptions(selection, null)) }
  }, [inputValue])

  const separator = <span className={classes.separator}></span>

  /**
   * Compare equality between {@param option} and {@param val}
   *
   * @param option
   * @param val
   * @returns {null|boolean}
   */
  const isOptionEqualToValue = (option, val) => val && (
    Array.isArray(val)
      ? val[0]?.key
        ? val[0].key === option.key
        : val[0].value === option.value
      : val.key
        ? val.key === option.key
        : val.value === option.value
  )

  return (
    <ErrorBoundary>
      <Autocomplete
        multiple={props.multiple || false}
        limitTags={props.limitTags || -1}
        disabled={props.disabled}
        fullWidth
        open={open}
        onKeyDownCapture={(e) => {
          // If we hit enter, but the mouse is currently not inside the list box,
          // just close the box and do not go to the selected item
          if (e.key === 'Enter' && mouseRef?.current?.box === false && mouseRef?.current?.field === false) {
            e.stopPropagation()
            e.target?.blur() // close the dropdown
          }
        }}
        includeInputInList={true}
        onMouseEnter={ () => { mouseRef.current.field = true } }
        onMouseLeave={ () => { mouseRef.current.field = false } }
        onOpen={ () => setOpen(true) }
        onClose={ () => setOpen(false) }
        isOptionEqualToValue={isOptionEqualToValue}
        // Needed to dynamically update options
        // https://stackoverflow.com/questions/62323166/material-ui-autocomplete-not-updating-options
        filterOptions={
          /**
           * Filtering down options as one types is usually done via the {@code onOpen} handler by
           * returning a subset with the search term taken into consideration. If the handler
           * returns a static list of options (and doesn't take the search term into consideration),
           * the options never get filtered. Therefore, let MUI filter the options if we have a
           * search term. However, this breaks if the {@code onOpen} handler returns a subset of
           * items where the search term is not part of the name, like
           * - SI -> serial number
           * - Org -> number
           * So let's only do this if we specifically told the component to do so with
           * {@code props.filterLocally}.
           *
           * TODO: We can make the {@code prop.filterLocally} obsolete by providing a richer filter
           *   function to also match the cases described above (once we refactor this?)
           *   Alternatively, static onOpen handlers (like {@link listStatus}) can be expanded to
           *   take the search term into consideration
           */
          (unfilteredOptions, state) => {
            const slicedOptions = maxOptions
              ? unfilteredOptions.slice(0, maxOptions)
              : unfilteredOptions

            return slicedOptions
              .filter((x) => {
                const optionLabel = getOptionLabel(x)
                return optionLabel.length && props?.filterLocally && state?.inputValue
                  ? optionLabel.toLowerCase()
                    .includes(state.inputValue.toLowerCase())
                  : true
              })
          }
        }
        getOptionDisabled={option => option.disabled}
        getOptionLabel={getOptionLabel}
        groupBy={option => (selection?.length && option._rev ? separator : '')}
        classes={{
          groupLabel: classes.separatorLabel,
          ...classes.root && { root: classes.root },
          ...classes.popper && { popper: classes.popper },
        }}
        sx={{
          // Resetting MUIs endAdornment styles if we have a link (so it doesn't stick to the right)
          ...!link ? null : {
            '& .MuiAutocomplete-endAdornment': {
              position: 'unset!important',
              right: 'unset!important',
              top: 'unset!important',
            },
          },
        }}
        // filter out empty selection, otherwise we'll throw unique key error
        renderOption={renderOptionFn}
        // We need to wrap the listbox component into a suspense because we preload the option
        // template.
        ListboxComponent={
          React.forwardRef((listboxProps, forwardedRef) => (
            <Suspense fallback={''}>
              <ul ref={forwardedRef} {...listboxProps} />
            </Suspense>
          ))
        }
        options={options}
        loading={loading}
        freeSolo={freeText}
        onChange={handleChange}
        {...freeText && { onInputChange: debouncedInputChange }}
        value={parsedValue}
        ListboxProps={{
          onMouseEnter: () => { mouseRef.current.box = true },
          onMouseLeave: () => { mouseRef.current.box = false },
          style: {
            backgroundColor: 'white', // '#fafafa',
          },
        }}
        renderInput={renderInputProps =>
          ((inputVariant === 'basic' && <>
            <BasicInput
              {...renderInputProps}
              props={props}
            />
          </>) || (inputVariant === 'search' && <>
            <SearchInput
              {...renderInputProps}
              props={{
                ...props,
                ref,
                loading,
                events: {
                  onSearch: event => events.onChange(new PlatformEvent(event, {
                    item: parsedValue || inputValue || [],
                    options,
                  })),
                },
                classes,
                helperText,
              }}
            />
          </>)) || (inputVariant === 'default' && <>
            <TextFieldInput
              {...renderInputProps}
              props={{
                ...props,
                ref,
                link,
                loading,
                ...searchIcon && inputValue && {
                  icon: true,
                  parentEvents: {
                    onChange: () => events.onChange(new PlatformEvent('click', {
                      item: parsedValue || inputValue,
                      options,
                    })),
                  },
                },
                classes,
                helperText,
                parsedValue,
                openInNewTab,
              }}
            />
          </>)
        }
      />
    </ErrorBoundary>
  )
}

export default AutocompleteField
