/* global G, React */
import useMemoRef from '@platform/react/hook/useMemoRef'
import ApplicationContext from '@platform/react/context/application'

const { useContext, useEffect } = React

/**
 * Attachment Gallery component
 *
 * Asks the attachment adapter to create a new group on mount and asks it to destroy it on unmount.
 *
 * Used to define sections of the view in which an attachment group is available.
 *
 * @param {string} name     the attachment group identifier
 * @param {string} cache    whether the attachment group itself holds a cache
 * @param {string} undo     whether the undo flag is set when the group changes
 * @param {Object} filter   additional filters for the group
 * @returns {JSX.Element}
 * @constructor
 */
const AttachmentGroup = ({ forwardedRef, name: groupName, cache, undo, filter }) => {
  const { eventBus } = useContext(ApplicationContext)

  useEffect(() => {
    eventBus.dispatch(eventBus.type(G.ATTACHMENT, G.INIT),
      {
        [G.DATA]: {
          [G.GROUP]: groupName,
          [G.CACHE]: cache,
          [G.UNDO]: undo,
          [G.FILTER]: filter,
        },
      })
    return () => eventBus.dispatch(eventBus.type(G.ATTACHMENT, G.DESTROY, groupName))
  }, [])

  return (
    <div
      ref={forwardedRef}
      style={{ display: 'none' }}
    />
  )
}

export default useMemoRef(AttachmentGroup, [])
