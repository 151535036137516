import CellHOC from '@platform/react/hoc/list/cell'
import LabeledHOC from '@platform/react/hoc/list/labeled'
import { ListItemCellUserStatus, styles } from 'ui/Element/List/Item/Cell/User/Status'

export default CellHOC(
  LabeledHOC(
    props => props.labels.status,
    ListItemCellUserStatus,
  ), styles,
)
