import CellHOC from '@platform/react/hoc/list/cell'
import LabeledHOC from '@platform/react/hoc/list/labeled'
import { ListItemCellRequestRecipient } from 'ui/Element/List/Item/Cell/Request/Recipient'

export default CellHOC(
  LabeledHOC(
    props => props.labels.request,
    ListItemCellRequestRecipient,
  ),
)
