import { Grid } from '@mui/material'
import { withStyles } from '@mui/styles'
import { useMemoRef } from '@platform/react/hook'
import DecoratedText from 'ui/Element/Text/Decorated'

/**
 * Styles for the component
 *
 * @param {Object} theme
 * @return {Object}
 */
const styles = theme => ({
  root: {
    backgroundColor: '#EEE',
    borderRadius: 16,
    padding: theme.spacing(1, 2),
    margin: theme.spacing(1, 0),
    textTransform: 'uppercase',
  },
  ...theme.custom.stripeSegment,
})

/**
 * Stripe Segment element.
 *
 * Prints header text within a decorated single row.
 *
 * @param {string} value    the segment's title text
 * @param {object} [labels] an object containing possible values mapped to labels that would be used
 *                          as a title for the segment headers of those values
 * @param {object} [classes]  an object containing the element's css classes
 * @returns {JSX.Element}
 * @constructor
 */
const StripeSegment = ({ value, labels = {}, classes = {} }) => (
  <Grid
    item
    className={classes.root}
    role={'rowheader'}
    xs={12}
  >
    <DecoratedText
      color={'textPrimary'}
      decorators={{ bold: true }}
    >
      {labels[value] || value}
    </DecoratedText>
  </Grid>
)

export default useMemoRef(withStyles(styles)(StripeSegment), [])
