import { Box, Typography } from '@mui/material'
import CellHOC from '@platform/react/hoc/list/cell'
import { def, getFirstItem } from 'lib/util'
import internal from 'model/organisation/collection/internal'
import { empty } from 'lib/util/object'

/**
 * Special organisation name styles
 *
 * @param theme
 *
 * @return {
 * {subRow: {alignItems: string, gap: string, display: string},
 * root: {overflow: string, flexDirection: string, display: string, justifyContent: string},
 * name: {fontWeight: number}, type: {color}, validation: {color}}
 * }
 */
const styles = theme => ({
  root: {
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
  },
  subRow: {
    gap: '5px',
    display: 'flex',
    alignItems: 'center',
  },
  name: {
    fontWeight: 700,
  },
  type: {
    color: theme.palette.info.main,
  },
  validation: {
    color: theme.palette.signal.main,
  },
  duplicateOf: {
    color: theme.palette.warning.main,
  },
})

/**
 * Intended to display the name of the organisation alongside a status. Also attempts to display
 * additional information about the organisation, like {@code toBeValidated} if the user in
 * question passed the acl check.
 *
 * @param {Object} props    element's properties
 * @param {Object} classes  element's style classes
 *
 * @returns {JSX.Element}
 */
const ListItemCellOrganisationWithStatus = (classes, props = {}) => {
  const {
    acl,
    type,
    name,
    typeInfo,
    parent: { key: parentId = null, refs: { parent: baseParent = null } = {} } = {},
    duplicateOf = {},
    toBeValidated,
    duplicateOfInfo,
    supportUnitInfo,
    toBeApprovedInfo,
    toBeValidatedInfo,
    support: { provides = null } = {},
  } = props

  const baseParentId = getFirstItem(baseParent)?.key || null

  const displayApproval = internal.some(x => x.key === parentId || x.key === baseParentId)
  const displayValidation = def(toBeValidated) && acl !== false

  const subRow = []
  const organisationType = provides
    ? supportUnitInfo : typeInfo?.[type] || null

  organisationType
    && subRow.push({ class: classes.type, value: organisationType })

  displayValidation
    && subRow.push({ class: classes.validation, value: toBeValidatedInfo })

  !displayValidation
    && displayApproval
    && subRow.push({ class: classes.validation, value: toBeApprovedInfo })

  !displayValidation
    && !displayApproval
    && !empty(duplicateOf)
    && subRow.push({
      class: classes.duplicateOf,
      value: duplicateOfInfo,
      suffix: duplicateOf.value?.name,
    })

  return (
    <>
      <Typography className={`${classes.row} ${classes.name}`}>
        {name}
      </Typography>
      <Box className={`${classes.subRow}`}>
        {subRow.map((item, index) => (
          <Typography
            key={index}
            variant={'caption'}
            color={'textSecondary'}
            className={`${classes.row} ${item.class}`}
          >
            {item.value} {item?.suffix}
          </Typography>
        )).reduce((result, item) => (result.length ? [result, ' ● ', item] : [item]), [])}
      </Box>
    </>
  )
}

export default CellHOC(ListItemCellOrganisationWithStatus, styles)
