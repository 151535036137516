/* eslint-disable no-nested-ternary */
import OptionHOC from '@platform/react/hoc/autocomplete/option'
import { Typography } from '@mui/material'

const styles = theme => ({})

/**
 * Intended to display a salesOrganisation alongside a customer number.
 *
 * @param {Object} classes  styling for the option
 * @param {Object} props    props for the option
 *
 * @return {*}
 * @constructor
 */
const SalesOrganisation = (classes, props = {}) => {
  const { option, labels } = props || {}

  const { salesOrganisation: salesOrganisationLabel, customerNumber: customerNumberLabel } = labels
  const { value } = option

  const [salesorganisation, customerNumber] = value.split(',')

  return (
    <Typography>
      {`${salesOrganisationLabel}: ${salesorganisation} / ${customerNumberLabel}: ${customerNumber}`}
    </Typography>
  )
}

export default OptionHOC(SalesOrganisation, styles)
