import { Box, useTheme } from '@mui/material'
import CellHOC from '@platform/react/hoc/list/cell'
import OverflowTooltip from 'ui/Element/Text/OverflowTooltip'
import { useContext, useEffect, useState } from 'react'
import ApplicationContext from 'platform/react/context/application'

/**
 * Cell styles
 */
const styles = () => ({
  label: {
    maxWidth: 'calc(100%)',
  },
  description: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '0.5rem',
  },
})

/**
 * Get the translation for displaying the attachment
 * label (e.g. "2 new files")
 *
 * @param {Object} intl   intl adapter
 * @param {number} count  the number of attachments
 * @returns {*}
 */
const _getAttachmentLabel = (intl, count) => intl._t('label.newAttachment', {
  _key: 'label.newAttachment',
  ns: 'common',
  count,
})

/**
 * Displays the description of the message notification
 *
 * @param {Object} classes  styles for the component
 * @param {Object} props    props for the component
 * @return {JSX.Element}
 * @constructor
 */
const ListItemCellNotificationDescription = (classes, props = {}) => {
  const theme = useTheme()

  const [cellState, setCellState] = useState({})

  const { title, text, submitTimestamp, partner, events, type, attachments = [] } = props
  const { getMessageTypes = null } = events

  useEffect(() => {
    (async () => {
      const messageTypes = await getMessageTypes?.(null)
      setCellState({ messageTypes })
    })()
  }, [])

  const targetMessageType = (cellState?.messageTypes || []).find(messageType => messageType.key === type && type !== 'message')

  const { intl } = useContext(ApplicationContext)
  const localDate = intl.getLocaleDate(submitTimestamp, { dateStyle: 'medium', timeStyle: 'medium' })

  const newAttachmentLabel = attachments.length
    ? _getAttachmentLabel(intl, attachments.length)
    : null

  return (
    <Box className={classes.description}>
      <Box className={classes.label}>
        <Box className={classes.header}>
          <OverflowTooltip
            variant={'14/bold'}
            classes={{ label: classes.row }}
            color={theme.palette.common.black}
          >
            {title}
          </OverflowTooltip>
          <OverflowTooltip
            variant={'12/medium'}
            classes={{ label: classes.row }}
            color={theme.palette.gray[400]}
          >
            {partner}
          </OverflowTooltip>
        </Box>
        <OverflowTooltip
          variant={'14/medium'}
          classes={{ label: classes.row }}
          color={theme.palette.common.black}
          sx={{ fontStyle: newAttachmentLabel ? 'italic' : 'normal' }}
        >
          {text || newAttachmentLabel || '-'}
        </OverflowTooltip>
        <OverflowTooltip
          variant={'12/regular'}
          color={theme.palette.gray[400]}
          classes={{ label: classes.row }}
        >
          {`${localDate} ${targetMessageType ? '• ' : ''}`}
          {
            targetMessageType
              ? <span style={{ color: theme.palette[targetMessageType?.color || 'info'].main, fontWeight: 700 }}>
                {targetMessageType?.value}
              </span>
              : ''
          }
        </OverflowTooltip>
      </Box>
    </Box>
  )
}

export default CellHOC(ListItemCellNotificationDescription, styles)
