import { Typography } from '@mui/material'
import CellHOC from '@platform/react/hoc/list/cell'

/**
 * Displays the {@code requesterContact} property in cell.
 *
 * @param {Object} classes  styling for the cell
 * @param {Object} props    props for the cell
 *
 * @returns {JSX.Element}
 */
export const ListItemCellRequestRequester = (classes, props = {}) => {
  const {
    requesterContact: { value: requesterContactData = {} },
    requesterContactOrg: { value: requesterOrgData = { address: {} } },
  } = props
  const requesterContact = `${requesterContactData.firstName} ${requesterContactData.lastName}`
  const requesterOrg = `${requesterOrgData.name}, ${requesterOrgData.address.city}`

  return (
    <>
      <Typography
        variant={'body2'}
        color={'black'}
        className={classes.row}
        sx={{ fontWeight: 700 }}
      >
        {requesterContact}
      </Typography>
      <Typography
        variant={'caption'}
        color={'textSecondary'}
        className={classes.row}
      >
        {requesterOrg}
      </Typography>
    </>
  )
}

export default CellHOC(ListItemCellRequestRequester)
