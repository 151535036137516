/* global G */
/* eslint-disable object-curly-newline,no-undefined,no-unused-expressions,no-unused-vars */
import React, { useContext, useEffect, useRef, useTransition } from 'react'
import {
  Badge,
  Button,
  Grid,
  Icon,
  InputAdornment,
  InputBase,
  lighten,
  List,
  ListItemButton,
  ListItemIcon,
  Typography,
} from '@mui/material'
import { withStyles } from '@mui/styles'
import { ArrowDropDown, ArrowDropUp, AttachFile, Clear, Send, Star } from '@mui/icons-material'
import PlatformEvent from 'lib/util/event'
import { useEventCallback, useEventHandler, useMemoRef } from '@platform/react/hook'
import ApplicationContext from '@platform/react/context/application'

const styles = theme => ({
  root: {
    padding: [[0], '!important'],
    flex: [[0, 0, 'auto']],
  },
  button: {
    height: 68,
    borderRadius: 0,
    width: 'calc(100% + 2px)',
  },
  badge: {
    '& .MuiBadge-badge': {
      top: -10,
      border: `2px solid ${theme.palette.background.paper}`,
      padding: '0 4px',
    },
  },
  field: {
    minHeight: 68,
    paddingLeft: 20,
  },
  input: {
    overflowY: [['auto'], '!important'],
  },
  fieldContainer: {
    border: '1px solid #DDDDDD',
    borderRight: 0,
    margin: [[0, -1]],
  },
  menuButtonContainer: {
    width: 90,
    border: '1px solid #DDDDDD',
    borderLeftWidth: '0px',
    marginLeft: 0,
  },
  menuButton: {
    width: '100%',
    height: '100%',
  },
  primaryColor: {
    color: theme.palette.primary.main,
  },
  secondaryColor: {
    color: theme.palette.primary.main,
  },
  warningColor: {
    color: theme.palette.warning.main,
  },
  successColor: {
    color: theme.palette.success.main,
  },
  errorColor: {
    color: theme.palette.error.main,
  },
  sendButtonContainer: {
    width: 84,
    border: '1px solid #DDDDDD',
    borderRightWidth: '0px',
    borderLeft: 0,
    margin: 0,
  },
  sendButton: {
    width: '100%',
    height: '100%',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  menu: {
    boxShadow: `0px -7px 10px ${lighten('#DDDDDD', 0.3)}`,
    borderRadius: 0,
  },
  menuList: {
    padding: 0,
    boxShadow: 'none',
    borderRadius: 0,
  },
  menuItem: {
    height: 68,
    cursor: 'pointer',
    width: 'inherit',
    borderColor: theme.palette.divider,
    borderStyle: 'solid',
    borderWidth: [[1, 0]],
    margin: [[-1, 0]],
  },
  itemIcon: {
    display: 'flex',
    fontSize: '2rem',
    textAlign: 'center',
    width: 84,
    height: 'auto',
    alignItems: 'center',
    justifyContent: 'center',
  },
  ...theme.custom.messageField,
})

/**
 * StarAdornment element
 *
 * Returns the star button adornment, to be appended to the message field.
 *
 * @param {object} classes    the component's style classes
 * @param {function} onClick  the handler to call whenever the button is clicked
 * @returns {JSX.Element}     the new ClearAdornment element
 * @constructor
 */
const StarAdornment = ({ classes, onClick }) => (
  <InputAdornment
    position={'end'}
    className={classes.clearAdornment}
    // don't display component until functionality is added (SP-803)
    style={{ display: 'none' }}
  >
    <Star
      cursor={'pointer'}
      className={classes.clearIcon}
      onClick={onClick}
    />
  </InputAdornment>
)

/**
 * AttachmentAdornment element
 *
 * Returns the attachment button adornment, to be appended to the message field.
 *
 * @param {object} classes    the component's style classes
 * @param {function} onClick  the handler to call whenever the button is clicked
 * @returns {JSX.Element}     the new ClearAdornment element
 * @constructor
 */
const AttachmentAdornment = ({ classes, onClick }) => (
  <InputAdornment
    position={'end'}
    className={classes.clearAdornment}
  >
    <AttachFile
      cursor={'pointer'}
      className={classes.clearIcon}
      onClick={onClick}
    />
  </InputAdornment>
)

/**
 * ClearAdornment element
 *
 * Returns the clear button adornment, to be appended to the message field.
 *
 * @param {object} classes    the component's style classes
 * @param {function} onClick  the handler to call whenever the button is clicked
 * @param {boolean} show      whether the button should be displayed
 * @returns {JSX.Element}     the new ClearAdornment element
 * @constructor
 */
const ClearAdornment = ({ classes, onClick, show }) => (
  <InputAdornment
    position={'end'}
    className={classes.clearAdornment}
  >
    {show && (
      <Clear
        cursor={'pointer'}
        className={classes.clearIcon}
        onClick={onClick}
      />
    )}
  </InputAdornment>
)

/**
 * OptionsMenu element.
 *
 * Shows an absolutely positioned menu as a list above the message field.
 *
 * @param {boolean} isDisplayed   whether the options menu is displayed
 * @param {object} classes        an object containing the element's css classes
 * @param {object[]} children     the contents of the options menu
 * @returns {JSX.Element}
 * @constructor
 */
const OptionsMenu = ({ classes, children, isDisplayed }) => {
  const ref = useRef(null)

  return (
    <div id={'lo'} style={{ width: 'inherit' }}>
      {isDisplayed ? <List
        classes={classes}
        ref={ref}
        disablePadding={true}
        style={{ width: 'inherit' }}
      >
        {children}
      </List> : null }
    </div>
  )
}

/**
 * MessageField ui element.
 *
 * @param {object} forwardedRef                     a reference to the root element
 * @param {String} group                            the attachment group to watch
 * @param {object} value                            the value of the field
 * @param {object} value.key                        the key of the currently selected option
 * @param {object} value.text                       the current value of the input field
 * @param {object} classes                          an object containing the element's css classes
 * @param {object} events                           the element's event handlers
 * @param {function} events.onSend                  a function to be called whenever enter or the
 *                                                  send button are pressed
 * @param {function} events.onAttach                a function to be called whenever attach is
 *                                                  clicked
 * @param {function} events.onStar                  a function to be called whenever star is clicked
 * @param {boolean} [sendOnEnter]                   whether onSend should be called on enter
 * @param {string} currentType                      type of the current ticket/request
 * @param {number} currentStatus                    status of the current ticket/request
 * @returns {JSX.Element}
 * @constructor
 */
// eslint-disable-next-line max-len
const MessageField = ({ forwardedRef, classes, value = {}, group, events, sendOnEnter, currentType, currentStatus }) => {
  const { onOpen, onChange, onSend, onAttach, onStar, onFocus, onAttachment } = events
  const [, startTransition] = useTransition()
  const { eventBus } = useContext(ApplicationContext)
  const anchorRef = React.useRef(null)
  const [attachmentCount, setAttachmentCount] = React.useState(0)
  const [options] = React.useState(
    onOpen({
      status: currentStatus,
      type: currentType,
    }),
  )

  if (group) {
    // dispatched from adapter, once data or cache have been updated
    const _eventName = useRef(eventBus.type(G.ATTACHMENT, G.DONE, group))
    const _eventHandler = useEventCallback(({ detail }) => {
      setAttachmentCount(detail[G.DATA].length)
    })
    useEventHandler(eventBus, _eventName.current, _eventHandler)
  }

  const defaultOption = options.find(option => option.default)
  const initialOption = options && (defaultOption || options[0])
  const selectionRequired = options.some(e => e.priorSelection)
  const [inputOpen, setInputOpen] = React.useState(!selectionRequired)
  const [open, setOpen] = React.useState(selectionRequired)
  const [isDisplayed, setDisplay] = React.useState(selectionRequired)
  const [textValue, setTextValue] = React.useState(
    initialOption?.proposedText
      ? initialOption.proposedText
      : value.text || '',
  )
  const [selectedOption, setSelectedOption] = React.useState(initialOption)

  // Setting text value if incoming value changes
  React.useEffect(() => {
    value.text && setTextValue(value.text)
  }, [value])

  /**
   * Select the new default option after the available types have changes.
   * E.g. after a status update.
   */
  useEffect(() => {
    setSelectedOption(initialOption)
  }, [options])

  /**
   * Clears the input field.
   */
  const clear = useEventCallback((event) => {
    const empty = ''
    setTextValue(empty)
    // TODO: It would be better to trigger the native 'change' event here, so that our
    //  handleInputChange function is called also when we clear the field through the x button, but
    //      const event = new Event('change' /* or 'input' */, { bubbles: true /* or false */ })
    //      inputRef.current.dispatchEvent(event)
    //  doesn't seem to trigger it, so we have to pass a custom event in this case:
    onChange?.(new PlatformEvent(event, { value: empty }))
  })

  /**
   * Sets {@code option} as the currently selected option.
   * @param {object} option the option to be set as selected
   */
  const handleMenuItemClick = option => (event) => {
    const newValue = option.proposedText || ''
    setSelectedOption(option)
    setTextValue(newValue)
    startTransition(() => onChange?.(new PlatformEvent(event, { value: newValue })))
    setOpen(false)
    setDisplay(false)
  }

  /**
   * Sets the text value state on input change and calls the existing onChange event-handler.
   * @param {object} event  the change event
   */
  const handleInputChange = (event) => {
    const rawValue = event.target.value
    const text = sendOnEnter ? rawValue.replace(/(\r\n|\n|\r)/gm, '') : rawValue.trim()
    setTextValue(rawValue)
    startTransition(() => onChange?.(new PlatformEvent(event, { text })))
    setDisplay(false)
  }

  /**
   * Closes the options dropdown when the message input field is clicked.
   */
  const handleInputClick = () => {
    setDisplay(false)
  }

  /**
   * Sends an event with the current key and text state values.
   */
  const sendMessage = handler => (event) => {
    const newStatus = selectedOption?.newStatus
    const newStatusReason = selectedOption?.newStatusReason
    const type = selectedOption?.key
    // Trims all leading and trailing newlines in text messages
    const text = textValue.trim()
    // eslint-disable-next-line no-unused-expressions
    startTransition(() => {
      handler?.(new PlatformEvent(event, { newStatus, newStatusReason, text, type }))
    })
    setTextValue('')
  }

  /**
   * Toggles the options' dropdown.
   */
  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen)
    setDisplay(!isDisplayed)
  }

  const handleAttachmentPaste = (event) => {
    if (event.clipboardData.files.length) {
      event.clipboardData?.files.length
      && onAttachment?.(new PlatformEvent(event, { files: event.clipboardData.files }))
    }
  }

  return (
    <Grid
      ref={forwardedRef}
      container
      alignItems="flex-end"
      className={classes.root}
    >
      {isDisplayed ? <OptionsMenu
        setInputOpen={setInputOpen}
        inputOpen={inputOpen}
        setDisplay={setDisplay}
        isDisplayed={isDisplayed}
        style={{ width: 'inherit' }}
      >
        {options && options.map(option => (option.hidden ? null : (
          <ListItemButton
            key={option.key}
            selected={option === selectedOption}
            onClick={handleMenuItemClick(option)}
            className={classes.menuItem}
          >
            {!option.icon ? null : (
              <ListItemIcon>
                <Icon
                  className={`${classes[`${option.color}Color`]} ${classes.itemIcon}`}
                >
                  {option.icon}
                </Icon>
              </ListItemIcon>
            )}
            <Typography>
              {option.labelText}
            </Typography>
          </ListItemButton>
        )))}
      </OptionsMenu> : null
      }
      <OptionsMenu
        setInputOpen={setInputOpen}
        inputOpen={inputOpen}
      >
        {options && options.map(option => (option.hidden ? null : (
          <ListItemButton
            key={option.key}
            selected={option === selectedOption}
            onClick={handleMenuItemClick(option)}
            className={classes.menuItem}
          >
            {!option.icon ? null : (
              <ListItemIcon>
                <Icon
                  className={`${classes[`${option.color}Color`]} ${classes.itemIcon}`}
                >
                  {option.icon}
                </Icon>
              </ListItemIcon>
            )}
            <Typography>
              {option.labelText}
            </Typography>
          </ListItemButton>
        )))}
      </OptionsMenu>
      <Grid
        ref={anchorRef}
        item
        container
      >
        <Grid
          item
          className={classes.menuButtonContainer}
          xs={'auto'}
        >
          <Button
            color="primary"
            size="small"
            aria-controls={open ? 'split-button-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-label="select merge strategy"
            aria-haspopup="menu"
            onClick={handleToggle}
            className={classes.menuButton}
          >
            {open ? <ArrowDropUp/> : <ArrowDropDown/>}
            {selectedOption && (
              <Icon
                className={classes[`${selectedOption.color}Color`]}
              >
                {selectedOption.icon}
              </Icon>
            )}
          </Button>

        </Grid>
        <Grid
          item
          onPaste={handleAttachmentPaste}
          className={classes.fieldContainer}
          xs={true}
        >
          <InputBase
            fullWidth
            value={textValue}
            placeholder={selectedOption?.backgroundText}
            classes={{ root: classes.field, input: classes.input }}
            onChange={handleInputChange}
            onClick={handleInputClick}
            onKeyUp={event => sendOnEnter && event.key === 'Enter' && textValue
                              && sendMessage(onSend)(event)}
            autoFocus={true}
            multiline={true}
            autoComplete={'new-password'}
            endAdornment={
              <>
                <ClearAdornment
                  show={!!textValue}
                  onClick={clear}
                  classes={classes}
                />
                <StarAdornment
                  onClick={onStar}
                  classes={classes}
                />
                <Badge
                  className={classes.badge}
                  badgeContent={attachmentCount}
                  color={'primary'}
                >
                  <AttachmentAdornment
                    onClick={sendMessage(onAttach)}
                    classes={classes}
                  />
                </Badge>
              </>
            }/>
        </Grid>
        <Grid
          item
          className={classes.sendButtonContainer}
          xs={'auto'}
        >
          <Button
            size="small"
            disabled={!textValue && attachmentCount === 0}
            onClick={sendMessage(onSend)}
            className={`${classes[`${selectedOption?.color}Color`]} ${classes.sendButton}`}
          >
            <Send
              className={classes.sendIcon}
            />
          </Button>

        </Grid>

      </Grid>
    </Grid>
  )
}

export default useMemoRef(withStyles(styles)(MessageField), props => [props.value, props.types])
