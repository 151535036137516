/* eslint-disable no-unused-vars */
/* global G, React */
import { useMemoRef, useStyles } from 'platform/react/hook'
import ChildrenHOC from 'platform/react/hoc/children'
import ApplicationContext from 'platform/react/context/application'
import { useContext, useEffect, useRef, useState } from 'react'
import { Box, ButtonBase, Grid, Typography, useTheme } from '@mui/material'
import FabButton from 'ui/Element/Button/Fab'
import OverflowTooltip from 'ui/Element/Text/OverflowTooltip'
import SvgIcon from 'ui/Element/Icon/Svg'
import {
  Download as DownloadIcon,
  ExpandLess as DownIcon,
  ExpandMore as UpIcon,
} from '@mui/icons-material'
import MuiListTemplateButton from 'ui/Component/Attachment/List/Template/Button'
import { formatFilesize } from 'lib/util/attachment'
import { getReadableDate } from 'lib/util/date'

const styles = theme => ({
  root: {
    height: '100%',
    flexDirection: 'column',
    flexWrap: 'nowrap',
  },
  toolbarContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    padding: '1rem',
    paddingBottom: 0,
    backgroundColor: theme.palette.background.content,
  },
  toolbar: {
    color: theme.palette.common.black,
    backgroundColor: theme.palette.common.white,
    borderRadius: '0.5rem',
    padding: '0.5rem',
    alignItems: 'center',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    '& > *': {
      display: 'flex',
      alignItems: 'center',
      gap: '0.5rem',
    },
  },
  button: {
    width: '2rem',
    height: '2rem',
    backgroundColor: theme.palette.background.content,
    borderRadius: '0.25rem',
    '&:disabled': {
      backgroundColor: theme.palette.common.white,
      '& > *': {
        backgroundColor: theme.palette.border.main,
      },
    },
  },
  navigationButton: {
    backgroundColor: theme.palette.black.main,
  },
  prevButton: {
    '& > *': { // rotating arrow icon
      transform: 'rotate(180deg)',
      cursor: 'pointer',
    },
  },
  previewContainer: {
    height: '100%',
    overflow: 'hidden',
  },
})

/**
 * Toolbar
 *
 * Shows a toolbar to navigate the attachment group
 *
 * @param {Object} toolbarProps                         props
 * @param {Object} toolbarProps.currentAttachment       the current attachment
 * @param {number} toolbarProps.currentAttachmentIndex  the current attachment index
 * @param {number} toolbarProps.attachmentsCount        number of attachments
 * @param {Object} toolbarProps.classes                 classes from parent component
 * @param {Object} toolbarProps.icons                   icons to show
 * @param {Object} toolbarProps.events                  events to use
 * @param {Object} toolbarProps.props                   additional props
 * @returns {JSX.Element}
 * @constructor
 */
const Toolbar = ({
  currentAttachment,
  currentAttachmentIndex,
  attachmentsCount,
  classes,
  icons,
  events,
  ...props
}) => {
  const theme = useTheme()
  const toolbarRef = useRef()
  const [width, setWidth] = useState(null)

  const title = `${currentAttachment?.value?.name || false} (${currentAttachmentIndex + 1}/${attachmentsCount})`

  useEffect(() => {
    toolbarRef?.current && setWidth(toolbarRef.current.clientWidth)
  }, [toolbarRef?.current])

  const resizeHandler = () => { setWidth(toolbarRef?.current?.clientWidth) }

  // Recalculating height if we resize the window
  useEffect(() => {
    window.addEventListener('resize', resizeHandler)
    return () => { window.removeEventListener('resize', resizeHandler) }
  }, [])

  const filesize = currentAttachment?.value?.size
    ? formatFilesize(currentAttachment?.value?.size)
    : false

  const created = currentAttachment?.value?.submitTimestamp
    ? getReadableDate(currentAttachment?.value?.submitTimestamp)
    : false

  const nextIcon = icons.nextIcon
    ? <SvgIcon
      width={'1.5rem'}
      height={'1.5rem'}
      color={theme.palette.white.main}
      background={theme.palette.black.main}
      icon={icons.nextIcon.name}
      variant={icons.nextIcon.variant}
    />
    : <DownIcon />

  const previousIcon = icons.previousIcon
    ? <SvgIcon
      width={'1.5rem'}
      height={'1.5rem'}
      color={theme.palette.white.main}
      icon={icons.previousIcon.name}
      variant={icons.previousIcon.variant}
    />
    : <UpIcon />

  const downloadIcon = icons.downloadIcon
    ? <SvgIcon
      width={'1.5rem'}
      height={'1.5rem'}
      color={theme.palette.black.main}
      icon={icons.downloadIcon.name}
      variant={icons.downloadIcon.variant}
    />
    : <DownloadIcon />

  return (
    <Grid
      item
      container
      columnGap={1}
      ref={toolbarRef}
      className={classes.toolbar}
    >
      <Grid
        item
        xs={'auto'}
      >
        <ButtonBase
          onClick={events?.onPrevious}
          disabled={currentAttachmentIndex === 0}
          className={`${classes.button} ${classes.navigationButton} ${classes.prevButton}`}
        >
          {previousIcon}
        </ButtonBase>
      </Grid>
      <Grid
        item
        xs={12}
        sm={8}
        sx={{ justifyContent: 'center', maxWidth: `calc(${width}px - 5rem)!important` }}
      >
        {title && (
          <>
            <OverflowTooltip variant={'14/medium'}>
              {title}
            </OverflowTooltip>
            <Box /* Divider (like pipe char) */
              sx={{ width: '1px', height: '2rem', backgroundColor: theme.palette.divider }}
            />
          </>
        )}
        {filesize && (
          <>
            <OverflowTooltip variant={'14/medium'}>
              {filesize}
            </OverflowTooltip>
            <Box /* Divider (like pipe char) */
              sx={{ width: '1px', height: '2rem', backgroundColor: theme.palette.divider }}
            />
          </>
        )}
        {created && (
          <>
            <OverflowTooltip variant={'14/medium'}>
              {created}
            </OverflowTooltip>
            <Box /* Divider (like pipe char) */
              sx={{ width: '1px', height: '2rem', backgroundColor: theme.palette.divider }}
            />
          </>
        )}
        <MuiListTemplateButton
          value={null}
          fullWidth={false}
          showProgress={false}
          startIcon={downloadIcon}
          attachment={currentAttachment}
          size={'small'}
        />
      </Grid>
      <Grid
        item
        xs={'auto'}
        sx={{ justifyContent: 'flex-end' }}
      >
        <ButtonBase
          onClick={events?.onNext}
          disabled={currentAttachmentIndex === attachmentsCount - 1}
          className={`${classes.button} ${classes.navigationButton}`}
        >
          {nextIcon}
        </ButtonBase>
      </Grid>
    </Grid>
  )
}

/**
 * Attachment Multiple
 *
 * Component to browse through mutiple attachments using {@link AttachmentBig} component. Will display a
 * toolbar at the top with next and previous buttons.
 *
 * @param {Object} multipleProps                    props
 * @param {Object} multipleProps.initialAttachment  the initial attachment to show in the group
 * @param {String} multipleProps.groupName          attachment group name
 * @param {Object} multipleProps.props              additional props
 * @param {Object} multipleProps.props.downloadIcon download button
 * @param {Object} multipleProps.props.nextIcon     arrow right button
 * @param {Object} multipleProps.props.previousIcon arrow left button
 * @returns {JSX.Element}
 * @constructor
 */
const AttachmentMultiple = ({ attachment: initialAttachment, group: groupName, ...props }) => {
  const { eventBus } = useContext(ApplicationContext)

  const { downloadIcon, nextIcon, previousIcon } = props

  const [attachments, setAttachments] = useState(null)
  const [currentAttachmentIndex, setCurrentAttachmentIndex] = useState(null)

  useEffect(() => {
    if (attachments) {
      const index = initialAttachment
        ? attachments.findIndex(attachment => attachment.key === initialAttachment.key) || 0
        : 0
      setCurrentAttachmentIndex(index)
    }
  }, [attachments])

  const classes = useStyles(styles)()
  const { Big } = props.childrenObject

  useEffect(() => {
    // listening for the attachment API to set a new list of images to the attachment group
    eventBus.add(eventBus.type(G.ATTACHMENT, G.DONE, groupName), (event) => {
      const newAttachments = event.detail[G.DATA]
      const sortedAttachments = newAttachments.sort((a, b) => new Date(a.value.submitTimestamp) - new Date(b.value.submitTimestamp))
      setAttachments(sortedAttachments)
    }, { once: true })
    // forcing the attachment API to fire its current list of images from groupName (by sending an
    // empty G.DATA object), so that the listener above gets the attachments
    eventBus.dispatch(eventBus.type(G.ATTACHMENT, G.CACHE, groupName), { [G.DATA]: {} })
  }, [])

  const handleIncrease = () => {
    setCurrentAttachmentIndex(prevState => prevState + 1)
  }

  const handleDecrease = () => {
    setCurrentAttachmentIndex(prevState => prevState - 1)
  }

  return (
    <Grid
      item
      xs={12}
      container
      className={classes.root}
    >
      <Grid
        item
        container
        className={classes.toolbarContainer}
      >
        {attachments !== null
            && Number.isInteger(currentAttachmentIndex)
            && <Toolbar
              classes={classes}
              attachmentsCount={attachments.length}
              currentAttachmentIndex={currentAttachmentIndex}
              icons={{ downloadIcon, nextIcon, previousIcon }}
              currentAttachment={attachments[currentAttachmentIndex]}
              events={{
                onNext: handleIncrease,
                onPrevious: handleDecrease,
              }}
            />}
      </Grid>
      <Grid
        item
        container
        className={classes.previewContainer}
      >
        {attachments !== null
            && Number.isInteger(currentAttachmentIndex)
            && <Big attachment={attachments[currentAttachmentIndex]}/>
        }
      </Grid>
    </Grid>
  )
}

export default useMemoRef(ChildrenHOC(AttachmentMultiple), props => [props.children, props.attachment])
