import { Typography } from '@mui/material'
import CellHOC from '@platform/react/hoc/list/cell'

/**
 * Intended to display the name of the recipient of a cart.
 *
 * @param {object} props    element's properties
 * @param {object} classes  element's style classes
 *
 * @returns {JSX.Element}
 */
const ListItemCellCartRecipient = (classes, props = {}) => {
  const { recipientData: { name = null } = {} } = props

  return <>
    <Typography
      variant={'14/medium'}
      color={'textDark'}
    >
      {name || '-'}
    </Typography>
  </>
}

export default CellHOC(ListItemCellCartRecipient)
