/* eslint-disable no-nested-ternary */
import OptionHOC from '@platform/react/hoc/autocomplete/option'
import OverflowTooltip from 'ui/Element/Text/OverflowTooltip'
import { Box, useTheme } from '@mui/material'
import AvatarImage from 'ui/Element/Image/Avatar'
import { getThemeColor } from 'lib/util'

const styles = theme => ({
  content: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
})

/**
 * Displays an Article for the {@link Autocomplete} component. Will display a top label consisting
 * of the type of part (part or assembly) and additionally where it's assembled in. Will also
 * display a sublabel showing its order number, if available.
 *
 * @param {Object} classes  styling for the option
 * @param {Object} props    props for the option
 *
 * @return {*}
 * @constructor
 */
const ArticleOption = (classes, props = {}) => {
  const { option, labels, partIcon = 'part' } = props || {}
  const theme = useTheme()

  const {
    value: {
      image = null,
      serialLong,
      info1,
      label,
    } = {},
  } = option || {}

  const { part } = labels || {}

  const subLabel = `${part} • ${serialLong || info1 || null}`

  const currentAttachment = image?.attachment?.value?.name
    ? { ...image.attachment, key: image?.value?.attachmentId || image.id, api: { ref: 'equipment' } }
    : { value: { name: '' } }

  return (
    <Box className={classes.content}>
      <Box sx={{ width: '40px', marginRight: '16px' }}>
        <AvatarImage
          attachment={currentAttachment}
          fallback={{
            icon: partIcon,
            color: getThemeColor(theme, 'text.regular'),
            variant: 'outlined',
          }}
        />
      </Box>
      <Box className={classes.option}>
        <OverflowTooltip>
          {label}
        </OverflowTooltip>
        {subLabel && (
          <OverflowTooltip classes={{ label: classes.label }}>
            {subLabel}
          </OverflowTooltip>
        )}
      </Box>
    </Box>
  )
}

export default OptionHOC(ArticleOption, styles)
