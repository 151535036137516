/* global React */
import { MenuItem } from '@mui/material'
import format from 'lib/util/string'
import PlatformEvent from 'lib/util/event'
import { useTransition } from 'react'

/**
 * Generic Context menu item option that sends the clicked item on click.
 *
 * @param {string} value              the text to be used as the item's label
 * @param {object} events             element's event handlers
 * @param {function} events.onClick   event handler called when the item is clicked
 * @param {function} events.onOpen    event handler called on initialization that should return
 *                                    the item inside an object a reference to the {@link MenuItem}
 *                                    at the root
 * @param {object} ref                a reference to the {@link MenuItem} at the root
 * @returns {JSX.Element}
 * @constructor
 */
const MenuChild = ({ events, value }, ref) => {
  const [, startTransition] = useTransition()
  const { item } = events?.onOpen(null) || {}
  const match = value && value.match(/{(.*)}/)
  const [, key] = match || []
  const child = key && item?.refs?.[key]?.[0]
  const label = child ? format(value, { [key]: child.value.name }) : value

  const handleClick = (event) => {
    startTransition(() => events?.onClick(new PlatformEvent(event, { item })))
  }

  return !child ? null : (
    <MenuItem
      ref={ref}
      onClick={handleClick}>
      {label}
    </MenuItem>
  )
}

export default MenuChild
