/* eslint-disable operator-assignment,no-nested-ternary */
import { useEffect, useRef, useState, useTransition } from 'react'
import { ButtonGroup } from '@mui/material'
import { useStyles } from '@platform/react/hook'
import { debounce, getThemeColor, isNum, PlatformEvent } from 'lib/util'
import Simple from 'ui/Element/Button/Simple'

const styles = (theme, { color }) => ({
  root: {
    maxWidth: '100%',
    borderRadius: '0.5rem',
    border: `1px solid ${theme.palette.divider}`,
    '& > button': {
      '&:nth-child(2)': { // center button
        '&:disabled': {
          border: 'none',
          color: getThemeColor(theme, color),
        },
      },
      border: 'none',
      ...color && {
        color: getThemeColor(theme, color),
      },
      '&:disabled': {
        border: 'none',
        color: theme.palette.divider,
      },
      '&:hover': {
        border: 'none',
        ...color && {
          color: getThemeColor(theme, color),
        },
      },
    },
  },
})

/**
 * Amount Component
 *
 * Displays a button group consisting for a +, -, and value button giving the user a menu to
 * increase or decrease a given value.
 *
 * The {@code onClick} handlers of the + and - buttons are debounced. Their handlers will record
 * every click of the button, and when the debounce delay expired, the business event handler will
 * be called with the current value + times the button was clicked. Once the debounce delay expired,
 * the buttons will be disabled for the duration of the operation so that another click cannot
 * interfere until we have a result.
 *
 * @param {number} [value=0]      the value to display
 * @param {number} [min=1]        the minimum value
 * @param {number|undefined} max  the maximum value
 * @param {Object} events         events for the component
 * @param {Object} props          additional props
 * @returns {null|JSX.Element}
 * @constructor
 */
const Amount = ({ value = 0, min = 1, max = undefined, events, ...props }) => {
  const { color, size, variant = 'outlined' } = props
  const { onChange = null } = events

  const classes = useStyles(styles, { color })()
  const [, startTransition] = useTransition()
  const [disabled, setDisabled] = useState(false)

  const amountRef = useRef(value)

  useEffect(() => {
    amountRef.current = value
    disabled && setDisabled(false)
  }, [value])

  const handler = (event) => {
    startTransition(() => { setDisabled(true) })
    onChange?.(new PlatformEvent(event, {
      value: amountRef.current < min
        ? min
        : amountRef.current > max
          ? max
          : amountRef.current,
    }))
    amountRef.current = 0
  }

  const debouncedHandler = debounce(handler, 200)

  const handleIncrease = (event) => {
    if (!max || amountRef.current + 1 <= max) {
      amountRef.current = amountRef.current + 1
    }
    debouncedHandler(event)
  }

  const handleDecrease = (event) => {
    if (amountRef.current - 1 >= min) {
      amountRef.current = amountRef.current - 1
    }
    debouncedHandler(event)
  }

  const showIncrease = isNum(max) ? value < max : true
  const showDecrease = isNum(min) ? value > min : true

  return props?.hidden ? null : (
    <ButtonGroup
      disableFocusRipple
      size={size}
      variant={variant}
      className={classes.root}
    >
      <Simple
        value={'-'}
        disabled={disabled || !showDecrease}
        onClick={handleDecrease}
        sx={{ minWidth: '33%!important' }}
      />
      <Simple
        disabled
        value={value}
        sx={{ minWidth: '33%!important' }}
      />
      <Simple
        value={'+'}
        disabled={disabled || !showIncrease}
        onClick={handleIncrease}
        sx={{ minWidth: '33%!important' }}
      />
    </ButtonGroup>
  )
}

export default Amount
