/* global React */
import { useState, useEffect, useTransition, cloneElement, Children } from 'react'
import { Grid, StepConnector, Stepper } from '@mui/material'
import { makeStyles } from '@mui/styles'
import PlatformEvent from 'lib/util/event'
import { useMemoRef, mapClasses } from '@platform/react/hook'
import { sectionStyles } from 'ui/Component/Grid/Section'

const styles = makeStyles(theme => ({
  root: {
    padding: 0,
  },
  connector: {
    width: '100%',
    '& > *': {
      borderColor: theme.palette.divider,
    },
  },
  ...theme.custom.verticalStepper,
}))

/**
 * Stepper component designed to be displayed vertically and to display each child as a single step.
 *
 * @param {object} forwardedRef           a reference object to the root element
 * @param {object} events                 an object containing an onClick event handler
 * @param {function} events.onClick       an object containing an onClick event handler
 * @param {object[]} [children]           the children to be used each as a single step
 * @param {boolean} [hidden]              whether to hide the component
 * @param {object} props                  additional component's properties
 * @param {number} [props.activeStep]     the index of the currently active step
 * @param {string} [props.skipButtonText] text to be displayed as skip button's label
 * @param {string} [props.skipTitle]      title to be display if step is skipped
 * @param {number} [props.xs]             the number of grids used by the stepper from xs width
 * @param {number} [props.sm]             the number of grids used by the stepper from sm width
 * @param {number} [props.md]             the number of grids used by the stepper from md width
 * @param {number} [props.lg]             the number of grids used by the stepper from lg width
 * @param {number} [props.xl]             the number of grids used by the stepper from xl width
 * @param {string[]} [props.classes]      an array containing classes to be mapped by
 *                                        {@link mapClasses}
 * @returns {null|JSX.Element}
 * @constructor
 */
const VerticalStepper = ({ forwardedRef, events, children, hidden, ...props }) => {
  const [, startTransition] = useTransition()
  const { skipTitle, skipButtonText } = props
  const classes = styles()
  const className = mapClasses(props.classes, sectionStyles)
  const [activeStep, setActiveStep] = useState(props.activeStep || 0)

  useEffect(() => {
    setActiveStep(props.activeStep || 0)
  }, [props.activeStep])

  /**
   * Calls the attached onClick event handler by passing a {@link PlatformEvent} with {@param index}
   * as detail to it.
   *
   * @param {number} index            the index of the clicked element
   * @param {boolean} [skipped=false] whether or not the step was skipped
   * @returns {(function(*=): void)|*}
   */
  const handleClick = (index, skipped = false) => (event) => {
    startTransition(() => events?.onClick?.(new PlatformEvent(event, { index, skipped })))
  }

  return hidden ? null : (
    <Grid
      item
      container
      ref={forwardedRef}
      className={className}
      component={Stepper}
      activeStep={activeStep}
      orientation={'vertical'}
      connector={
        <StepConnector
          classes={{ root: classes.connector }}
        />
      }
      nonLinear
      xs={props.xs}
      sm={props.sm}
      md={props.md}
      lg={props.lg}
      xl={props.xl}
    >
      {Children.map(children, child => (
        child.props.hidden ? null : cloneElement(child, {
          onClick: handleClick,
          activeStep,
          skipButtonText,
          skipTitle,
        })
      ))}
    </Grid>
  )
}

export default useMemoRef(VerticalStepper, false)
