/* eslint-disable no-empty,object-curly-newline */
/* global React */
import { TextField } from '@mui/material'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers'
import PlatformEvent from 'lib/util/event'
import useMemoRef from '@platform/react/hook/useMemoRef'

/**
 * Modal DatePicker
 *
 * {@link TextField} that makes use of MUIs {@link TimePicker} to display a modal to facilitate the
 * selection of a time.
 *
 * https://mui.com/x/react-date-pickers/getting-started/
 *
 * @param {object} forwardedRef             a reference to the root element
 * @param {string} value                    the current input's value
 * @param {object} events                   an object containing an onChange event handler
 * @param {function} events.onChange        a handler executed whenever a date is set
 * @param {boolean} [disabled]              if the component is disabled
 * @param {object} [props]                  additional component's properties
 * @param {string} [props.label]            a string to be used as the input's label
 * @param {Array} [props.views]             array of possible views for the time
 * @param {Boolean} [props.twentyFourHours] whether to display the time in 12h or 24h format
 * @param {Boolean} [props.defaultNow]      whether to use the current time. Will only work if
 *                                          {@param value} is not set
 * @param {boolean} [props.focused]         whether the field should start focused
 * @param {string} [props.helperText]       information text to display under the field
 * @param {boolean} [props.error]           whether to display the field in error state
 * @returns {JSX.Element}                   a new DatePickerField component
 * @constructor
 */
const TimePickerField = ({ forwardedRef, value, events, disabled,
  ...props }) => {
  const { views, twentyFourHours, defaultNow } = props

  const now = new Date(Date.now())
  const defaultTime = defaultNow ? now : null
  const startTime = value ? new Date(value) : defaultTime

  const [selectedTime, setSelectedTime] = React.useState(startTime)

  const handleTimeChange = (newTime) => {
    setSelectedTime(newTime)
    const event = new CustomEvent('change', { bubbles: true })
    const platformEvent = new PlatformEvent(event, { value: newTime })
    events.onChange(platformEvent)
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <TimePicker
        ampm={!twentyFourHours}
        ampmInClock={!twentyFourHours}
        views={views}
        label={props.label}
        disabled={disabled}
        value={selectedTime}
        onChange={handleTimeChange}
        renderInput={inputProps => (
          // this has to be a controlled component; we cannot use our InputField here because it's
          // setting its defaultValue prop - a controlled component must set its value prop
          <TextField
            {...inputProps}
            ref={forwardedRef}
            helperText={props.helperText || '\u00A0'}
            error={props.error}
            autoFocus={props.focused}
            variant={'standard'}
            fullWidth
          />
        )}
        {...props}
      />
    </LocalizationProvider>
  )
}

export default useMemoRef(TimePickerField, props => [props.value, props.error])
