import { lazy, Suspense } from 'react'
import { useMemoRef } from '@platform/react/hook'

export const preload = path => lazy(() => import(`ui/${path}`))

const DynamicElement = ({ view, ...props }) => {
  const View = preload(view)
  return (
    <Suspense fallback={<></>}>
      <View {...props} />
    </Suspense>
  )
}

/**
 * List Main Header
 *
 * Lazy-loads the {@param view} element and passes {@param label} to it as value.
 *
 * @param {object} className  the styles to be set to the header's root
 * @param {string} view       the path to the header element to load
 * @param {string} [label]    a label to be used by the loaded header element
 * @param {boolean} [hidden]  whether the element should be hidden
 * @returns {JSX.Element}
 * @constructor
 */
const ListHeader = useMemoRef(({ className, view, label, hidden }) => !hidden && (
  <DynamicElement
    key={'list-header'}
    view={view}
    classes={{ root: className }}
    value={label}
  />
), props => [props.hidden])

export default ListHeader
