import { Typography } from '@mui/material'
import CellHOC from '@platform/react/hoc/list/cell'

/**
 * Display the status for a given text template.
 *
 * @param {Object} classes  styling for the cell
 * @param {Object} props    props for the cell
 *
 * @returns {JSX.Element}
 */
export const ListItemCellTextTemplateStatus = (classes, props) => {
  const { status = '-' } = props

  return (
    <Typography
      className={classes.row}
    >
      {status}
    </Typography>
  )
}

export default CellHOC(ListItemCellTextTemplateStatus)
