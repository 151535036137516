/* eslint-disable no-unused-vars */
import { useEffect, useRef, useState } from 'react'
import { Thumbnail } from 'react-pdf'
import { Grid } from '@mui/material'
import { repeat } from 'lib/util'
import { useMemoRef, useStyles } from 'platform/react/hook'
import ScrollGrid from 'ui/Component/Grid/ScrollGrid'

const styles = (theme, { width }) => ({
  thumbnailContainer: {
    width,
    height: '100%',
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    borderRadius: '0.5rem',
    flexShrink: 0,
  },
  thumbnailScrollContainer: {
    height: '100%',
    padding: [['1rem', '1.5rem']],
  },
  thumbnailScrollContent: {
    display: 'flex',
    gap: '1rem',
  },
  thumbnail: {
    '& .react-pdf__Thumbnail__page__canvas': {
      border: `1px solid ${theme.palette.text.secondary}`,
    },
    opacity: 0.4,
  },
  currentPage: {
    opacity: 1,
  },
})

/**
 * Simple Pdf Thumbnails Component
 *
 * Shows a container with all thumbnails of the current pdf.
 *
 * @param {Object} thumbnails                 props
 * @param {React.Ref} thumbnails.forwardedRef ref to the dom node
 * @param {Object} thumbnails.classes         styles from parent component
 * @param {string|number} thumbnails.width    width of the thumbnail container
 * @param {number} thumbnails.pages           number of pages
 * @param {Object} props                      additional props
 * @returns {JSX.Element}
 * @constructor
 */
const Thumbnails = ({ forwardedRef, classes: parentClasses, width, pages, currentPage, ...props }) => {
  const classes = useStyles(styles, { width })()

  const { currentPageLoaded } = props

  // const [loadedThumbnails, setLoadedThumbnails] = useState({})
  const [thumbnailWidth, setThumbnailWidth] = useState(100)
  const containerRef = useRef(null)

  useEffect(() => {
    if (containerRef?.current) {
      // We need the content container of the ScrollGrid, in order to know its width, so we
      // can set the thumbnail width accordingly. It needs a fixed width in px, otherwise it
      // will default to what's defined in the pdf itself -> bad
      const thumbnailsContentContainer = containerRef
        .current.firstChild.firstChild.firstChild

      setThumbnailWidth(thumbnailsContentContainer.clientWidth - 2) // considering borders
    }
  }, [])

  return (
    <Grid
      item
      ref={forwardedRef}
      className={classes.thumbnailContainer}
    >
      <ScrollGrid
        ref={containerRef}
        classes={{
          root: classes.thumbnailScrollContainer,
          content: classes.thumbnailScrollContent,
        }}
      >
        {currentPageLoaded && repeat(pages, i => (
          <Thumbnail
            loading={null}
            pageNumber={i + 1}
            key={`thumbnail-${i}`}
            className={`${classes.thumbnail} ${currentPage === i + 1 ? classes.currentPage : ''}`}
            width={thumbnailWidth}
            inputRef={
              (currentPage === i + 1) || (!currentPage && i === 0)
                ? currentPageRef => currentPageRef && currentPageRef.scrollIntoView({ block: 'start' })
                : null
            }
          />
        ))}
      </ScrollGrid>
    </Grid>
  )
}

export default useMemoRef(Thumbnails, props => [
  props.width,
  props.pages,
  props.currentPage,
  props.currentPageLoaded,
])
