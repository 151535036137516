/* eslint-disable no-nested-ternary */
import { useTransition } from 'react'
import { Box, Grid, Icon, IconButton } from '@mui/material'
import { withStyles } from '@mui/styles'

const styles = (theme) => {
  const { palette } = theme

  const base = {
    transition: [
      'background-color 100ms ease, color 250ms ease',
      'border-color 100ms ease, color 250ms ease',
    ],
    borderWidth: '2px',
    borderStyle: 'solid',
    margin: 6,
    padding: 8,
    borderColor: palette.primary.dark,
    '&:hover': {
      backgroundColor: [[palette.primary.contrastText], '!important'],
      color: palette.primary.dark,
      borderColor: palette.primary.dark,
    },
  }

  return {
    actionLinks: {
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
    contained: {
      ...base,
      backgroundColor: palette.primary.dark,
      color: palette.text.inverted,
    },
    outlined: {
      ...base,
      backgroundColor: 'transparent',
      color: palette.primary.dark,
      '&:hover': {
        backgroundColor: [[palette.primary.dark], '!important'],
        color: palette.primary.contrastText,
      },
    },
    ...theme.custom.actionLinks,
  }
}

/**
 * QuickAction Layout element
 *
 * A quick action button intended to be displayed in the {@link MuiAppBar.}
 *
 * @param {object} classes                        the css class name to be used by the element
 * @returns {function(object): JSX.Element|null}  a function waiting for the element's props
 * @constructor
 */
const QuickAction = ({ classes, ...props }) => {
  const [, startTransition] = useTransition()

  const handleClick = event => startTransition(() => props.events?.onClick(event))

  return props.hidden ? null : (
    <Grid
      item
      component={IconButton}
      key={props.key}
      title={props.title}
      className={classes[props.variant || 'contained']}
      onClick={handleClick}
      style={{
        marginLeft: props.spaced ? 40 : false,
      }}
    >
      <Icon>
        {props.icon || ' '}
      </Icon>
    </Grid>
  )
}

/**
 * ActionLinks Layout component.
 *
 * A sequence of quick action buttons intended to be displayed in the {@link MuiAppBar}.
 *
 * To use them, an action must include a {@code quickActions} array of objects, each of which must
 * contain props for every {@link QuickAction} element to be created.
 *
 * @param {object[]} actions  an array containing the properties of the actions to be displayed
 * @param {object} classes    the classes to be used, defined in {@link styles}
 * @param {boolean} [hidden]  whether the element should not be rendered
 * @returns {JSX.Element}
 * @constructor
 */
const ActionLinks = ({ actions, classes, hidden }) => (hidden ? null : (
  <Grid
    item
    container
    component={Box}
    className={classes.actionLinks}
    xs={'auto'}
  >
    {actions?.map(props => (
      <QuickAction
        {...props}
        classes={classes}
      />
    ))}
  </Grid>
))

export default withStyles(styles)(ActionLinks)
