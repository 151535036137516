import { Typography } from '@mui/material'
import CellHOC from '@platform/react/hoc/list/cell'

/**
 * Intended to display the device of the ticket.
 *
 * @param {Object} classes  styling for the cell
 * @param {Object} props    props for the cell
 *
 * @returns {JSX.Element}
 */
export const ListItemCellTicketDevice = (classes, props = {}) => {
  const { itemData = {}, inverted = false } = props
  const itemName = itemData.name || '-'
  const itemSerial = itemData.serial || '-'
  return (
    <>
      <Typography
        color={'black'}
        variant={'body2'}
        className={classes.row}
        sx={{ fontWeight: 700 }}
        title={!inverted ? itemName : itemSerial}
      >
        {!inverted ? itemName : itemSerial}
      </Typography>
      <Typography
        variant={'caption'}
        className={classes.row}
        color={'textSecondary'}
        title={!inverted ? itemSerial : itemName}
      >
        {!inverted ? itemSerial : itemName}
      </Typography>
    </>
  )
}

export default CellHOC(ListItemCellTicketDevice)
