/* eslint-disable no-unused-vars */
/* global React */
import { useAttachmentAPI, useMemoRef, useStyles } from '@platform/react/hook'
import { Grid } from '@mui/material'
import Text from 'ui/Element/Text/Simple'
import FileImage from 'ui/Element/Attachment/Item/File'

/**
 * Returns the AttachmentListTemplateLine element's current theme styles.
 *
 * @param {object} theme  the application's current theme
 * @returns {object}      the AttachmentListTemplateLine component's styles
 */
const styles = theme => ({
  root: {
    padding: theme.spacing(1, 0),
    alignItems: 'stretch',
    width: 0,
  },
  image: {
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'left',
    backgroundSize: 'contain',
  },
  text: {
    display: 'inline-block',
    whiteSpace: [['nowrap'], '!important'],
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  ...theme.custom.attachmentListTemplateLine,
})

/**
 * AttachmentListTemplateLine attachment component
 *
 * Displays a small representation of the file together with its name as a line.
 *
 * @param {object} attachment the attached file
 * @param {object} types      the types for the {@link FileImage} element
 */
const AttachmentListTemplateLine = ({ attachment, types, download = true, events }) => {
  const classes = useStyles(styles)()
  const { value: item } = attachment

  return (
    <Grid
      item
      container
      className={classes.root}
      title={item.name}
      xs={12}
      md={12}
      xl={12}
    >
      <FileImage
        types={types}
        attachment={attachment}
        download={download}
        events={{
          onClick: events?.onClick,
        }}
        md={1}
        lg={1}
        xl={1}
      />
      <Grid
        item
        container
        component={Text}
        className={classes.text}
        color={'textSecondary'}
        alignContent={'center'}
        xs={11}
        md={11}
        xl={11}
      >
        {item.name}
      </Grid>
    </Grid>
  )
}

export default useMemoRef(AttachmentListTemplateLine, props => [props.attachment])
