/* eslint-disable object-curly-newline, arrow-body-style,implicit-arrow-linebreak */
import { useMemoRef } from '@platform/react/hook'
import DecoratedText from 'ui/Element/Text/Decorated'

/**
 * StatusText UI Element.
 * A {@link DecoratedText} element that can be given a different appearance according to the truthy
 * properties passed to it.
 *
 * @param {String} [value]          a string to be used as value
 * @param {Boolean} [bold]          whether to add bold css class styling to the element
 * @param {Boolean} [success]       whether to add success css class styling to the element
 * @param {Boolean} [warning]       whether to add warning css class styling to the element
 * @param {Boolean} [error]         whether to add error css class styling to the element
 * @param {Boolean} [list]          whether to add list css class styling to the element
 * @param {Boolean} [hidden=false]  whether to show the component
 * @param {Object} decorators       additional decorators
 *
 * @returns {JSX.Element}           the new StatusText element
 * @constructor
 */
const StatusText = ({ value, bold, success, warning, error, list, hidden = false, decorators }) => (
  hidden ? null : (
    <DecoratedText
      value={value}
      decorators={{ bold, success, warning, error, list, ...decorators }}
    />
  ))

export default useMemoRef(StatusText, props =>
  [props.error, props.success, props.list, props.bold])
