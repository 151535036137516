import { Grid, Typography } from '@mui/material'
import { AddOutlined, SentimentDissatisfiedOutlined } from '@mui/icons-material'
import { useStyles } from '@platform/react/hook'
import Simple from 'ui/Element/Button/Simple'
import IconSvg from 'ui/Element/Icon/Svg'

export const styles = (theme, { large, fullHeight }) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    // Roughly. Should be 100vh - header height
    // Defaults to 70
    height: fullHeight ? '70vh' : '22vh',
  },
  noDataIcon: {
    color: theme.palette.common.black,
    marginBottom: 10,
  },
  noDataLabel: {
    fontSize: large ? '24px' : 'inherit',
    color: theme.palette.common.black,
    fontWeight: 700,
    marginBottom: '12px',
  },
  noDataDescription: {
    fontSize: large ? '16px' : 'inherit',
    color: theme.palette.common.black,
    marginBottom: 32,
    maxWidth: 300,
    textAlign: 'center',
  },
  button: {
    borderRadius: '8px',
    boxShadow: 'none',
    fontWeight: 700,
    fontSize: 14,
    height: 48,
  },
  ...theme.custom.noData,
})

/**
 * Generic NoData component.
 *
 * @param {Object} props data for the component
 * @param {String} icon                   icon to show at the top
 *                                        will default to {@link SentimentDissatisfiedOutlined}
 * @param {String} label                  label to display
 * @param {String} description            description to display below the {@param label}
 * @param {Boolean} action                whether or not to display the button
 * @param {Boolean} color                 color to use for the button
 * @param {Boolean} large                 whether or not to use large texts
 * @param {Boolean} [fullHeight=true]     whether to use the full height or not.
 *                                        Should be set to {@code false} in short lists
 * @param {String} props.buttonIcon       icon to show inside the action button
 *                                        will default to {@link AddOutlined}
 * @param {String} props.buttonLabel      label for the button
 * @param {Number[]} props.aclProps       list of permissions to check against
 * @param {Boolean} props.showDescription whether or not to display the description
 * @param {Object} ref                    reference to the root element
 *
 * @returns {JSX.Element}
 */
const NoData = ({ icon, label, description, action, color, large, fullHeight = true, ...props }, ref) => {
  const classes = useStyles(styles, { large, fullHeight })()
  const hasPermission = props?.events?.acl?.(props?.aclProps || null) || false

  const buttonIcon = props.buttonIcon
    ? <IconSvg
      icon={props.buttonIcon}
      raw={false}
      height={24}
      width={24}
      variant={'outlined'}
      color={'common.white'}
    />
    : <AddOutlined />

  const noDataIcon = icon
    ? <IconSvg
      icon={icon}
      raw={false}
      height={80}
      width={80}
      variant={'outlined'}
    />
    : <SentimentDissatisfiedOutlined sx={{ fontSize: 100 }} />

  return <Grid
    className={classes.root}
    ref={ref}
    container
    item
    xs={12}
    sm={12}
    md={12}
    lg={12}
    xl={12}
  >
    {props.showIcon && (
      <span className={classes.noDataIcon}>
        {noDataIcon}
      </span>
    )}
    <Typography
      className={classes.noDataLabel}
      variant={'body1'}>
      {label}
    </Typography>
    {props.showDescription && (
      <Typography
        className={classes.noDataDescription}
        variant={'body2'}>
        {description}
      </Typography>
    )}
    {action && hasPermission && (
      <Simple
        className={classes.button}
        value={props.buttonLabel}
        events={props.events}
        endIcon={props.showButtonIcon && buttonIcon}
        color={color || 'primary'}
        variant={'contained'}
        fullWidth={false}
      />
    )}
  </Grid>
}

export default NoData
