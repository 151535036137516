import { Box, Typography } from '@mui/material'
import CellHOC from '@platform/react/hoc/list/cell'

/**
 * Intended to display a team's organisation.
 *
 * @param {Object} classes  styling for the cell
 * @param {Object} props    props for the cell
 *
 * @return {*}
 * @constructor
 */
const ListItemCellTeamOrganization = (classes, props = {}) => {
  const {
    organisation = {
      value: {
        name: '-',
      },
    },
  } = props

  return (
    <Typography>
      <Box
        component={'span'}
        className={classes.row}
        title={organisation.value.name}
      >
        {organisation.value.name}
      </Box>
    </Typography>
  )
}

export default CellHOC(ListItemCellTeamOrganization)
