import OptionHOC, { _getStars, getOptionLabel } from '@platform/react/hoc/autocomplete/option'
import OverflowTooltip from 'ui/Element/Text/OverflowTooltip'
import { Box, Icon } from '@mui/material'

/**
 * Displays a generic option for the {@link Autocomplete} menu with options for setting a
 * {@code label}, {@code subLabel}, {@code infoText}, {@code hasRanking} and {@code icon}.
 *
 * @param {Object} classes  styling for the option
 * @param {Object} props    props for the option
 *
 * @return {*}
 * @constructor
 */
const GenericOption = (classes, props = {}) => {
  const { option } = props

  return (
    <>
      <Box
        key={'item'}
        component={'div'}
        className={classes.option}
      >
        {!option.label ? null : (
          <Box
            key={'label'}
            component={'span'}
            className={classes.label}
          >
            {option.label}
          </Box>
        )}
        <OverflowTooltip>
          {getOptionLabel(option)}
        </OverflowTooltip>
        {!option.subLabel ? null : (
          <Box className={classes.label}>
            {option.subLabel}
          </Box>
        )}
      </Box>
      {!option.icon || option?.iconInOption === false ? null : (
        <Icon
          key={'icon'}
          color={option?.color || 'primary'}
        >
          {option.icon}
        </Icon>
      )}
      {option.hasRanking
        ? _getStars(option.score)
        : null}
      {!option.infoText ? null : (
        <Box className={classes.label}>
          {option.infoText}
        </Box>
      )}
    </>
  )
}

export default OptionHOC(GenericOption)
