/* eslint-disable no-unused-vars */
/* global React */
import CellHOC from '@platform/react/hoc/list/cell'
import { useEffect, useRef, useState } from 'react'
import Check from 'ui/Element/Field/Check'
import PlatformEvent from 'lib/util/event'

/**
 * Returns the ListItemCellUserAdminRoleDelete element's current theme styles.
 *
 * @param {Object} theme    the application's current theme
 * @returns {Object}        the ListItemCellUserAdminRoleDelete component's styles
 */
const styles = theme => ({
  root: {
    '& .MuiFormControlLabel-root': {
      margin: 0,
    },
  },
})
/**
 * Displays a button to delete the current users' respective role.
 *
 * @param {Object} classes  styling for the cell
 * @param {Object} props    props for the cell
 *
 * @return {*}
 * @constructor
 */
export const ListItemCellUserAdminRoleDelete = (classes, props = {}) => {
  const { index, label, events } = props
  const parentRef = useRef(null)
  const [disabled, setDisabled] = useState(false)

  const Field = React.forwardRef(Check)

  useEffect(() => {
    (async () => {
      const event = new CustomEvent('open')
      setDisabled(await events?.onRoleDeleteOpen?.(
        new PlatformEvent(event, index),
      ))
    })()
  })

  return (
    <Field
      events={{
        onChange: events?.onRoleDelete(parseInt(index, 10)),
      }}
      size={'5'}
      label={label}
      checked={disabled || false}
      ref={parentRef}
    />
  )
}

export default CellHOC(ListItemCellUserAdminRoleDelete, styles)
