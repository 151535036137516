import { Typography } from '@mui/material'
import CellHOC from '@platform/react/hoc/list/cell'

/**
 * Intended to display the name and address of the additional party.
 *
 * @param {Object} classes  styling for the cell
 * @param {Object} props    props for the cell
 *
 * @returns {JSX.Element}
 */
export const ListItemCellTicketAdditionalParty = (classes, props = {}) => {
  const { additionalPartyData } = props
  const { address = { city: '', street: '', number: '' } } = additionalPartyData || {}
  const shortAddress = address.city ? `${address.city}, ${address.street} ${address.number}` : '-'

  return !props.additionalPartyData ? null : (
    <>
      <Typography
        color={'black'}
        variant={'body2'}
        className={classes.row}
        sx={{ fontWeight: 700 }}
      >
        {additionalPartyData.name}
      </Typography>
      <Typography
        variant={'caption'}
        color={'textSecondary'}
        className={classes.row}
      >
        {shortAddress}
      </Typography>
    </>
  )
}

export default CellHOC(ListItemCellTicketAdditionalParty)
