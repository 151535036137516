import { Typography } from '@mui/material'
import CellHOC from '@platform/react/hoc/list/cell'

/**
 * Display the name for a given text template.
 *
 * @param {Object} classes  styling for the cell
 * @param {Object} props    props for the cell
 *
 * @returns {JSX.Element}
 */
export const ListItemCellTextTemplateName = (classes, props) => {
  const { name = '-' } = props

  return (
    <Typography
      className={classes.row}
      sx={{ fontWeight: 'bold' }}
    >
      {name}
    </Typography>
  )
}

export default CellHOC(ListItemCellTextTemplateName)
