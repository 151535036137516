/* eslint-disable no-nested-ternary */
import OptionHOC from '@platform/react/hoc/autocomplete/option'
import { Box, Typography, useTheme } from '@mui/material'
import AvatarImage from 'ui/Element/Image/Avatar'
import OverflowTooltip from 'ui/Element/Text/OverflowTooltip'
import { forwardRef } from 'react'
import Chip from 'ui/Element/Text/Chip'
import settings from '@tenant/settings'

const styles = theme => ({
  topLabel: {
    ...theme.typography['12/medium'],
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  subLabel: {
    '& div': {
      '&:not(:last-child)': {
        marginRight: '0.5rem',
      },
    },
    '& .MuiChip-label': {
      fontSize: '0.75rem',
    },
  },
})

/**
 * Helper function to get the icon for the current option from the tenant settings.
 *
 * @param {string} nodeType type of documentation
 * @param {string} name     name of the documentation
 * @returns {*|null}
 * @private
 */
const _getOptionIcon = ({ nodeType, name }) => {
  const availableIcons = settings?.documentationTreeNodeIcons
  if (!nodeType || !name || !availableIcons?.length) return null

  const targetIcon = availableIcons.find(icon => icon.name === name && icon.nodeType === nodeType)

  return targetIcon?.icon || null
}

/**
 * Displays a Docchunk for the {@link Autocomplete} component. Will display a {@link Chip}
 * at the bottom with all the products where the doc chunk was found.
 *
 * @param {Object} classes  styling for the option
 * @param {Object} props    props for the option
 *
 * @return {*}
 * @constructor
 */
const DocumentationOption = (classes, props = {}) => {
  const { option, labels, docChunkIcon = 'document_rounded' } = props || {}
  const theme = useTheme()

  const icon = _getOptionIcon({
    name: option.value?.documentationType,
    nodeType: 'documentation',
  })

  const {
    value: {
      image = null,
      highlights: { content = [] },
      product,
      label,
    } = {},
  } = option || {}

  const { docChunk } = labels || {}
  const ForwardedChip = forwardRef((chipProps, ref) => <Chip forwardedRef={ref} {...chipProps} />)
  const productLabels = product.map((item, index) => (<ForwardedChip key={index} text={item} />))

  const currentAttachment = image?.attachment?.value?.name
    ? { ...image.attachment, key: image?.value?.attachmentId || image.id, api: { ref: 'equipment' } }
    : { value: { name: '' } }

  return (
    <Box className={classes.content}>
      <Box sx={{ width: '2.5rem', marginRight: '1rem' }}>
        <AvatarImage
          attachment={currentAttachment}
          fallback={{
            ...icon ? {
              icon: icon.name,
              variant: icon.variant,
            } : {
              icon: docChunkIcon,
              variant: 'outlined',
            },
            color: theme.palette.text.regular,
          }}
        />
      </Box>
      <Box className={classes.option}>
        {label && (
          <OverflowTooltip>
            {label}
          </OverflowTooltip>
        )}
        <Box>
          <OverflowTooltip classes={{ label: classes.subLabel }}>
            {productLabels}
          </OverflowTooltip>
        </Box>
      </Box>
    </Box>
  )
}

export default OptionHOC(DocumentationOption, styles)
