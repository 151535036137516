import IconBlock from 'ui/Element/Text/IconBlock'

/**
 * Displays an empty list.
 *
 * @param {Object} props  props for the component
 * @return {JSX.Element}
 * @constructor
 */
const EmptyList = props => (
  <IconBlock
    direction={'column'}
    textAlign={'center'}
    md={12}
    {...props}
  />
)

export default EmptyList
