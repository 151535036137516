import { Box, useTheme } from '@mui/material'
import CellHOC from '@platform/react/hoc/list/cell'
import OverflowTooltip from 'ui/Element/Text/OverflowTooltip'
import { useContext } from 'react'
import ApplicationContext from 'platform/react/context/application'

/**
 * Cell styles
 */
const styles = () => ({
  label: {
    maxWidth: 'calc(100%)',
  },
  description: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
})

/**
 * Displays the description of the alert notification
 *
 * @param {Object} classes  styles for the component
 * @param {Object} props    props for the component
 * @return {JSX.Element}
 * @constructor
 */
const ListItemCellNotificationDescription = (classes, props = {}) => {
  const theme = useTheme()
  const { title, body, created } = props

  const { intl } = useContext(ApplicationContext)
  const localDate = intl.getLocaleDate(created, { dateStyle: 'medium', timeStyle: 'medium' })

  return (
    <Box className={classes.description}>
      <Box className={classes.label}>
        <OverflowTooltip
          variant={'14/bold'}
          classes={{ label: classes.row }}
          color={theme.palette.common.black}
        >
          {title}
        </OverflowTooltip>
        <OverflowTooltip
          variant={'14/medium'}
          classes={{ label: classes.row }}
          color={theme.palette.common.black}
        >
          {body}
        </OverflowTooltip>
        <OverflowTooltip
          variant={'12/regular'}
          color={theme.palette.gray[400]}
          classes={{ label: classes.row }}
        >
          {localDate}
        </OverflowTooltip>
      </Box>
    </Box>
  )
}

export default CellHOC(ListItemCellNotificationDescription, styles)
