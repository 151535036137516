/* eslint-disable import/no-extraneous-dependencies, arrow-body-style,object-curly-newline,max-len */
import { _modelProps, StructuredList } from 'ui/Component/List/Structured'
import { preload, useMemoRef } from '@platform/react/hook'
import NoDataHOC from '@platform/react/hoc/nodata'
import { empty } from 'lib/util/object'
import useAcl from '@platform/react/hook/useAcl'

/**
 * ACL Cell Iterator Function
 *
 * Iterates over the {@param structure}, preloads the view for every cell and returns the cell
 * component alongside its props and sizes. Additionally, it performs an ACL check if the cell
 * configuration has a known acl property, like {@code acl}, {@code aclContextRole} and
 * {@code aclContext}.
 *
 * @param {Object[]} data         data to display as list of cell groups
 * @param {ItemProps[]} structure view and props of each cell
 * @param {number[]} size         grid width of each cell
 * @param {Object} events         event handlers available for the cells
 * @returns {Item[]}
 */

const aclRowIterator = (data, { structure, size, events }) => data.map((item) => {
  /**
   * @typedef {Object} Cell
   * @property {React.ReactNode} View
   * @property {Object} cellProps
   * @property {*} cellProps.value
   * @property {Object} cellProps.labels
   * @property {Object} sizes
   * @property {number} sizes.xs
   * @type {Cell[]}
   */
  const cells = item.value.error || structure.map(({ view, options }, cellIndex) => {
    const cell = structure[cellIndex]

    const { acl } = events
    const [aclProps] = useAcl(acl, cell)

    const { keys = {}, xs, sm, md, lg, xl, ...rest } = options || {}
    const modelProps = _modelProps(item, keys, events)
    const View = preload(view)
    const cellProps = {
      ...modelProps,
      ...rest,
      ...!empty(aclProps) && aclProps,
      events,
      id: item.key,
    }
    const sizes = { xs: size?.[cellIndex] || xs, sm, md, lg, xl }

    return { View, cellProps, sizes }
  })

  return { key: item.key, data: item, cells }
})

export default useMemoRef(NoDataHOC(props => <StructuredList iterator={aclRowIterator} { ...props } />), props => [props.update])
