import { Typography } from '@mui/material'
import CellHOC from '@platform/react/hoc/list/cell'

/**
 * Display the text for a given text template.
 *
 * @param {Object} classes  styling for the cell
 * @param {Object} props    props for the cell
 *
 * @returns {JSX.Element}
 */
export const ListItemCellTextTemplateText = (classes, props) => {
  const { text = '-' } = props

  return (
    <Typography
      className={classes.row}
    >
      {text}
    </Typography>
  )
}

export default CellHOC(ListItemCellTextTemplateText)
