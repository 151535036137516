import CellHOC from '@platform/react/hoc/list/cell'
import LabeledHOC from '@platform/react/hoc/list/labeled'
import { ListItemCellPersonInfo } from 'ui/Element/List/Item/Cell/Person/Info'

export default CellHOC(
  LabeledHOC(
    props => props.label,
    ListItemCellPersonInfo,
  ),
)
