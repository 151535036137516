/* global G */
import { Box, Typography } from '@mui/material'
import CellHOC from '@platform/react/hoc/list/cell'

const styles = theme => ({
  amount: {
    display: 'flex',
    flexDirection: 'row-reverse',
  },
})

/**
 * Displays the amount of an item of the cart for the summary list
 *
 * @param {Object} classes  styling for the cell
 * @param {Object} props    props for the cell
 *
 * @return {*}
 * @constructor
 */
export const ListItemCellCartPositionsAmount = (classes, props = {}) => {
  const { amount } = props

  return (
    <Box className={classes.amount}>
      <Typography
        variant={'16/bold'}
        color={'textBlack'}
      >
        {amount}
      </Typography>
    </Box>
  )
}

export default CellHOC(ListItemCellCartPositionsAmount, styles)
