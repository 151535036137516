import CellHOC from '@platform/react/hoc/list/cell'
import LabeledHOC from '@platform/react/hoc/list/labeled'
import { ListItemCellUserRole } from 'ui/Element/List/Item/Cell/User/Role'

export default CellHOC(
  LabeledHOC(
    props => props.labels.role,
    ListItemCellUserRole,
  ),
)
