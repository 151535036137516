import { Box, Typography } from '@mui/material'

/**
 * Generates the elements used to display the props.
 *
 * @param {object} props  element properties
 * @return {JSX.Element[]}
 */
const propsToContent = (props = {}) => {
  const {
    name,
  } = props
  return [
    <Typography key={0}>
      <Box
        component={'span'}
        fontWeight={'fontWeightBold'}
      >
        {name}
      </Box>
    </Typography>,
  ]
}

/**
 * Intended to display the name of a product inside a row's cell.
 *
 * @param {object} props      element properties
 * @param {string} props.name the name to display
 *
 * @return {JSX.Element}
 * @constructor
 */
export const ListItemCellProductName = (props) => {
  const content = propsToContent(props)
  return <Box>{content}</Box>
}

export default ListItemCellProductName
