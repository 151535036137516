import CellHOC from '@platform/react/hoc/list/cell'
import LabeledHOC from '@platform/react/hoc/list/labeled'
import { ListItemCellTicketAdditionalParty } from 'ui/Element/List/Item/Cell/Ticket/AdditionalParty'

export default CellHOC(
  LabeledHOC(
    props => (props.additionalPartyData ? props.labels.service : null),
    ListItemCellTicketAdditionalParty,
  ),
)
