/* eslint-disable no-param-reassign */
/* global G */
import { forwardRef, useImperativeHandle, useRef, useState, useTransition } from 'react'
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles'
import { CssBaseline } from '@mui/material'
import { Provider as AppProvider } from 'platform/react/context/application'
import { BooleanProvider } from '@platform/react/context/boolean'
import theme from '@platform/react/theme'
import AppBar from '@ui/Component/Layout/AppBar'
import SideBar from '@ui/Component/Layout/SideBar'
import ContentGrid from '@ui/Component/Layout/ContentGrid'
import Dialog from '@ui/Component/Layout/Dialog'
import Drawer from 'ui/Component/Layout/Drawer'
import SnackBar from '@ui/Component/Layout/SnackBar'
import Confirmation from '@ui/Component/Layout/Confirm'
import Loader from '@ui/Component/Layout/Loader'
import Portal from '@ui/Component/Layout/Portal'
import OfflineBox from 'ui/Component/Layout/OfflineBox'
import Frame from 'ui/Component/Layout/Frame'
import ErrorBoundary from '@ui/Error'

const Layout = forwardRef((props, ref) => {
  const [, startTransition] = useTransition()
  const [, setState] = useState({})

  const refContent = useRef()
  const refAppBar = useRef()
  const refSideBar = useRef()
  const refDialog = useRef()
  const refDrawer = useRef()
  const refPortal = useRef()

  useImperativeHandle(ref, () => ({
    setState: newState => startTransition(() => setState(newState)),
    getContent: () => refContent.current,
    getAppBar: () => refAppBar.current,
    getSideBar: () => refSideBar.current,
    getDialog: () => refDialog.current,
    getDrawer: () => refDrawer.current,
    getPortal: () => refPortal.current,
  }))

  const {
    session,
    eventBus,
    navigation,
    menu,
    links,
    router,
    acl,
    intl,
    socket,
    maxPreviewSize,
    previewableTypes,
  } = props

  const appProviderValue = {
    session: session[G.STATE],
    isLogged: !!session[G.STATE][G.META],
    isOnline: session[G.STATE][G.ONLINE],
    maxPreviewSize,
    previewableTypes,
    eventBus,
    navigation,
    menu,
    links,
    router,
    acl,
    intl,
    socket,
  }

  return (
    <ErrorBoundary>
      <AppProvider value={appProviderValue}>
        <Portal ref={refPortal}/>
        <OfflineBox />
        <Frame>
          <BooleanProvider>
            {() => (
              <>
                <AppBar ref={refAppBar}/>
                <SideBar ref={refSideBar}/>
              </>
            )}
          </BooleanProvider>
          <ContentGrid ref={refContent} />
        </Frame>
        <Dialog ref={refDialog}/>
        <Drawer ref={refDrawer}/>
        <SnackBar/>
        <Confirmation/>
        <Loader/>
      </AppProvider>
    </ErrorBoundary>
  )
})

export default (props, ref) => (
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={theme}>
      <CssBaseline/>
      <Layout
        {...props}
        ref={(element) => {
          ref.current = element
          props.callback()
        }}
      />
    </ThemeProvider>
  </StyledEngineProvider>
)
