import { Box, useTheme } from '@mui/material'
import CellHOC from '@platform/react/hoc/list/cell'
import SvgIcon from 'ui/Element/Icon/Svg'

/**
 * Cell styles
 * @returns {{root: {width: string, height: string}}}
 */
const styles = () => ({
  root: {
    width: '2.5rem',
    height: '2.5rem',
  },
})

/**
 * Displays the icon of a notification
 *
 * @param {Object} classes  styles for the component
 * @param {Object} props    props for the component
 * @return {JSX.Element}
 * @constructor
 */
const ListItemCellNotificationIcon = (classes, props = {}) => {
  const theme = useTheme()
  const { type, group } = props

  const icon = ((group === 'alert') && { name: 'document_rounded', variant: 'outlined' })
      || ((group === 'message') && { name: 'envelope', variant: 'outlined' })
      || {}

  return (
    <Box className={classes.root}>
      <SvgIcon
        padding={'0.5rem'}
        color={theme.palette.black.main}
        background={theme.palette.grey[960]}
        rounded={true}
        icon={icon.name}
        variant={icon.variant}
      />
    </Box>
  )
}

export default CellHOC(ListItemCellNotificationIcon, styles)
