/* eslint-disable no-nested-ternary */
/* global React */
import { useCallback, useEffect, useTransition } from 'react'
import { RadioGroup, FormControl, FormLabel, Grid, FormHelperText } from '@mui/material'
import PlatformEvent from 'lib/util/event'
import useMemoRef from '@platform/react/hook/useMemoRef'
import { useStyles } from '@platform/react/hook'
import Radio from 'ui/Element/Field/Radio'

const styles = theme => ({
  root: {
    flexDirection: 'row',
  },
  helperText: {
    margin: 0,
  },
  ...theme.custom.radioGroupField,
})

/**
 * Radio Group component.
 *
 * @param {object} forwardedRef             a reference to the root element
 * @param {object} events                   an object containing event handlers
 * @param {function} [events.onChange}      a function triggered whenever the selection changes
 * @param {function} [events.getSelection}  a function triggered during the first render to
 *                                          alternatively obtain the options to display
 * @param {string} label                    the field's label
 * @param {boolean} checked                 whether the component is checked
 * @param {boolean} inline                  whether to force displaying the options in the same line
 * @param {object} props                    additional component's properties
 * @param {boolean} row                     whether to display the options in a row
 * @param {string} props.value              the component's current value
 * @param {object[]} props.options          a list of options to display
 * @param {boolean} props.disabled          whether to render the group as disabled
 * @param {boolean} props.error             whether the component is in error state
 * @param {string} props.helperText         additional information text
 * @returns {JSX.Element}
 * @constructor
 */
const RadioGroupField = ({ forwardedRef, events, label, row, inline, checked, ...props }) => {
  const [, startTransition] = useTransition()
  const classes = useStyles(styles)()
  const [value, setValue] = React.useState(props.value || '')
  const [options, setOptions] = React.useState(props.options || [])
  const count = options.filter(option => !option.hidden).length
  const childWidth = row ? inline ? 12 / (count ? count % 12 : 1) : 'auto' : 12

  // Event handler utilizing useCallback ...
  // ... so that reference never changes.
  const handleClick = useCallback((event) => {
    const { value: newValue, checked: isChecked } = event.target
    setValue(newValue)
    startTransition(() => {
      events?.onChange(new PlatformEvent(event, { value: newValue, checked: isChecked }))
    })
  },
  [setValue])

  // Allowing options to be set through a getSelection handler
  useEffect(() => {
    (async () => {
      const newOptions = await events?.getSelection?.(null)
      newOptions?.length && setOptions(newOptions)
    })()
  }, [])

  return (
    <Grid
      item
      container
      ref={forwardedRef}
      component={FormControl}
      className={classes.root}
      error={props.error}
      fullWidth
    >
      <Grid
        item
        container
        direction={'row'}
        justifyContent={row ? 'space-between' : 'flex-start'}
        alignItems={row ? 'flex-start' : 'center'}
        spacing={row && 1}
        xs
      >
        {!label ? null : (
          <Grid
            item
            xs={row ? 12 : 6}
          >
            <FormLabel
              component='legend'
            >
              {label}
            </FormLabel>
          </Grid>
        )}
        <Grid
          item
          container
          component={RadioGroup}
          value={value}
          aria-label={label}
          xs={row || (label ? 6 : 12)}
        >
          {options.reduce((acc, option) => {
            !option.hidden && acc.push(
              <Grid
                key={option.key}
                item
                sm={childWidth}
                xs={12}
              >
                <Radio
                  key={option.key}
                  label={option.value}
                  value={option.key}
                  checkedIcon={option.icon}
                  onClick={handleClick}
                  icon={option.icon}
                  iconColor={option.color}
                  disabled={option.disabled || props.disabled}
                />
              </Grid>,
            )
            return acc
          }, [])}
        </Grid>
      </Grid>
      <Grid
        item
        component={FormHelperText}
        className={classes.helperText}
        xs={12}
      >
        {props.helperText || '\u00A0'}
      </Grid>
    </Grid>
  )
}

export default useMemoRef(RadioGroupField, props => [
  props.value, props.error, props.options, props.disabled,
])
