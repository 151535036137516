/* eslint-disable no-unused-vars */
/* global React */
import { useCallback } from 'react'
import { useMemoRef, useStyles } from '@platform/react/hook'
import { Grid } from '@mui/material'
import Text from 'ui/Element/Text/Simple'
import FabButton from 'ui/Element/Button/Fab'
import FileImage from 'ui/Element/Attachment/Item/File'

/**
 * Returns the AttachmentListTemplateRow element's current theme styles.
 *
 * @param {object} theme  the application's current theme
 * @returns {object}      the AttachmentListTemplateRow component's styles
 */
const styles = theme => ({
  root: {
    borderRadius: 4,
    overflow: 'hidden',
    alignItems: 'stretch',
    padding: theme.spacing(1),
    '&:hover': {
      backgroundColor: 'rgba(87, 87, 87, 0.04)',
    },
  },
  name: {
    display: 'block',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    paddingLeft: theme.spacing(2),
  },
  ...theme.custom.attachmentListTemplateRow,
})

/**
 * AttachmentListTemplateRow attachment component
 *
 * Displays an attached file, together with a button to remove it, as a row.
 *
 * @param {Object} attachment the attached file
 * @param {Object} types      the types for the {@link FileImage} element
 * @param {string} group      the attachment group identifier
 * @param {Function} onClick  a handler to be called whenever the clear button is clicked
 * @param {Object} props      additional props
 */
const AttachmentListTemplateRow = ({ attachment, types, group, events: { onClick }, ...props }) => {
  const classes = useStyles(styles)()

  const { clickable, skipPlaceholder } = props
  const { value: item } = attachment

  const handleClick = useCallback(onClick(group, attachment), [attachment])

  return (
    <Grid
      item
      container
      className={classes.root}
      xs={12}
    >
      <FileImage
        types={types}
        clickable={clickable}
        attachment={attachment}
        skipPlaceholder={skipPlaceholder}
        xs={2}
        sm={2}
        md={2}
        lg={2}
        xl={2}
      />
      <Grid
        item
        component={Text}
        alignContent={'center'}
        className={classes.name}
        xs={8}
      >
        {item.name}
      </Grid>
      <Grid
        item
        display={'flex'}
        justifyContent={'flex-end'}
        xs={2}
      >
        <FabButton
          icon={{ name: 'clear', variant: 'filled' }}
          classes={{ icon: classes.closeIcon }}
          events={{ onClick: handleClick }}
        />
      </Grid>
    </Grid>
  )
}

export default useMemoRef(AttachmentListTemplateRow, props => [props.attachment])
