/* global React */
import { Grid, lighten } from '@mui/material'
import { useMemoRef, useStyles } from '@platform/react/hook'
import Text from 'ui/Element/Text/Simple'
import { lazy, Suspense } from 'react'
import ErrorBoundary from 'ui/Error'
import Simple from 'ui/Element/Button/Simple'

/**
 * Returns the Date element's current theme styles.
 *
 * @param {object} theme  the application's current theme
 * @returns {object}      the Date element's styles
 */
const styles = theme => ({
  root: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    alignItems: 'center',
    padding: theme.spacing(1),
    borderBottom: `1px solid ${theme.palette.divider}`,
    '& *': {
      marginRight: theme.spacing(1),
    },
  },
  message: {
    color: theme.palette.text.primary,
    fontWeight: 700,
    textAlign: 'center',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  button: {
    backgroundColor: lighten(theme.palette.primary.main, 0.9),
    color: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: lighten(theme.palette.primary.main, 0.9),
      boxShadow: 'none',
    },
  },
  icon: {
    margin: 0,
  },
  ...theme.custom.systemMessage,
})

/**
 * Conversation helper function
 *
 * Returns a list of conversations for the current resource (e.g. ticket).
 *
 * @param {Object} classes          styles
 * @param {Object[]} conversations  list of recipients
 * @param {Object} Template         component for rendering each recipient
 * @return {*}
 * @private
 */
const _conversations = ({ classes, conversations, Template }) => conversations.map((conversation, index) => {
  const recipient = `${conversation.firstName} ${conversation.lastName}`

  return (
    <Template
      key={index}
      fullWidth={false}
      value={recipient}
      variant={'contained'}
      startIcon={'person'}
      startIconProps={{
        color: 'primary.main',
        variant: 'filled',
        raw: false,
        height: 24,
        width: 24,
      }}
      classes={{
        contained: classes.button,
        startIcon: classes.icon,
      }}
    />
  )
})

/**
 * Conversations
 *
 * Show a bar of currently ongoing conversations for the current ressource (e.g. ticket)
 *
 * TODO: Currently, only one recipient/conversation per ticket is allowed. Later on, we need this
 * component to function as a tab bar to switch between the different conversations.
 *
 * @param {Boolean} hidden                  whether to show the component
 * @param {Object} label                    the label
 * @param {Function: JSX.Element} template  the component to use for each conversation.
 * @param {Object} events                   incoming events
 * @param {Object} props                    additional props
 *
 * @return {null|JSX.Element}
 * @constructor
 */
const Conversations = ({ hidden = false, label, template = Simple, events, ...props }) => {
  const { onOpen } = events
  const classes = useStyles(styles)()
  const Button = lazy(() => import(`@ui/${template}`))

  const { hidden: isEmpty, data: conversationList } = onOpen?.(null) || {}

  return hidden || isEmpty ? null : (
    <ErrorBoundary>
      <Suspense fallback={null}>
        <Grid
          item
          className={classes.root}
        >
          <Text
            key={'label'}
            className={classes.message}
            variant={'body1'}
          >
            {label}
          </Text>
          {_conversations({ classes, conversations: conversationList, Template: Button })}
        </Grid>
      </Suspense>
    </ErrorBoundary>
  )
}

export default useMemoRef(Conversations, props => [props.value])
