/* global React */
import { useMemoRef, useStyles } from '@platform/react/hook'
import { Grid, useTheme } from '@mui/material'

const styles = theme => ({
  root: {},
})

/**
 * CartSteps component
 *
 * @param {Object} cart             the currently active cart
 * @param {React.Ref} forwardedRef  forwarded ref
 * @param {Object} parentClasses    classes injected from the parent
 * @param {number} activeStep       the currently active step
 * @param {Object} props            additional props
 * @returns {JSX.Element}
 * @constructor
 */
const CartSteps = ({ cart, classes: parentClasses, activeStep, children, ...props }) => {
  const theme = useTheme()
  const classes = { ...useStyles(styles)(), parentClasses }
  const { spacing = 0, space = 0, gap = 0 } = props

  return (
    <Grid
      item
      container
      className={classes.root}
      xs={props.xs}
      sm={props.sm}
      md={props.md}
      lg={props.lg}
      xl={props.xl}
      style={{
        padding: spacing
          ? theme.spacing(spacing)
          : theme.spacing(space, gap),
      }}
    >
      {!cart ? null
        : React.Children.map(children, (child, i) => (
          i !== activeStep ? null : React.cloneElement(child, {
            update: props.update,
          })
        ))
      }
    </Grid>
  )
}

export default useMemoRef(CartSteps, false)
