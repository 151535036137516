import Article from 'ui/Element/List/Item/Cell/EquipmentInformation/Name/Article'
import Equipment from 'ui/Element/List/Item/Cell/EquipmentInformation/Name/Equipment'
import DocChunk from 'ui/Element/List/Item/Cell/EquipmentInformation/Name/DocChunk'
import Documentation from 'ui/Element/List/Item/Cell/EquipmentInformation/Name/Documentation'

/**
 * Displays the appropriate list item based on the incoming {@code type}.
 *
 * @param {object} props    element's properties
 *
 * @returns {JSX.Element}
 * @constructor
 */
export const ListItemCellEquipmentInformationName = (props) => {
  const { type } = props

  return (
    <>
      {type === 'article' && (<Article {...props} />)}
      {type === 'equipment' && (<Equipment {...props} />)}
      {type === 'docChunk' && (<DocChunk {...props} />)}
      {type === 'documentation' && (<Documentation {...props} />)}
    </>
  )
}

export default ListItemCellEquipmentInformationName
