import { Grid, Typography, Icon, useTheme } from '@mui/material'

/**
 * AttachmentUploadLabel element
 *
 * Displays a label intended to be displayed when there are no files attached to an Upload
 * component.
 *
 * @param {string} text       a string to be used as the label's text
 * @param {function} onClick  a function to be executed whenever the label is clicked
 * @param {number} minHeight  the number of theme's spacings the minimum height should take
 * @param {boolean} hidden    whether an empty string should be returned instead of the label
 * @returns {JSX.Element}
 * @constructor
 */
const AttachmentUploadLabel = ({ text = 'Drop or Click', onClick, minHeight = 0, hidden }) => {
  const theme = useTheme()
  return hidden ? '' : (
    <Grid
      item
      container
      direction={'column'}
      justifyContent={'center'}
      alignItems={'center'}
      onClick={onClick}
      style={{
        minHeight: theme.spacing(minHeight),
        textAlign: 'center',
      }}
    >
      <Grid
        item
        component={Icon}
        // xs={12}
        fontSize={'large'}
        color={'primary'}
        style={{
          opacity: 0.6,
        }}
      >
        cloud_upload
      </Grid>
      <Grid
        item
        component={Typography}
      >
        {text}
      </Grid>
    </Grid>
  )
}
export default AttachmentUploadLabel
