import { Typography } from '@mui/material'
import CellHOC from '@platform/react/hoc/list/cell'

/**
 * Custom styles for the note spent time.
 *
 * @return {{root: {overflow: string}}}
 */
const styles = () => ({
  root: {
    overflow: 'visible',
  },
})

/**
 * Displays the {@param props.spentTime} of the note.
 *
 * @param {Object} classes  styling for the cell
 * @param {Object} props    props for the cell
 *
 * @returns {JSX.Element}
 */
const ListItemCellNoteSpentTime = (classes, props = {}) => {
  const { spentTime = 0 } = props
  const h = Math.floor(parseInt(spentTime, 10) / 3600)
  const m = Math.floor((parseInt(spentTime, 10) - h * 3600) / 60) % 60

  return (
    <>
      <Typography
        color={'black'}
        variant={'body1'}
        component={'span'}
        sx={{ fontWeight: 700 }}
      >
        {`${String(h).padStart(2, '0')}:${String(m).padStart(2, '0')}`}
      </Typography>
    </>
  )
}

export default CellHOC(ListItemCellNoteSpentTime, styles)
