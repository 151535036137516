/* global React */
import { Menu, MenuItem } from '@mui/material'
import AppContext from '@platform/react/context/application'
// import { useStyles } from '@platform/react/hook'

// TODO: The intention of these styles is to allow the user to interact with the content even if the
//       context menu is open, but for some reason, even if it is working, the context menu opened
//       in this way always seem to be the one opened in the first place. We should find the root of
//       the problem before enabling this functionality again.
// const styles = () => ({
//   root: {
//     pointerEvents: 'none',
//   },
//   paper: {
//     pointerEvents: 'none',
//   },
//   list: {
//     pointerEvents: 'all',
//   },
// })

/**
 * Displays a disabled informative label to be used when a context menu has options available.
 *
 * @returns {JSX.Element}
 * @constructor
 */
const EmptyMenuItem = () => {
  const { intl } = React.useContext(AppContext)
  const _key = 'button.noActionsAvailable'
  const label = intl._t(_key, {
    ns: 'common',
    _key,
    defaultValue: 'No actions available',
  })

  return (
    <MenuItem
      disabled={true}
    >
      {label}
    </MenuItem>
  )
}

/**
 * Context menu element to be (usually) displayed with a right mouse click.
 *
 * @param {object} anchorEl     the element the menu is attached to
 * @param {boolean} open        whether the menu should be displayed
 * @param {boolean} hidden      whether the menu should be hidden
 * @param {object} pos          the position of the menu relative to {@param anchorEl}
 * @param {object} props        additional element's properties
 * @param {object} ref          a reference to the {@link Menu} element at the root
 * @returns {null|JSX.Element}
 * @constructor
 */
// eslint-disable-next-line object-curly-newline
const ContextMenu = ({ anchorEl, open, hidden, pos, ...props }, ref) => (hidden ? null : (
  <Menu
    ref={ref}
    open={open}
    keepMounted
    anchorReference={'anchorPosition'}
    anchorPosition={pos}
  >
    {props.data.key
      ? props.children
      : <EmptyMenuItem/>}
  </Menu>
))

export default ContextMenu
