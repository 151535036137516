/* eslint-disable import/no-extraneous-dependencies,object-curly-newline */
/* global React, G */
import {
  forwardRef,
  useCallback,
  useContext,
  useImperativeHandle,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { Drawer, Grid, MenuList, Paper } from '@mui/material'
import { withStyles } from '@mui/styles'
import { GuestProvider } from 'platform/react/context/guest'
import BooleanContext from 'platform/react/context/boolean'
import AppContext from 'platform/react/context/application'
import useACLIterator from '@platform/react/hook/useAclIterator'
import AclLinkItem from 'ui/Component/Acl/Item/Link'
import settings from '@tenant/settings'

const { staticActions } = settings

const styles = theme => ({
  drawer: {
    position: 'relative',
    width: theme.layout.navigationDrawerWidth,
    backgroundColor: theme.palette.sideBar.background || theme.palette.gray[960],
    height: '100%',
    flexShrink: 0,
    flexGrow: 1,
  },
  drawerPaperOverwrite: {
    position: 'absolute',
    width: theme.layout.navigationDrawerWidth,
    backgroundColor: theme.palette.sideBar.background || theme.palette.gray[960],
    borderColor: theme.palette.gray[900],
    zIndex: 1150,
    // paddingTop: theme.layout.appBarHeight,
    paddingBottom: 8,
    borderRadius: 0,
  },
  logo: {
    // height: theme.custom.appBar.height,
    ...theme.mixins.toolbar,
    // backgroundColor: theme.palette.appBar.actionBar || theme.palette.primary.main,
    backgroundImage: theme.logo.url,
    backgroundColor: 'transparent',
    // backgroundSize: '100% 100%',
    backgroundSize: theme.logo.backgroundSize || '80%',
    imageRendering: 'high-quality',
    // backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    width: theme.layout.navigationDrawerWidth,
    height: theme.layout.actionBarHeight,
    flexShrink: 0,
  },
  toolbarMargin: {
    height: theme.layout.toolBarHeight,
    backgroundColor: 'transparent',
    // backgroundColor: theme.palette.appBar.toolBar || theme.palette.primary.dark,
    flexShrink: 0,
  },
  temporaryDrawerRoot: {
    backgroundColor: theme.palette.sideBar.background || theme.palette.gray[960],
    borderColor: theme.palette.gray[900],
    width: theme.layout.navigationDrawerWidth,
    paddingBottom: 8,
    borderRadius: 0,
  },
  menuItem: {
    opacity: [1, '!important'],
    margin: [['.4rem', '0.5rem', 0]],
    padding: [['0.5rem', '0.75rem']],
    borderRadius: '100px',
    color: theme.palette.gray[500],
    '& .MuiListItemIcon-root > div': {
      backgroundColor: theme.palette.gray[500],
    },
    '&.Mui-selected': {
      backgroundColor: theme.palette.white.main,
      color: theme.palette.black.main,
      opacity: 1,
      '& .MuiListItemIcon-root > div': {
        backgroundColor: theme.palette.black.main,
      },
    },
  },
  menuItemIcon: {
  },
  menuItemText: {
    fontWeight: 500,
    whiteSpace: 'normal',
  },
  chip: {
    alignSelf: 'center',
    backgroundColor: 'signal.main',
    color: 'common.white',
    fontWeight: 'bold',
  },
  ...theme.custom.sideBar,
})

const _staticItems = (selected, session, intl, classes) => staticActions?.map(({ name, icon }) => ({
  key: `static.${name}`,
  options: {
    key: `static.${name}`,
    icon: icon || 'favorite',
  },
  label: intl._t(`navigation.${name}`, {
    ns: 'custom',
    _key: `navigation.${name}`,
    defaultValue: name,
  }),
  route: {
    module: 'static',
    action: 'text',
    ref: name,
  },
  variant: 'outlined',
  icon: icon || 'favorite',
  selected,
  context: session[G.CONTEXT],
  classes,
})) || []

/**
 * Side Bar Component
 *
 * @type {React.ForwardRefExoticComponent<React.PropsWithoutRef<{}> & React.RefAttributes<unknown>>}
 */
const SideBar = forwardRef((props, ref) => {
  const { classes } = props
  const [state, setState] = useState({})
  const [highlight, setHighlight] = useState([])
  const { boolean, setBoolean } = useContext(BooleanContext)
  const { eventBus, navigation, session, acl, intl, router } = useContext(AppContext)
  const init = useRef('gaia:init')
  const change = useRef('gaia:hashchange')
  const staticItems = useMemo(() => _staticItems(highlight, session, intl, classes), [highlight])

  useImperativeHandle(ref, () => ({
    setState: newState => setState({ ...newState }),
  }))

  const fn = useCallback(() => {
    const highlightKeys = []
    const { module, action, key } = router.path()
    navigation.concat(staticItems).forEach((link) => {
      const { route, options: { key: linkKey } } = link
      const condition = route.module === module
        && route.action === action
        && (!key || route.ref === key)

      condition && highlightKeys.push(linkKey)
      // highlightKey = !highlightKey ? condition && key : highlightKey
      return condition
    })
    setHighlight(highlightKeys)
  }, [])

  // subscribe to app's hashchange events, set highlight
  useLayoutEffect(() => {
    eventBus.addEventListener(init.current, fn, { once: true })
    eventBus.addEventListener(change.current, fn)
    return () => eventBus.removeEventListener(change.current, fn)
  }, [])

  // set initial highlight
  // useLayoutEffect(fn, [])

  const onLinkClick = useCallback(() => setBoolean(false), [])
  const events = useRef({ onClick: onLinkClick })

  const options = {
    events: events.current,
    acl: acl.link,
    Template: AclLinkItem,
  }

  const [topChildren, bottomChildren] = navigation.reduce(([top, bottom], item) => {
    const target = item.options.position === 'bottom' ? bottom : top

    target.push({
      ...item,
      ...item.options,
      label: item.options.label
        ? intl._t(`navigation.${item.options.key}`, {
          ns: 'sidebar',
          _key: `navigation.${item.options.key}`,
          defaultValue: item.options.label,
        })
        : '',
      selected: highlight,
      context: session[G.CONTEXT],
      classes,
    })

    return [top, bottom]
  }, [[], []])
    .map((items, index) => (index === 0 ? items.concat(staticItems) : items))
    .map(items => useACLIterator(items, options))

  return (
    <GuestProvider>
      {
        v => v && !state.fullScreen && (<>
          <Grid sx={{ display: { xs: 'none', md: 'block' } }}>
            {/* overwriting default class, for available classes, see: https://material-ui.com/api/drawer/#css */}
            <Drawer
              classes={{ paper: classes.drawerPaperOverwrite }}
              variant={'permanent'}
              className={classes.drawer}
              elevation={0}
            >
              <Paper
                square={true}
                elevation={0}
                className={classes.toolbarMargin}
              />
              <Paper
                square={true}
                className={classes.logo}
                // set to 0, if appBar has no Padding, otherwise shadow doesn't look good
                elevation={0}
              />
              <Grid
                container
                direction={'column'}
                justifyContent={'space-between'}
                height={'100%'}
              >
                <MenuList key={'top'}>
                  {topChildren}
                </MenuList>
                <MenuList key={'bottom'}>
                  {bottomChildren}
                </MenuList>
              </Grid>
            </Drawer>
          </Grid>
          <Grid sx={{ display: { md: 'none', xs: 'block' } }}>
            <Drawer
              classes={{ paper: classes.temporaryDrawerRoot }}
              variant={'temporary'}
              open={boolean}
              onClose={() => setBoolean(!boolean)}
              className={classes.drawer}
              elevation={0}
            >
              <Paper
                square={true}
                elevation={0}
                className={classes.toolbarMargin}
              />
              <Paper
                square={true}
                className={classes.logo}
                // set to 0, if appBar has no Padding, otherwise shadow doesn't look good
                elevation={0}
              />
              <Grid
                container
                direction={'column'}
                justifyContent={'space-between'}
                height={'100%'}
              >
                <MenuList key={'top'}>
                  {topChildren}
                </MenuList>
                <MenuList key={'bottom'}>
                  {bottomChildren}
                </MenuList>
              </Grid>
            </Drawer>
          </Grid>
        </>)
      }
    </GuestProvider>
  )
})

export default withStyles(styles)(SideBar)
