import Article from 'ui/Element/Field/Autocomplete/Option/EquipmentInformation/Article'
import Equipment from 'ui/Element/Field/Autocomplete/Option/EquipmentInformation/Equipment'
import DocChunk from 'ui/Element/Field/Autocomplete/Option/EquipmentInformation/DocChunk'
import Documentation from 'ui/Element/Field/Autocomplete/Option/EquipmentInformation/Documentation'

/**
 * Will load the appropriate option component based on the {@code type} of the incoming
 * option.
 *
 * @param {Object} props    props for the option
 *
 * @return {*}
 * @constructor
 */
const EquipmentInformationOption = (props) => {
  const { option: { value: { type } } } = props

  return (
    <>
      {type === 'article' && (<Article {...props} />)}
      {type === 'equipment' && (<Equipment {...props} />)}
      {type === 'docChunk' && (<DocChunk {...props} />)}
      {type === 'documentation' && (<Documentation {...props} />)}
    </>
  )
}

export default EquipmentInformationOption
