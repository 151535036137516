import { Typography } from '@mui/material'
import CellHOC from '@platform/react/hoc/list/cell'

/**
 * Intended to display the name of the organisation alongside a status.
 *
 * @param {object} props    element's properties
 * @param {object} classes  element's style classes
 *
 * @returns {JSX.Element}
 */
const ListItemCellOrganisationSupportedBy = (classes, props = {}) => {
  const { supportedBy: { value: { name = '' } = {} } = {} } = props


  return <>
    {!name ? null : (
      <Typography
        className={classes.row}
      >
        {name}
      </Typography>
    )}
  </>
}

export default CellHOC(ListItemCellOrganisationSupportedBy)
