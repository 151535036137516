import { Typography } from '@mui/material'
import CellHOC from '@platform/react/hoc/list/cell'
import ApplicationContext from '@platform/react/context/application'
import { useContext } from 'react'

/**
 * Intended to display name and address of the organisation where a person belongs to.
 *
 * @param {Object} classes  styling for the cell
 * @param {Object} props    props for the cell
 *
 * @returns {JSX.Element}
 */
const ListItemCellPersonOrganisation = (classes, props = {}) => {
  const { intl } = useContext(ApplicationContext)
  const { organisation = {} } = props
  const {
    value: {
      name,
      address = {},
    } = {},
  } = organisation
  const addressSummary = intl.address(address)

  return (
    <>
      <Typography
        color={'black'}
        variant={'body2'}
        className={classes.row}
        sx={{ fontWeight: 700 }}
      >
        {name}
      </Typography>
      <Typography
        variant={'caption'}
        color={'textSecondary'}
        className={classes.row}
      >
        {addressSummary}
      </Typography>
    </>
  )
}

export default CellHOC(ListItemCellPersonOrganisation)
