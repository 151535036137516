/* eslint-disable no-nested-ternary */
import React, { useEffect, useRef, useState } from 'react'
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography, useTheme } from '@mui/material'
import { useAttachmentAPI, useMemoRef, useStyles } from '@platform/react/hook'
import ErrorBoundary from 'ui/Error'
import PlaceholderSimple from 'ui/Element/Placeholder/Simple'
import { isObj, PlatformEvent } from 'lib/util'
import SvgIcon from 'ui/Element/Icon/Svg'
import { empty } from 'lib/util/object'
import { CloseOutlined } from '@mui/icons-material'
import Button from 'ui/Element/Button/Simple'
import { formatFilesize } from 'lib/util/attachment'

/**
 * Returns the avatar element's current theme styles.
 *
 * @param {object} theme      the application's current theme
 * @param {boolean} stretch   whether the parent container should get full height
 * @param {string} ratio      ratio to use for the image
 * @param {number} imageRatio natural ratio of the image
 * @returns {object}      the avatar's styles
 */
const styles = (theme, { stretch, ratio, imageRatio, canFullScreen }) => ({
  root: {
    // [theme.breakpoints.up('md')]: {
    flex: 1,
    // Makes images parent container take full height, only use this if ratio === null and the
    // image doesn't show because it has 0 height
    ...stretch && {
      height: '100%',
    },
    width: '100%',
    position: 'relative',
    // display: 'inline-block',
    // },
    display: 'flex',
    ...ratio === 'circular' && {
      borderRadius: '50%',
    },
    ...(ratio === 'circular' || ratio === 'square') && {
      aspectRatio: '1 / 1',
    },
    ...ratio === 'native' && {
      aspectRatio: !Number.isNaN(imageRatio) ? imageRatio : 1,
    },
  },
  imgContainer: {
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  image: {
    // [theme.breakpoints.up('md')]: {
    display: 'block',
    position: 'absolute',
    top: '50%',
    left: '50%',
    minHeight: '100%',
    minWidth: '100%',
    transform: 'translate(-50%, -50%)',
    // },
    maxWidth: '100%',
    width: 'auto',
    height: '100%',
    ...!(ratio === 'circular')
      ? { objectFit: 'contain' }
      : { borderRadius: '50%' },
    aspectRatio: '1 / 1',
    ...canFullScreen && { cursor: 'pointer' },
  },
  ...theme.custom.image,
})

const fullScreenStyles = theme => ({
  header: {
    height: '3.75rem',
    borderBottom: `1px solid ${theme.palette.gray[900]}`,
    padding: [['0.75rem', '1rem']],
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  title: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    columnGap: '0.5rem',
    marginRight: 'auto',
  },
  content: {
    overflow: 'hidden',
    padding: [['1rem', '1rem', 0, '1rem'], '!important'],
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    columnGap: '1rem',
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
    borderRadius: '0.5rem',
    cursor: 'pointer',
  },
})

export const createItem = (ref, name, api = {}) => ({
  key: ref,
  name,
  api: {
    ...api,
    ref,
  },
  value: {
    name,
    type: 'image/jpeg',
  },
})

/**
 * Fullscreen Box
 *
 * Show a fullscreen modal containing the image with an option for better
 * visibility with an option to download it.
 *
 * @param {boolean} open  open state of the modal
 * @param {string} src    blob- or external url to the attachment
 * @param {string} title  name of the attachment
 * @param {Object} events events for the modal
 * @param {Object} labels labels for the modal
 * @param {string} size   size for the attachment
 * @returns {Element}
 * @constructor
 */
const FullScreenBox = ({ open, src, title, events, labels, size }) => {
  const { onClick = null } = events
  const [isOpen, setIsOpen] = useState(false)
  const [link, setLink] = useState(null)

  useEffect(() => {
    const a = document.createElement('a')
    a.href = src
    a.download = title
    document.body.appendChild(a)
    setLink(a)

    return () => {
      document.body.removeChild(a)
      setLink(null)
    }
  }, [])

  useEffect(() => { setIsOpen(open) }, [open])

  const theme = useTheme()
  const classes = useStyles(fullScreenStyles)()

  return (
    <Dialog
      open={isOpen}
      onClose={onClick}
      fullScreen={true}
    >
      <DialogTitle className={classes.header} component={'div'}>
        <Box className={classes.title}>
          <Typography variant={'14/bold'}>
            {title}
          </Typography>
          <Typography variant={'14/medium'} color={'gray.500'}>
            {formatFilesize(size)}
          </Typography>
        </Box>
        <IconButton
          onClick={onClick}
          sx={{ color: theme.palette.black.main }}
        >
          <CloseOutlined/>
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <img
          alt={title}
          src={src}
          className={classes.image}
          onClick={onClick}
        />
      </DialogContent>
      <DialogActions className={classes.actions}>
        {!link ? null : (
          <Button
            fullWidth={false}
            variant={'contained'}
            color={'primary'}
            onClick={() => link?.click()}
            value={labels?.download || 'Download'}
          />
        )}
        <Button
          fullWidth={false}
          variant={'text'}
          color={'primary'}
          value={labels?.close || 'Close'}
          onClick={onClick}
        />
      </DialogActions>
    </Dialog>
  )
}

/**
 * Image Presenter
 *
 * @param {Object} forwardedRef           a reference to the avatar element
 * @param {Object} classes                an object containing the element classes
 * @param {Object} props                  additional props
 * @param {Object} [props.attachment]     attachment document for remote fetching
 * @param {string} [props.value]          key for the ressource to get
 * @param {Object} [props.api]            api information for the ressource to get
 * @param {string} [props.ratio='native'] ratio for the image. Can be 'native', 'square', 'circular'
 * @param {boolean} [props.stretch=false] whether the parent container should get full height
 * @param {String} [filename]             image filename for local images
 * @param {String|Object} fallback        image to show as a fallback
 * @returns {JSX.Element}                 the new avatar element
 * @constructor
 */
const Image = ({ forwardedRef, filename, fallback, ...props }) => {
  const {
    attachment,
    value,
    api,
    ratio = 'native',
    stretch = false,
    skipSkeleton = false,
    events = {},
    canFullScreen = false,
  } = props

  const valueIsObject = value ? isObj(value) : false
  const ref = valueIsObject ? value.key : value ?? attachment?.key
  const name = valueIsObject ? undefined : 'avatar'

  const imageRef = useRef()
  const [imageRatio, setImageRatio] = useState(null)
  const [isFullScreen, setIsFullScreen] = useState(false)

  // Executing onAttachment if present. If this component is wrapped by {@link Big},
  // it will have already applied it, that's why this event isn't added in the component
  // config, and needs to be added manually where needed.
  const trimmedAttachment = events?.onAttachment?.(new PlatformEvent('open', { value: attachment })) || null

  useEffect(() => {
    imageRef?.current
    && setImageRatio(imageRef.current.naturalWidth / imageRef.current.naturalHeight)
  }, [imageRef?.current])

  const classes = useStyles(styles, { stretch, ratio, imageRatio, canFullScreen })()

  const item = !empty(attachment)
    ? { api, ...trimmedAttachment || attachment } // if attachment has an api property, it prevails
    : createItem(ref, name, api)

  const fallBackIsIcon = fallback && isObj(fallback)
  const localPath = 'assets/img'

  // IMPORTANT: filename takes precedence over attachment. This means if we have a filename prop,
  // we'll always display this instead of the attachment. However, if we have an attachment prop
  // in addition to this, we also pass it to useAttachmentAPI, but not fetch it yet. The parent
  // component should decide when to fetch it!
  const { state, onLoad, fetch } = useAttachmentAPI({
    attachment: item,
    initial: filename === undefined && !!item.key,
  })

  const { src = null, loading } = state || {}

  // stateSetter is a setState function passed by a parent component that needs information about
  // the attachment. Let's execute it with the current state whenever it changes. But let's only
  // do this if we are working with an actual attachment and not a plain filename to avoid un
  // necessary re-renders.
  useEffect(() => { events?.onState?.({ state, fetch }) }, [state])
  useEffect(() => { events?.onState?.({ state: null, fetch }) }, [attachment])

  // Toggle handler for fullscreen
  const handleFullScreen = () => setIsFullScreen(prevState => !prevState)

  const image = filename
    ? filename.includes('http') ? filename : `${localPath}/${filename}`
    : src

  const imageElement = (imageSrc, alt) => imageSrc && (
    <img
      alt={alt}
      ref={imageRef}
      src={imageSrc}
      onLoad={onLoad}
      className={classes.image}
      {...canFullScreen && { onClick: handleFullScreen }}
    />
  )

  const iconElement = fallbackSrc => (
    <Box className={classes.imgContainer}>
      <SvgIcon {...fallbackSrc} />
    </Box>
  )

  const { background, ...fallbackProps } = fallBackIsIcon ? fallback : {}

  const img = image
    ? imageElement(image, filename)
    : fallBackIsIcon
      ? iconElement(fallbackProps)
      : fallback ? imageElement(`${localPath}/${fallback}`, fallback) : null

  return (
    <ErrorBoundary>
      {canFullScreen && image && !loading && (
        <FullScreenBox
          src={image}
          open={isFullScreen}
          size={item?.value?.size}
          title={item.value?.name || filename}
          events={{ onClick: handleFullScreen }}
          labels={{
            close: props?.closeLabel,
            download: props?.downloadLabel,
          }}
        />
      )}
      <Box
        ref={forwardedRef}
        className={classes.root}
        sx={{
          backgroundColor: (!loading || !item.key) && fallBackIsIcon ? background : null,
        }}
      >
        {!filename && !!item.key && loading
          ? !skipSkeleton && (
            <PlaceholderSimple
              isHidden={false}
              placeholderProps={{
                height: '100%',
                ...ratio === 'circular' && { variant: 'circular' },
              }}
            />
          )
          : img}
      </Box>
    </ErrorBoundary>
  )
}

export default useMemoRef(Image, props => [props.filename, props.attachment, props.value])
