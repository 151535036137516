import CellHOC from '@platform/react/hoc/list/cell'
import LabeledHOC from '@platform/react/hoc/list/labeled'
import { ListItemCellRequestType } from 'ui/Element/List/Item/Cell/Request/Type'
import { isStr } from 'lib/util'

/**
 * Function to return the desired label.
 *
 * @param {Object} props  props for the component
 * @return {string}
 */
const labelFn = (props) => {
  const { events, typeKey, requestLabel } = props
  const [type] = events?.getTicketType?.(typeKey) || []
  const request = (isStr(requestLabel) && requestLabel)
      || requestLabel?.props?.children
      || '-'

  return type
    ? `${type.value} ${request}`
    : `${requestLabel}`
}

export default CellHOC(
  LabeledHOC(
    labelFn,
    ListItemCellRequestType,
  ),
)
