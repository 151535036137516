import CellHOC from '@platform/react/hoc/list/cell'
import LabeledHOC from '@platform/react/hoc/list/labeled'
import {
  ListItemCellServiceItemInstalledAt,
} from 'ui/Element/List/Item/Cell/ServiceItem/InstalledAt'

export default CellHOC(
  LabeledHOC(
    props => props.labels.installedAt, ListItemCellServiceItemInstalledAt,
  ),
)
