import { Typography } from '@mui/material'
import CellHOC from '@platform/react/hoc/list/cell'

/**
 * Intended to display the description of a request.
 *
 * @param {Object} classes  styling for the cell
 * @param {Object} props    props for the cell
 *
 * @return {JSX.Element}
 * @constructor
 */
export const ListItemCellRequestDescription = (classes, props = {}) => {
  const { description = {} } = props

  return (
    <>
      <Typography
        variant={'body2'}
        color={'black'}
        className={classes.row}
        sx={{ fontWeight: 700 }}
      >
        {description.text}
      </Typography>
    </>
  )
}

export default CellHOC(ListItemCellRequestDescription)
