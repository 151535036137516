import SearchField from 'ui/Element/Field/Search'
import { Box, CircularProgress, Grid } from '@mui/material'
import SvgIcon from 'ui/Element/Icon/Svg'
import { forwardRef } from 'react'

const ForwardedRefSearchField = forwardRef(SearchField)

/**
 * Renders a {@link SearchField} input field to be used with {@link Autocomplete}.
 *
 * @param {Object} InputLabelProps  MUI's InputLabelProps
 * @param {Object} InputProps       MUI's InputProps
 * @param {Object} props            props from our {@link Autocomplete}
 * @param {Object} rest             residual props
 * @returns {JSX.Element}
 * @constructor
 */
const SearchInput = ({ InputLabelProps, InputProps, props, ...rest }) => (
  <>
    <ForwardedRefSearchField
      {...InputProps}
      {...rest}
      inputRef={props.ref}
      events={props.events}
      placeholder={props.label}
      helperText={props.helperText}
      searchOnClear={false}
      iconEnd={true}
      variant={'standard'}
      size={'large'}
      fullWidth
      endAdornment={(
        <>
          {props.loading ? <CircularProgress color="inherit" size={20} /> : null}
          {!props.icon ? null : (
            <Box
              onClick={props.events.onSearch}
              sx={{ cursor: 'pointer' }}
            >
              <SvgIcon
                width={'1rem'}
                height={'1rem'}
                icon={'search'}
                variant={'outlined'}
              />
            </Box>
          )}
        </>
      )}
      {...props.multiple && {
        multiline: true,
        minRows: 1,
      }}
    />
    <Grid
      container
      ref={InputProps.ref}
      sx={{
        marginTop: -2,
      }}
    />
  </>
)

export default SearchInput
