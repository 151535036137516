import Part from 'ui/Element/Field/Autocomplete/Option/Cart/Part'
import Assembly from 'ui/Element/Field/Autocomplete/Option/Cart/Assembly'

/**
 * Will load the appropriate option component based on the {@code type} of the incoming
 * option.
 *
 * @param {Object} props    props for the option
 *
 * @return {*}
 * @constructor
 */
const CartOption = (props) => {
  const { option: { type } } = props

  return (
    <>
      {type === 'File' && (<Part {...props} />)}
      {type === 'Directory' && (<Assembly {...props} />)}
    </>
  )
}

export default CartOption
