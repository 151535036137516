import CellHOC from '@platform/react/hoc/list/cell'
import LabeledHOC from '@platform/react/hoc/list/labeled'
import { ListItemCellTicketStatus, styles } from 'ui/Element/List/Item/Cell/Ticket/Status'

export default CellHOC(
  LabeledHOC(
    props => props.labels.label, ListItemCellTicketStatus,
  ),
  styles,
)
