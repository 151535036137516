import { Typography } from '@mui/material'
import CellHOC from '@platform/react/hoc/list/cell'
import { getFirstItem } from 'lib/util'

/**
 * Intended to display the name of the status of a cart.
 *
 * @param {object} props    element's properties
 * @param {object} classes  element's style classes
 *
 * @returns {JSX.Element}
 */
const ListItemCellCartStatus = (classes, props = {}) => {
  const { status, events = [] } = props

  const statusLabel = getFirstItem(events?.getStatus?.(status)) || {}

  return <>
    <Typography
      variant={'14/medium'}
      transform={'uppercase'}
      color={statusLabel?.color || 'textDark'}
    >
      {statusLabel?.value || '-'}
    </Typography>
  </>
}

export default CellHOC(ListItemCellCartStatus)
