import { Box, Typography } from '@mui/material'
import CellHOC from '@platform/react/hoc/list/cell'

/**
 * Intended to display the team's name.
 *
 * @param {Object} classes  styling for the cell
 * @param {Object} props    props for the cell
 *
 * @return {*}
 * @constructor
 */
const ListItemCellTeamName = (classes, props = {}) => {
  const { name } = props
  return (
    <Typography
      style={{ fontWeight: 'bold' }}
    >
      <Box
        component={'span'}
        className={classes.row}
        title={name}
      >
        {name}
      </Box>
    </Typography>
  )
}

export default CellHOC(ListItemCellTeamName)
