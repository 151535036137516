/* eslint-disable import/no-extraneous-dependencies, arrow-body-style,object-curly-newline */
import Tabs from 'ui/Component/Group/Tabs'
import useAcl from '@platform/react/hook/useAcl'

const _getAclChildren = (children, acl) => children.filter((child) => {
  const [props] = useAcl(acl, child)
  return !props?.hidden
})
/**
 * TabsGroup Component.
 *
 * Designed to represent each direct child with a tab element. Each child (tab) can have an id
 * prop set. A child element is displayed, whenever the {@param props.activeTab} has that
 * child's id as value.
 *
 * @param {Object} props.forwardedRef           a reference object to the root element
 * @param {Boolean} props.header                {@code true} to display a tab button for each child.
 *                                              The button's text will be set from a label property
 *                                              in each child's options
 * @param {Object} [props]                      additional component's properties
 * @param {Object} ref                          a reference object to the root element
 * @param {Object[]} [props.children]           the children elements to be set as one tab each
 * @param {Object[]} [props.children.events]    events for the tab
 * @param {Number|String} [props.children.id]   the value the {@param props.activeTab} must have for
 *                                              this element to be displayed
 * @param {Number|String} [props.activeTab]     the key of the currently active tab's element
 * @constructor
 */
const AclTabs = (props, ref) => Tabs({
  ...props,
  children: _getAclChildren(props.children, props.events.acl),
}, ref)
export default AclTabs
