/* eslint-disable object-curly-newline,implicit-arrow-linebreak */
import { Typography } from '@mui/material'
import { useMemoRef, useStyles } from '@platform/react/hook'

const styles = (theme, { noWrap }) => ({
  root: {
    whiteSpace: noWrap ? 'nowrap' : 'pre-line', // ref: https://github.com/mui-org/material-ui/issues/9189
    '& a': {
      color: theme.palette.secondary.main,
      textDecoration: 'none',
      fontWeight: 'bold',
    },
    // Should only be relevant if we use {@code component: 'div'} in config and want top render
    // a list
    '& > ul': {
      whiteSpace: 'normal',
      marginTop: '0.5rem',
      marginBottom: '0.5rem',
    },
  },
  ...theme.custom.text,
})

/**
 * Text UI Element.
 * Simple element used to display dynamic text, which can also be given variant, color, className
 * and children properties.
 *
 * @param {object} forwardedRef a reference to the root element
 * @param {string} [value]      a string to be used as value
 * @param {string} [variant]    a text style variant to set for the element
 * @param {string} [color]      a color style to set for the element
 * @param {string} [align]      the text alignment
 * @param {number} [maxLines]   the maximum number of lines to display
 * @param {Boolean} [noWrap]    whether or not to wrap the text
 * @param {string} [className]  additional classNames to set for the element
 * @param {any} [children]      a list of children elements to be used as content
 * @returns {JSX.Element}       the new Text element
 * @constructor
 */
const Text = ({
  forwardedRef,
  value,
  variant,
  component = 'p',
  color,
  align,
  maxLines,
  noWrap = false,
  className,
  children,
}) => {
  const classes = useStyles(styles, { noWrap })()
  return (
    <Typography
      className={`${classes.root} ${className}`}
      component={component}
      ref={forwardedRef}
      variant={variant}
      color={color}
      align={align}
      style={maxLines > 0 ? {
        maxWidth: '100%',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        textAlign: 'justify',
        lineClamp: maxLines,
        WebkitLineClamp: maxLines,
        display: '-webkit-box',
        boxOrient: 'vertical',
        WebkitBoxOrient: 'vertical',
      } : {}}
    >
      {value}
      {children}
    </Typography>
  )
}

export default useMemoRef(Text, props =>
  [props.value, props.color, props.variant, props.className, props.children])
