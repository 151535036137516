import { Box, Typography } from '@mui/material'
import CellHOC from '@platform/react/hoc/list/cell'
import { useContext } from 'react'
import ApplicationContext from '@platform/react/context/application'
import { getThemeColor } from 'lib/util'

/**
 * Custom styles for the component.
 *
 * @param {Object} theme
 * @return {{
 * workState20: {color},
 * workState10: {color},
 * workState30: {color}
 * }}
 */
export const styles = theme => ({
  workState10: {
    color: theme.palette.primary.main,
  },
  workState20: {
    color: theme.palette.warning.main,
  },
  workState30: {
    color: theme.palette.success.main,
  },
  closed: {
    color: theme.palette.text.secondary,
  },
})

/**
 * Intended to display the status of the ticket.
 *
 * @param {Object} classes  styling for the cell
 * @param {Object} props    props for the cell
 *
 * @returns {JSX.Element}
 */
export const ListItemCellTicketStatus = (classes, props = {}) => {
  const { intl } = useContext(ApplicationContext)
  const {
    status: statusKey,
    statusReason: statusReasonKey,
    modify: { timestamp: modifyTimestamp = '' },
    workState,
    events,
  } = props
  const [status] = events?.getStatus?.(statusKey || -1) || []
  const [statusReason] = events?.getStatusReason?.(statusReasonKey || -1) || []
  const statusReasonLabel = statusReason?.value
  const statusLabel = status?.value.toUpperCase()
  const modifyDate = modifyTimestamp
    ? intl.getLocaleDate(modifyTimestamp, { dateStyle: 'short' })
    : ''

  return (
    <>
      <Typography
        className={
          `${statusKey === 80
            ? classes.closed
            : classes[`workState${workState}`]} ${classes.row}`
        }
        sx={{
          fontWeight: 500,
          lineHeight: 1.3,
        }}
        variant={'body1'}
      >
        {statusLabel}
      </Typography>
      <Typography
        variant={'caption'}
        color={'textSecondary'}
        className={classes.row}
      >
        {!statusReasonLabel ? null : (
          <>
            <Box
              component={'span'}
              sx={{
                fontWeight: 'bold',
                color: theme => getThemeColor(theme, statusReason.color),
              }}
            >
              {statusReason?.value}
            </Box>
            &nbsp;-&nbsp;
          </>
        )}
        { modifyDate }
      </Typography>
    </>
  )
}

export default CellHOC(ListItemCellTicketStatus, styles)
