import { Box, Typography, useTheme } from '@mui/material'
import CellHOC from '@platform/react/hoc/list/cell'
import { SvgRoundedIcon } from 'ui/Element/Icon/Svg'
import AvatarImage from 'ui/Element/Image/Avatar'
import OverflowTooltip from 'ui/Element/Text/OverflowTooltip'

const styles = theme => ({
  root: {
    display: 'flex',
  },
  image: {
    maxWidth: '40px',
    width: '100%',
    height: '100%',
    marginRight: '0.75rem',
  },
  labels: {
    overflow: 'hidden',
  },
  comment: {
    marginTop: theme.spacing(1),
  },
})

/**
 * Displays the part name, articleNumber and comment for a part in the 'add part' list.
 *
 * @param {Object} classes  styling for the cell
 * @param {Object} props    props for the cell
 *
 * @return {*}
 * @constructor
 */
export const ListItemCellCartPartName = (classes, props = {}) => {
  const theme = useTheme()
  const { image, label, info1, partIcon, articleNumberLabel } = props || {}

  const currentAttachment = image?.attachment?.value?.name
    ? { ...image.attachment, key: image?.value?.attachmentId || image?.id || undefined, api: { ref: 'equipment' } }
    : { value: { name: '' } }

  return (
    <>
      <Box className={classes.image}>
        <AvatarImage
          attachment={currentAttachment}
          fallback={{
            icon: partIcon,
            color: theme.palette.text.secondary,
            variant: 'outlined',
          }}
        />
      </Box>
      <Box className={classes.labels}>
        <OverflowTooltip
          variant={'14/bold'}
          color={'textDark'}
        >
          {label}
        </OverflowTooltip>
        <OverflowTooltip
          variant={'12/regular'}
          color={'textSecondary'}
        >
          {`${articleNumberLabel} - ${info1}`}
        </OverflowTooltip>
      </Box>
    </>
  )
}

export default CellHOC(ListItemCellCartPartName, styles)
