/* eslint-disable object-curly-newline */
/* global React */
import { useRef, useCallback, forwardRef } from 'react'
import { Grid } from '@mui/material'

/**
 * Scroll Pane AttachmentCarousel
 *
 * @type {React.ForwardRefExoticComponent<React.PropsWithoutRef<{}> & React.RefAttributes<unknown>>}
 */
const AttachmentCarousel = forwardRef((props, ref) => {
  const { focus, data, ListItem, events, size, onClick, onDone } = props
  const children = data.map((item, i) => (
    <ListItem
      key = {item.key}
      attachment = {item}
      events = {events}
      focus={focus === i}
      onClick={onClick(i)}
      size={size}
    />
  ))

  const init = useRef(0)
  const onLoad = useCallback((e) => {
    // eslint-disable-next-line no-plusplus
    e.currentTarget.children[focus] && init.current++
    init.current === 1 && onDone(e.currentTarget.children[focus])
  }, [focus])

  return (
    <Grid
      item
      container
      ref={ref}
      onLoad={onLoad}
      direction={'column'}
      xs={10}
      md={10}
      component={'ul'}
      style={{
        overflowX: 'auto',
        maxHeight: size[1],
        listStyle: 'none',
      }}
    >
      {children}
    </Grid>)
})

export default AttachmentCarousel
