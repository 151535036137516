import { Typography } from '@mui/material'
import CellHOC from '@platform/react/hoc/list/cell'

/**
 * Intended to display the requester of the ticket.
 *
 * @param {Object} classes  styling for the cell
 * @param {Object} props    props for the cell
 *
 * @returns {JSX.Element}
 */
export const ListItemCellTicketRequester = (classes, props = {}) => {
  const { requesterContactData = {}, requesterOrgData = { address: {} } } = props
  const requesterContact = `${requesterContactData.firstName} ${requesterContactData.lastName}`
  const requesterOrg = `${requesterOrgData.name}, ${requesterOrgData.address.city}`

  return (
    <>
      <Typography
        color={'black'}
        variant={'body2'}
        className={classes.row}
        sx={{ fontWeight: 700 }}
      >
        {requesterContact}
      </Typography>
      <Typography
        variant={'caption'}
        color={'textSecondary'}
        className={classes.row}
      >
        {requesterOrg}
      </Typography>
    </>
  )
}

export default CellHOC(ListItemCellTicketRequester)
