/* eslint-disable no-param-reassign */
/* global React */
import { Children, cloneElement, useCallback, useEffect, useState } from 'react'
import { Grid, useMediaQuery } from '@mui/material'
import { PlatformEvent } from 'lib/util'
import { useStyles } from '@platform/react/hook'
import ErrorBoundary from 'ui/Error'

const styles = (theme, { totalHeight }) => ({
  root: {
    margin: 0,
    height: `${totalHeight}px`,
    maxHeight: 'calc(100vh - 5rem)',
    width: '100%',
    justifyContent: 'space-around',

    overflow: 'hidden',
    [theme.breakpoints.down('md')]: {
      overflow: 'scroll',
    },
  },
  content: {
    height: '100%',
    overflow: 'hidden',
  },
  left: {
    padding: [['2rem', '1rem'], '!important'],
    borderRight: `1px solid ${theme.palette.divider}`,
    height: '100%',
  },
  right: {
    padding: [['2rem', '2.5rem'], '!important'],
    height: '100%',
    width: '100%',
  },
  ...theme.custom.fileExplorer,
})

/**
 * FileExplorer component
 *
 * This is a wrapper component around {@link FileTree} and {@link FileFolder}
 * that holds and controls the shared state.
 *
 * @param {Number} spacing                spacing to use between child components
 * @param {Object} events                 provided events
 * @param {Function} events.onOpen        handler responsible for fetching the document tree
 * @param {Function} events.onAttachment  handler responsible for doing something with a single
 *                                        attachment
 * @param {Function} events.onNode        handler responsible for clicking on a node.
 *                                        Will be passed as {@code onClick} to child components
 * @param {Object} props                  additional props
 * @param {Object} props.folderProps      additional props for the folder component
 * @param {Object} props.treeProps        additional props for the tree component
 * @param {React.Ref} ref                 forwarded ref
 * @returns {JSX.Element}
 * @constructor
 */
const FileExplorer = ({ value = {}, events, ...props }, ref) => {
  const { onNode, onNodeType, onAttachment } = events
  const [nodeTree, setNodeTree] = useState({})
  const [jumpNode, setJumpNode] = useState(false)
  const [selectedNode, setSelectedNode] = useState(null)

  const [expandedNodes, setExpandedNodes] = useState([])
  const [height, setHeight] = useState(null)

  const { spacing = 0, gap = 0, space = 0, label } = props

  // Dynamically calculating the height based on the parents height
  const resizeHandler = () => {
    setHeight(ref?.current?.parentElement?.clientHeight)
  }

  // Recalculating height if we resize the window
  useEffect(() => {
    window.addEventListener('resize', resizeHandler)
    return () => { window.removeEventListener('resize', resizeHandler) }
  }, [])

  // Initially set the correct height once the DOM's ready
  useEffect(() => { resizeHandler() }, [ref?.current])

  const classes = useStyles(styles, { totalHeight: height, space })()

  const handleClick = useCallback(async (event) => {
    const { id, jump = false, back } = event.detail
    const { skipSingleNode = false, notifyOnSkippable = false } = event.detail
    const isExpanded = expandedNodes.some(expandedNode => expandedNode === id)

    const platformEvent = new PlatformEvent(event, {
      node: nodeTree,
      nodeId: id,
      skipSingleNode: isExpanded === false ? skipSingleNode : false,
      notifyOnSkippable: skipSingleNode === false ? notifyOnSkippable : false,
    })
    const node = await onNode(platformEvent)
    const nodeType = onNodeType(node.type)

    if (jump) {
      setJumpNode({ ...node, type: nodeType })
    }

    setSelectedNode({ ...node, type: nodeType, back })

    return node
  }, [onNode, nodeTree, expandedNodes])

  /**
   * Setting the tree
   */
  useEffect(() => {
    (async () => {
      // if we have value, the tree is a cachedTree, no need to execute onOpen
      const newTree = value?.$children
        ? value
        : await events?.onOpen?.(new PlatformEvent('open', null)) || {}

      if (newTree) {
        // if we have value, the cached tree is already filtered
        const filteredTree = value?.$children
          ? value
          : events?.filter?.(new PlatformEvent('filter', { value: newTree })) || newTree

        // if we have value, we don't need to remember the tree again
        !value.$children && events?.onChange?.(new PlatformEvent('change', { value: filteredTree }))

        // If we need to substitute labels in the tree (given @{code props.nodeLabels} is present),
        // lets feed them into the {@code onTree} handler, it will take care of it.
        const nodeLabels = props?.nodeLabels
          ? Object.keys(props.nodeLabels).reduce((acc, key) => (
            { ...acc, [key]: props[props.nodeLabels[key]] || undefined }
          ), {})
          : null
        const completeTree = events?.onTree?.({ node: filteredTree, labels: nodeLabels, label })

        setNodeTree(completeTree)

        const initialNode = events?.onInitialNode?.(new PlatformEvent('open', { value: completeTree }))
            || completeTree

        // Set root node as selected node initially.
        setSelectedNode(initialNode)
      }
    })()
  }, [])

  const { children, ...options } = props
  const isSm = useMediaQuery(t => t.breakpoints.down('md'))

  return !nodeTree.$children ? null : (
    <ErrorBoundary>
      <Grid
        container
        ref={ref}
        className={classes.root}
      >
        <Grid
          item
          container
          className={classes.content}
          {...props?.fullWidth
            ? { xs: 12 }
            : {
              xs: 12,
              sm: 11,
              md: 10,
              lg: 10,
              xl: 9,
            }}
        >
          {!isSm && Children.map(children, child => (
            child.props.hidden || child.key !== 'left' ? null : cloneElement(child, {
              nodes: nodeTree,
              selected: selectedNode,
              expanded: expandedNodes,
              className: classes.left,
              defaultSelected: selectedNode,
              setExpanded: setExpandedNodes,
              jumpNode,
              parentEvents: {
                onClick: handleClick,
                onNodeType: events.onNodeType,
              },
              ...options,
              ...props.leftProps,
            })
          ))}
          {Children.map(children, child => (
            child.props.hidden || child.key !== 'right' ? null : cloneElement(child, {
              node: selectedNode,
              className: classes.right,
              events: {
                ...child.props.events,
                onAttachment,
                onClick: handleClick,
                onNodeType: events.onNodeType,
                onNodeLabel: events.onNodeLabel,
                onBreadcrumbs: events.onBreadcrumbs,
                onSkip: events.onSkip,
              },
              ...options,
              ...props.rightProps,
            })
          ))}
        </Grid>
      </Grid>
    </ErrorBoundary>
  )
}

export default FileExplorer
