import { forwardRef, useCallback, useEffect, useState } from 'react'
import MenuItem from 'ui/Element/Menu/Item'
import { Menu } from '@mui/material'

/**
 * Helper function to return a event handler that can optionally
 * execute a callback.
 *
 * @returns {function(*, {handler?: null, callback?: null}): *}
 */
const useHandler = () => useCallback((event, { handler = null, callback = null }) => {
  event.preventDefault()
  event.stopPropagation()

  const result = handler?.(event)
  callback?.()

  return result ?? undefined
}, [])

/**
 * Context menu for each list item
 *
 * @param {boolean} hidden          whether to show the context menu
 * @param {string} value            the value of the {@link MenuItem} inside the context menu
 * @param {React.ReactNode} anchor  the anchor of the context menu
 * @param {Object} item             the list item
 * @param {Event} onClick           handler for clicking on the {@link MenuItem}
 * @returns {null|JSX.Element}
 * @constructor
 */
const ContextMenu = ({ hidden, value, anchor, item, onClick }) => {
  const [anchorEl, setAnchorEl] = useState(anchor)
  useEffect(() => {
    anchor && setAnchorEl(anchor)
  }, [anchor])

  const handler = useHandler()

  const handleClick = useCallback(async (event) => {
    const href = await onClick?.({
      ...event,
      detail: {
        ...event.detail,
        item: event.detail.item,
        openInNewTab: true,
      },
    })

    if (href) {
      const link = document.createElement('a')
      link.href = href
      link.target = '_blank'
      link.click()
    }

    setAnchorEl(null)
  }, [])

  const handleClose = event => handler(event, { callback: setAnchorEl(null) })

  const ForwardedMenuItem = forwardRef(MenuItem)

  return hidden ? null : (
    <Menu
      keepMounted
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={handleClose}
      elevation={3}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'center',
        horizontal: 'center',
      }}
    >
      <ForwardedMenuItem
        value={value}
        events={{
          onOpen: () => ({ item }),
          onClick: handleClick,
        }}
      />
    </Menu>
  )
}

export default ContextMenu
