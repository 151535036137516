/* global React */
import { Grid, Paper } from '@mui/material'
import { useMemoRef, useStyles } from '@platform/react/hook'
import ErrorBoundary from 'ui/Error'

const styles = theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    padding: '0.25rem 0.25rem 0.25rem 0.8rem',
    borderRadius: 0,
    border: `1px solid ${theme.palette.border.main}`,
  },
  horizontal: {
    flexDirection: 'row',
    borderLeft: 'none',
    borderRight: 'none',
  },
  vertical: {
    flexDirection: 'column',
    borderTop: 'none',
    borderBottom: 'none',
  },
  ...theme.custom.inlineBox,
})

/**
 * Inline Box component.
 *
 * @param direction
 * @param minHeight
 * @param forwardedRef
 * @param children
 * @param hidden
 * @returns {null|JSX.Element}
 * @constructor
 */
const InlineBox = ({
  direction = null, minHeight = 0, forwardedRef, children, hidden,
}) => {
  const classes = useStyles(styles)()

  return hidden ? null : (
    <ErrorBoundary>
      <Grid
        className={`${classes.root} ${direction && classes[direction]}`}
        style={{ minHeight }}
        ref={forwardedRef}
        component={Paper}
        direction={'row'}
        container
        item
      >
        {children}
      </Grid>
    </ErrorBoundary>
  )
}

export default useMemoRef(InlineBox, props => [props.children])
