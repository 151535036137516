import { useCallback } from 'react'
import { Grid } from '@mui/material'
import Button from 'ui/Element/Button/Simple'

/**
 * Close Button element
 *
 * Intended to be displayed by {@link AppBar} if the action UI configuration has the fullScreen
 * flag.
 *
 * @param {function} onClick  handler executed whenever the button is clicked
 * @param {boolean} [hidden]  whether the element should not be rendered
 * @return {JSX.Element}
 */
const CloseButton = ({ onClick, hidden }) => {
  const handleClick = useCallback(event => onClick(event), [onClick])

  return hidden ? null : (
    <Grid
      item
      container
      justifyContent={'center'}
      alignItems={'center'}
      xs={'auto'}
    >
      <Button
        icon={'close'}
        iconProps
        events={{ onClick: handleClick }}
        fullWidth={false}
      />
    </Grid>
  )
}

export default CloseButton
