/* eslint-disable object-curly-newline */
/* global React */
import { Grid } from '@mui/material'
import { useMemoRef, useStyles } from '@platform/react/hook'
import { format, parseISO } from 'date-fns'
import GaiaImageList from 'ui/Component/Attachment/List'
import fileConfig from 'configPlatform/ui/_shared/file.json5'
import MuiListTemplateSmall from 'ui/Component/Attachment/List/Template/Small'
import MuiListTemplateAction from 'ui/Component/Attachment/List/Template/Action'
import DecoratedText from 'ui/Element/Text/Hyper/Decorated'

/**
 * Returns the Message element's current theme styles.
 *
 * @param {object} theme  the application's current theme
 * @returns {object}      the Message element's styles
 */
const variantsStyles = {
  base: theme => ({
    row: {
      display: 'flex',
      width: '100%',
    },
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: theme.spacing(0.2),
      marginBottom: theme.spacing(0.2),
      width: '80%',
    },
    message: {
      padding: theme.spacing(1),
      borderStyle: 'none',
      borderRadius: '8px',
      boxShadow: [[0, 0, 1, 0, 'white', 'inset']],
      wordBreak: 'break-word',
    },
    messageEmoji: {
      fontSize: '2rem',
    },
    attachments: {
      marginTop: theme.spacing(0.4),
    },
    attachedImages: {
      justifyContent: 'flex-end',
    },
    attachedImage: {
      minHeight: '9.375rem!important',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundSize: 'contain',
      borderRadius: 8,
      paddingBottom: '100%',
    },
    footer: {
      color: '#afb1b3',
      display: 'flex',
      marginBottom: theme.spacing(1),
    },
    ...theme.custom.message,
  }),
  primary: theme => ({
    row: {
      flexDirection: 'row-reverse',
    },
    message: {
      alignSelf: 'flex-end',
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.text.inverted,
      '& a': {
        color: [[theme.palette.text.inverted], '!important'],
        textDecoration: 'underline!important',
        '&:hover': {
          textDecoration: 'none!important',
        },
      },
    },
    footer: {
      flexDirection: 'row-reverse',
    },
    ...theme.custom.messagePrimary,
  }),
  secondary: theme => ({
    row: {
      flexDirection: 'row',
    },
    message: {
      alignSelf: 'flex-start',
      backgroundColor: '#EEE',
      color: '#000',
      '& a': {
        color: '#000!important',
        textDecoration: 'underline!important',
        '&:hover': {
          textDecoration: 'none!important',
        },
      },
    },
    attachedImages: {
      justifyContent: 'flex-start',
    },
    footer: {
      flexDirection: 'row',
    },
    ...theme.custom.messageSecondary,
  }),
}

/**
 * Splits the list of attachment objects passed as {@param data} into image and non-image files and
 * uses a different template to display each of them.
 *
 * @param {object[]} data     a list of attachment objects
 * @param {object} classes    an object containing the element's css classes
 * @param {boolean} hidden    whether to return null instead of the element
 * @param {object} props      additional element's properties
 * @return {null|JSX.Element}
 * @constructor
 */
const Attachments = ({ data = [], classes, hidden, events, ...props }) => (hidden ? null : (
  <Grid
    {...props}
    item
    key={'attachments'}
    className={classes.attachments}
  >
    <GaiaImageList
      gap={0}
      size={3}
      space={1}
      spacing={0}
      data={data}
      download={false}
      types={fileConfig.types}
      filter={{ type: ['image'] }}
      ListItem={MuiListTemplateSmall}
      events={{ onClick: events?.onClick }}
      classes={{ vertical: classes.attachedImages }}
      itemClasses={{ image: classes.attachedImage }}
    />
    <GaiaImageList
      data={data}
      download={false}
      types={fileConfig.types}
      filter={{ type: ['!image'] }}
      ListItem={MuiListTemplateAction}
      events={{ onClick: events?.onClick }}
      classes={{ vertical: classes.attachedImages }}
    />
  </Grid>
))

/**
 * Displays the name of the author the creation date of the message.
 *
 * @param {string} authorName   a person's name
 * @param {string} timestamp    an ISO date string
 * @param {string} className    a css class to set for the root element
 * @param {string} sendingLabel text to be displayed while a message is being sent
 * @param {boolean} [hidden]    whether to return null instead of the element
 * @return {null|JSX.Element}
 * @constructor
 */
const Footer = ({ authorName, timestamp, className, sendingLabel, hidden }) => (hidden ? null : (
  <Grid className={className}>
    <span>
      { timestamp ? format(parseISO(timestamp), 'HH:mm') : sendingLabel }
    </span>
    <span>
      &nbsp;- &nbsp;
    </span>
    <span>
      { authorName }
    </span>
  </Grid>
))

const _classes = (variant) => {
  const variantsClasses = Object.keys(variantsStyles).reduce((acc, variantName) => {
    acc[variantName] = useStyles(variantsStyles[variantName])()
    return acc
  }, {})

  return Object.keys(variantsClasses.base).reduce((acc, key) => {
    const baseValue = variantsClasses.base[key]
    const variantValue = variantsClasses[variant][key]
    const values = [baseValue]
    variantValue && values.push(variantValue)
    acc[key] = values.join(' ')
    return acc
  }, {})
}

/**
 * Message Item.
 *
 * Item used to display any kind of message sent by a user.
 *
 * @param {Gaia.Model.Message} message      the message object
 * @param {('primary'|'secondary')} variant the style variant
 * @param {string} currentUserId            the ID of the current user
 * @param {boolean} showFooter              whether to display the footer
 * @param {object} props                    additional element's properties
 * @param {object} labels                   translated information labels
 * @param {string} labels.meLabel           translation of english 'me'
 * @param {string} labels.sendingLabel      text to be displayed while a message is being sent
 * @returns {JSX.Element}                   the new Message element
 * @constructor
 */
const Message = ({ message, variant = 'secondary', currentUserId, showFooter, labels, events }) => {
  const classes = _classes(variant)
  const { meLabel, sendingLabel } = labels
  const { value, refs } = message
  const {
    text,
    submitTimestamp,
    attachments = [],
  } = value
  const submitter = refs.submitter?.[0]
  const {
    person: [submitterPerson] = [],
  } = submitter?.refs || {}

  const submitterName = submitterPerson?.value.name || value.sourcePartner || ''
  const authorName = refs.submitter?.[0].key === currentUserId ? meLabel : submitterName
  const emojiOnly = /^[\p{Emoji}\s]{1,5}$/u.test(text)

  const emojiClassName = emojiOnly ? classes.messageEmoji : ''

  return (
    <Grid
      item
      className={classes.row}
    >
      <Grid
        item
        className={classes.wrapper}
      >
        <Attachments
          data={attachments}
          classes={classes}
          events={events}
          hidden={!attachments || !attachments.length}
          xs={12}
        />
        {!text ? null : (
          <Grid
            item
            className={`${classes.message} ${emojiClassName}`}
          >
            <DecoratedText
              value={text}
            />
          </Grid>
        )}
        <Footer
          authorName={authorName}
          timestamp={submitTimestamp}
          sendingLabel={sendingLabel}
          className={classes.footer}
          hidden={!showFooter}
        />
      </Grid>
    </Grid>
  )
}

export default useMemoRef(Message, props => [props.value, props.showFooter])
