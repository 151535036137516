/* eslint-disable object-curly-newline,implicit-arrow-linebreak */
import { Alert as MUIAlert, Tooltip, Typography, useTheme } from '@mui/material'
import { useMemoRef, useStyles } from '@platform/react/hook'
import { getThemeColor } from 'lib/util'
import SvgIcon from 'ui/Element/Icon/Svg'

/**
 * Mapping between severity and colors
 * @type {{success: string, warning: string, error: string, info: string}}
 */
const colorMapping = {
  info: 'pending',
  warning: 'warning',
  error: 'error',
  success: 'success',
}

/**
 * Mapping between severity and icons
 * @type {{success: string, warning: string, error: string, info: string}}
 */
const iconMapping = {
  info: 'info',
  warning: 'triangle',
  error: 'close-circle',
  success: 'check',
}

const styles = theme => ({
  root: {
    width: '100%',
    padding: [['0.5rem', '0.75rem']],
    '& .MuiAlert-action': {
      padding: 0,
      paddingLeft: '0.5rem',
      marginRight: 0,
      alignItems: 'center',
    },
  },
  text: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  ...theme.custom.alert,
})

/**
 * Alert UI Element
 *
 * Element to display a text as a MUI alert.
 *
 * @param {object} forwardedRef         a reference to the root element
 * @param {string} [value]              a string to be used as value
 * @param {string} [variant]            a text style variant to set for the element
 * @param {string} [textColor]          a color style to override the default text color
 * @param {string} [severity]           a severity level
 * @param {Object} props                additional props
 * @param {Object} [props.endIcon]      end icon to use
 * @param {string} [props.tooltipText]  tooltip text to use
 * @returns {JSX.Element}       the new Text element
 * @constructor
 */
const Alert = ({ forwardedRef, value, variant = 'standard', severity = 'info', textColor, ...props }) => {
  const classes = useStyles(styles)()
  const theme = useTheme()

  const icon = <SvgIcon
    raw={false}
    color={
      textColor
        ? getThemeColor(theme, textColor)
        : theme.palette[colorMapping[severity]][700]
    }
    icon={iconMapping[severity]}
    variant={'outlined'}
  />

  const endIcon = props.endIcon
    ? <SvgIcon
      raw={false}
      color={
        textColor
          ? getThemeColor(theme, textColor)
          : theme.palette[colorMapping[severity]][700]
      }
      icon={props.endIcon.name}
      variant={props.endIcon.variant}
    />
    : null

  return (
    <Tooltip title={props.tooltipText || value}>
      <MUIAlert
        ref={forwardedRef}
        icon={icon}
        className={classes.root}
        variant={variant}
        severity={severity}
        action={endIcon}
      >
        <Typography
          variant={'14/bold'}
          className={classes.text}
          color={
            textColor
              ? getThemeColor(theme, textColor)
              : theme.palette[colorMapping[severity]][700]
          }
        >
          {value}
        </Typography>
      </MUIAlert>
    </Tooltip>
  )
}

export default useMemoRef(Alert, props => [props.value])
