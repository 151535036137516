import { Box, Typography, useTheme } from '@mui/material'
import CellHOC from '@platform/react/hoc/list/cell'
import { def } from 'lib/util'
import settings from '@tenant/settings'
import SvgIcon from 'ui/Element/Icon/Svg'

const styles = theme => ({
  validation: {
    color: theme.palette.signal.main,
  },
  subRow: {
    gap: '5px',
    display: 'flex',
    alignItems: 'center',
  },
  partsAvailable: {
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
  },
})
/**
 * Displays the name, serial and current state (whether it has an equipment and a product) of a
 * ServiceItem.
 *
 * @param {Object} props    element's properties
 * @param {Object} classes  element's style classes
 *
 * @returns {JSX.Element}
 */
const ListItemCellDeviceWithSerial = (classes, props = {}) => {
  const theme = useTheme()
  const {
    acl,
    name,
    serial,
    product,
    equipment,
    toBeValidated,
    inverted = false,
    toBeValidatedInfo,
    partsAvailable: partsAvailableLabel,
  } = props

  const { partsAvailable } = equipment?.value || {}

  const status = []
  !equipment && status.push(props.noEquipment)
  !product && status.push(props.noProduct)

  const displayValidation = def(toBeValidated) && acl !== false

  return <>
    <Typography
      title={!inverted ? name : serial}
      color={'black'}
      variant={'body2'}
      className={classes.row}
      sx={{ fontWeight: 700 }}
    >
      {!inverted ? name : serial}
    </Typography>
    <Typography
      title={!inverted ? serial : name}
      variant={'caption'}
      className={classes.row}
      color={theme.palette.text.disabled}
    >
      {!inverted ? serial : name}
    </Typography>
    <Box className={classes.subRow}>
      {status.map((item, index) => (
        <Typography
          key={index}
          variant={'caption'}
          className={classes.row}
          color={theme.palette.warning.main}
        >
          {item}
        </Typography>
      )).reduce((result, item) => (result.length ? [result, ' ● ', item] : [item]), [])}
    </Box>
    {!displayValidation ? null : (
      <Typography
        variant={'caption'}
        title={toBeValidatedInfo}
        className={`${classes.row} ${classes.validation}`}
      >
        {toBeValidatedInfo}
      </Typography>
    )}
    {partsAvailable === false && settings.showEquipmentPartsAvailable ? (
      <Box className={classes.partsAvailable}>
        <SvgIcon
          width={'1rem'}
          height={'1rem'}
          icon={'triangle'}
          variant={'outlined'}
          color={theme.palette.warning.main}
        />
        <Typography
          color={theme.palette.warning.main}
          variant={'12/medium'}
        >
          {partsAvailableLabel}
        </Typography>
      </Box>
    ) : null}
  </>
}

export default CellHOC(ListItemCellDeviceWithSerial, styles)
