import { Box, Typography } from '@mui/material'
import CellHOC from '@platform/react/hoc/list/cell'
import OverflowTooltip from 'ui/Element/Text/OverflowTooltip'

const styles = theme => ({
  comment: {
    marginTop: theme.spacing(1),
  },
})

/**
 * Displays the part name, articleNumber and comment for a part in the summary list.
 *
 * @param {Object} classes  styling for the cell
 * @param {Object} props    props for the cell
 *
 * @return {*}
 * @constructor
 */
export const ListItemCellCartSummaryPart = (classes, props = {}) => {
  const { name, comment, articleNumber } = props || {}

  return (
    <>
      <OverflowTooltip
        variant={'16/bold'}
        color={'textBlack'}
      >
        {name}
      </OverflowTooltip>
      <OverflowTooltip
        variant={'14/medium'}
        color={'textPrimary'}
      >
        {articleNumber}
      </OverflowTooltip>
      <Box className={classes.comment}>
        <OverflowTooltip
          variant={'12/regular'}
          color={'textDescription'}
        >
          {comment}
        </OverflowTooltip>
      </Box>
    </>
  )
}

export default CellHOC(ListItemCellCartSummaryPart, styles)
