import { Typography } from '@mui/material'
import CellHOC from '@platform/react/hoc/list/cell'

/**
 * Displays the {@code installedAt} property in cell.
 *
 * @param {Object} classes  styling for the cell
 * @param {Object} props    props for the cell
 *
 * @returns {JSX.Element}
 */
export const ListItemCellRequestInstalledAt = (classes, props = {}) => {
  const {
    itemInstalledAt: {
      value: {
        name = '-',
        address = '',
      } = {},
    } = {},
  } = props

  const addressSummary = address ? Object.values(address).join(', ') : '-'

  return (
    <>
      <Typography
        variant={'body2'}
        color={'black'}
        className={classes.row}
        sx={{ fontWeight: 700 }}
      >
        {name}
      </Typography>
      <Typography
        variant={'caption'}
        color={'textSecondary'}
        className={classes.row}
      >
        {addressSummary}
      </Typography>
    </>
  )
}

export default CellHOC(ListItemCellRequestInstalledAt)
