import { Typography } from '@mui/material'
import CellHOC from '@platform/react/hoc/list/cell'

/**
 * Returns the ListItemCellNameOrCount element's current theme styles.
 *
 * @returns {object}      the ListItemCellNameOrCount element's styles
 */
export const styles = (theme, { right }) => right && {
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
}

/**
 * Shows the corresponding label together with the value.
 *
 * @param {number} value              the value to show and to be considered to choose the label
 * @param {object} [labels]           an object containing te available labels
 * @param {string} [labels.none]      the label to show when value is 0
 * @param {string} [labels.singular]  the label to show when value is 1
 * @param {string} [labels.plural]    the label to show when value is greater than 1
 * @returns {string|number}           either a concatenation of the value and its label or the value
 *                                    alone if there are no labels
 */
const valueToLabel = (value, labels) => {
  if (!labels) {
    return value
  }
  switch (value) {
    case 0:
      return labels.none
    case 1:
      return `1 ${labels.singular}`
    default:
      return `${value} ${labels.plural}`
  }
}

/**
 * Displays the amount of open service requests
 *
 * Attempts to obtain the first property inside {@code props}. If it has a data property, which
 * should contain the only related object, returns its name. Otherwise, if there is no data object,
 * or it has no name, returns the count property. If there isn't any, returns 0.
 *
 * @param {Object} [classes]            style classes to be applied to the elements
 * @param {Object} [props.labels]       translated labels
 * @param {Number|Object} [props.count] Number of requests
 * @param {Object} [props]              props for the component
 *
 * @returns {JSX.Element}
 */
export const ListItemCellPersonNameOrCount = (classes, props = {}) => {
  const { labels, count: itemsCount } = props
  const itemsCountValue = itemsCount?.value || itemsCount
  const modelProp = itemsCountValue?.person || {}
  const refProp = modelProp?.[Object.keys(modelProp)[0]] || {}
  const {
    data,
    count,
  } = refProp

  const label = `${(data && (data.name || data.number)) || valueToLabel(count || 0, labels)}`

  return (
    <>
      <Typography
        color={'black'}
        variant={'body2'}
        className={classes.row}
        sx={{ fontWeight: 700 }}
      >
        {label}
      </Typography>
    </>
  )
}

export default CellHOC(ListItemCellPersonNameOrCount, styles)
