import { Avatar, Badge, Stack } from '@mui/material'
import { Email as Icon } from '@mui/icons-material'

/**
 * Displays an icon for each message thread.
 *
 * @param {Object} props    props for the component
 *
 * @return {JSX.Element}
 * @constructor
 */
const ListItemCellMessageImage = (props = {}) => (
  <Stack direction={'row'} spacing={2} sx={{ alignSelf: 'center' }}>
    <Badge
      color={'signal'} // background-color
      sx={{ color: 'white' }}
      badgeContent={props.unreadMessages}
      overlap={'circular'}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      <Avatar sx={{ bgcolor: 'primary.main', width: 56, height: 56 }}>
        <Icon sx={{ width: 32, height: 32 }}/>
      </Avatar>
    </Badge>
  </Stack>
)

export default ListItemCellMessageImage
