import { Box, CircularProgress } from '@mui/material'
import FabButton from 'ui/Element/Button/Fab'
import { useAttachmentAPI, useMemoRef, useStyles } from '@platform/react/hook'
import { useEffect, useRef } from 'react'

const styles = () => ({
  root: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  progress: {
    position: 'absolute',
  },
})

/**
 * AttachmentListTemplate Circle Button
 *
 * Displays a FAB Button that can be clicked to download {@param attachment}. Will display a
 * circular indicator around the button for the download progress.
 *
 * @param {Object} attachment         the attachment to download
 * @param {Object} events             events used in this button
 * @param {Function} [events.onClick] handler for clicking the button
 * @param {boolean} showProgress      whether to show the progress bar or not
 * @returns {JSX.Element}
 * @constructor
 */
const AttachmentListTemplateCircleButton = ({ attachment, events, showProgress = true }) => {
  const classes = useStyles(styles)()
  const { value: item } = attachment

  const link = useRef({
    a: document.createElement('a'),
    fetched: false,
  })

  const { state, fetch } = useAttachmentAPI({
    attachment,
    initial: false,
  })
  const { progress = 0 } = state || {}
  const isLoading = progress !== 0 && progress !== 100

  useEffect(() => {
    if (state?.src && state?.progress === 100 && state?.loading === false) {
      link.current.a.href = state.src
      link.current.a.download = item.name
      if (link.current.fetched) {
        link.current.a.click()
        link.current.fetched = false
      }
    }
  }, [state])

  const handleClick = (e) => {
    if (!state?.src) {
      link.current = { ...link.current, fetched: true }
      fetch()
    } else {
      link?.current?.a?.href && link.current.a.click()
      events?.onClick?.({ ...e, detail: { state } })
    }
  }

  return (
    <Box className={classes.root}>
      <FabButton
        color={'secondary'}
        events={{ onClick: handleClick }}
        icon={{ name: 'download', variant: 'filled' }}
      />
      {isLoading && showProgress && (
        <CircularProgress
          value={progress}
          color={'secondary'}
          variant={'determinate'}
          className={classes.progress}
        />
      )}
    </Box>
  )
}

export default useMemoRef(AttachmentListTemplateCircleButton, props => [props.attachment])
