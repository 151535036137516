/* global G, React */
import { Grid, IconButton, Icon } from '@mui/material'
import { useMemoRef } from '@platform/react/hook'
import ApplicationContext from '@platform/react/context/application'
import BigPreview from 'ui/Component/Attachment/Big'
import ScrollPane from 'ui/Component/Attachment/Carousel'

const scrollIntoView = component => component.scrollIntoView({
  inline: 'center',
  block: 'center',
  behavior: 'smooth',
})

const ScrollButton = useMemoRef(({ onClick, icon }) => (
  <Grid item container xs={1} justifyContent={'center'}>
    <IconButton onClick={onClick} size="large">
      <Icon>{icon}</Icon>
    </IconButton>
  </Grid>
), [])

const AttachmentPane = React.forwardRef((props, ref) => {
  const { eventBus } = React.useContext(ApplicationContext)
  const { data } = props
  const [focus, setFocus] = React.useState(10)
  const scrollPane = React.useRef()
  const focusRef = React.useRef(focus)

  React.useLayoutEffect(() => {
    scrollPane.current.children[focus] && ((el, key) => {
      eventBus.dispatch(
        eventBus.type(G.ATTACHMENT, G.DONE, key), {
          url: el.querySelector('img').src,
        },
      )
      scrollIntoView(el)
    })(scrollPane.current.children[focus], data[focus].key)
  }, [focus])

  const scrollTo = React.useCallback(dir => React.useCallback(() => {
    focusRef.current += dir
    const next = focusRef.current
    const canFocus = next <= data.length - 1 && next >= 0
    canFocus && setFocus(next)
  }, []), [])
  const onClick = React.useCallback(key => React.useCallback(() => {
    focusRef.current = key
    setFocus(key)
  }, []), [])

  return (
    <Grid item container
      ref={ref}
      xs={12}
      md={12}
      component={'section'}
      alignItems={'center'}
      justifyContent={'center'}
    >
      <ScrollButton onClick={scrollTo(-1)} icon={'arrow_backward'} />
      <BigPreview attachment={data[focus]} md={10} />
      <ScrollButton onClick={scrollTo(1)} icon={'arrow_forward'} />
      <ScrollButton onClick={scrollTo(-1)} icon={'arrow_left'} />
      <ScrollPane {...props}
        ref={scrollPane} focus={focus} onClick={onClick} onDone={scrollIntoView}
      />
      <ScrollButton onClick={scrollTo(1)} icon={'arrow_right'} />
    </Grid>
  )
})

export default useMemoRef(AttachmentPane, [])
