import { useMemoRef, useStyles } from '@platform/react/hook'
import { Box, Card, CardContent, Grid, Typography, useTheme } from '@mui/material'
import SvgIcon from 'ui/Element/Icon/Svg'
import { getThemeColor } from 'lib/util'
import OverflowTooltip from 'ui/Element/Text/OverflowTooltip'

const styles = (theme, { background, onClick }) => ({
  root: {
    width: '100%',
    border: 'none',
    padding: theme.spacing(0.5, 1), // 4px, 8px
    marginRight: theme.spacing(1),
    ...onClick && { cursor: 'pointer' },
    borderRadius: '3.5rem', // 56 px
    ...background && { backgroundColor: getThemeColor(theme, background) },
  },
  content: {
    padding: '0!important',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  iconContainer: {
    marginRight: theme.spacing(1),
  },
  labelContainer: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },
  label: {
    fontWeight: 500,
    fontSize: '0.875rem', // 14px
    color: theme.palette.common.black,
  },
  sublabel: {
    lineHeight: '1rem', // 16px
    color: theme.palette.text.secondary,
  },
  ...theme.custom.cardSimple,
})

/**
 * Small Card Component
 *
 * Displays a small card that is only one row tall (the height of the icon used)
 *
 * @param {React.Ref} forwardedRef  ref to the element
 * @param {Object} events           events for the element
 * @param {Object} props            additional props
 * @returns {JSX.Element}
 * @constructor
 */
const SmallCard = ({ forwardedRef, events, ...props }) => {
  const theme = useTheme()
  const { xs, sm, md, lg, xl, variant = 'outlined', background: cardBackground, ...options } = props

  const { icon, label = '-', subLabel = '-' } = options || {}
  const { onClick = null } = events || {}

  const classes = useStyles(styles, { background: cardBackground, onClick })()

  const {
    variant: iconVariant = 'outlined',
    width = 24,
    height = 24,
    name = 'device',
    color = 'common.black',
    background = 'background.light',
  } = icon || {}

  return (
    <Grid
      item
      container
      ref={forwardedRef}
      alignItems={'center'}
      justifyContent={'center'}
      xs={xs}
      sm={sm}
      md={md}
      lg={lg}
      xl={xl}
    >
      <Card
        variant={variant}
        onClick={e => onClick?.(e)}
        classes={{ root: classes.root }}
      >
        <CardContent classes={{ root: classes.content }}>
          <Box className={classes.iconContainer}>
            <SvgIcon
              rounded={true}
              icon={name}
              width={width}
              height={height}
              padding={'0.5rem'}
              variant={iconVariant}
              color={getThemeColor(theme, color)}
              background={getThemeColor(theme, background)}
            />
          </Box>
          <Box className={classes.labelContainer}>
            <OverflowTooltip classes={{ label: classes.label }}>
              {label}
            </OverflowTooltip>
            <Typography
              variant={'caption'}
              classes={{ root: classes.sublabel }}
            >
              {subLabel}
            </Typography>
          </Box>
        </CardContent>
      </Card>
    </Grid>
  )
}

export default useMemoRef(SmallCard, [])
