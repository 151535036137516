/* global G */
import { Box, IconButton, useTheme } from '@mui/material'
import CellHOC from '@platform/react/hoc/list/cell'
import SvgIcon from 'ui/Element/Icon/Svg'
import { PlatformEvent } from 'lib/util'
import { useContext, useEffect, useState, useTransition } from 'react'
import ApplicationContext from '@platform/react/context/application'
import { useEventCallback } from '@platform/react/hook'
import useEventHandler from '@platform/react/hook/useEventHandler'
import Amount from 'ui/Element/Button/Amount'

const styles = theme => ({
  content: {
    display: 'flex',
    height: '5.5rem',
    flexDirection: 'column',
    alignItems: 'end',
    justifyContent: 'space-between',
  },
  button: {
    padding: 0,
  },
})

/**
 * Displays actions for a part of the cart for the positions list.
 *
 * @param {Object} classes  styling for the cell
 * @param {Object} props    props for the cell
 *
 * @return {*}
 * @constructor
 */
export const ListItemCellCartPositionsAction = (classes, props = {}) => {
  const {
    id,
    name,
    amount,
    events,
  } = props || {}
  const { onRemove, onAmount } = events

  const theme = useTheme()
  const { eventBus } = useContext(ApplicationContext)
  const [, startTransition] = useTransition()
  const [currentAmount, setCurrentAmount] = useState(amount)

  useEffect(() => { eventBus.dispatch(eventBus.type(G.CART, G.READ)) }, [])

  const _cartEventName = eventBus.type(G.CART, G.DONE)
  const _cartEventHandler = useEventCallback(({ detail }) => {
    const { [G.DATA]: data } = detail
    const newPosition = data?.value.positions.find(x => x?.key === id || x?.name === name)

    if (newPosition) {
      newPosition?.amount
      && newPosition?.amount !== currentAmount
      && setCurrentAmount(newPosition.amount)
    }
  })
  useEventHandler(eventBus, _cartEventName, _cartEventHandler)

  const handleRemove = (event) => {
    startTransition(() => onRemove?.(new PlatformEvent(event, { position: {
      ...id !== name ? { key: id } : { name },
    } })))
  }

  const handleAmount = (event) => {
    startTransition(() => onAmount?.(new PlatformEvent(event, { position: {
      ...id !== name
        ? { key: id, amount: event.detail }
        : { name, amount: event.detail },
    } })))
  }

  return (
    <Box className={classes.content}>
      <IconButton
        onClick={handleRemove}
        className={classes.button}
      >
        <SvgIcon
          icon={'close'}
          variant={'filled'}
        />
      </IconButton>
      <Amount
        value={currentAmount}
        events={{ onChange: handleAmount }}
        color={theme.palette.text.dark}
      />
    </Box>
  )
}

export default CellHOC(ListItemCellCartPositionsAction, styles)
