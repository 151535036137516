import { Typography } from '@mui/material'
import CellHOC from '@platform/react/hoc/list/cell'

/**
 * Intended to display name and address of the organisation where a person belongs to.
 *
 * @param {Object} classes  styling for the cell
 * @param {Object} props    props for the cell
 *
 * @return {*|*[]}
 */
const ListItemCellTeamContactChannels = (classes, props = {}) => {
  const { contactChannels } = props
  return contactChannels ? contactChannels.map((channel, index) => (
    <Typography
      key={index}
      display={'block'}
      variant={'caption'}
    >
      {channel.value}
    </Typography>
  )) : []
}

export default CellHOC(ListItemCellTeamContactChannels)
