import { Box, Typography, useTheme } from '@mui/material'
import CellHOC from '@platform/react/hoc/list/cell'
import SvgIcon from 'ui/Element/Icon/Svg'
import { getThemeColor } from 'lib/util'
import OverflowTooltip from 'ui/Element/Text/OverflowTooltip'
import { forwardRef } from 'react'
import Chip from 'ui/Element/Text/Chip'

const styles = theme => ({
  topLabel: {
    ...theme.typography['12/medium'],
  },
  documentation: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  chips: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  subLabel: {
    '& .MuiChip-root': {
      '&:not(:last-child)': {
        marginRight: '0.5rem',
      },
    },
    '& .MuiChip-label': {
      fontSize: '0.75rem',
    },
  },
})

/**
 * Displays a doc chunk.
 *
 * @param {object} classes  element's style classes
 * @param {object} props    element's properties
 *
 * @returns {JSX.Element}
 * @constructor
 */
export const ListItemCellEquipmentInformationNameDocumentation = (classes, props = {}) => {
  const theme = useTheme()
  const { label = '-', product, highlights: { content = [] } } = props

  const ForwardedChip = forwardRef((chipProps, ref) => <Chip forwardedRef={ref} {...chipProps} />)
  const productLabels = product.map((item, index) => (<ForwardedChip key={index} text={item} />))

  return (
    <Box className={classes.documentation}>
      <Box className={classes.chips}>
        {label && (
          <OverflowTooltip classes={{ label: classes.topLabel }}>
            {label}
          </OverflowTooltip>
        )}
        <Typography
          color={'black'}
          component={'span'}
          variant={'14/medium'}
          sx={{ whiteSpace: 'wrap' }}
        >
          {content?.[0]
          // Server sends html we need to render
            ? <div dangerouslySetInnerHTML={{ __html: content[0] }} />
            : label}
        </Typography>
        <OverflowTooltip classes={{ label: classes.subLabel }}>
          {productLabels}
        </OverflowTooltip>
      </Box>
      <Box>
        <SvgIcon
          variant={'filled'}
          icon={'chevron_right'}
          color={getThemeColor(theme, 'text.secondary')}
        />
      </Box>
    </Box>
  )
}

export default CellHOC(ListItemCellEquipmentInformationNameDocumentation, styles)
