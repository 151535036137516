import { Typography } from '@mui/material'
import CellHOC from '@platform/react/hoc/list/cell'

/**
 * Displays a person's name above their business role.
 *
 * @param {Object} classes  styling for the cell
 * @param {Object} props    props for the cell
 *
 * @returns {JSX.Element}
 */
const ListItemCellPersonFullNameWithRole = (classes, props = {}) => {
  const { firstName, lastName, businessRole } = props
  const name = `${firstName} ${lastName}`

  return (
    <>
      <Typography
        title={name}
        color={'black'}
        variant={'body2'}
        className={classes.row}
        sx={{ fontWeight: 700 }}
      >
        {name}
      </Typography>
      <Typography
        variant={'caption'}
        title={businessRole}
        color={'textSecondary'}
        className={classes.row}
      >
        {businessRole}
      </Typography>
    </>
  )
}

export default CellHOC(ListItemCellPersonFullNameWithRole)
