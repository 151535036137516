/* eslint-disable no-unused-vars */
/* global React, G */
import { Children, cloneElement, useState } from 'react'
import { Grid } from '@mui/material'
import { useStyles, useMemoRef } from '@platform/react/hook'
import { getFileType } from 'lib/util/attachment'
import usePreviewableAttachment from '@platform/react/hook/usePreviewableAttachment'
import { PlatformEvent } from 'lib/util'

const styles = (theme, { isPreviewable, isLoaded, isImage, isVideo }) => ({
  root: {
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
    flex: '1 1 100%',
    overflow: 'hidden',
  },
  container: {
    display: 'flex',
    flex: 1,
    justifyContent: 'space-around',
    overflowY: 'auto',
    '& > * ': {
      ...((isLoaded && isImage) || isVideo) && { height: '100%' },
    },
    ...!isPreviewable && {
      '& > *': {
        maxWidth: (isLoaded && isImage) || isVideo ? 'none' : '6.25rem', // 100px, width of placeholder
      },
    },
  },
  information: {
    display: 'flex',
    alignItems: 'center',
    padding: [['0.5rem']],
    justifyContent: 'space-between',
  },
  fileInformation: {
    display: 'flex',
    gap: '1rem',
  },
  fileOptions: {
    display: 'flex',
    gap: '1rem',
    '& > div > button': {
      height: '2rem!important',
      minHeight: '2rem!important',
      borderRadius: '0.25rem!important',
      backgroundColor: `${theme.palette.white.main}!important`,
      '&:hover': {
        boxShadow: 'none',
      },
    },
  },
  button: {
    minWidth: '2rem',
    width: '2rem',
    height: '2rem',
    backgroundColor: theme.palette.white.main,
    borderRadius: '0.25rem',
  },
  ...theme.custom.attachmentListTemplateBig,
})

/**
 * Attachment Big Component
 *
 * Display an attachment in a big pane. Can either display an image or a file by using
 * {@link FileImage} or a PDF using {@link SimplePdf} or a video using {@link SimpleVideo}.
 *
 * @param {Object} bigProps                         props
 * @param {Object} bigProps.attachment              the attachment to show
 * @param {Object} bigProps.types                   types allowed for the attachment
 * @param {string[]|null} bigProps.previewableTypes types to show directly
 * @param {number|true} bigProps.maxPreviewSize     max size to show attachments directly
 * @param {number} spacing                          spacing for the component
 * @param {Object} children                         children to render
 * @param {Object} bigProps.props                   additional props
 * @param {React.Ref} ref                           forwarded ref
 * @returns {JSX.Element}
 * @constructor
 */
const AttachmentBig = ({
  forwardedRef,
  attachment,
  types,
  previewableTypes = null,
  maxPreviewSize = null,
  spacing = 0,
  children,
  events,
  ...props
}) => {
  const trimmedAttachment = events?.onAttachment?.(new PlatformEvent('open', { value: attachment })) || null

  const currentAttachment = attachment?.value?.name
    ? trimmedAttachment || attachment
    : { value: { name: '' } }

  const { value: item } = currentAttachment
  const [attachmentState, setAttachmentState] = useState(false)
  const { src = null } = attachmentState || {}

  const isPreviewable = usePreviewableAttachment({
    attachment: currentAttachment,
    previewableTypes,
    maxPreviewSize,
  })()
  const isLoaded = !!src

  const type = item && (
    item?.mimetype
      || (item?.url && getFileType(item, types))
      || (item?.name && getFileType({ url: item.name }, types))
  )

  // TODO: Find a generic solution for this, we can't check every mime group separately
  const isImage = type?.includes('image')
  const isVideo = type?.includes('video')
  const isPdf = type?.includes('pdf')
  const isText = type?.includes('text')
  const isGeneric = isImage || (!isVideo && !isPdf && !isText)

  const classes = useStyles(styles, {
    isVideo,
    isImage,
    isLoaded,
    isPreviewable,
  })()

  const toolbarOptions = {
    downloadIcon: props?.downloadIcon,
    feedbackIcon: props?.feedbackIcon,
    favoriteIcon: props?.favoriteIcon,
    noFavoriteIcon: props?.noFavoriteIcon,
    labels: {
      download: props?.downloadLabel,
    },
  }

  const toolbarEvents = {
    onFeedbackOpen: events?.onFeedbackOpen,
    onFavoriteOpen: events?.onFavoriteOpen,
    onFavoriteClick: events?.onFavoriteClick,
  }

  // Helper function to generate common props for each child
  const _childProps = (child, additionalEvents) => ({
    spacing,
    attachment: currentAttachment,
    showToolbar: props?.showToolbar,
    showFeedback: props?.showFeedback,
    showFavorite: props?.showFavorite,
    events: {
      ...child.props.events,
      onState: setAttachmentState,
      ...props?.showToolbar && { ...toolbarEvents },
    },
    ...props?.showToolbar && { ...toolbarOptions },
    ...additionalEvents,
  })

  return (
    <Grid
      ref={forwardedRef}
      container
      className={classes.root}
    >
      <Grid
        item
        container
        xs={12}
        className={classes.container}
      >
        {/* Generic */}
        {isGeneric && Children.map(children, child => (
          child.props.hidden || child.key !== 'generic' ? null : cloneElement(child, {
            ..._childProps(child),
            types,
            maxPreviewSize,
            previewableTypes,
            skipSkeleton: props?.skipSkeleton || true,
            showProgress: props?.showProgress || false,
            skipPlaceholder: props?.skipPlaceholder || false,
          })
        ))}
        {/* Video */}
        {isVideo && Children.map(children, child => (
          child.props.hidden || child.key !== 'video' ? null : cloneElement(child, { ..._childProps(child) })
        ))}
        {/* PDF */}
        {isPdf && Children.map(children, child => (
          child.props.hidden || child.key !== 'pdf' ? null : cloneElement(child, {
            ..._childProps(child, { onState: setAttachmentState }),
            ...props?.page && { page: props.page },
            ...props?.term && { term: props.term },
          })
        ))}
        {/* Text */}
        {isText && Children.map(children, child => (
          child.props.hidden || child.key !== 'text' ? null : cloneElement(child, { ..._childProps(child) })
        ))}
      </Grid>
    </Grid>
  )
}

export default useMemoRef(AttachmentBig, props => [props.attachment?.value?.name, props.update])
