import { Box, CircularProgress, TextField } from '@mui/material'
import IconLink from 'ui/Element/Button/IconLink'
import SvgIcon from 'ui/Element/Icon/Svg'

/**
 * Renders a {@link TextField} input field to be used with {@link Autocomplete}.
 *
 * @param {Object} InputLabelProps  MUI's InputLabelProps
 * @param {Object} InputProps       MUI's InputProps
 * @param {Object} props            props from our {@link Autocomplete}
 * @param {Object} rest             residual props
 * @returns {JSX.Element}
 * @constructor
 */
const TextFieldInput = ({ InputLabelProps, InputProps, props, ...rest }) => (
  <TextField
    {...InputLabelProps}
    {...rest}
    variant={'standard'}
    inputRef={props.ref}
    label={props.label}
    fullWidth
    helperText={props.helperText || '\u00A0'}
    error={props.error}
    autoFocus={props.focused}
    inputProps={{
      ...rest.inputProps,
      autoComplete: props.autoComplete || 'off',
    }}
    InputProps={{
      ...InputProps,
      endAdornment: (
        <>
          {props.loading ? <CircularProgress color="inherit" size={20} /> : null}
          {props.openInNewTab && props.link && props.parsedValue
            ? <IconLink
              icon={'open_in_new'}
              className={props.classes.link}
              events={{ onOpen: () => props.link }}
            />
            : null
          }
          {!props.icon ? null : (
            <Box
              onClick={props.parentEvents.onChange}
              sx={{ cursor: 'pointer' }}
            >
              <SvgIcon
                width={'1rem'}
                height={'1rem'}
                icon={'search'}
                variant={'outlined'}
              />
            </Box>
          )}
          {InputProps.endAdornment}
        </>
      ),
    }}
    {...props.multiple && {
      multiline: true,
      minRows: 1,
    }}
  />

)

export default TextFieldInput
