import CellHOC from '@platform/react/hoc/list/cell'
import LabeledHOC from '@platform/react/hoc/list/labeled'
import { ListItemCellUserLoginname } from 'ui/Element/List/Item/Cell/User/Loginname'

export default CellHOC(
  LabeledHOC(
    props => props.labels.contact,
    ListItemCellUserLoginname,
  ),
)
