import OptionHOC, { getOptionLabel } from '@platform/react/hoc/autocomplete/option'
import OverflowTooltip from 'ui/Element/Text/OverflowTooltip'
import { Box } from '@mui/material'
import { getFirstItem } from 'lib/util'

/**
 * Displays the name of a team alongside a sub-label consisting of a concatenated string
 * of default role, city and name.
 *
 * @param {Object} classes  styling for the cell
 * @param {Object} props    props for the cell
 *
 * @return {*}
 * @constructor
 */
const TeamOption = (classes, props = {}) => {
  const { option, events } = props
  const {
    value: { defaultRole: roles } = {},
    refs: { organisation = {} } = {},
  } = option || {}
  const defaultRole = getFirstItem(roles) || ''
  const teamOrganisation = getFirstItem(organisation)?.value || {}

  const typeLabel = defaultRole.length
    ? getFirstItem(events.getLabel?.(defaultRole))?.value
    : defaultRole
  const name = teamOrganisation?.name || ''
  const city = teamOrganisation?.address?.city || ''

  const subLabel = [typeLabel, city, name].reduce(
    (acc, key) => `${acc}${acc && key && ', '}${key}`,
    '',
  )

  return (
    <Box
      key={'item'}
      component={'div'}
      className={classes.option}
    >
      <OverflowTooltip>
        {getOptionLabel(option)}
      </OverflowTooltip>
      <Box className={classes.label}>
        {subLabel}
      </Box>
    </Box>
  )
}

export default OptionHOC(TeamOption)
