import { Box, Typography } from '@mui/material'
import CellHOC from '@platform/react/hoc/list/cell'
import { useContext } from 'react'
import ApplicationContext from '@platform/react/context/application'

/**
 * Intended to display an address.
 *
 * @param {Object} classes  styling for the cell
 * @param {Object} props    props for the cell
 *
 * @returns {JSX.Element}
 */
export const ListItemCellAddress = (classes, props = {}) => {
  const { intl } = useContext(ApplicationContext)
  const localizedContent = props?.address
    ? intl.address(props.address, { inline: true })
    : '-'

  return (
    <Box
      className={classes.root}
    >
      <Typography>
        <Box
          component={'span'}
          className={classes.row}
          title={localizedContent}
        >
          {localizedContent}
        </Box>
      </Typography>
    </Box>
  )
}

export default CellHOC(ListItemCellAddress)
