/* eslint-disable object-curly-newline,no-unused-vars */
/* global React */
import { useContext } from 'react'
import { Box, Grid, Icon } from '@mui/material'
import { withStyles } from '@mui/styles'
import { isNum } from 'lib/util'
import { useMemoRef } from '@platform/react/hook'
import stringFormat from '@gaia/util/string'
import { parseISO, format } from 'date-fns'
import DecoratedText from 'ui/Element/Text/Decorated'
import GaiaImageList from 'ui/Component/Attachment/List'
import MuiListTemplateSmall from 'ui/Component/Attachment/List/Template/Small'
import MuiListTemplateLine from 'ui/Component/Attachment/List/Template/Line'
import fileConfig from 'config/web/ui/_shared/file.json5'
import GaiaImageCount from 'ui/Component/Attachment/Count'
import ApplicationContext from '@platform/react/context/application'

const styles = theme => ({
  root: {
    width: '100%',
    display: 'flex',
    marginBottom: theme.spacing(2),
    backgroundColor: '#efefef',
    borderRadius: 4,

    '& #delete-button, & #edit-button': {
      display: 'none',
    },
    '&:hover #delete-button, &:hover #edit-button': {
      display: 'block',
    },
  },
  header: {
    padding: theme.spacing(1, 1, 0, 2.5),
  },
  editIcon: {
    color: [[theme.palette.text.secondary], '!important'],
  },
  deleteIcon: {
    color: [[theme.palette.text.secondary], '!important'],
  },
  content: {
    padding: theme.spacing(0, 2.5, 2),
  },
  author: {
    minHeight: 56,
    display: 'flex',
    alignItems: 'center',
  },
  text: {
    margin: theme.spacing(1, 0, 2, 0),
    textAlign: 'left',
    wordBreak: 'break-word',
  },
  type: {
    fontWeight: 500,
    textTransform: 'uppercase',
    fontSize: '.9rem',
  },
  footer: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    alignItems: 'center',
  },
  attachIcon: {
    color: [[theme.palette.text.secondary], '!important'],
  },
  attachments: {
    padding: theme.spacing(2, 2, 0),
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  timeLabel: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    padding: theme.spacing(1, 2),
  },
  timeTrackingLabel: {
    display: 'flex',
    justifyContent: 'flext-start',
    alignItems: 'center',
  },
  time: {
    color: theme.palette.text.light,
    fontWeight: 500,
    textTransform: 'uppercase',
  },
  date: {
    color: theme.palette.text.light,
    fontWeight: 500,
    textTransform: 'uppercase',
    textAlign: 'right',
  },
  ...theme.custom.note,
})

const AuthorLabel = ({ value, classes, ...props }) => (
  <Grid
    key={'author'}
    {...props}
    item
    component={DecoratedText}
    color={'textSecondary'}
    decorators={{ bold: true }}
    className={classes.author}
  >
    {value}
  </Grid>
)

// const EditButton = ({ onClick, classes, hidden, ...props }) => (hidden ? null : (
//   <Grid
//     {...props}
//     key={'edit'}
//     id={'edit-button'}
//     item
//   >
//     <FabButton
//       icon={'edit'}
//       events={{ onClick }}
//       classes={{ icon: classes.editIcon }}
//     />
//   </Grid>
// ))

// const DeleteButton = ({ onClick, classes, ...props }) => (
//   <Grid
//     {...props}
//     key={'delete'}
//     id={'delete-button'}
//     item
//   >
//     <FabButton
//       icon={'delete'}
//       events={{ onClick }}
//       classes={{ icon: classes.deleteIcon }}
//     />
//   </Grid>
// )

const TextLabel = ({ value, classes, hidden, ...props }) => (hidden ? null : (
  <Grid
    {...props}
    item
    component={DecoratedText}
    className={classes.text}
    color={'textPrimary'}
  >
    {value}
  </Grid>
))

const TypeLabel = ({ value, assignee, team, date, decorators, classes, ...props }) => (
  <Grid
    {...props}
    key={'type'}
    item
    container
    alignItems={'center'}
  >
    <DecoratedText
      className={classes.type}
      decorators={decorators}
    >
      {stringFormat(value, { assignee, team, date })}
    </DecoratedText>
  </Grid>
)

// const AttachButton = ({ onClick, classes, ...props }) => (
//   <Grid
//     {...props}
//     key={'attach'}
//     item
//   >
//     <FabButton
//       icon={'attach_file'}
//       small={true}
//       events={{ onClick }}
//       classes={{ icon: classes.attachIcon }}
//     />
//   </Grid>
// )

const Attachments = ({ data, classes, hidden, download = true, ...props }) => (hidden ? null : (
  <Grid
    {...props}
    item
    key={'attachments'}
    className={classes.attachments}
  >
    <GaiaImageCount
      space={1}
      data={data}
      autoHide={true}
      variant={'caption'}
      color={'textSecondary'}
      decorators={{ bold: true }}
      filter={{ type: ['!image'] }}
      label={'ATTACHED FILES ({count})'}
    />
    <GaiaImageList
      space={2}
      data={data}
      download={download}
      types={fileConfig.types}
      filter={{ type: ['!image'] }}
      ListItem={MuiListTemplateLine}
      events={{ onClick: props?.events?.onClick }}
    />
    <GaiaImageCount
      space={1}
      data={data}
      autoHide={true}
      variant={'caption'}
      color={'textSecondary'}
      decorators={{ bold: true }}
      filter={{ type: ['image'] }}
      label={'ATTACHED IMAGES ({count})'}
    />
    <GaiaImageList
      space={2}
      data={data}
      download={download}
      types={fileConfig.types}
      filter={{ type: ['image'] }}
      ListItem={MuiListTemplateSmall}
      events={{ onClick: props?.events?.onClick }}
    />
  </Grid>
))

const DateTimeLabel = ({ intl, spentTime, submitTimestamp, startTimestamp, classes, ...props }) => {
  const h = Math.floor(parseInt(spentTime, 10) / 3600)
  const m = Math.floor((parseInt(spentTime, 10) - h * 3600) / 60) % 60

  const submitDate = submitTimestamp ? intl.getLocaleDate(submitTimestamp, {
    dateStyle: 'medium',
    timeStyle: 'short',
  }) : null

  const startDate = startTimestamp ? intl.getLocaleDate(startTimestamp, {
    dateStyle: 'medium',
  }) : null

  return (
    <Box className={classes.timeLabel}>
      {isNum(spentTime) && (
        <Box className={classes.timeTrackingLabel}>
          <Icon sx={{ marginRight: '5px' }}>
              restore
          </Icon>
          <Grid
            {...props}
            item
            key={'spentTime'}
            component={DecoratedText}
            className={classes.time}
          >
            {`${String(h).padStart(2, '0')}:${String(m).padStart(2, '0')}`}
          </Grid>
        </Box>
      )}
      <Grid
        {...props}
        item
        key={'dateTime'}
        component={DecoratedText}
        className={classes.date}
      >
        {startDate || submitDate}
      </Grid>
    </Box>
  )
}

/**
 * Note Template.
 *
 * Used to display existing notes.
 *
 * @param {object} note                 the note object to display
 * @param {boolean} edit                whether the note is being edited
 * @param {object} types                the available types of notes
 * @param {string} userId               the id of the current user
 * @param {string} meLabel              translation of english 'me'
 * @param {object} events               an object containing event handlers
 * @param {function} events.getNoteType called at the beginning to alternatively obtain the
 *                                      {@param note}'s type
 * @param {function} events.onAttach    called whenever the attach button is clicked
 * @param {function} events.onDelete    called whenever the delete button is clicked
 * @param {object} classes
 * @returns {JSX.Element}
 * @constructor
 */
const Note = ({ note, edit, types, userId, meLabel, events, classes, ...props }) => {
  const { download } = props
  const { intl } = useContext(ApplicationContext)
  const { getNoteType, onDelete, onAttach } = events
  const { value, refs } = note
  const {
    text,
    spentTime = null,
    startDate = null,
    submitTimestamp,
    type: typeKey,
    date,
    attachments = [],
  } = value

  const {
    submitter: [submitter] = [],
    assignee: [assignee] = [],
    team: [team] = [],
  } = refs || {}
  const {
    person: [submitterPerson] = [],
  } = (submitter && submitter.refs) || {}
  const {
    person: [assigneePerson] = [],
  } = (assignee && assignee.refs) || {}

  const submitterRef = submitter?.key
  const submitterName = submitterPerson?.value?.name || 'Unknown'
  const authorName = submitterRef === userId ? meLabel : submitterName

  const assigneeName = assigneePerson?.value?.name || 'Unknown'
  const teamName = team?.value?.name || 'Unknown'
  const dateText = date ? format(parseISO(date), 'd.MM.yyyy') : 'Unknown'

  const [noteType = types ? types[typeKey] : {}] = getNoteType?.(typeKey) || []
  const decorators = {
    [noteType.color]: true,
    uppercase: true,
    bold: true,
  }

  // const handleEdit = () => {}

  // const handleDelete = () => {
  //   onDelete && onDelete(new CustomEvent('click', {
  //     detail: {
  //       key,
  //     },
  //   }))
  // }

  return (
    <Grid
      className={classes.root}
      container
      item
    >
      <Grid
        key={'header'}
        className={classes.header}
        container
        item
        xs={12}
      >
        <AuthorLabel
          value={authorName}
          classes={classes}
          xs={true}
        />
        {/* <EditButton */}
        {/*   onClick={handleEdit} */}
        {/*   classes={classes} */}
        {/*   hidden={edit} */}
        {/*   xs={'auto'} */}
        {/* /> */}
        {/* <DeleteButton */}
        {/*   onClick={handleDelete} */}
        {/*   classes={classes} */}
        {/*   xs={'auto'} */}
        {/* /> */}
      </Grid>
      <Grid
        key={'content'}
        className={classes.content}
        container
        item
        xs={12}
      >
        <TextLabel
          value={text}
          hidden={!text || edit}
          classes={classes}
          xs={12}
        />
        {noteType.key !== 'blank' && (
          <TypeLabel
            value={noteType.value}
            team={teamName}
            assignee={assigneeName}
            date={dateText}
            decorators={decorators}
            classes={classes}
            xs={12}
          />
        )}
      </Grid>
      <Grid
        key={'footer'}
        className={classes.footer}
        container
        item
      >
        <Attachments
          xs={12}
          download={download}
          events={events}
          data={attachments}
          classes={classes}
          hidden={!attachments?.length}
        />
        <DateTimeLabel
          intl={intl}
          spentTime={spentTime}
          startTimestamp={startDate}
          submitTimestamp={submitTimestamp}
          classes={classes}
          xs={12}
        />
      </Grid>
    </Grid>
  )
}

export default useMemoRef(withStyles(styles)(Note), false)
