import CellHOC from '@platform/react/hoc/list/cell'
import LabeledHOC from '@platform/react/hoc/list/labeled'
import { ListItemCellRequestStatus } from 'ui/Element/List/Item/Cell/Request/Status'

export default CellHOC(
  LabeledHOC(
    props => props.labels.label,
    ListItemCellRequestStatus,
  ),
)
