/* eslint-disable object-curly-newline */
import useMemoRef from '@platform/react/hook/useMemoRef'
import ErrorBoundary from 'ui/Error'
import { TextField } from '@mui/material'
import { useEffect, useState, useTransition } from 'react'
import PlatformEvent from 'lib/util/event'

const SelectField = ({ forwardedRef, events, children, selection, ...props }) => {
  // Make our input a controlled component
  // const [v, handleChange] = useHandleChange(value)
  const [, startTransition] = useTransition()
  const [value, setValue] = useState(props.value || '')
  const [options, setOptions] = useState(selection || [])

  useEffect(() => {
    setValue(props.value || '')
  }, [props.value])

  useEffect(() => {
    (async () => {
      const newSelection = await events.getSelection?.(null)
      newSelection?.length && setOptions(newSelection)
    })()
  }, [])

  const handleChange = (event) => {
    startTransition(() => {
      const newValue = event.target.value
      setValue(newValue)
      // selecting a "blank" option passes '' as value, but we need an invalid value for this case
      events.onChange(new PlatformEvent(event, { value: newValue || null }))
    })
  }

  return (
    <ErrorBoundary>
      <TextField
        ref={forwardedRef}
        fullWidth // magic to make field grow inside grid
        variant={'standard'}
        {...props}
        onChange={handleChange}
        select
        SelectProps={{
          native: true,
        }}
        autoFocus={props.focused}
        helperText={props.helperText || '\u00A0'}
        value={value}
      >
        {options && options.map(item => (
          <option
            key={item.key}
            value={item.key}
          >
            {item.value}
          </option>
        ))}
      </TextField>
    </ErrorBoundary>
  )
}

export default useMemoRef(SelectField, props => [props.value, props.options, props.error])
