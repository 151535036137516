/* eslint-disable no-undefined */
/* global G */
import { useContext, useRef, useState } from 'react'
import { Grid, Button, Typography } from '@mui/material'
import { useEventCallback, useEventHandler, useMemoRef, useStyles } from '@platform/react/hook'
import ApplicationContext from '@platform/react/context/application'
import { PlatformEvent } from 'lib/util'

const ric = window?.requestAnimationFrame || setImmediate || (c => setTimeout(c, 0))

const styles = theme => ({
  root: {
    position: 'relative',
    borderWidth: 0,
    borderStyle: 'solid',
    borderColor: theme.palette.signal.main,
  },
  header: {
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0,
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
  notch: {
    width: 'auto',
    height: theme.layout.toolBarHeight,
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: [[0, 0, 8, 8]],
    padding: theme.spacing(0, 1),
    columnGap: theme.spacing(2),
    backgroundColor: theme.palette.signal.main,
    color: theme.palette.text.inverted,
    zIndex: 1200,
  },
  button: {
    minHeight: 'auto',
    borderRadius: 6,
    backgroundColor: 'transparent',
    color: theme.palette.text.inverted,
    '&:hover': {
      backgroundColor: theme.palette.text.inverted,
      color: theme.palette.signal.main,
    },
  },
})

/**
 * Frame layout component.
 *
 * @param {React.ReactNode} children
 * @param {React.Ref} ref
 * @returns {JSX.Element}
 * @constructor
 */
const Frame = ({ children }) => {
  const { eventBus } = useContext(ApplicationContext)
  const classes = useStyles(styles)()
  const [state, setState] = useState({ open: false })
  const { open = false, title, actions = [], width = 5, color } = state

  // initialization events handler
  const initEventName = useRef(eventBus.type(G.FRAME, G.INIT))
  const initEventHandler = useEventCallback(({ detail }) => {
    ric(() => setState({ ...detail, open: true }))
  })
  useEventHandler(eventBus, initEventName.current, initEventHandler)
  // termination events handler
  const doneEventName = useRef(eventBus.type(G.FRAME, G.DONE))
  const doneEventHandler = useEventCallback(() => {
    ric(() => setState({ open: false }))
  })
  useEventHandler(eventBus, doneEventName.current, doneEventHandler)

  return (
    <Grid
      item
      container
      className={classes.root}
      flexWrap={'nowrap'}
      style={{
        ...open && {
          borderWidth: width,
          borderColor: color,
        },
      }}
    >
      {open && (
        <Grid
          item
          container
          className={classes.header}
          xs={12}
          style={{
            top: -width,
          }}
        >
          <Grid
            item
            container
            className={classes.notch}
            xs={'auto'}
            style={{
              backgroundColor: color,
            }}
          >
            <Grid
              item
              component={Typography}
              xs={'auto'}
            >
              {title}
            </Grid>
            {actions.map(({ text, onClick, close }, index) => (
              <Grid
                key={index}
                item
                xs={'auto'}
              >
                <Button
                  className={classes.button}
                  transform={'uppercase'}
                  onClick={(event) => {
                    close && setState({ open: false })
                    onClick(new PlatformEvent(event))
                  }}
                  size={'small'}
                >
                  {text}
                </Button>
              </Grid>
            ))}
          </Grid>
        </Grid>
      )}
      {children}
    </Grid>
  )
}

export default useMemoRef(Frame)
