/* eslint-disable no-nested-ternary */
import OptionHOC from '@platform/react/hoc/autocomplete/option'
import OverflowTooltip from 'ui/Element/Text/OverflowTooltip'
import { Box, useTheme } from '@mui/material'
import AvatarImage from 'ui/Element/Image/Avatar'
import settings from '@tenant/settings'

const styles = theme => ({
  content: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
})

/**
 * Helper function to get the icon for the current option from the tenant settings.
 *
 * @param {string} nodeType type of documentation
 * @param {string} name     name of the documentation
 * @returns {*|null}
 * @private
 */
const _getOptionIcon = ({ nodeType, name }) => {
  const availableIcons = settings?.documentationTreeNodeIcons
  if (!nodeType || !name || !availableIcons?.length) return null

  const targetIcon = availableIcons.find(icon => icon.name === name && icon.nodeType === nodeType)

  return targetIcon?.icon || null
}

/**
 * Displays an Equipment for the {@link Autocomplete} component. Will display a top label consisting
 * of the type of part (part or assembly) and additionally where it's assembled in. Will also
 * display a sublabel showing its order number, if available.
 *
 * @param {Object} classes  styling for the option
 * @param {Object} props    props for the option
 *
 * @return {*}
 * @constructor
 */
const EquipmentOption = (classes, props = {}) => {
  const { option, labels, equipmentIcon = 'device_simple' } = props || {}
  const theme = useTheme()

  const icon = _getOptionIcon({
    name: option.value?.productType,
    nodeType: 'product',
  })

  const {
    value: {
      image = null,
      serialLong,
      info1,
      label,
    } = {},
  } = option || {}

  const { serial } = labels || {}
  const subLabel = `${serial} • ${serialLong || info1 || null}`

  const currentAttachment = image?.attachment?.value?.name
    ? { ...image.attachment, key: image?.value?.attachmentId || image.id, api: { ref: 'equipment' } }
    : { value: { name: '' } }

  return (
    <Box className={classes.content}>
      <Box sx={{ width: '40px', marginRight: '16px' }}>
        <AvatarImage
          attachment={currentAttachment}
          fallback={{
            ...icon ? {
              icon: icon.name,
              variant: icon.variant,
            } : {
              icon: equipmentIcon,
              variant: 'outlined',
            },
            color: theme.palette.text.regular,
          }}
        />
      </Box>
      <Box className={classes.option}>
        <OverflowTooltip>
          {label}
        </OverflowTooltip>
        {subLabel && (
          <OverflowTooltip classes={{ label: classes.label }}>
            {subLabel}
          </OverflowTooltip>
        )}
      </Box>
    </Box>
  )
}

export default OptionHOC(EquipmentOption, styles)
