import CellHOC from '@platform/react/hoc/list/cell'
import LabeledHOC from '@platform/react/hoc/list/labeled'
import { ListItemCellPersonFullName } from 'ui/Element/List/Item/Cell/Person/FullName'

export default CellHOC(
  LabeledHOC(
    props => props.labels.name,
    ListItemCellPersonFullName,
  ),
)
