/* eslint-disable object-curly-newline,implicit-arrow-linebreak */
import { useMemoRef, useStyles } from '@platform/react/hook'
import { Chip as MuiChip, Icon } from '@mui/material'

const styles = () => ({
  root: {
    width: 'auto',
    maxWidth: '100%',
  },
  label: {
    width: 'auto',
    fontWeight: 500,
    lineHeight: 'normal',
  },
})

/**
 * Chip UI Element.
 *
 * Simple element used to display dynamic text with an {@param icon}, which can also be given
 * {@param variant}, {@param color} and {@param outline} properties.
 *
 * @param {object} forwardedRef           a reference to the root element
 * @param {string} [icon]                 the name of an icon
 * @param {boolean} iconEnd               whether to place the icon at the end of the element
 * @param {string} [text]                 a string to be used as text
 * @param {'filled'|'outlined'} [variant] a text style variant to set for the element
 * @param {string} [color]                a theme's color to set for the element
 * @param {'rounded'} [outline]           a theme's color to set for the element
 * @returns {JSX.Element}                 the new Chip element
 * @constructor
 */
const Chip = ({ forwardedRef, icon, iconEnd, text, variant, color, outline }) => {
  const classes = useStyles(styles)()

  return (
    <MuiChip
      ref={forwardedRef}
      label={text}
      variant={variant}
      size={'small'}
      title={text}
      {...color && {
        color,
      }}
      {...icon && {
        icon: <Icon>{icon}</Icon>,
      }}
      classes={classes}
      sx={{
        ...outline === 'rounded' && {
          borderRadius: '5px!important',
        },
        ...iconEnd && {
          flexDirection: 'row-reverse',
          '& .MuiChip-icon': {
            marginLeft: -0.5,
            marginRight: 0.5,
          },
        },
      }}
    />
  )
}

export default useMemoRef(Chip, props =>
  [props.text, props.icon, props.color, props.variant])
