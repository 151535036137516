import { Box, Typography } from '@mui/material'

/**
 * Intended to display a context
 *
 * @param {Object} props    props for the component
 * @return {JSX.Element}
 * @constructor
 */
export const ListItemCellContext = (props) => {
  const { name = '', labels = {} } = props
  return (
    <Box>
      <Typography
        key={name}
        color={'textPrimary'}
      >
        {labels[name] || name}
      </Typography>
    </Box>
  )
}

export default ListItemCellContext
