/* global React */
import { MenuItem as MuiMenuItem } from '@mui/material'
import PlatformEvent from 'lib/util/event'

/**
 * Generic Context menu item option that sends the clicked item on click.
 *
 * @param {object} props                    element's properties
 * @param {string} props.value              the text to be used as the item's label
 * @param {object} props.events             element's event handlers
 * @param {function} props.events.onClick   event handler called when the item is clicked
 * @param {function} props.events.onOpen    event handler called on initialization that should
 *                                          return the item inside an object
 * @param {object} ref                      a reference to the {@link MenuItem} at the root
 * @returns {JSX.Element}
 * @constructor
 */
const MenuItem = (props, ref) => {
  const { item } = props.events?.onOpen?.(null) || {}

  const handleClick = (event) => {
    props.events?.onClick?.(new PlatformEvent(event, { item }))
  }

  return (
    <MuiMenuItem
      ref={ref}
      onClick={handleClick}>
      {props.value}
    </MuiMenuItem>
  )
}

export default MenuItem
