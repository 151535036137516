/* eslint-disable no-unused-vars */
/* global React */
import { Typography } from '@mui/material'
import CellHOC from '@platform/react/hoc/list/cell'

/**
 * Returns the ListItemCellUserAdminRoleAtOrg element's current theme styles.
 *
 * @param {Object} theme    the application's current theme
 * @returns {Object}        the ListItemCellUserAdminRoleAtOrg component's styles
 */
const styles = theme => ({
  root: {
    textAlign: 'center',
    width: '100%',
  },
})

/**
 * Displays the "at" translation.
 *
 * @param {Object} classes  styling for the cell
 * @param {Object} props    props for the cell
 *
 * @return {*}
 * @constructor
 */
export const ListItemCellUserAdminRoleAt = (classes, props = {}) => {
  const { label } = props

  return (
    <Typography>
      {label}
    </Typography>
  )
}

export default CellHOC(ListItemCellUserAdminRoleAt, styles)
