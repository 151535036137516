/* global React */
import { useMemoRef, useStyles } from '@platform/react/hook'
import { useEffect, useState } from 'react'
import { Grid } from '@mui/material'

const styles = theme => ({
  root: {
    justifyContent: 'space-around',
    alignItems: 'center',
    border: '1px solid black',
  },
  content: {
    justifyContent: 'space-around',
  },
  header: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
})

/**
 * Cart Component
 *
 * @param {Object} cart         the current cart
 * @param {number} currentStep  the currently active step
 * @param {Object[]} children   children to render
 * @param {Object} props        additional props
 * @returns {JSX.Element}
 * @constructor
 */
const Cart = ({ cart, activeStep: currentStep = 0, children, ...props }) => {
  const [activeStep, setActiveStep] = useState(currentStep)

  useEffect(() => {
    activeStep !== currentStep && setActiveStep(currentStep)
  }, [currentStep])

  const classes = useStyles(styles)()

  const steps = children.find(x => x.key === 'steps')?.props.children
  const stepData = steps.map((step, i) => ({
    index: i,
    key: step.key,
    options: {
      label: step.props.label,
    },
  }))

  const { spacing = 0, space = 0, gap = 0, cartProps } = props

  return (
    <>
      <Grid
        item
        xs={12}
        container
        className={`${classes.content} ${classes.header}`}
      >
        <Grid
          item
          container
          xs={cartProps.xs}
          sm={cartProps.sm}
          md={cartProps.md}
          lg={cartProps.lg}
          xl={cartProps.xl}
          spacing={spacing}
          rowSpacing={space}
          columnSpacing={gap}
        >
          {React.Children.map(children, child => (
            child.props.hidden || child.key !== 'header' ? null : React.cloneElement(child, {
              steps: stepData,
              activeStep,
              cart,
            })
          ))}
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        container
        className={classes.content}
      >
        <Grid
          item
          container
          xs={cartProps.xs}
          sm={cartProps.sm}
          md={cartProps.md}
          lg={cartProps.lg}
          xl={cartProps.xl}
        >
          {React.Children.map(children, child => (
            child.props.hidden || child.key === 'header' ? null : React.cloneElement(child, {
              steps: stepData,
              activeStep,
              cart,
            })
          ))}
        </Grid>
      </Grid>
    </>
  )
}

export default useMemoRef(Cart, false)
