import useMemoRef from '@platform/react/hook/useMemoRef'

/**
 * Generic Trigger component.
 *
 * @param {Object} forwardedRef ref
 *
 * @return {JSX.Element}
 * @constructor
 */
const MentionTrigger = ({ forwardedRef }) => <div ref={forwardedRef} />

export default useMemoRef(MentionTrigger, [])
