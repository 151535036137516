/* eslint-disable implicit-arrow-linebreak,max-len */
import { useEffect, useState } from 'react'
import { Box, Grid, useTheme } from '@mui/material'
import { PlatformEvent } from 'lib/util'
import settings from '@tenant/settings'
import { useMemoRef, useStyles } from 'platform/react/hook'
import ErrorBoundary from 'ui/Error'
import OverflowTooltip from 'ui/Element/Text/OverflowTooltip'

const styles = theme => ({
  root: {
    overflow: 'scroll',
    height: '100%',
    [theme.breakpoints.down('md')]: {
      height: '30%',
    },
    width: '100%',
    '& .MuiTreeItem-root': {
      overflow: 'inherit',
    },
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
  },
  step: {
    marginBottom: '1.5rem',
  },
  clickableStep: {
    cursor: 'pointer',
  },
  unclickableStep: {
    fontStyle: 'italic',
  },
  stepTitle: {
    color: theme.palette.grey['400'],
  },
  activeStepTitle: {
    color: theme.palette.black.main,
  },
  stepValue: {
    color: theme.palette.black.main,
  },
})

/**
 * Helper function to render the flat list of steps for the drilldown.
 *
 * @param {Object[]} steps      list of steps to render
 * @param {Object} classes      styling for each step
 * @param {Object} currentStep  the current step
 * @param {Object} events       events for each step
 * @param {Object} options      additional props
 * @returns {null|*}
 * @private
 */
const _content = ({ steps, classes, currentStep, events, ...options }) => (!steps.length ? null : steps.map((step, i) => {
  const isCurrentStep = currentStep?.$children?.[0].name === step.name

  return (
    <Box
      key={i}
      className={`${classes.step} ${step?.id ? classes.clickableStep : classes.unclickableStep}`}
      {...step?.id && { onClick: (e) => {
        const clickEvent = new PlatformEvent(e, { jump: true, id: step.id })
        events?.onClick(clickEvent)
      } }}
    >
      <OverflowTooltip
        variant={isCurrentStep ? '14/bold' : '14/medium'}
        classes={{ label: `${classes.stepTitle} ${isCurrentStep ? classes.activeStepTitle : ''}` }}
      >
        {step.name}
      </OverflowTooltip>
      {step?.value && (
        <OverflowTooltip
          variant={'14/bold'}
          classes={{ label: classes.stepValue }}
        >
          {step.value}
        </OverflowTooltip>
      )}
    </Box>
  )
}))

/**
 * DrillDown Component
 *
 * Shows a flat list of possible drill down steps for the given {@param tree}.
 *
 * The list should be calculated by the {@param props.events.onOpen} handler dynamically based on
 * the given {@param selectedNode}.
 *
 * @param {Object} className            styling for the component
 * @param {Object} tree                 the tree to use
 * @param {Object} selectedNode         the current node
 * @param {Object} jumpNode             also the current node, but only if we jumped (meaning not actively
 *                                      selected something in the list on the right side) here
 * @param {Object} props                additional props
 * @param {Object} props.events         events for the component
 * @param {Object} props.parentEvents   events from the parent component
 *
 * @returns {JSX.Element}
 * @constructor
 */
const Drilldown = ({ className, nodes: tree, selected: selectedNode, jumpNode, ...props }) => {
  const {
    spacing = 0,
    space = 0,
    gap = 0,
    events = {},
    parentEvents = {},
  } = props

  const [steps, setSteps] = useState([])

  const theme = useTheme()
  const classes = useStyles(styles)()
  const minStepsDepth = settings?.drilldown?.minStepsDepth || 0

  useEffect(() => {
    if (tree?.$children?.length) {
      setSteps([{ name: tree.name, root: true }])
    }
  }, [tree])

  useEffect(() => {
    // If {@link jumpNode} is non-nil, it means we "jumped" to this node. Clicking something on the left side of the
    // {@link Explorer}, as well as hitting the back button in the {@link Folder} is considered jumping. Clicking on
    // a list item in the {@link Folder} (right side of the {@link Explorer}) is considered actively selecting the
    // next step, and thus not jumping.
    // If we jumped to this node, we don't want to change anything in the current list of steps.
    if (tree?.$children?.length && selectedNode?.id && jumpNode?.id !== selectedNode.id) {
      // We only need to execute {@code onOpen} handler in case 1 and 2 (see below), let's check for that here
      if (selectedNode?.depth <= minStepsDepth) {
        // Getting the static steps
        const staticStepEvent = new PlatformEvent('open', { value: tree, minStepsDepth, dynamic: false })
        const staticSteps = events?.onOpen?.(staticStepEvent)

        // First case: We selected a node higher than {@link minStepsDepth}, this means we want
        // to render the list of static steps (that are known for each possible path)
        if (selectedNode?.depth < minStepsDepth) {
          setSteps(staticSteps)
        }

        // Second case: We selected the node at {@link minStepsDepth}, meaning the root node of the dynamic
        // steps. Get the list of dynamic steps based on the node, and concat them to the static steps
        if (selectedNode?.depth === minStepsDepth) {
          const correctChild = tree.$children.find(child => child.id === selectedNode.id)
          const dynamicStepEvent = new PlatformEvent('open', { value: correctChild, minStepsDepth, dynamic: true })
          const dynamicSteps = events?.onOpen?.(dynamicStepEvent)
          setSteps([...staticSteps, ...dynamicSteps].map((step, i) => (
            i !== 0 ? step : { ...step, value: selectedNode.name, id: selectedNode.parent.id })))
        }
      }

      // Third case: We selected a deeper node, see comments below
      if (selectedNode?.depth > minStepsDepth) {
        setSteps((prevSteps) => {
          const currentStepIndex = prevSteps.findIndex(step => step.name === selectedNode.name)
          return prevSteps.map((step, i) =>
            // All previous step should be untouched
            (i < currentStepIndex && step)
              // Currently selected step should point to parent node
              || (i === currentStepIndex && { ...step, value: selectedNode.value, id: selectedNode.parent.id })
              // next step should point to current node, but with no value
              || (i === currentStepIndex + 1 && { ...step, value: undefined, id: selectedNode.id })
              // all following steps should have no value
              || (i > currentStepIndex + 1 && { ...step, value: undefined, id: undefined }))
        })
      }
    }
  }, [selectedNode, tree])

  return (
    <ErrorBoundary>
      <Grid
        item
        container
        xs={props.xs}
        sm={props.sm}
        md={props.md}
        lg={props.lg}
        xl={props.xl}
        style={{
          padding: spacing
            ? theme.spacing(spacing)
            : theme.spacing(space, gap),
        }}
        className={`${classes.root} ${classes.container} ${className}`}
      >
        {_content({
          steps,
          classes,
          events: parentEvents,
          currentStep: selectedNode,
        })}
      </Grid>
    </ErrorBoundary>
  )
}

export default useMemoRef(Drilldown, props => [props.nodes, props.className, props.selected])
