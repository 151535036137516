import { Typography } from '@mui/material'
import CellHOC from '@platform/react/hoc/list/cell'
import PlatformEvent from 'lib/util/event'
import Simple from 'ui/Element/Button/Simple'

const styles = theme => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
})

/**
 * Displays an 'Add' button to add the part to the cart.
 *
 * @param {Object} classes  styling for the cell
 * @param {Object} props    props for the cell
 *
 * @return {*}
 * @constructor
 */
export const ListItemCellCartPartName = (classes, props = {}) => {
  const { id, info1, label, orderable, events, addLabel, notOrderableLabel } = props || {}
  const { onPartClick = null } = events

  return (
    <Simple
      disabled={!orderable}
      color={'primary'}
      size={'small'}
      variant={orderable ? 'contained' : 'text'}
      events={{ onClick: (e) => {
        onPartClick?.(new PlatformEvent(e, {
          node: {
            articleId: id,
            name: label,
            orderNumber: info1,
          },
        }))
      } }}
      value={
        <Typography variant={'14/bold'}>
          {orderable ? addLabel : notOrderableLabel}
        </Typography>
      }
    />
  )
}

export default CellHOC(ListItemCellCartPartName, styles)
