/* eslint-disable no-unused-vars */
import { Box } from '@mui/material'
import {
  Business,
  Factory,
  Handshake,
  Help,
  HomeRepairService,
  MapsHomeWork,
  // Uncommenting this prevents tree-shaking from working and adds all icons to the bundle
  // SvgIconComponent,
} from '@mui/icons-material'
import CellHOC from '@platform/react/hoc/list/cell'

/**
 * Mapping between icons and components
 *
 * @type {{
 * factory: SvgIconComponent,
 * handshake: SvgIconComponent,
 * business: SvgIconComponent,
 * home_repair_service: SvgIconComponent,
 * maps_home_work: SvgIconComponent
 * }}
 */
const iconsMap = {
  factory: Factory,
  home_repair_service: HomeRepairService,
  business: Business,
  handshake: Handshake,
  maps_home_work: MapsHomeWork,
}

/**
 * Styling for the icon.
 *
 * @param {Object} theme
 * @return {{
 * root: {alignItems: string, color, display: string, width: string},
 * icon: {alignItems: string, display: string}
 * }}
 */
const styles = theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.primary.main,
    width: '100%',
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
})

/**
 * Intended to display an icon for the organisation.
 *
 * @param {Object} classes  styling for the cell
 * @param {Object} props    props for the cell
 *
 * @returns {JSX.Element}
 */
export const ListItemCellOrganisationIcon = (classes, { type: typeKey, events }) => {
  const [type] = events?.getOrganisationType?.(typeKey) || []
  const typeIcon = type?.icon
  const Icon = typeIcon ? iconsMap[typeIcon] : Help

  return (
    <Box className={classes.root}>
      <Icon
        classes={{ root: classes.icon }}
        viewBox={'2 2 20 20'}
      >
        {typeIcon}
      </Icon>
    </Box>
  )
}

export default CellHOC(ListItemCellOrganisationIcon, styles)
