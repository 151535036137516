import { Breadcrumbs as MUIBreadcrumbs, Grid, Typography } from '@mui/material'
import ErrorBoundary from 'ui/Error'
import { useStyles } from '@platform/react/hook'
import IconSvg from 'ui/Element/Icon/Svg'
import { PlatformEvent } from 'lib/util'
import OverflowTooltip from 'ui/Element/Text/OverflowTooltip'
import { useEffect, useState } from 'react'

const styles = (theme, { variant = 'default', totalWidth, childrenCount }) => ({
  ...variant === 'folder' && {
    root: {},
    breadcrumbs: {},
    icon: {
      color: theme.palette.primary,
    },
    item: {
      display: 'block',
      overflow: 'hidden',
      color: theme.palette.common.black,
      maxWidth: `calc(${totalWidth}px / ${childrenCount})`,
    },
    ...theme.custom.breadcrumbs,
  },
  ...variant === 'default' && {
    root: {
      '& li': {
        '& span': {
          fontSize: '0.9rem',
          color: theme.palette.text.secondary,
        },
        '&:not(:last-child) span, & div': {
          fontWeight: 500,
          cursor: 'pointer',
          '&:hover': {
            color: theme.palette.text.dark,
          },
        },
        '&:last-child span': {
          fontWeight: 700,
          cursor: 'default',
        },
      },
    },
    item: {
      display: 'block',
      overflow: 'hidden',
      maxWidth: `calc(${totalWidth}px / ${childrenCount})`,
    },
  },
})

/**
 * Breadcrumbs component.
 *
 * Displays horizontally arranged links representing the user's current and previous locations.
 *
 * @param {Object} [node]                       the current node
 * @param {Object} [events]                     event handlers used by this component
 * @param {function} [events.onOpen]            called on each render to obtain the list of links
 * @param {function} [events.onClick]           called whenever a link is clicked
 * @param {string} [variant]                    a name of the visual appearance of the element
 * @param {Object} [parentClasses]              classes to replace the default ones with
 * @param {string} [parentClasses.root]         class applied to the root component
 * @param {string} [parentClasses.breadcrumbs]  class applied to the inner breadcrumbs component
 * @param {string} [parentClasses.item]         class applied to each item
 * @param {Object} [props]                      additional component properties
 * @param {string} [props.rootIcon]             icon used for the root element
 * @param {string} [props.separator]            character used as separator between links
 * @param {number} [props.maxItems]             maximum number of items to display without shrinking
 * @param {number} [props.minItems]             minimum number of items for the component to be
 *                                              displayed at all
 * @param {number} [props.itemsBefore]          number of items shown before ellipsis if collapsed
 * @param {number} [props.itemsAfter]           number of items shown after ellipsis if collapsed
 * @param {number|boolean|'auto'} [props.xs]    the number of grids to use from xs width
 * @param {number|boolean|'auto'} [props.sm]    the number of grids to use from sm width
 * @param {number|boolean|'auto'} [props.md]    the number of grids to use from md width
 * @param {number|boolean|'auto'} [props.lg]    the number of grids to use from lg width
 * @param {number|boolean|'auto'} [props.xl]    the number of grids to use from xl width
 * @param {React.Ref} ref                       incoming ref
 * @returns {JSX.Element}
 * @constructor
 */
const Breadcrumbs = ({ node, events, variant, classes: parentClasses, ...props }, ref) => {
  const [totalWidth, setTotalWidth] = useState(null)

  const {
    separator = '/',
    maxItems = 4,
    minItems = 1, // 2 would hide root folder
    rootIcon = null,
    itemsAfter = 1,
    itemsBefore = 1,
  } = props
  const { onOpen, onClick } = events || {}

  const {
    root = null,
    breadcrumbs = null,
    item = null,
  } = parentClasses || {}

  const openEvent = new PlatformEvent('open', { node })
  const nodes = onOpen?.(openEvent) || []
  const children = nodes.length < minItems ? [] : nodes

  const childrenCount = children.filter(child => child?.name)?.length || 1
  const classes = useStyles(styles, { variant, totalWidth, childrenCount })()

  const rootClass = root ?? classes.root
  const breadcrumbsClass = breadcrumbs ?? classes.breadcrumbs
  const itemClass = item ?? classes.item

  const handleClick = (child, index) => async (event) => {
    if (onClick) {
      const { id, name } = child
      const clickEvent = new PlatformEvent(event, { id, name, index })
      await onClick(clickEvent)
    }
  }

  useEffect(() => {
    ref?.current && setTotalWidth(ref.current.clientWidth)
  }, [ref?.current])

  return (
    <ErrorBoundary>
      <Grid
        item
        xs={props.xs}
        sm={props.sm}
        md={props.md}
        lg={props.lg}
        xl={props.xl}
        className={rootClass}
      >
        <MUIBreadcrumbs
          ref={ref}
          className={breadcrumbsClass}
          separator={separator}
          maxItems={maxItems}
          itemsAfterCollapse={itemsAfter}
          itemsBeforeCollapse={itemsBefore}
          aria-label={'breadcrumb'}
        >
          {/** @type React.ReactNode[] */
            children.map((child, index) => (
              index === 0 && rootIcon
                ? <Typography
                  key={index}
                  component={'span'}
                  onClick={handleClick(child, index)}
                >
                  <IconSvg
                    key={index}
                    icon={rootIcon}
                    raw={false}
                    height={20}
                    width={20}
                    variant={'outlined'}
                    color={'common.black'}
                  />
                </Typography>
                : <OverflowTooltip
                  key={index}
                  variant={'caption'}
                  classes={{ label: itemClass }}
                  onClick={handleClick(child, index)}
                >
                  {child.name}
                </OverflowTooltip>
            ))}
        </MUIBreadcrumbs>
      </Grid>
    </ErrorBoundary>
  )
}

export default Breadcrumbs
