/* eslint-disable object-curly-newline */
/* global React */
import { Grid, Button } from '@mui/material'
import { useMemoRef } from '@platform/react/hook'
import SvgIcon from 'ui/Element/Icon/Svg'
import LabeledText from 'ui/Element/Text/Labeled'
import Text from 'ui/Element/Text/Simple'
import Icon from 'ui/Element/Icon/Simple'

/**
 * A ListItemButton element with some options to configure it.
 *
 * This is a row consisting of an icon on the left, a label and value on the right, optional text
 * further to the right and a chevron at the end.
 *
 * @param {Object} forwardedRef           a reference to the root Button element
 * @param {Object} [events]               an object containing the element's event handlers
 * @param {Function} [events.onClick]     an event handler function called whenever
 *                                        the button is clicked
 * @param {String} value                  the button's text TODO: rename to label
 * @param {Boolean} [fullWidth=true]      whether the button should take parent's whole width
 * @param {Object} [props]                additional button's properties
 * @param {String} [props.startIcon]      the name of an icon to be displayed before the label
 * @param {String} [props.endIcon]        the name of an icon to be displayed after the label
 * @param {Object} [props.startIconProps] additional props for the start icon
 * @param {Object} [props.endIconProps]   additional props for the end icon
 *
 * @returns {JSX.Element} a new ListItem button element
 * @constructor
 */
const ListItemButton = ({ forwardedRef, events, fullWidth = true, ...props }) => {
  const { icon = {}, label, value, text, children } = props

  const handleClick = event => events.onClick(event)

  return (
    <Grid
      container
      ref={forwardedRef}
      component={Button}
      onClick={handleClick}
      alignItems={'center'}
      justifyContent={'flex-start'}
      fullWidth={fullWidth}
      flexWrap={'nowrap'}
      columnSpacing={1.5}
      paddingY={1.5}
      paddingX={1}
      margin={0}
    >
      <Grid
        item
        xs={'auto'}
      >
        <SvgIcon
          icon={icon.name || 'info'}
          size={20}
          color={'text.secondary'}
          variant={icon.variant || 'outlined'}
          rounded={true}
          raw={false}
        />
      </Grid>
      <Grid
        item
        container
        justifyContent={'space-between'}
        alignItems={'center'}
        flexWrap={'nowrap'}
        sx={{
          textAlign: 'left',
        }}
        xs={12}
      >
        <Grid
          item
          component={LabeledText}
          label={label}
          value={value}
          autoHide={false}
          children={children}
          justifyContent={'flex-start'}
          alignItems={'center'}
          background={'none'}
          xs={'auto'}
        />
        {text && (
          <Grid
            item
            component={Text}
            value={text}
            xs={6}
          />
        )}
        <Grid
          item
          xs={'auto'}
          paddingX={1}
        >
          <Icon
            icon={'chevron_right'}
            color={'action'}
            size={40}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default useMemoRef(ListItemButton, props => [
  props.value, props.disabled, props.events, props.children,
])
