import { Badge, Box, Icon, lighten, Stack, Typography } from '@mui/material'
import CellHOC from '@platform/react/hoc/list/cell'
import { getThemeColor } from 'lib/util'
import { useMemoRef } from '@platform/react/hook'

/**
 * Custom styles for the component.
 *
 * @param {Object} theme
 * @return {{
 * root: {alignItems: string, display: string, justifyContent: string, height: string},
 * closed: {backgroundColor: string, color},
 * workState20: {backgroundColor: string, color},
 * workState10: {backgroundColor: string, color},
 * workState30: {backgroundColor: string, color}
 * }}
 */
const cellStyles = theme => ({
  root: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    // justifyContent: 'center',
  },
  workState10: {
    color: theme.palette.primary.main,
    backgroundColor: lighten(theme.palette.primary.main, 0.9),
  },
  workState20: {
    color: theme.palette.warning.main,
    backgroundColor: lighten(theme.palette.warning.main, 0.9),
  },
  workState30: {
    color: theme.palette.success.main,
    backgroundColor: lighten(theme.palette.success.main, 0.9),
  },
  closed: {
    color: theme.palette.text.secondary,
    backgroundColor: lighten(theme.palette.text.secondary, 0.9),
  },
})

/**
 * Intended to display the icon of the ticket.
 *
 * @param {Object} classes  styling for the cell
 * @param {Object} props    props for the cell
 *
 * @returns {JSX.Element}
 */
const ListItemCellTicketIcon = (classes, props = {}) => {
  const { type: typeKey, workState, status, unreadMessages, events } = props
  const [type] = events?.getTicketType?.(typeKey) || []
  const typeIcon = type?.icon

  return (
    <Stack direction={'row'} spacing={2} sx={{ alignSelf: 'center' }}>
      <Badge
        color={'signal'} // background-color
        overlap={'circular'}
        sx={{ color: 'white' }}
        badgeContent={unreadMessages}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Typography
          key={typeKey}
          component={'div'}
        >
          <Box
            component={'span'}
            className={status === 80 ? classes.closed : classes[`workState${workState}`]}
            sx={{
              width: 40,
              height: 40,
              display: 'flex',
              borderRadius: '50%',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Icon
              sx={{
                ...type?.color && { color: theme => getThemeColor(theme, type.color) },
              }}
            >
              {typeIcon}
            </Icon>
          </Box>
        </Typography>
      </Badge>
    </Stack>
  )
}

export default useMemoRef(CellHOC(ListItemCellTicketIcon, cellStyles), props => [
  props.type,
  props.workState,
  props.status,
  props.unreadMessages,
])
