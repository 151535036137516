/* eslint-disable object-curly-newline */
/* global React */
import { useContext, useRef } from 'react'
import {
  Box, Card, CardActionArea, CardActions, CardContent, CardHeader, darken, lighten,
} from '@mui/material'
import PlatformEvent from 'lib/util/event'
import { useMemoRef, useStyles } from '@platform/react/hook'
import ChildrenHOC from '@platform/react/hoc/children'
import ApplicationContext from '@platform/react/context/application'
import IconSvg from 'ui/Element/Icon/Svg'
import ErrorBoundary from 'ui/Error'

const styles = (theme, { variant }) => ({
  // Styling
  card: {
    borderRadius: '8px',
    height: '256px',
    minWidth: '272px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    boxShadow: 'none',
    background: variant === 'dark'
      ? theme.palette.primary.dark
      : theme.palette.common.white,
    color: variant === 'dark'
      ? theme.palette.common.white
      : theme.palette.common.black,
    '& .MuiTypography-root': {
      color: variant === 'dark'
        ? theme.palette.common.white
        : theme.palette.common.black,
    },
  },
  header: {
    paddingTop: '22px',
    flexDirection: 'row-reverse',
    '& .MuiCardHeader-action': {
      alignSelf: 'auto',
    },
    '& .MuiIcon-root': {
      color: theme.palette.common.white,
    },
  },
  action: {
    flex: 1,
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  icon: {
    backgroundColor: variant === 'dark'
      ? theme.palette.primary.main
      : lighten(theme.palette.primary.main, 0.4),
    padding: '4px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '4px',
    cursor: 'pointer',
    transition: 'transform 0.2s',
    '&:hover': {
      boxShadow: `-3px 3px ${
        variant === 'dark'
          ? darken(theme.palette.primary.main, 0.3)
          : darken(theme.palette.common.white, 0.1)
      }`,
      transform: 'translate(3px, -3px)',
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    // Number
    '& :nth-child(1)': {
      fontWeight: 700,
      fontSize: '72px',
      lineHeight: '60px',
      marginRight: '8px',
    },
    // Text
    '& :nth-child(2)': {
      // One word per line
      wordSpacing: '9999rem',
      display: 'table-caption',
      fontSize: '14px',
      fontWeight: 500,
    },
  },
  empty: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    // ZeroTitle
    '& :nth-child(1)': {
      fontWeight: 700,
      fontSize: '18px',
      marginBottom: '8px',
    },
    // ZeroBody
    '& :nth-child(2)': {
      fontSize: '14px',
      fontWeight: 400,
      maxHeight: '40px',
    },
  },
  footer: {
    padding: '0px',
    '& .MuiButtonBase-root': {
      height: '48px',
      margin: '16px',
      flexDirection: 'row-reverse',
      justifyContent: 'space-between',
      borderRadius: '8px',
      '& .MuiTypography-root': {
        fontSize: '14px',
        fontWeight: 700,
      },
      color: variant === 'dark'
        ? theme.palette.common.white
        : theme.palette.common.black,
      '& .MuiIcon-root': {
        color: variant === 'dark'
          ? theme.palette.common.white
          : theme.palette.common.black,
      },
      backgroundColor: variant === 'dark'
        ? theme.palette.primary.main
        : theme.palette.background.dark,
      '&:hover': {
        backgroundColor: variant === 'dark'
          ? lighten(theme.palette.primary.main, 0.05)
          : darken(theme.palette.background.dark, 0.05),
      },
    },
  },
  ...theme.custom.widgetNarrow,
})

/**
 * Return appropriate label based on {@param count}.
 *
 * @param {String} key    the key, like 'title' or 'body'
 * @param {String} label  the actual label
 * @param {Number} count  the number of items (0, 1, >1)
 * @returns {*}
 */
export const countToLabel = (key, label, count) => {
  if (key === 'footer' || key === 'title') {
    return label[key]
  }

  switch (count) {
    case 0:
      return label[key][`${key}_zero`]
    case 1:
      return label[key][`${key}_one`]
    default:
      return label[key][`${key}_other`]
  }
}

/**
 * Dashboard Narrow widget
 *
 * 3 distinct states:
 *
 * ZERO (count === 0)
 * - {@code zeroIcon}: fabLike icon to be displayed
 * - {@code zeroBody}: text (like "no devices found")
 *
 * OTHER (count >= 1)
 * - {@code otherTitle}: count (like "3")
 * - {@code otherBody}: secondary text ("open requests", "registered devices", ...)
 *
 * {@code footerAction}: Usually a button that redirect to module's create route.
 *
 * The {@code onPublication} event handler is responsible for
 * returning the correct response.
 *
 * @example
 * if (count === 0): { total: {count: 0} }
 * if (count >= 1): { total: {count: 3} }
 *
 * @param props
 * @return {JSX.Element}
 * @constructor
 */
const SubbedNarrowWidget = (props) => {
  const { events, variant = 'dark', icon = 'device' } = props
  const { socket: { ref: socket } } = useContext(ApplicationContext)
  const classes = useStyles(styles, { variant })()
  const {
    ZeroTitle,
    ZeroBody,
    OtherTitle,
    OtherBody,
    FooterAction,
  } = props.childrenObject
  const socketKey = useRef(null)
  const customEvent = new CustomEvent('load', { detail: { key: socketKey.current } })
  const platformEvent = new PlatformEvent(customEvent)
  const event = useRef(() => platformEvent)
  const [count, setCount] = React.useState(0)

  React.useLayoutEffect(() => {
    let isMounted = true;

    /**
     * Fetching of initial data
     */
    (async () => {
      socketKey.current = `${events.onOpen(null)}_${props.documentType}`
      const result = isMounted && await props.events.onPublication(event.current())

      if (isMounted) {
        setCount(result.total.count)
      }
    })()

    /**
     * Subscription based data
     * @returns {Promise<void>}
     */
    const listener = async () => {
      const result = isMounted && await props.events.onPublication(event.current())

      if (isMounted) {
        setCount(result.total.count)
      }
    }

    socket.sub(socketKey.current, () => {
      socket.on(socketKey.current, listener)
    })

    return () => {
      isMounted = false
      socket.unsub(socketKey.current, () => {
        socket.off(socketKey.current, listener)
      })
    }
  }, [])

  return (
    <ErrorBoundary>
      <Card
        className={classes.card}
        variant={
          variant === 'light'
            ? 'outlined'
            : 'normal'
        }
      >
        <>
          <CardHeader
            className={classes.header}
            action={
              <Box
                className={classes.icon}
                onClick={e => props.events.onClick(e, null)}
              >
                <IconSvg
                  icon={icon}
                  raw={false}
                  variant={'outlined'}
                  color={'common.white'}
                />
              </Box>
            }
          />
          {count > 0
            ? (
              <CardActionArea
                className={classes.action}
                onClick={e => props.events.onClick(e, null)}
              >
                <CardContent className={classes.content}>
                  <OtherTitle value={count}/>
                  <OtherBody value={countToLabel('body', props.label, count)}/>
                </CardContent>
              </CardActionArea>
            )
            : (
              <CardContent className={classes.empty}>
                <ZeroTitle value={countToLabel('title', props.label, 0)}/>
                <ZeroBody
                  value={countToLabel('body', props.label, 0)}
                  hidden={false}/>
              </CardContent>
            )
          }
        </>
        <CardActions className={classes.footer}>
          <FooterAction
            fullWidth
            disabled={false}
            variant={'extended'}
            value={countToLabel('footer', props.label, 0)} />
        </CardActions>
      </Card>
    </ErrorBoundary>
  )
}

export default useMemoRef(ChildrenHOC(SubbedNarrowWidget), [])
