import OptionHOC, { getOptionLabel } from '@platform/react/hoc/autocomplete/option'
import OverflowTooltip from 'ui/Element/Text/OverflowTooltip'
import { Box } from '@mui/material'
import { getFirstItem } from 'lib/util'

/**
 * Displays the name of an organisation alongside a sub-label consisting of a concatenated string
 * of organisation type, city and number.
 *
 * @param {Object} classes  styling for the cell
 * @param {Object} props    props for the cell
 *
 * @return {*}
 * @constructor
 */
const OrganisationOption = (classes, props = {}) => {
  const { option, events } = props
  const { value: { type = '', address: { city = '' } = {}, number = '' } } = option || {}
  const typeLabel = type.length ? getFirstItem(events.getLabel?.(type))?.value : type

  const subLabel = [typeLabel, city, number].reduce(
    (acc, key) => `${acc}${acc && key && ', '}${key}`,
    '',
  )

  return (
    <Box
      key={'item'}
      component={'div'}
      className={classes.option}
    >
      <OverflowTooltip>
        {getOptionLabel(option)}
      </OverflowTooltip>
      <Box className={classes.label}>
        {subLabel}
      </Box>
    </Box>
  )
}

export default OptionHOC(OrganisationOption)
