/* eslint-disable object-curly-newline, implicit-arrow-linebreak */
/* global React */
import { Grid, List } from '@mui/material'
import { useStyles, useMemoRef } from '@platform/react/hook'

/**
 * Styles for the component.
 *
 * @param {Object} theme
 *
 * @return {Object}
 */
const style = theme => ({
  root: {
    listStyle: 'initial',
    paddingInlineStart: '16px',
    padding: 0,
    color: theme.palette.text.primary,
  },
  item: {
    marginBottom: 20,
  },
  itemIconContainer: {
    margin: '-5px 0 0 0',
  },
  itemIcon: {
    width: 30,
    height: 30,
    lineHeight: '30px',
    backgroundColor: theme.palette.primary.main,
    borderRadius: '50%',
    color: '#FFFFFF',
    textAlign: 'center',
    verticalAlign: 'middle',
  },
  itemHeading: {
    lineHeight: '30px',
    verticalAlign: 'middle',
    color: theme.palette.text.primary,
    marginBottom: 20,
    fontWeight: 500,
  },
  itemContainer: {},
  itemContent: {},
  itemChild: {
    '&:nth-child(3), &:nth-child(4)': {
      marginLeft: -8,
      marginRight: 16,
    },
  },
  ...theme.custom.simpleList,
})

/**
 * Extracts the Grid attributes from props.
 *
 * @param {object} props  the properties to extract the grid attributes from
 * @returns {object}      an object containing an object with the grid props,
 *                        the gutterBottom's value and an object with the child props
 */
const filterGridProps = (props) => {
  const { xs, sm, md, lg, xl, marginBottom, ...other } = props
  const gridProps = { xs, sm, md, lg, xl, marginBottom }
  return { gridProps, props: other }
}

/**
 * SimpleListItems element. Returns an array containing the item elements of the list.
 *
 * @param {object[]} children   the list's items
 * @param {object} classes      the component's style classes
 * @param {boolean} numbered    whether the list should be numbered or make use of the items' icons
 * @returns {JSX.Element[]}     the items of the list
 * @constructor
 */
const SimpleListItems = ({ children, classes }) =>
  React.Children.map(children, (item, index) => {
    const { props } = filterGridProps(item.props)
    return { ...item, key: index, props: { classes, ...props } }
  })

/**
 * SimpleList Element. Displays a simple list of items.
 *
 * @param {object} forwardedRef a reference to the root element
 * @param {object} props        additional element's properties
 * @returns {JSX.Element}       the SimpleList element
 * @constructor
 * @deprecated Its usage can and should be replaced with other existing components.
 */
const SimpleList = ({ forwardedRef, ...props }) => {
  const classes = useStyles(style)()
  return (
    <Grid
      ref={forwardedRef}
      component={List}
      className={classes.root}>
      <SimpleListItems {...props} classes={classes}/>
    </Grid>
  )
}

export default useMemoRef(SimpleList, props => [props.children])
