/* global React */
import {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
  useTransition,
} from 'react'
import { Drawer as MuiDrawer, Fade, Grid, Icon, IconButton, Typography } from '@mui/material'
import { withStyles } from '@mui/styles'
import { Consumer } from '@platform/react/context/application'
import ScrollGrid from 'ui/Component/Grid/ScrollGrid'
import ErrorBoundary from 'ui/Error'

const styles = theme => ({
  root: {
    [theme.breakpoints.up('xs')]: {
      width: '100%',
    },
    [theme.breakpoints.up('md')]: {
      width: theme.layout.drawerWidth,
    },
    backgroundColor: theme.palette.background.main,
    overflowX: 'hidden',
    boxShadow: 'none',
    flex: '1 1 auto',
    borderRadius: 0,
  },
  header: {
    padding: '16px',
    borderBottom: `1px solid ${theme.palette.gray[900]}`,
  },
  title: {},
  content: {
    padding: theme.layout.drawerPadding,
    paddingBottom: 1,
    overflow: 'auto',
    justifyContent: 'center',
  },
  footer: {
    padding: [[theme.spacing(2), theme.layout.drawerPadding]],
    borderTop: `1px solid ${theme.palette.divider}`,
  },
  close: {
    position: 'absolute',
    right: '8px',
    top: '8px',
    color: theme.palette.black.main,
  },
  ...theme.custom.drawer,
})

/**
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const Header = ({ title, onClose, classes, xs }) => (
  <Grid
    item
    container
    xs={xs}
  >
    <Grid
      item
      className={classes.header}
      xs={12}
    >
      <Typography
        variant={'h6'}
        color={'black'}
        className={classes.title}
      >
        {title}
      </Typography>
      <IconButton
        className={classes.close}
        onClick={onClose}
        size={'large'}
      >
        <Icon>close</Icon>
      </IconButton>
    </Grid>
  </Grid>
)

const Drawer = forwardRef((props, ref) => {
  const [, startTransition] = useTransition()
  const [state, setState] = useState({})
  const [nextState, setNextState] = useState({})
  const containerRef = useRef()

  useImperativeHandle(ref, () => ({
    setState: newState => startTransition(() => {
      const { open, children: [rootComponent] = [] } = newState
      const newActions = rootComponent?.props.actions
      // making next access to actions easier
      const newDrawerState = { ...newState, actions: newActions }
      const oldDrawerState = { ...state, open }

      setState(open ? newDrawerState : oldDrawerState)
      setNextState(newDrawerState)
    }),
    state,
  }))

  const handleExit = useCallback(() => {
    setState(state.open ? state : nextState)
  }, [state, nextState])

  return (
    <Consumer>
      {({ router }) => {
        const { classes } = props
        const { restore } = router
        const [actionComponent] = state.children || []
        const { props: childProps } = actionComponent || {}
        const { events } = childProps || {}
        const { onClose } = events || {}

        return (
          <ErrorBoundary>
            <MuiDrawer
              open={state.open}
              // not having an onClose handler,
              // disables the ability to close dialog by clicking outside of it.
              onClose={ onClose || restore }
              anchor={'right'}
              classes={{ paper: classes.root }}
              onTransitionExited={handleExit}
              PaperProps={{
                sx: { maxWidth: theme => theme.breakpoints.values[props.maxWidth || 'lg'] },
              }}
            >
              <Grid
                container
                direction={'column'}
                flexWrap={'nowrap'}
                height={'100%'}
              >
                <Header
                  classes={classes}
                  title={state.title}
                  onClose={ onClose || restore }
                  xs={'auto'}
                />
                <ScrollGrid
                  ref={containerRef}
                  item
                  container
                  classes={classes}
                  xs={12}
                >
                  {state.children}
                </ScrollGrid>
                {state.actions && (
                  <Grid
                    item
                    container
                    className={classes.footer}
                    xs={'auto'}
                  >
                    {state.actions}
                  </Grid>
                )}
              </Grid>
            </MuiDrawer>
          </ErrorBoundary>
        )
      }}
    </Consumer>
  )
})

export default withStyles(styles)(Drawer)
