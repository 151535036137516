/* global G */
import { Box, Typography, useTheme } from '@mui/material'
import CellHOC from '@platform/react/hoc/list/cell'
import { debounce, PlatformEvent } from 'lib/util'
import { useContext, useEffect, useState, useTransition } from 'react'
import ApplicationContext from '@platform/react/context/application'
import OverflowTooltip from 'ui/Element/Text/OverflowTooltip'
import Input from 'ui/Element/Field/Input'
import Button from 'ui/Element/Button/Simple'
import { useEventCallback } from '@platform/react/hook'
import useEventHandler from '@platform/react/hook/useEventHandler'

const styles = theme => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  button: {
    padding: 0,
  },
  comment: {
    paddingTop: '1rem',
  },
})

/**
 * Displays information about a part of the cart for the positions list.
 *
 * @param {Object} classes  styling for the cell
 * @param {Object} props    props for the cell
 *
 * @return {*}
 * @constructor
 */
export const ListItemCellCartPositionsHeader = (classes, props = {}) => {
  const {
    id,
    name,
    articleNumber = null,
    comment,
    commentLabel,
    commentAddLabel,
    commentIcon,
    events,
  } = props || {}
  const { onCommentAdd } = events

  const theme = useTheme()
  const { eventBus } = useContext(ApplicationContext)
  const [, startTransition] = useTransition()
  const [showInput, setShowInput] = useState(false)
  const [currentComment, setCurrentComment] = useState(comment)

  useEffect(() => { eventBus.dispatch(eventBus.type(G.CART, G.READ)) }, [])

  const _cartEventName = eventBus.type(G.CART, G.DONE)
  const _cartEventHandler = useEventCallback(({ detail }) => {
    const { [G.DATA]: data } = detail
    const newPosition = data?.value.positions.find(x => x?.key === id || x?.name === name)

    if (newPosition) {
      newPosition?.comment
        && newPosition?.comment !== currentComment
        && setCurrentComment(newPosition.comment)
    }
  })
  useEventHandler(eventBus, _cartEventName, _cartEventHandler)

  const handleClick = () => setShowInput(!showInput)
  const handleComment = debounce((event) => {
    startTransition(() => onCommentAdd?.(new PlatformEvent(event, { position: {
      ...id !== name
        ? { key: id, comment: event.detail }
        : { name, comment: event.detail },
    } })))

    if (event?.key === 'Enter') setShowInput(false)
  }, 1000, false)

  return (
    <Box>
      <OverflowTooltip
        variant={'16/bold'}
        color={'textBlack'}
      >
        {name}
      </OverflowTooltip>
      <Typography
        variant={'14/medium'}
        color={'textPrimary'}
      >
        {articleNumber || '-'}
      </Typography>
      <Box className={classes.comment}>
        {!currentComment || showInput ? (
          <>
            {showInput && (
              <Input
                label={commentLabel}
                value={currentComment || ''}
                events={{ onKeyPress: handleComment }}
              />
            )}
            {!showInput && (
              <Button
                bold={false}
                fullWidth={false}
                onClick={handleClick}
                startIcon={commentIcon.name}
                startIconProps={{
                  variant: commentIcon.variant,
                  color: theme.palette.text.secondary,
                }}
                value={commentAddLabel}
              />
            )}
          </>
        ) : (
          <Typography
            onClick={() => setShowInput(true)}
            variant={'14/medium'}
            color={'textDescription'}
          >
            {/** @typedef React.ReactNode */}
            {currentComment}
          </Typography>
        )}
      </Box>
    </Box>
  )
}

export default CellHOC(ListItemCellCartPositionsHeader, styles)
