/* eslint-disable object-curly-newline, arrow-body-style, import/no-extraneous-dependencies */
import { useMemoRef } from '@platform/react/hook'
import ChildrenHOC from '@platform/react/hoc/children'
import LabeledText from 'ui/Element/Text/Labeled'

const classes = {
  label: {
    fontWeight: '500',
    whiteSpace: 'pre-line', // ref: https://github.com/mui-org/material-ui/issues/9189
    marginBottom: '8px',
  },
  value: {
    fontWeight: 'normal',
    whiteSpace: 'pre-line',
    lineHeight: 'normal',
    fontSize: '12px',
  },
}

/**
 * {@link LabeledText} with special styling for widgets.
 *
 * @param props             default props
 * @returns {JSX.Element}
 * @constructor
 */
const LabeledWidgetText = ({ ...props }) => <LabeledText {...props} classes={classes} />

export default useMemoRef(ChildrenHOC(LabeledWidgetText), p => [p.value])
