import { Typography } from '@mui/material'
import CellHOC from '@platform/react/hoc/list/cell'
import { def, getFirstItem } from 'lib/util'
import internal from 'model/organisation/collection/internal'

/**
 * Special username styles
 *
 * @param {Object} theme
 * @return {{verified: {color}, invited: {color}}}
 */
const styles = theme => ({
  root: {
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
  },
  verified: {
    color: theme.palette.info.main,
  },
  invited: {
    color: theme.palette.signal.main,
  },
  subRow: {
    gap: '5px',
    display: 'flex',
    alignItems: 'center',
  },
})

/**
 * Intended to display the name of the person alongside additional information like
 * {@code toBeValidated}, {@code toBeApproved} and invitation information. Some information is
 * only visible to user roles that passed the cell acl check.
 *
 * @param {Object} classes  styling for the cell
 * @param {Object} props    props for the cell
 *
 * @returns {JSX.Element}
 */
export const ListItemCellPersonFullName = (classes, props = {}) => {
  const { getStatus = null } = props?.events || {}

  const {
    acl,
    toBeValidated,
    blockedInfo,
    toBeApprovedInfo,
    toBeValidatedInfo,
    contactChannels = [],
    invite: {
      role: invitationRole,
      status: invitationStatusCache,
    } = {},
    organisation: {
      key: parentId = null,
      refs: {
        parent: baseParent = null,
      } = {},
    } = {},
    invitedAs,
    firstName,
    lastName,
    name,
    role,
  } = props

  const baseParentId = getFirstItem(baseParent)?.key || null

  const displayApproval = internal.some(x => x.key === baseParentId)
  const displayBlocked = internal.some(x => x.key === parentId)
  const displayValidation = def(toBeValidated)

  const [email = {}] = contactChannels
  const fullName = name ?? (
    firstName && lastName
      ? `${firstName.trim()} ${lastName.trim()}`
      : null
  )

  const invitationStatus = getFirstItem(getStatus?.(invitationStatusCache)) || {}
  const showInvitationStatus = invitationStatus?.showDescription

  const userInfo = (invitationRole && showInvitationStatus && <>{invitedAs} {invitationRole}</>)
    || (acl !== false && displayValidation && <>{toBeValidatedInfo}</>)
    || (acl !== false && displayApproval && <>{toBeApprovedInfo}</>)
    || (displayBlocked && <>{blockedInfo}</>)
    || role

  return <>
    <Typography
      color={'black'}
      variant={'body2'}
      className={classes.row}
      sx={{ fontWeight: 700 }}
      title={fullName || email.value || '-'}
    >
      {fullName || email.value || '-'}
    </Typography>
    <Typography
      variant={'caption'}
      color={'textSecondary'}
      className={`${classes.row} ${
        invitationRole
        || displayValidation
        || displayApproval
        || displayBlocked
          ? classes.invited
          : classes.verified}`}
    >
      {userInfo}
    </Typography>
  </>
}

export default CellHOC(ListItemCellPersonFullName, styles)
