/* eslint-disable import/no-named-as-default */
import { useCallback, useMemo, useState } from 'react'
import { Box, Grid, Menu, useTheme } from '@mui/material'
import OverflowTooltip from 'ui/Element/Text/OverflowTooltip'
import ContextMenu from 'ui/Component/Acl/Item/NotificationMenu/ContextMenu'
import StructuredList from 'ui/Component/List/Structured'
import SimpleButton from 'ui/Element/Button/Simple'
import { capitalizeFirstLetter } from 'lib/util/string'

/**
 * Helper function to return a event handler that can optionally
 * execute a callback.
 *
 * @returns {function(*, {handler?: null, callback?: null}): *}
 */
const useHandler = () => useCallback((event, { handler = null, callback = null }) => {
  event.preventDefault()
  event.stopPropagation()

  const result = handler?.(event)
  callback?.()

  return result ?? undefined
}, [])

/**
 * Maps properties to list row cells
 * @private
 */
const _propertyMapping = {
  alert: {
    description: {
      title: true,
      body: true,
      created: true,
    },
  },
  message: {
    description: {
      attachments: true,
      partner: true,
      submitTimestamp: true,
      text: true,
      title: true,
      type: true,
      unreadMessages: true,
    },
  },
}

/**
 * Returns the general structure of the {@link StructuredList} used for notifications.
 *
 * @param {Object} labels labels for the cell components
 * @param {string} type   type of notification (alert, messages, ...)
 * @returns {Object[]}
 * @private
 */
const _getStructure = (labels, type) => [
  {
    view: 'Element/List/Item/Cell/Notification/Icon',
    placeholder: 'Element/Placeholder/List/Item/Cell/Block',
    options: {
      group: type,
      type: 'documentation', // TODO: change this once we handle more types
      keys: {},
    },
  },
  {
    view: `Element/List/Item/Cell/Notification/${capitalizeFirstLetter(type)}/Description`,
    placeholder: 'Element/Placeholder/List/Item/Cell/Block',
    options: {
      keys: _propertyMapping[type].description,
    },
  },
  {
    view: `Element/List/Item/Cell/Notification/${capitalizeFirstLetter(type)}/Actions`,
    placeholder: 'Element/Placeholder/List/Item/Cell/Block',
    options: {
      labels,
      keys: {},
    },
  },
]

/**
 * Helper function to create the list component
 *
 * @param {Object} events events for the list
 * @param {Object} labels labels for the list
 * @param {Object} props  additional props
 * @returns {JSX.Element}
 * @private
 */
const _createList = ({ events, labels, ...props }) => {
  const {
    data,
    update,
    reload,
    page,
    size,
    structure,
    justify,
    type,
    documentType,
    openInNewTab,
  } = props || {}

  const { noData, openInNewTabLabel, ...restLabels } = labels
  const [contextMenu, setContextMenu] = useState({ anchor: null })

  const listLabels = Object.keys(restLabels).map(label => (
    { type: label, label: restLabels[label] }
  ))

  const listStructure = useMemo(() => structure ?? _getStructure(listLabels, type), [])

  const handler = useHandler()
  const handleContextMenu = (event, item) => handler(
    event,
    { callback: () => openInNewTab && setContextMenu({ anchor: event.target, item }) },
  )

  return !events?.onOpen ? null
    : [
      <StructuredList
        key={'list'}
        data={data}
        page={page}
        update={update}
        reload={reload}
        documentType={documentType}
        size={size || [1, 9, 2]}
        structure={listStructure}
        justify={justify || 'space-between'}
        events={{
          ...events,
          onContextMenu: item => event => handleContextMenu(event, item),
        }}
        children={[
          <Box
            key={'nodata'}
            sx={{
              flex: 1,
              display: 'flex',
              justifyContent: 'space-around',
              alignItems: 'center',
            }}
          >
            <OverflowTooltip variant={'16/medium'}>
              {noData}
            </OverflowTooltip>
          </Box>,
        ]}
      />,
      <ContextMenu
        key={'contextmenu'}
        value={openInNewTabLabel}
        hidden={!openInNewTab}
        anchor={contextMenu?.anchor}
        item={contextMenu?.item}
        onClick={events?.onClick}
      />,
    ]
}

/**
 * Helper function to create the top menu
 *
 * @param {Object} labels       labels for the menu
 * @param {Object} events       events for the menu
 * @param {number} unreadCount  number of unread notifications
 * @param {number} count        number of total notifications
 * @returns {JSX.Element}
 * @private
 */
const _createTopMenu = ({ labels, events, unreadCount, count, ...props }) => {
  const theme = useTheme()
  const { onDeleteAll, onUpdateAll } = events

  const handler = useHandler()
  const handleDelete = event => handler(event, { handler: onDeleteAll })
  const handleUpdate = event => handler(event, { handler: onUpdateAll })

  return (
    <Grid
      container
      sx={{
        top: 0,
        zIndex: 1,
        paddingX: '1rem',
        paddingY: '0.625rem',
        position: 'sticky',
        background: theme.palette.white.main,
        borderBottom: `1px solid ${theme.palette.gray[900]}`,
      }}
    >
      <Grid
        item
        xs={12}
        sm={4}
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
        }}
      >
        <OverflowTooltip
          variant={'20/bold'}
          color={theme.palette.black.main}
        >
          {labels.title}
        </OverflowTooltip>
      </Grid>
      <Grid
        item
        xs={12}
        sm={8}
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
        }}
      >
        {onUpdateAll && (
          <SimpleButton
            fullWidth={false}
            disabled={unreadCount === 0}
            variant={'text'}
            color={'black'}
            onClick={handleUpdate}
            value={labels.updateAll}
          />
        )}
        {onDeleteAll && (
          <SimpleButton
            fullWidth={false}
            disabled={count === 0}
            variant={'text'}
            color={'black'}
            onClick={handleDelete}
            value={labels.deleteAll}
          />
        )}
      </Grid>
    </Grid>
  )
}

/**
 * Menu that should be toggled on clicking the notification icon.
 *
 * @param {React.ReactNode} anchor  anchor element for the menu
 * @param {Object} events           events for the menu
 * @param {Boolean} open            whether the menu is visible
 * @param {Object} labels           labels for the menu
 * @param {Object} props            additional props
 * @returns {JSX.Element}
 * @constructor
 */
const FloatingMenu = ({ anchor, events, open, labels, ...props }) => {
  const { listOptions, menuOptions } = props
  const listEvents = {
    onOpen: events?.onOpen,
    onUpdate: events?.onUpdate,
    onDelete: events?.onDelete,
    onPage: events?.onPage,
    onClick: events?.onShow,
    ...events?.listEvents && { ...events.listEvents },
  }

  const menuEvents = {
    onDeleteAll: events?.onDeleteAll,
    onUpdateAll: events?.onUpdateAll,
  }

  const listLabels = {
    noData: labels.noData,
    update: labels.update,
    updateReverse: labels.updateReverse,
    delete: labels.del,
    openInNewTabLabel: labels.openInNewTabLabel,
  }

  const menuLabels = {
    title: labels.title,
    description: labels.description,
    updateAll: labels.updateAll,
    deleteAll: labels.deleteAll,
  }

  return (
    <Menu
      keepMounted
      open={open}
      anchorEl={anchor}
      onClose={events?.onClose}
      elevation={3}
      MenuListProps={{
        sx: {
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
        },
      }}
      slotProps={{
        paper: {
          sx: {
            position: 'relative',
            width: '37.5rem',
            height: '43.75rem',
            '&::before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        },
      }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      {_createTopMenu({
        ...menuOptions,
        events: menuEvents,
        labels: menuLabels,
      })}
      {_createList({
        ...listOptions,
        events: listEvents,
        labels: listLabels,
      })}
    </Menu>
  )
}

export default FloatingMenu
