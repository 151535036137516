import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { createPortal } from 'react-dom'

const Portal = forwardRef((props, ref) => {
  const [state, setState] = useState({ open: false, children: [] })
  const callbackRef = useRef()
  const stateChangedRef = useRef(false)
  const portalRef = useRef()

  const setStateCallback = (newState, callback) => {
    stateChangedRef.current = true
    callbackRef.current = callback
    setState(newState)
  }

  useImperativeHandle(ref, () => ({
    setState: setStateCallback,
    getPortal: () => portalRef.current,
    state,
  }))

  useEffect(() => {
    if (stateChangedRef.current) {
      callbackRef.current?.()
      stateChangedRef.current = false
    }
  }, [state])

  const children = () => state.open && createPortal(state.children, portalRef.current)

  return (
    <div ref={portalRef} >
      {children()}
    </div>
  )
})

export default Portal
