import { Typography } from '@mui/material'
import CellHOC from '@platform/react/hoc/list/cell'
import LabeledHOC from '@platform/react/hoc/list/labeled'

/**
 * Intented to display the serviceBy property in labelled lists.
 *
 * @param {Object} classes  styling for the cell
 * @param {Object} props    props for the cell
 *
 * @returns {JSX.Element}
 */
const ListItemCellDeviceServicedBy = (classes, props = {}) => {
  const {
    serviceBy: {
      value: {
        name = '-',
      } = {},
    } = {},
  } = props

  return (
    <>
      <Typography
        color={'black'}
        variant={'body2'}
        className={classes.row}
        sx={{ fontWeight: 700 }}
      >
        {name}
      </Typography>
    </>)
}

export default CellHOC(
  LabeledHOC(
    props => props.labels.serviceBy,
    ListItemCellDeviceServicedBy,
  ),
)
