/* global G */
import { Box, Grid, IconButton, Typography, useTheme } from '@mui/material'
import { useMemoRef, useStyles } from '@platform/react/hook'
import { useContext, useTransition } from 'react'
import SvgIcon, { SvgRoundedIcon } from 'ui/Element/Icon/Svg'
import ApplicationContext from '@platform/react/context/application'

const styles = theme => ({
  root: {
    height: '4rem',
    justifyContent: 'space-around',
  },
  content: {
    display: 'flex',
    alignItems: 'center',
  },
  backIcon: {
    padding: 0,
    marginRight: '1rem',
    '& > *': { // rotating arrow icon
      transform: 'rotate(180deg)',
      cursor: 'pointer',
    },
  },
  closeButton: {
    cursor: 'pointer',
  },
  label: {
    flex: '1 1 auto',
  },
})

/**
 * CartHeader component
 *
 * @param {Object} cart             the currently active cart
 * @param {React.Ref} forwardedRef  forwarded ref
 * @param {Object} parentClasses    classes injected from the parent
 * @param {number} activeStep       the currently active step
 * @param {Object[]} steps          list of steps
 * @param {Object} events           events for the component
 * @param {Object} props            additional props
 * @returns {JSX.Element}
 * @constructor
 */
const CartHeader = ({ cart, forwardedRef, classes: parentClasses, activeStep, steps, events, ...props }) => {
  const theme = useTheme()
  const classes = { ...useStyles(styles)(), ...parentClasses }

  const {
    session: { [G.MODULE]: { [G.STATE]: { [G.ACTION]: action } } },
  } = useContext(ApplicationContext)
  const onModalClose = action[G.COMPONENT][G.EVENTS]?.onClose

  const currentStep = steps.find(step => step.index === activeStep)
  const { label } = currentStep.options

  const { onClick = null } = events
  const { spacing = 0, space = 0, gap = 0, gridProps, ...options } = props
  const { backIcon, closeIcon } = options

  const canStep = activeStep !== 0

  return (
    <Grid
      item
      container
      ref={forwardedRef}
      className={classes.root}
      xs={props.xs}
      sm={props.sm}
      md={props.md}
      lg={props.lg}
      xl={props.xl}
      style={{
        padding: spacing
          ? theme.spacing(spacing)
          : theme.spacing(space, gap),
      }}
    >
      <Grid
        item
        xs={12}
        className={classes.content}
      >
        {!canStep ? null : (
          <IconButton
            onClick={onClick}
            className={classes.backIcon}
          >
            <SvgIcon
              icon={backIcon.name}
              variant={backIcon.variant}
            />
          </IconButton>
        )}
        <Typography
          variant={'24/bold'}
          color={'textDark'}
          className={classes.label}
        >
          {label}
        </Typography>
        <Box
          onClick={onModalClose}
          className={classes.closeButton}
        >
          <SvgIcon
            icon={closeIcon.name}
            variant={closeIcon.variant}
            background={theme.palette.common.white}
            rounded={true}
            size={30}
          />
        </Box>
      </Grid>
    </Grid>
  )
}

export default useMemoRef(CartHeader, props => [props.activeStep, props.steps])
