/* eslint-disable no-unused-vars */
/* global React */
import CellHOC from '@platform/react/hoc/list/cell'
import Autocomplete from 'ui/Component/Field/Autocomplete'
import { useEffect, useRef, useState } from 'react'
import { PlatformEvent } from 'lib/util'

/**
 * Returns the ListItemCellUserAdminRoleAtOrg element's current theme styles.
 *
 * @param {Object} theme    the application's current theme
 * @returns {Object}        the ListItemCellUserAdminRoleName component's styles
 */
const styles = theme => ({
  root: {
    width: '100%',
    marginRight: '10px',
  },
})
/**
 * Displays the role input field for each users' role.
 *
 * @param {Object} classes  styling for the cell
 * @param {Object} props    props for the cell
 *
 * @return {*}
 * @constructor
 */
export const ListItemCellUserAdminRoleName = (classes, props = {}) => {
  const { index, label, role, events, view } = props
  const parentRef = useRef(null)
  const [disabled, setDisabled] = useState(false)

  useEffect(() => {
    (async () => {
      const event = new CustomEvent('create')
      setDisabled(await events?.onRoleCreate(
        new PlatformEvent(event, index),
      ))
    })()
  })

  const Field = React.forwardRef(Autocomplete)

  return (
    <Field
      events={{
        onOpen: events?.onRoleOpen,
        onChange: events?.onRoleChange(parseInt(index, 10)),
      }}
      fullWidth={false}
      ref={parentRef}
      view={view}
      value={role}
      label={label}
      oneShot={true}
      freeText={false}
      filterLocally={true}
      disabled={disabled}
    />
  )
}

export default CellHOC(ListItemCellUserAdminRoleName, styles)
