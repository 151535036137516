import { Badge, Box, Icon, Typography } from '@mui/material'
import CellHOC from '@platform/react/hoc/list/cell'

/**
 * Intended to display an icon for the request
 *
 * @param {Object} classes  styling for the cell
 * @param {Object} props    props for the cell
 *
 * @return {JSX.Element}
 */
const ListItemCellRequestMessageIcon = (classes, props = {}) => {
  const { unreadMessages } = props
  return (
    <Typography
      component={'div'}
    >
      <Box
        sx={{
          width: 40,
          height: 40,
        }}
        className={classes.root}
      >
        <Badge
          color={'signal'}
          badgeContent={unreadMessages}
          sx={{ color: 'common.white' }}
        >
          <Icon sx={{ color: 'secondary.main' }}>mail</Icon>
        </Badge>
      </Box>
    </Typography>
  )
}

export default CellHOC(ListItemCellRequestMessageIcon)
