import NameOrCount from 'ui/Element/List/Item/Cell/NameOrCount'

/**
 * ListItemCellCountAvailable element
 *
 * @param {object} props  additional element properties
 * @returns {JSX.Element} the new ListItemCellCountAvailable element
 * @constructor
 *
 * @todo change element to work with organisation, team, user refs
 */
export const ListItemCellTextTemplateCountAvailable = ({ count }) => {
  const available = Object.values(count).reduce(
    (acc, item) => acc + (item.textTemplate.count || 0), 0,
  )
  return <NameOrCount {...available} />
}

export default ListItemCellTextTemplateCountAvailable
