import CellHOC from '@platform/react/hoc/list/cell'
import LabeledHOC from '@platform/react/hoc/list/labeled'
import { ListItemCellRequestRequester } from 'ui/Element/List/Item/Cell/Request/Requester'

export default CellHOC(
  LabeledHOC(
    props => props.labels.request,
    ListItemCellRequestRequester,
  ),
)
