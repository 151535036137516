/* eslint-disable object-curly-newline */
/* global G */
import { useContext, useRef, useState } from 'react'
import { Grid, useTheme } from '@mui/material'
import ApplicationContext from '@platform/react/context/application'
import { useMemoRef, useEventCallback, useEventHandler } from '@platform/react/hook'
import format from 'lib/util/string'
import DecoratedText from 'ui/Element/Text/Decorated'
import { filterItems } from 'ui/Component/Attachment/List'

/**
 * Attachment ImageList component
 *
 * Uses the attachment adapter to obtain the list of currently attached files and display its
 * quantity.
 *
 * @param {object} forwardedRef
 * @param {object[]} [data]           a list of items to be counted
 * @param {string} [group]            the attachment group identifier
 * @param {object} [props]            additional component's properties
 * @param {number} [props.limit]      the maximum count to display
 * @param {object} [props.filter]     the filters to be used to specify the elements to be counted
 * @param {string} [props.label]      the text to use instead of just the count value
 * @param {boolean} [props.autoHide]  whether the element should be hidden when count is 0
 * @param {number} [props.space]      the number of spacings the bottom margin should have
 * @returns {JSX.Element}       a new AttachmentCount element
 * @constructor
 */
const AttachmentCount = ({ forwardedRef, data, group, ...props }) => {
  const { limit, filter, label, autoHide, space = 0, ...restProps } = props
  const { eventBus } = useContext(ApplicationContext)
  const [count, setCount] = useState(data ? filterItems(data, limit, filter).length : 0)
  const theme = useTheme()

  if (group) {
    // dispatched from adapter, once data or cache have been updated
    const _eventName = useRef(eventBus.type(G.ATTACHMENT, G.DONE, group))
    const _eventHandler = useEventCallback(({ detail }) => {
      const filteredItems = filterItems(detail[G.DATA], limit, filter)
      setCount(filteredItems.length)
    })
    useEventHandler(eventBus, _eventName.current, _eventHandler)
  }

  return autoHide && count < 1 ? null : (
    <Grid
      {...restProps}
      item
      style={{
        marginBottom: theme.spacing(space),
      }}
    >
      <DecoratedText
        {...restProps}
      >
        { label ? format(label, { count }) : count }
      </DecoratedText>
    </Grid>
  )
}

export default useMemoRef(AttachmentCount, [])
