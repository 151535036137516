/* global React */

/**
 * ErrorBoundary component class
 *
 * {@link https://reactjs.org/docs/error-boundaries.html}
 */
class ErrorBoundary extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error }
  }

  // eslint-disable-next-line class-methods-use-this
  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.warn(error, errorInfo)
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <h6>UI Error<p>{this.state.error.message}</p></h6>
    }

    return this.props.children
  }
}

export default ErrorBoundary
