/* eslint-disable no-unused-vars,no-nested-ternary */
/* global React */
import { useCallback, useState } from 'react'
import { Grid } from '@mui/material'
import { getReadableDate } from 'lib/util/date'
import { useMemoRef, useStyles } from '@platform/react/hook'
import Text from 'ui/Element/Text/Simple'
import FileImage from 'ui/Element/Attachment/Item/File'
import FabButton from 'ui/Element/Button/Fab'
import { formatFilesize } from 'lib/util/attachment'
import { isRemote } from '@platform/adapter/attachment/helper'
import AttachmentListTemplateCircleButton from 'ui/Component/Attachment/List/Template/CircleButton'

/**
 * Returns the AttachmentListTemplateBigLine element's current theme styles.
 *
 * @param {Object} theme            the application's current theme
 * @param {Boolean} optionsVisible  whether the options should be visible
 * @param {Boolean} isLoading       whether the current attachment is being downloaded
 *
 * @returns {object}      the AttachmentListTemplateBigLine component's styles
 */
const styles = (theme, { optionsVisible, isLoading }) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    borderStyle: 'solid',
    borderColor: '#E6E7E8',
    borderWidth: '0 0 1px 0',
    justifyContent: 'flex-start',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    '&:last-child': { borderWidth: 0 },
    '&:hover': {
      backgroundColor: 'rgba(87, 87, 87, 0.04)',
    },
  },
  download: {
    cursor: 'pointer',
  },
  name: {
    overflow: 'hidden',
    flexDirection: 'column',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  info: {
    alignContent: 'center',
    flexDirection: 'column',
  },
  label: {
    fontWeight: 500,
    display: 'block',
    overflow: 'hidden',
    whiteSpace: 'nowrap!important', // overwriting {@code Text} styles
    textOverflow: 'ellipsis',
    color: theme.palette.text.dark,
  },
  size: {
    fontSize: '14px',
    fontWeight: 'normal',
    color: theme.palette.text.secondary,
  },
  submitter: {
    fontSize: '16px',
    fontWeight: 'normal',
    color: theme.palette.text.secondary,
  },
  new: {
    fontWeight: 500,
    textTransform: 'uppercase',
    color: theme.palette.secondary.main,
  },
  options: {
    flex: 1,
    display: 'flex',
    alignSelf: 'center',
    alignItems: 'center',
    position: 'relative',
    justifyContent: 'center',
    visibility: optionsVisible || isLoading ? 'visible' : 'hidden',
  },
  ...theme.custom.attachmentListTemplateBigLine,
})

/**
 * Displays an attachment with some additional information
 * about the author and upload time in a thick row.
 *
 * @param {object} attachment     the attached file
 * @param {object} types          the types for the {@link FileImage} element
 * @param {string} group          the attachment group identifier
 * @param {string} meLabel        translated label for 'Me'
 * @param {string} newLabel       translated label for 'New'
 * @param {function} onClick      a handler to be called whenever the clear button is clicked
 * @param {boolean} readOnly      whether or not the delete button for an attachment should be
 *                                visible
 */
const AttachmentListTemplateBigLine = ({
  attachment,
  types,
  group,
  meLabel,
  newLabel,
  events: { onClick },
  readOnly,
}) => {
  const [optionsVisible, setOptionsVisible] = useState(false)
  const handleClick = useCallback(onClick(group, attachment), [attachment])

  const { value: item } = attachment
  const [attachmentState, setAttachmentState] = useState({})
  const { progress = 0 } = attachmentState || {}
  const isLoading = progress !== 0 && progress !== 100

  const classes = useStyles(styles, { optionsVisible, isLoading })()

  return (
    <Grid
      item
      container
      xs={12}
      md={12}
      xl={12}
      className={classes.root}
      onMouseEnter={() => setOptionsVisible(true)}
      onMouseLeave={() => setOptionsVisible(false)}
    >
      <FileImage
        md={1}
        lg={1}
        xl={1}
        types={types}
        attachment={attachment}
        events={{ onState: setAttachmentState }}
      />
      <Grid
        item
        xs={6}
        md={6}
        xl={6}
        className={classes.name}
      >
        <Grid
          item
          component={Text}
          className={classes.label}
        >
          {item.name}
        </Grid>
        <Grid
          item
          component={Text}
          className={classes.size}
        >
          {formatFilesize(item.size)}
        </Grid>
      </Grid>
      <Grid
        item
        xs={3}
        md={3}
        xl={3}
        className={classes.info}
      >
        {isRemote(attachment) ? (
          <>
            <Grid
              item
              component={Text}
              className={classes.submitter}
            >
              {attachment.value?.submitTimestamp ? getReadableDate(attachment.value.submitTimestamp) : ''}
            </Grid>
            <Grid
              item
              component={Text}
              className={classes.size}
            >
              {attachment.value.me ? meLabel : attachment.value.submitter ? attachment.value.submitter : ''}
            </Grid>
          </>
        ) : (
          <Grid
            item
            component={Text}
            className={classes.new}
          >
            {newLabel}
          </Grid>
        )}
      </Grid>
      <Grid
        item
        xs={'auto'}
        md={'auto'}
        xl={'auto'}
        className={classes.options}
      >
        <AttachmentListTemplateCircleButton attachment={attachment} />
        {!readOnly && attachment.value.parentType !== 'note' && (
          <FabButton
            color={'secondary'}
            events={{ onClick: handleClick }}
            icon={{ name: 'close', variant: 'filled' }}
          />
        )}
      </Grid>
    </Grid>
  )
}

export default useMemoRef(AttachmentListTemplateBigLine, props => [props.attachment])
