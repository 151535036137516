import { Typography } from '@mui/material'
import CellHOC from '@platform/react/hoc/list/cell'
import { useContext } from 'react'
import ApplicationContext from '@platform/react/context/application'

/**
 * Intended to display name and address of the organisation where a person belongs to.
 *
 * @param {Object} classes  styling for the cell
 * @param {Object} props    props for the cell
 *
 * @returns {JSX.Element}
 */
export const ListItemCellRequestType = (classes, props = {}) => {
  const { intl } = useContext(ApplicationContext)
  const { number, submit: { timestamp = '' } = {} } = props
  const submitDate = timestamp
    ? intl.getLocaleDate(timestamp, { dateStyle: 'short' })
    : ''

  return (
    <>
      <Typography
        color={'black'}
        variant={'body2'}
        className={classes.row}
        sx={{ fontWeight: 700 }}
      >
        {number || '-'}
      </Typography>
      <Typography
        variant={'caption'}
        color={'textSecondary'}
        className={classes.row}
      >
        {submitDate}
      </Typography>
    </>
  )
}

export default CellHOC(ListItemCellRequestType)
