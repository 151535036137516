import OptionHOC, { getOptionLabel } from '@platform/react/hoc/autocomplete/option'
import OverflowTooltip from 'ui/Element/Text/OverflowTooltip'
import { Box } from '@mui/material'
import { getFirstItem } from 'lib/util'

/**
 * Displays the name of a service item alongside its serial number and product name as a sublabel.
 *
 * @param {Object} classes  styling for the cell
 * @param {Object} props    props for the cell
 *
 * @return {*}
 * @constructor
 */
const ServiceItemOption = (classes, props = {}) => {
  const { option } = props
  const {
    value: { serial = '' } = {},
    refs: { product = {} } = {},
  } = option || {}
  const productName = getFirstItem(product)?.value?.name || ''

  const subLabel = [serial, productName].reduce(
    (acc, key) => `${acc}${acc && key && ', '}${key}`,
    '',
  )

  return (
    <Box
      key={'item'}
      component={'div'}
      className={classes.option}
    >
      <OverflowTooltip>
        {getOptionLabel(option)}
      </OverflowTooltip>
      <Box className={classes.label}>
        {subLabel}
      </Box>
    </Box>
  )
}

export default OptionHOC(ServiceItemOption)
