/* global G */
/* eslint-disable object-curly-newline,no-unused-vars */
import {Badge, IconButton, ListItem} from '@mui/material'
import {useMemoRef} from '@platform/react/hook'
import SvgIcon from 'ui/Element/Icon/Svg'
import {useCallback, useContext, useMemo} from 'react'
import ApplicationContext from 'platform/react/context/application'
import {isArr} from 'lib/util'
import FloatingMenu from 'ui/Component/Acl/Item/NotificationMenu/Menu'

/**
 * Helper function to return a event handler that can optionally
 * execute a callback.
 *
 * @returns {function(*, {handler?: null, callback?: null}): *}
 */
const useHandler = () => useCallback((event, { handler = null, callback = null }) => {
  event.preventDefault()
  event.stopPropagation()

  const result = handler?.(event)
  callback?.()

  return result ?? undefined
}, [])

/**
 * Simple, ACL-controlled {@link Menu} meant to be used for links rendered in the
 * {@link Toolbar} that make use of the notification adapter.
 *
 * It gets rendered by the {@link notification} HOC, passing it the {@param} group.
 * The group adapters {@code G.API} will be used to get the various events needed.
 *
 * The current state of the notification will be passes as {@param notification}.
 * The {@code open} state of the menu is controlled by the adapter and passed via
 * the {@param notification.anchor} prop.
 *
 * @param {Object} child            various options for the icon
 * @param {Object} [options]        additional options
 * @param {Object} notification     state of the notification
 * @param {string} group            the name of the group
 * @param {Object} props            additional props
 * @returns {JSX.Element}
 * @constructor
 */
const NotificationMenu = ({ child, options, notification, group, ...props }) => {
  const {
    key,
    label,
    noDataLabel,
    openInNewTabLabel,
    description,
    active,
    disabled,
    icon,
    color,
    classes,
    openInNewTab = false,
  } = child

  const { session: { [G.MODULE]: currentModule } } = useContext(ApplicationContext)
  const { [G.GROUP]: { [group]: adapter } } = currentModule[G.ADAPTER][G.NOTIFICATION]
  const { [G.INTL]: intlAdapter } = currentModule[G.ADAPTER]

  const {
    toggle,
    read: onOpen,
    update: onUpdate,
    delete: onDelete,
    deleteAll: onDeleteAll,
    updateAll: onUpdateAll,
    set: onPage,
    show: onShow,
  } = adapter?.[G.API] || {}

  const listEvents = adapter?.[G.STATE]?.[G.LIST]?.[G.EVENTS] || null

  const { update, updateReverse, updateAll, delete: del, deleteAll } = useMemo(() => intlAdapter._t(
    'actions', {
      ns: 'common',
      _key: 'actions',
      returnObjects: true,
      defaultValue: {},
    },
  ), [])

  const {
    data,
    page,
    count: unreadCount,
    update: updateList,
    reload,
    anchor = null,
  } = notification

  const startData = updateList && isArr(data) ? data : undefined
  const count = data && isArr(data) ? data.length : 0

  const menuOptions = { count, unreadCount }
  const listOptions = {
    data: startData,
    page,
    update: updateList,
    reload,
    type: group,
    documentType: options?.notification?.documentType,
    openInNewTab,
  }

  const handler = useHandler()
  const handleClick = event => handler(event, { callback: () => { toggle?.(event.currentTarget) } })
  const handleClose = event => handler(event, { callback: () => { toggle?.(null) } })
  const handleShow = event => handler(event, { handler: onShow })

  const Icon = <SvgIcon
    color={color || 'black.main'}
    icon={icon?.name || 'bell'}
    variant={icon?.variant || 'outlined'}
  />

  return props.hidden ? null : (
    <ListItem
      key={key}
      className={`${classes.toolbarLinksItem}`}
    >
      <FloatingMenu
        open={Boolean(anchor)}
        anchor={anchor}
        listOptions={listOptions}
        menuOptions={menuOptions}
        events={{
          onOpen,
          onUpdate,
          onUpdateAll,
          onDelete,
          onDeleteAll,
          onPage,
          ...listEvents && { listEvents },
          onShow: handleShow,
          onClick: handleClick,
          onClose: handleClose,
        }}
        labels={{
          title: label,
          noData: noDataLabel,
          openInNewTabLabel,
          description,
          update,
          updateReverse,
          updateAll,
          del,
          deleteAll,
        }}
      />
      <IconButton
        size={'small'}
        onClick={handleClick}
        disabled={disabled || active}
      >
        {unreadCount > 0
          ? (<Badge
            badgeContent={unreadCount}
            classes={{ badge: classes.toolbarLinksItemBadge }}
          >
            {Icon}
          </Badge>)
          : Icon
        }
      </IconButton>
    </ListItem>
  )
}

export default useMemoRef(NotificationMenu)
