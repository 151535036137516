/* eslint-disable import/no-extraneous-dependencies */
/* global React */
import { Box, Typography } from '@mui/material'

import { useStyles, useMemoRef } from '@platform/react/hook'
import { getThemeColor } from 'lib/util'

const styles = (theme, { align, headingColor }) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontWeight: 700,
    textAlign: align,
    whiteSpace: 'pre-line',
    ...headingColor && { color: getThemeColor(theme, headingColor) },
  },
  shortTitle: {
    color: theme.palette.text.primary,
    fontWeight: 500,
    marginBottom: 8,
  },
  text: {
    color: theme.palette.text.primary,
    whiteSpace: 'pre-line',
    textAlign: align,
  },
  ...theme.custom.prefaceText,
})

export const PrefaceText = ({ forwardedRef, ...props }) => {
  const {
    variant = {},
    gutterBottom = {},
    color = {},
    heading,
    label,
    shortTitle,
    text,
    value,
    align = 'left',
    classes: parentClasses = {},
    headingColor,
  } = props

  const classes = useStyles(styles, { align, headingColor })()
  return (
    <Box
      elevation={0}
      ref={forwardedRef}
      component={'header'}
      className={classes.root}
    >
      {!shortTitle ? null : (
        <Typography
          component={'p'}
          color={color.shortTitle}
          variant={variant.shortTitle || 'caption'}
          textTransform={'uppercase'}
          className={parentClasses.shortTitle || classes.shortTitle}
        >
          { shortTitle }
        </Typography>
      )}
      <Typography
        color={color.heading}
        variant={variant.heading}
        className={parentClasses.heading || classes.heading}
        gutterBottom={gutterBottom.heading}
      >
        { heading || label }
      </Typography>
      <Typography
        color={color.text}
        variant={variant.text}
        className={parentClasses.text || classes.text}
        gutterBottom={gutterBottom.text}
      >
        { text || value }
      </Typography>
    </Box>
  )
}

export default useMemoRef(PrefaceText, props => [
  props.heading, props.label, props.text, props.value,
])
