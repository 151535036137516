/* global React */
// eslint-disable-next-line object-curly-newline
import { useCallback } from 'react'
import { ImageListItemBar, ImageListItem, IconButton, Icon } from '@mui/material'
import { useMemoRef, useStyles, useAttachmentAPI } from '@platform/react/hook'

const styles = theme => ({
  tile: {
    display: 'flex',
    flexBasis: '22%',
    // height: 'auto',
    minHeight: 150,
    minWidth: 150,
    maxHeight: 180,
    marginBottom: '1%',
  },
  actionIcon: {
    display: 'flex',
  },
  icon: {
    color: '#fff',
  },
  ...theme.custom.attachmentListTemplateGeneric,
})

const AttachmentListTemplateGeneric = useMemoRef(({ attachment, ...props }) => {
  const { value: item } = attachment
  const classes = useStyles(styles)()
  const { state, onLoad } = useAttachmentAPI({ attachment })
  const { src, progress } = state

  const onClick = useCallback(props.events.onClick(attachment), [attachment])

  const onMore = useCallback((e) => {
    e.preventDefault()
    e.stopPropagation()
    console.log('onMore')
  }, [attachment])

  // here we are going to use whatever ui element / component to display image / type icons
  // embed could be used instead of img to display pdf preview,
  // embedded doc HAS to be detached on destruction
  return (
    <ImageListItem
      onClick={onClick}
      // cols={1}
      className={classes.tile}
    >
      <img
        src={src}
        alt={item.name}
        onLoad={onLoad}
        style={{
          width: '100%',
          height: 'auto',
        }}
      />
      <ImageListItemBar
        title={item.name}
        /* eslint-disable-next-line no-plusplus */
        subtitle={`${progress !== 100 ? `${progress}%` : ''}`}
        classes={{
          actionIcon: classes.actionIcon,
        }}
        actionIcon={
          <IconButton
            aria-label={`${item.name}`}
            className={classes.icon}
            onClick={onMore}
            size="large">
            <Icon>more_vert</Icon>
          </IconButton>
        }
      />
    </ImageListItem>
  )
}, props => [props.attachment])

export default AttachmentListTemplateGeneric
