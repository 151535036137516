/* eslint-disable object-curly-newline */
import { Chip, Link, ListItemIcon, ListItemText, MenuItem } from '@mui/material'
import useMemoRef from '@platform/react/hook/useMemoRef'
import SvgIcon from 'ui/Element/Icon/Svg'

/**
 * @example app defaults nav entry
 * {
 *  options: { key: 'organisation', label: 'Customers', icon: 'group' },
 *  route: { module: 'organisation', action: 'index' },
 *  acl: { organisation: [20]}
 * },
 */
const AclLinkItem = ({ child, options, notification }) => {
  const {
    key,
    selected,
    disabled,
    icon,
    variant,
    label,
    classes,
    context,
    route: { module, action, ref },
  } = child

  const { props, events: { onClick } } = options

  const active = selected.includes(key)

  return (
    <MenuItem
      dense
      selected={active}
      disabled={disabled || active}
      key={key}
      component={Link}
      href={`/#/${context}/${module}/${action}${ref ? `/${ref}` : ''}`}
      onClick={onClick}
      className={classes.menuItem}
      style={{ textDecoration: 'none' }}
      {...props}
    >
      <ListItemIcon
        className={classes.menuItemIcon}
      >
        {/* <Icon>{icon || ''}</Icon> */}
        <SvgIcon
          icon={icon}
          variant={variant}
        />
      </ListItemIcon>
      <ListItemText
        classes={{ primary: classes.menuItemText }}
        primary={label}
      />
      {notification?.count > 0
            && <Chip
              label={notification?.count}
              size={'small'}
              className={classes.chip}
            />
      }
    </MenuItem>
  )
}

export default useMemoRef(AclLinkItem)
