/* global G */
import { Grid } from '@mui/material'
import { useCallback, useContext } from 'react'
import AppContext from 'platform/react/context/application'
import { useMemoRef } from 'platform/react/hook'
import Button from 'ui/Element/Button/Simple'

/**
 * Navigation layout component
 *
 * Displays controls to navigate through the browser's history.
 *
 * @param {React.Ref} forwardedRef  a forwarded reference to the root element
 * @param {boolean} [hidden]        whether the element should not be rendered
 * @return {JSX.Element}
 * @constructor
 */
const BackButton = useMemoRef(({ forwardedRef, hidden }) => {
  const { session, router } = useContext(AppContext)
  const stack = session[G.PREV] || []

  const back = useCallback(() => router.back(), [])

  return hidden || !stack.length ? null : (
    <Grid
      ref={forwardedRef}
      item
      container
      height={'100%'}
      margin={0}
      flexWrap={'nowrap'}
      alignItems={'center'}
      justifyContent={'flex-start'}
      columnSpacing={2}
      xs={'auto'}
    >
      <Button
        icon={'arrow_left'}
        iconProps={{
          variant: 'filled',
        }}
        fullWidth={false}
        variant={'text'}
        color={'black'}
        size={'small'}
        events={{
          onClick: back,
        }}
      />
    </Grid>
  )
}, props => [props.update, props.hidden])

export default BackButton
