import { Box, Icon } from '@mui/material'
import { useStyles } from '@platform/react/hook'

/**
 * Returns the ListItemCellIcon element's current theme styles.
 *
 * @param {object} theme  the application's current theme
 * @returns {object}      the ListItemCellIcon element's styles
 */
const styles = theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: theme.typography.pxToRem(theme.typography.fontSize * 3),
    height: theme.typography.pxToRem(theme.typography.fontSize * 3),
  },
  icon: {
    height: '1.2em',
    width: '1.2em',
  },
})

/**
 * ListItemCellIcon element.
 *
 * Displays an icon.
 *
 * @param {object} props  element's properties
 * @returns {JSX.Element} the new ListItemCellIcon element
 * @constructor
 */
export const ListItemCellIcon = (props) => {
  const classes = useStyles(styles)()
  return (
    <Box
      className={classes.root}
    >
      <Icon
        className={classes.icon}
        color={props.color}
      >
        {props.icon}
      </Icon>
    </Box>
  )
}

export default ListItemCellIcon
