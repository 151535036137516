/* eslint-disable object-curly-newline,no-plusplus,implicit-arrow-linebreak */
/* global React, G */
import { Grid, List, Typography } from '@mui/material'
import ErrorBoundary from 'ui/Error'
import useMemoRef from '@platform/react/hook/useMemoRef'
import { Suspense, useContext, useEffect, useState } from 'react'
import { useMessageAPI, useStyles } from '@platform/react/hook'
import { differenceInMinutes, isBefore, parseISO, startOfDay } from 'date-fns'
import ApplicationContext from '@platform/react/context/application'

/**
 * Returns the MessageList element's current theme styles.
 *
 * @param {object} theme  the application's current theme
 * @returns {object}      the MessageList element's styles
 */
export const styles = theme => ({
  root: {
    backgroundColor: 'inherit',
    padding: theme.spacing(2),
    margin: [[-1, 1, 1, -1]],
    flex: [[1, 1, '100%']],
  },
  serial: {
    color: theme.palette.text.disabled,
  },
  header: {
    fontSize: '1rem',
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.background.content,
    borderStyle: 'solid',
    borderColor: '#E6E7E8',
    borderWidth: [[0, 0, 1, 0]],
    padding: 16,
  },
  ...theme.custom.messageList,
})

/**
 * Returns the elements to be displayed in case there is no data to show.
 *
 * @param {object} props    the elements' properties
 * @returns {JSX.Element[]} the returned elements' array
 * @constructor
 */
export const NoData = props => [
  <Grid
    item
    container
    component={'li'}
    key={'noData'}
    justifyContent={'center'}
    xs={props.xs}
    md={props.md}
  >
    <Typography
      variant={'subtitle1'}
      color={'textPrimary'}
      align={'center'}
    >
      {props.content || 'No messages.'}
    </Typography>
  </Grid>,
]

/**
 * Processes the dates of the children messages in order to conditionally display their footer and
 * date messages.
 *
 * @param {object[]} data                 the current list of messages
 * @param {string} sourceId               the current source set for the list
 * @param {string} userId                 the current source set for the list
 * @param {object} labels                 translated information labels
 * @param {object[]} systemMessageTypes   list of available system message types
 * @param {object} items                  an object containing the message items
 * @param {object} items.MessageItem      the item used to display a user's message
 * @param {object} items.SystemItem       the item used to display a system message
 * @param {object} items.DateItem         the item used to display a date message
 * @returns {*}
 * @private
 */
const _children = ({ data, sourceId, userId, systemMessageTypes, labels, events, ...items }) => {
  const { MessageItem, SystemItem, DateItem } = items
  return data.reduce((messages, current, index) => {
    const firstItem = index === 0
    const lastItem = index === data.length - 1
    // parsing current message's timestamp
    const { submitTimestamp } = current.value
    const currentTimestamp = submitTimestamp && parseISO(submitTimestamp)
    const currentSourceKey = current.refs.source[0]
    const currentSubmitterKey = current.refs.submitter?.[0].key
    // gathering previous message's relevant values
    const previous = data[index - 1]
    const previousTimestamp = previous && parseISO(previous.value.submitTimestamp)
    const previousSourceKey = previous?.refs.source[0]
    // const previousSubmitterKey = previous?.refs.submitter?.[0].key
    // gathering next message's relevant values
    const next = data[index + 1]
    const nextTimestamp = next && parseISO(next.value.submitTimestamp)
    const nextSubmitterKey = next?.refs.submitter?.[0].key
    // checking messages' parameters
    const currentStartOfDay = startOfDay(currentTimestamp)
    const dayChanged = previousTimestamp ? isBefore(previousTimestamp, currentStartOfDay) : true
    const timeExceeded = nextTimestamp && differenceInMinutes(nextTimestamp, currentTimestamp) > 10
    const sameSource = previousSourceKey === currentSourceKey
    const sameSubmitter = nextSubmitterKey === currentSubmitterKey
    // setting flags
    const showFooter = lastItem || timeExceeded || !sameSource || !sameSubmitter
    const showDate = firstItem || dayChanged
    const systemMessage = systemMessageTypes.find(
      systemMessageType => systemMessageType.key === current.value.type,
    )

    // conditionally adding date item
    showDate && messages.push(
      <DateItem
        key={currentTimestamp}
        value={currentTimestamp}
      />,
    )
    // adding message
    messages.push(
      systemMessage
        ? <SystemItem
          key={current.key}
          message={systemMessage}
        />
        : <MessageItem
          {...current}
          key={current.key || index}
          events={events}
          message={{
            id: current.key,
            ...current,
          }}
          currentUserId={userId}
          labels={labels}
          variant={!currentSourceKey || sourceId === currentSourceKey ? 'primary' : 'secondary'}
          showFooter={showFooter}
        />,
    )
    return messages
  }, [])
}

/**
 * Adds the attachments from the listed messages to the {@param groupName} attachment group.
 *
 * TODO: Extend Attachment API so that we don't have to do this.
 *
 * @param {object} state                      the state of the list
 * @param {string} groupName                  the name of the group to attach the attachments to
 * @param {boolean|function} [handler=false]  function for fetching message attachments
 */
export const attachToGroup = (state, groupName, handler = false) => {
  const handlerRef = React.useRef(false)
  const { eventBus } = useContext(ApplicationContext)
  const [attachments, setAttachments] = useState([])
  const { messages } = state

  useEffect(() => {
    // listening for the attachment API to set a new list of images to the attachment group
    eventBus.add(eventBus.type(G.ATTACHMENT, G.DONE, groupName), (event) => {
      const newAttachments = event.detail[G.DATA]
      const changed = newAttachments.filter(newAttachment => !attachments.some(attachment =>
        attachment.key === newAttachment.key))
      setAttachments(changed.length ? newAttachments : [])
    }, { once: true })
    // forcing the attachment API to fire its current list of images from groupName (by sending an
    // empty G.DATA object), so that the listener above gets the attachments
    eventBus.dispatch(eventBus.type(G.ATTACHMENT, G.CACHE, groupName), { [G.DATA]: {} })
  }, [])

  useEffect(() => {
    if (messages?.length) {
      /**
       * We only want to do this once after the messages
       * are available, therefore we use a reference.
       */
      !handlerRef.current
        && handler
        && handler(messages).then((fetchedAttachments) => {
          handlerRef.current = true
          /**
           * On initial load, the GET to ticket/<id>/listing/attachment returns ticket-
           * as well as message attachments. Because we may already have message
           * attachments, we need to replace them with the ones we enriched
           * with the additional data.
           *
           * Simply calling setAttachments(fetchedAttachments)
           * would override the ticket attachments!
           */
          setAttachments((prevState) => {
            /**
             * First our attachments, then the prevState so that
             * {@code findIndex} below finds our attachments
             * first.
             */
            const concatenatedState = fetchedAttachments.concat(prevState)
            return concatenatedState.filter(
              (m, i) => i === concatenatedState.findIndex(n => n.key === m.key),
            )
          })
        })

      // concatenating current group attachments with those from the messages
      const data = attachments?.concat(
        messages
          // accepting only messages that have attachments
          .filter(message => message.value.attachments?.length)
          // mapping each message item to its attachments
          .map(message => message.value.attachments)
          // converting the resulting array of arrays to a single-level array
          .flat()
          // accepting only attachments not already contained by the attachments state
          .filter(messageAttachment => !attachments.some(attachment =>
            messageAttachment.key === attachment.key))
          // setting new = true for attachments that don't have a submitTimestamp
          // e.g. new attachments
          .map(message =>
            (!message.value?.submitTimestamp && { ...message, newAttachment: true }) || message),
      )

      // dispatching the new list attachments (if any)
      data.length && eventBus.dispatch(eventBus.type(G.ATTACHMENT, G.CACHE, groupName), {
        [G.DATA]: {
          [G.DATA]: data,
        },
      })
    }
  }, [state, attachments])
}

/**
 * MessageList component.
 *
 * Listens to messages, processes them and displays them as a list.
 *
 * @param {Object} forwardedRef                     a reference to the root element
 * @param {Object} MessageItem                      the item to be used as message
 * @param {Object} DateItem                         the item to be used as header
 * @param {Object} SystemItem                       the item to be used as system message
 * @param {Object} NoRequestItem                    the item to be used if there is no request
 * @param {Object} value                            value to be set through an action or an event
 * @param {String} value.source                     the id of the current messages' source
 * @param {Object} props                            additional component's properties
 * @param {Object} props.events                     object containing the component's event handlers
 * @param {Number} props.xs                         number of grids used by the list from xs width
 * @param {Number} props.md                         number of grids used by the list from md width
 * @param {String} props.meLabel                    translation of english 'me'
 * @param {String} props.sendingLabel               text to be shown while a message is being sent
 * @param {String} props.noMessagesLabel            translation of english 'No messages.'
 * @param {Object} props.contactChannels            information about requester's contactChannels
 * @param {Object} props.contactChannels.prefix     available prefixes for contact channel links
 * @param {Object} props.contactChannels.decorators available prefixes for contact channel links
 * @param {String} [props.attachmentsGroup]         the name of a group of attachments to which to
 *                                                  optionally add the attachments of the messages
 *                                                  in the list
 * @returns {JSX.Element}
 * @constructor
 */
const MessageList = ({ forwardedRef, MessageItem, DateItem, SystemItem, NoRequestItem, value, ...props }) => {
  const { meLabel, sendingLabel, noMessagesLabel, noRequestLabel, events } = props
  const { onCreate, getSystemMessageTypes, onAttachments, onClick = null } = events
  const { sourceId, userId } = value || {}
  const classes = useStyles(styles)()
  const { state } = useMessageAPI()
  const { messages } = state
  const systemMessageTypes = getSystemMessageTypes(null)

  /**
   * Get information about the corresponding request for the current ticket. If we're displaying
   * messages for a request, {@code request} will be {@code undefined} and we don't do anything.
   */
  const { request, requesterContact, channelLabels } = onCreate?.(null) || {}

  const children = messages.length && _children({
    data: messages,
    sourceId,
    userId,
    systemMessageTypes,
    events: {
      onClick,
    },
    labels: {
      meLabel,
      sendingLabel,
    },
    MessageItem,
    SystemItem,
    DateItem,
  })

  const content = children
      || (!request
          && <NoRequestItem
            label={noRequestLabel}
            content={requesterContact}
            channelLabels={channelLabels}
            prefixes={props.contactChannels.prefix}
            decorators={props.contactChannels.decorators}
          />
      )
      || <NoData classes={classes} xs={props.xs} md={props.md} content={noMessagesLabel}/>

  props.attachmentsGroup
    && attachToGroup(
      state,
      props.attachmentsGroup,
      onAttachments,
    )

  useEffect(() => {
    const element = forwardedRef.current.parentNode
    setTimeout(() => {
      element && (element.scrollTop = element.scrollHeight - element.clientHeight)
    })
  }, [state])

  return (
    <ErrorBoundary>
      <Grid
        ref={forwardedRef}
        component={List}
        className={classes.root}
        container
        item
        xs={props.xs}
        md={props.md}
      >
        <Suspense fallback={<></>}>
          {content}
        </Suspense>
      </Grid>
    </ErrorBoundary>
  )
}

export default useMemoRef(MessageList, props => [props.children])
