import { Grid, Typography } from '@mui/material'
import { withStyles } from '@mui/styles'
import { Consumer as AppConsumer } from 'platform/react/context/guest'

const styles = theme => ({
  ...theme.custom.actionTitle,
})

/**
 * ActionTitle component
 *
 * Displays the title of the current action (action.ui.title).
 *
 * @param {object} classes
 * @param {string} title
 * @returns {JSX.Element}
 * @constructor
 */
const ActionTitle = ({ classes, title }) => (
  <Grid
    item
    alignContent={'center'}
    flexShrink={1}
    xs={12}
  >
    <AppConsumer>
      {() => (
        <Typography
          className={classes.title}
          variant={'20/bold'}
          noWrap={true}
        >
          {title}
        </Typography>
      )}
    </AppConsumer>
  </Grid>
)

export default withStyles(styles)(ActionTitle)
