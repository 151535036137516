/* eslint-disable import/no-extraneous-dependencies, arrow-body-style,object-curly-newline */
import Section from 'ui/Component/Grid/Section'
import useAclIterator from '@platform/react/hook/useAclIterator'

/**
 * High-order section component designed to place and arrange other elements inside it, which will
 * be also automatically wrapped by another component to facilitate their placement. The arrangement
 * of these elements depends on their number and the width they occupy. This component's classes,
 * spacing, space, gap and width (xs, sm, md, lg and xl) properties offer additional possibilities.
 *
 * The padding set to its children by using the spacing, space and gap properties is corrected with
 * a higher width and negative margin on the container, so that the nesting of multiple instances of
 * this component should not be noticeable.
 *
 * @param {Object} [events]       component event handlers
 * @param {SectionProps} [props]  additional component's properties
 * @param {React.Ref} ref            a forwarded reference object to the root element
 * @returns {JSX.Element} the new component
 * @constructor
 */
const AclSection = ({ events = {}, ...props }, ref) => {
  const { acl } = events

  const iterator = (children, options) => useAclIterator(children, { ...options, acl })

  return Section({ ...props, iterator }, ref)
}

export default AclSection
