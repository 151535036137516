import { InputBase } from '@mui/material'

/**
 * Renders a {@link InputBase} input field to be used with {@link Autocomplete}.
 *
 * @param {Object} InputLabelProps  MUI's InputLabelProps
 * @param {Object} InputProps       MUI's InputProps
 * @param {Object} props            props from our {@link Autocomplete}
 * @param {Object} rest             residual props
 * @returns {JSX.Element}
 * @constructor
 */
const BasicInput = ({ InputLabelProps, InputProps, props, ...rest }) => (
  <InputBase
    {...InputProps}
    {...rest}
    label={props.label}
    variant={'outlined'}
  />
)

export default BasicInput
