import { Box, Typography } from '@mui/material'
import CellHOC from '@platform/react/hoc/list/cell'
import { getFirstItem } from 'lib/util'
import { empty } from 'lib/util/object'
import internal from 'model/organisation/collection/internal'
import { useEffect, useState } from 'react'

/**
 * Intended to display additional information about the service item. Is only visible if the user
 * in question passes the acl check.
 *
 * @param {object} classes  element's style classes
 * @param {object} props    element's properties
 *
 * @returns {JSX.Element}
 * @constructor
 */
export const ListItemCellServiceItemInfo = (classes, props = {}) => {
  const { onParent = null } = props?.events || {}
  const [approval, setApproval] = useState(null)

  const information = []
  const {
    acl,
    locationExtension = null,
    placement = null,
    toBeValidated = {},
    toBeValidatedInfo = null,
    toBeApprovedInfo = null,
    installedAt: {
      key: parentId = null,
      refs: {
        parent: baseParent = null,
      } = {},
    } = {},
  } = props

  const displayValidation = !empty(toBeValidated)

  useEffect(() => {
    (async () => {
      const baseParentId = getFirstItem(baseParent) || null
      const isInternalBaseParent = internal.some(x => x.key === baseParentId)
      const baseParentModel = !isInternalBaseParent
        ? await onParent?.(baseParentId)
        : null
      const grandParent = !isInternalBaseParent
        ? getFirstItem(baseParentModel?.refs?.parent)
        : null

      setApproval(internal.some(x => x.key === parentId
          || x.key === (baseParentId?.key || baseParentId)
          || x.key === grandParent?.key))
    })()
  }, [])

  locationExtension && information.push(locationExtension)
  placement && information.push(placement)
  !information.length && information.push('-')

  return (
    <Typography
      color={'black'}
      variant={'body2'}
      className={classes.row}
    >
      { /** @type {React.ReactNode[]} */
        acl !== false && (displayValidation || approval)
          ? <Box
            component={'span'}
            className={classes.row}
            sx={{
              color: theme => theme.palette.signal.main,
              fontWeight: 700,
            }}
          >
            {approval ? toBeApprovedInfo : toBeValidatedInfo}
          </Box>
          : information.map((item, index) => (
            <Box
              key={index}
              component={'span'}
              className={classes.row}
            >
              {item}
            </Box>
          )).reduce((result, item) => (result.length ? [result, ', ', item] : [item]), [])}
    </Typography>
  )
}

export default CellHOC(ListItemCellServiceItemInfo)
