/* eslint-disable object-curly-newline,no-unused-vars */
import { Badge, IconButton, ListItem } from '@mui/material'
import { useMemoRef } from '@platform/react/hook'
import SvgIcon from 'ui/Element/Icon/Svg'

/**
 * Simple, ACL-controlled {@link Icon} meant to be used in
 * the application's {@link Toolbar}.
 *
 * @param {Object} child            various options for the icon
 * @param {Object} [options]        additional options
 * @param {Object} [notification]   optional notification count
 * @returns {JSX.Element}
 * @constructor
 */
const AclSimpleIcon = ({ child, options, notification }) => {
  const {
    key,
    active,
    disabled,
    icon,
    color,
    classes,
    context,
    route: { module, action },
  } = child

  const Icon = <SvgIcon
    color={color}
    icon={icon.name}
    variant={icon.variant}
  />

  return (
    <ListItem
      key={key}
      className={`${classes.toolbarLinksItem}`}
    >
      <IconButton
        size={'small'}
        disabled={disabled || active}
        href={`/#/${context}/${module}/${action}`}
      >
        {notification?.count > 0
          ? (<Badge
            badgeContent={notification.count}
            classes={{ badge: classes.toolbarLinksItemBadge }}
          >
            {Icon}
          </Badge>)
          : Icon
        }
      </IconButton>
    </ListItem>
  )
}

export default useMemoRef(AclSimpleIcon)
