import { Box, Typography } from '@mui/material'
import { useContext } from 'react'
import ApplicationContext from '@platform/react/context/application'
import DecoratedText from 'ui/Element/Text/Decorated'
import CellHOC from '@platform/react/hoc/list/cell'

/**
 * Custom styles for the component.
 *
 * @return {{root: {overflow: string, flexDirection: string, display: string, justifyContent: string}}}
 */
const styles = () => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    justifyContent: 'center',
  },
})

/**
 * Returns the translated device's notRegisteredYet label.
 *
 * @returns {string}  the notRegisteredYet label
 */
const notRegisteredLabel = () => {
  const { intl } = useContext(ApplicationContext)
  return intl._t(
    'label.notRegisteredYet',
    {
      ns: 'device',
      _key: 'label.notRegisteredYet',
      defaultValue: 'Not registered, yet',
    },
  )
}

/**
 * ListItemCellInstalledAtOrNotRegistered element.
 *
 * Displays a summary of the ServiceItem's installedAt organisation or a label stating that it has
 * not yet been installed.
 *
 * @param {object} classes  element's style classes
 * @param {object} props    element's properties
 *
 * @returns {JSX.Element}
 * @constructor
 */
const ListItemCellDeviceInstalledAtOrNotRegistered = (classes, props = {}) => {
  const { intl } = useContext(ApplicationContext)

  const {
    installedAt: {
      value: {
        name,
        address,
      } = {},
    } = {},
  } = props

  const addressSummary = intl.address(address, { inline: true })
  const notRegistered = notRegisteredLabel()

  return name ? (
    <>
      <Typography
        title={name}
        color={'black'}
        variant={'body2'}
        className={classes.row}
        sx={{ fontWeight: 700 }}
      >
        {name}
      </Typography>
      <Typography
        variant={'caption'}
        title={addressSummary}
        color={'textSecondary'}
        className={classes.row}
      >
        {addressSummary}
      </Typography>
    </>
  ) : (
    <>
      <DecoratedText
        variant={'body2'}
        decorators={{
          error: true,
          uppercase: true,
          bold: true,
        }}
      >
        <Box
          component={'span'}
          title={notRegistered}
          className={classes.row}
        >
          {notRegistered}
        </Box>
      </DecoratedText>
    </>
  )
}

export default CellHOC(ListItemCellDeviceInstalledAtOrNotRegistered, styles)
