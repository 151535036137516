/* eslint-disable object-curly-newline */
import { useCallback, useEffect, useRef, useState } from 'react'
import { Grid } from '@mui/material'
import { useMemoRef } from 'platform/react/hook'
import SearchField from '@ui/Element/Field/Search'

/**
 * SearchField component
 *
 * Returns a field to be used to search among different domain objects, of one type at each given
 * time. The SearchField is additionally composed by a search button and a clear button, and the
 * search event can be fired either by clicking on the said search button or by pressing the enter
 * key.
 *
 * @param {string} [value]              a string to set as the value of the field
 * @param {function} onSearch           the handler to be called whenever the search button is
 *                                      clicked or the enter key is pressed
 * @param {boolean} [hidden]            whether the element should not be rendered
 * @param {Object} [props]              additional object properties
 * @param {boolean} [props.focused]     whether the field should be the screen's focused element
 * @param {string} [props.placeholder]  the text to be used as the search field's placeholder
 * @param {Object} [props.transform]    transform type to be used for the search request
 * @returns {JSX.Element}               the new SearchField component
 * @constructor
 */
const ActionSearchField = useMemoRef(({ onSearch, hidden, ...props }) => {
  const [value, setValue] = useState(props.value || '')
  const firstRender = useRef(true)

  const onChange = useCallback((event) => {
    setValue(event.detail.value)
  }, [])

  useEffect(() => {
    props.reset && !firstRender.current && setValue(props.value || '')
  }, [props.reset])

  useEffect(() => {
    firstRender.current = false
  }, [])

  useEffect(() => {
    setValue(props.value || '')
  }, [props.value])

  return hidden ? null : (
    <Grid
      item
      flexShrink={1}
      xs={12}
    >
      <SearchField
        {...props}
        value={value}
        placeholder={props.placeholder || 'search'}
        events={{ onSearch, onChange }}
        focused={props.focused}
        fullWidth
        iconEnd
      />
    </Grid>
  )
}, props => [props.value, props.reset, props.hidden])

export default ActionSearchField
