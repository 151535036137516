/* global React */
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Typography, useTheme } from '@mui/material'
import Simple from 'ui/Element/Button/Simple'
import { forwardRef, useEffect, useState } from 'react'
import { PlatformEvent } from 'lib/util'
import ErrorBoundary from 'ui/Error'
import SvgIcon from 'ui/Element/Icon/Svg'
import { useStyles } from 'platform/react/hook'

const styles = theme => ({
  title: {
    height: '4rem',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: [['0.75rem', '1rem'], '!important'],
  },
  content: {
    borderTop: `1px solid ${theme.palette.gray[900]}`,
    paddingTop: '1.5rem!important',
    paddingLeft: '1rem',
    paddingRight: '1rem',
    minHeight: '10.25rem',
  },
  actions: {
    padding: [['1rem'], '!important'],
  },
})

/**
 * ModalDialog
 *
 * Modal showing arbitrary content set through configuration.
 *
 * @param {boolean} open    whether the modal is open
 * @param {Object} content  content to display
 * @param {Object} labels   labels for the component
 * @param {Object} events   events for the component
 * @returns {JSX.Element}
 * @constructor
 */
const ModalDialog = forwardRef(({ open, children, labels, events, width }, ref) => {
  const classes = useStyles(styles)()
  const theme = useTheme()
  const { onClick, onClose } = events

  return (
    <Dialog
      ref={ref}
      open={open}
      fullWidth={true}
      maxWidth={width}
      onClose={onClose}
    >
      <DialogTitle className={classes.title}>
        <Typography variant={'14/bold'}>
          {labels.title}
        </Typography>
        <Box onClick={onClose} sx={{ cursor: 'pointer' }}>
          <SvgIcon
            icon={'close'}
            variant={'filled'}
            color={theme.palette.common.black}
          />
        </Box>
      </DialogTitle>
      <DialogContent className={classes.content}>
        {children}
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Simple
          fullWidth={false}
          onClick={onClose}
          color={'primary'}
          value={labels.close}
          transform={'uppercase'}
        />
        <Simple
          fullWidth={false}
          variant={'contained'}
          onClick={onClick}
          value={labels.send}
          transform={'uppercase'}
        />
      </DialogActions>
    </Dialog>
  )
})

/**
 * Modal
 *
 * Renders the {@link ModalDialog} if open is truthy.
 *
 * @param {boolean} open    whether the dialog is shown
 * @param {Object} document  information about the document the feedback is about
 * @param {Object} events   events for the dialog
 * @param {Object} children children to get the dialog form from
 * @param {Object} props    additional props
 * @param {React.Ref} ref   forwarded ref
 * @returns {JSX.Element}
 * @constructor
 */
const Modal = ({ open, payload: payloadId, events, children, options, ...props }, ref) => {
  const { onClick = null, onClose = null } = events
  const { titleLabel, sendLabel, closeLabel } = props

  const [payload, setPayload] = useState(null)
  const [modalOpen, setModalOpen] = useState(false)

  useEffect(() => { open !== undefined && setModalOpen(open) }, [open])
  useEffect(() => { payloadId !== null && setPayload(payloadId) }, [payloadId])

  const event = new PlatformEvent('modal', { payload, options })
  const modalEvents = {
    onClick: () => onClick?.(event),
    onClose: () => {
      setModalOpen(false)
      onClose?.(event)
    },
  }

  return (
    <ErrorBoundary>
      <ModalDialog
        ref={ref}
        children={children}
        open={modalOpen}
        events={modalEvents}
        width={props?.width || 'sm'}
        labels={{
          title: titleLabel,
          send: sendLabel,
          close: closeLabel,
        }}
      />
    </ErrorBoundary>
  )
}

export default Modal
