import { Grid } from '@mui/material'
import { withStyles } from '@mui/styles'
import { useMemoRef } from '@platform/react/hook'
import DecoratedText from 'ui/Element/Text/Decorated'

/**
 * Styles for the component.
 *
 * @param {Object} theme
 * @return {Object}
 */
const styles = theme => ({
  root: {
    padding: theme.spacing(1, 2),
    margin: theme.spacing(1, 0),
  },
  text: {
    color: theme.palette.text.dark,
  },
  ...theme.custom.titleSegment,
})

/**
 * Title Segment element.
 *
 * Prints header text within a single row.
 *
 * @param {string} value      the segment's title text
 * @param {object} [classes]  an object containing the element's css classes
 * @returns {JSX.Element}
 * @constructor
 */
const TitleSegment = ({ value, classes }) => (
  <Grid
    item
    className={classes.root}
    role={'rowheader'}
    xs={12}
  >
    <DecoratedText
      className={classes.text}
      decorators={{ bold: true }}
    >
      {value}
    </DecoratedText>
  </Grid>
)
export default useMemoRef(withStyles(styles)(TitleSegment), [])
