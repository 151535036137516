import OptionHOC, { getOptionLabel } from '@platform/react/hoc/autocomplete/option'
import OverflowTooltip from 'ui/Element/Text/OverflowTooltip'
import { Box } from '@mui/material'

/**
 * Displays the name of a person alongside their email as a sub label.
 *
 * @param {Object} classes  styling for the cell
 * @param {Object} props    props for the cell
 *
 * @return {*}
 * @constructor
 */
const PersonOption = (classes, props = {}) => {
  const { option } = props
  const { value: { contactChannels = [] } } = option || {}
  const email = contactChannels.find(channel => channel.type === 'email')?.value

  return (
    <Box
      key={'item'}
      component={'div'}
      className={classes.option}
    >
      <OverflowTooltip>
        {getOptionLabel(option)}
      </OverflowTooltip>
      {!email ? null : (
        <Box className={classes.label}>
          {email}
        </Box>
      )}
    </Box>
  )
}

export default OptionHOC(PersonOption)
