import { Typography } from '@mui/material'
import CellHOC from '@platform/react/hoc/list/cell'

/**
 * Intended to display a serviceItem's serviceBy property.
 *
 * @param {object} classes  element's style classes
 * @param {object} props    element's properties
 *
 * @returns {JSX.Element}
 * @constructor
 */
export const ListItemCellDeviceServicedBy = (classes, props = {}) => {
  const {
    serviceBy: {
      value: {
        name = '-',
        address = '',
      } = {},
    } = {},
  } = props

  const addressSummary = address ? Object.values(address).join(', ') : '-'

  return (
    <>
      <Typography
        title={name}
        component={'span'}
        className={classes.row}
        sx={{ fontWeight: 'bold' }}
      >
        {name}
      </Typography>
      <Typography
        component={'span'}
        variant={'caption'}
        title={addressSummary}
        className={classes.row}
        sx={theme => ({ color: theme.palette.text.disabled })}
      >
        {addressSummary}
      </Typography>
    </>
  )
}

export default CellHOC(ListItemCellDeviceServicedBy)
