/* eslint-disable no-unused-vars */
import { Ballot as Icon } from '@mui/icons-material'
import { useStyles } from '@platform/react/hook'

/**
 * ListItemCellProductImage Styles
 *
 * @param {object} theme - mui theme
 * @return {object} styles - themed styles
 */
const styles = theme => ({
  root: {
    borderRadius: '50%',
    color: theme.palette.primary.main, // theme.palette.getContrastText(theme.palette.primary[100]),
    width: theme.typography.pxToRem(theme.typography.fontSize * 3),
    height: theme.typography.pxToRem(theme.typography.fontSize * 3),
  },
})

/**
 * Intended to display the image of a product inside a row's cell.
 *
 * @param {object} props  element properties
 * @return {*}
 * @constructor
 */
const ListItemCellProductImage = (props) => {
  const classes = useStyles(styles)()
  return (<Icon classes={classes} />)
}

export default ListItemCellProductImage
