import { Box, useTheme } from '@mui/material'
import CellHOC from '@platform/react/hoc/list/cell'
import SvgIcon from 'ui/Element/Icon/Svg'
import { PlatformEvent } from 'lib/util'
import { useCallback } from 'react'

const styles = theme => ({
  actions: {
    marginLeft: 'auto', // pull to right
    minWidth: '9.375rem',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '0.5rem',
  },
})

/**
 * Displays actions.
 *
 * @param {object} classes  element's style classes
 * @param {object} props    element's properties
 *
 * @returns {JSX.Element}
 * @constructor
 */
export const ListItemCellFavoriteActions = (classes, props = {}) => {
  const theme = useTheme()
  const { rowHovered, documentType, description, favoriteIcon, editIcon, chevronIcon, events } = props
  const { onFavoriteClick = null, onDescriptionClick = null } = events || {}

  /**
   * Creates a platform event based on the incoming {@param event}.
   *
   * @param {Event} event     incoming event
   * @param {Boolean} remove  whether to remove the item
   * @returns {PlatformEvent}
   */
  const clickEvent = useCallback((event, remove) => new PlatformEvent(
    event,
    { payload: props.id, options: { type: documentType, description, delete: remove } },
  ), [])

  return (
    <Box className={classes.actions}>
      <Box
        className={classes.buttons}
        style={{ opacity: rowHovered ? 1 : 0 }}
      >
        <Box onClick={
          (e) => {
            e.preventDefault()
            e.stopPropagation()
            onFavoriteClick?.(clickEvent(e, true))
          }
        }>
          <SvgIcon
            rounded={true}
            icon={favoriteIcon.name}
            variant={favoriteIcon.variant}
            color={theme.palette.signal.main}
            background={theme.palette.white.main}
          />
        </Box>
        <Box onClick={
          (e) => {
            e.preventDefault()
            e.stopPropagation()
            onDescriptionClick?.(clickEvent(e, false))
          }
        }>
          <SvgIcon
            rounded={true}
            icon={editIcon.name}
            variant={editIcon.variant}
            color={theme.palette.black.main}
            background={theme.palette.white.main}
          />
        </Box>
      </Box>
      <SvgIcon
        icon={chevronIcon.name}
        variant={chevronIcon.variant}
        color={theme.palette.gray[500]}
      />
    </Box>
  )
}

export default CellHOC(ListItemCellFavoriteActions, styles)
