import { Typography } from '@mui/material'
import CellHOC from '@platform/react/hoc/list/cell'

/**
 * Returns the ListItemCellUserStatus element's current theme styles.
 *
 * @returns {object}      the ListItemCellNameOrCount element's styles
 */
export const styles = (theme, { right }) => right && {
  root: {
    textAlign: 'right',
  },
}
/**
 * Displays the full name of the user's person.
 *
 * @param {Object} classes  styling for the cell
 * @param {Object} props    props for the cell
 *
 * @return {*}
 * @constructor
 */
export const ListItemCellUserStatus = (classes, props = {}) => {
  const { status: statusNumber, events } = props
  const [status] = events?.getStatus?.(statusNumber || 0) || []

  return (
    <Typography
      color={'black'}
      variant={'body2'}
      className={classes.row}
      sx={{ fontWeight: 700 }}
    >
      {status?.value}
    </Typography>
  )
}

export default CellHOC(ListItemCellUserStatus, styles)
