import AvatarImage from 'ui/Element/Image/Avatar'
import { Badge, Box, useTheme } from '@mui/material'
import CellHOC from '@platform/react/hoc/list/cell'
import settings from '@tenant/settings'
import SvgIcon from 'ui/Element/Icon/Svg'

const styles = theme => ({
  root: {
    width: '3rem',
    height: '3rem',
  },
  image: {
    maxWidth: '40px',
    width: '100%',
    height: '100%',
  },
})

/**
 * Helper function to get the icon for the current option from the tenant settings.
 *
 * @param {string} nodeType type of documentation
 * @param {string} name     name of the documentation
 * @returns {*|null}
 * @private
 */
const _getOptionIcon = ({ nodeType, name }) => {
  const availableIcons = settings?.documentationTreeNodeIcons
  if (!nodeType || !name || !availableIcons?.length) return null

  const targetIcon = availableIcons.find(icon => icon.name === name && icon.nodeType === nodeType)

  return targetIcon?.icon || null
}

/**
 * Displays the icon of the equipment ot part
 *
 * @param {Object} classes  styles for the component
 * @param {Object} props    props for the component
 * @return {JSX.Element}
 * @constructor
 */
const ListItemCellFavoriteIcon = (classes, props = {}) => {
  const theme = useTheme()

  const {
    image,
    documentType,
    partIcon,
    equipmentIcon,
    docChunkIcon,
    documentationType,
    type,
  } = props

  const targetType = documentType
    ? documentType === 'documentation' && { name: documentationType || type, nodeType: documentType }
    : null
  const targetIcon = targetType ? _getOptionIcon(targetType) : null

  const currentAttachment = image?.attachment?.value?.name
    ? { ...image.attachment, key: image?.value?.attachmentId || image?.id || undefined, api: { ref: 'equipment' } }
    : { value: { name: '' } }

  const fallbackIcon = (documentType === 'article' && partIcon)
    || (documentType === 'equipment' && equipmentIcon)
    || (documentType === 'documentation' && docChunkIcon)

  return (
    <Box className={classes.root}>
      <AvatarImage
        attachment={currentAttachment}
        fallback={{
          ...targetIcon
            ? {
              icon: targetIcon.name,
              variant: targetIcon.variant,
            }
            : {
              icon: fallbackIcon,
              variant: 'outlined',
            },
          background: theme.palette.gray[960],
          color: theme.palette.text.secondary,
        }}
      />
    </Box>
  )
}

export default CellHOC(ListItemCellFavoriteIcon, styles)
