/* eslint-disable no-unused-vars */
/* global G */
import { useEventCallback, useEventHandler, useMemoRef, useStyles } from 'platform/react/hook'
import ReactPlayer from 'react-player'
import { useContext, useEffect, useState } from 'react'
import ApplicationContext from 'platform/react/context/application'
import { Grid } from '@mui/material'
import Toolbar from 'platform/react/hoc/attachment/toolbar'

const styles = theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    backgroundColor: theme.palette.common.black,
  },
})

/**
 * Simple Video
 *
 * Uses {@link ReactPlayer} to display {@param attachment}s video. Can work with both external
 * attachments (having {@param attachment.value.url} set) or internal attachments. In the latter
 * case, it will fetch its URL using {@code G.ATTACHMENT, G.HTTP, attachment.key} event, so that
 * react player can buffer the video, and we don't download the full binary.
 *
 * @param {Object} attachment   the video to play
 * @param {boolean} controls    whether or not to show controls in the player
 * @param {Object} props        additional props
 * @returns {JSX.Element}
 * @constructor
 */
const SimpleVideo = ({ attachment, controls = true, ...props }) => {
  const classes = useStyles(styles)()
  const { eventBus } = useContext(ApplicationContext)
  const isInternal = !!attachment?.key
  const currentAttachment = attachment?.value?.name
    ? attachment
    : { value: { name: '' } }

  const [url, setUrl] = useState(false)

  // In case we are displaying an internal video, dispatch an event to get its URL and let
  // ReactPlayer buffer it instead of downloading the full binary.
  useEffect(() => {
    isInternal
    && eventBus.dispatch(eventBus.type(G.ATTACHMENT, G.HTTP), { [G.DATA]: currentAttachment })
  }, [currentAttachment])

  const urlEventName = eventBus.type(G.ATTACHMENT, G.HTTP, attachment.key)
  const urlEventHandler = useEventCallback(({ detail }) => {
    detail.url && setUrl(detail.url)
  })
  useEventHandler(eventBus, urlEventName, urlEventHandler)

  useEffect(() => {
    attachment?.value?.url && setUrl(attachment.value.url)
  }, [attachment])

  return (
    <Grid
      item
      xs={12}
      container
      className={classes.root}
    >
      {!url ? null : (
        <ReactPlayer
          url={url}
          width={'100%'}
          height={'100%'}
          controls={controls}
        />
      )}
    </Grid>
  )
}

export default useMemoRef(Toolbar(SimpleVideo), props => [props.attachment])
