import { Typography } from '@mui/material'
import CellHOC from '@platform/react/hoc/list/cell'

/**
 * Display the serial number for a given device.
 *
 * @param {Object} classes  styling for the cell
 * @param {Object} props    props for the cell
 *
 * @returns {JSX.Element}
 */
export const ListItemCellServiceItemSerial = (classes, props = {}) => {
  const { serial = '-' } = props

  return (
    <Typography
      color={'black'}
      variant={'body2'}
      className={classes.row}
      sx={{ fontWeight: 700 }}
    >
      {serial}
    </Typography>
  )
}

export default CellHOC(ListItemCellServiceItemSerial)
