import CellHOC from '@platform/react/hoc/list/cell'
import LabeledHOC from '@platform/react/hoc/list/labeled'
import { ListItemCellRequestInstalledAt } from 'ui/Element/List/Item/Cell/Request/InstalledAt'

export default CellHOC(
  LabeledHOC(
    props => props.labels.installedAt,
    ListItemCellRequestInstalledAt,
  ),
)
