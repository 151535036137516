import { Box, useTheme } from '@mui/material'
import CellHOC from '@platform/react/hoc/list/cell'
import SvgIcon from 'ui/Element/Icon/Svg'
import { getFirstItem } from 'lib/util'

/**
 * Cell styles
 */
const styles = () => ({
  actions: {
    gap: '0.75rem',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'right',
    alignItems: 'center',
  },
})

/**
 * Displays actions for the given message notification
 *
 * @param {Object} classes  styles for the component
 * @param {Object} props    props for the component
 * @return {JSX.Element}
 * @constructor
 */
const ListItemCellNotificationActions = (classes, props = {}) => {
  const theme = useTheme()
  const { value: { read, unreadMessages } } = props

  const isRead = getFirstItem(read)?.timestamp?.length || unreadMessages === 0 || false

  return (
    <Box
      className={classes.actions}
      sx={{ opacity: !isRead ? 1 : 0 }}
    >
      <SvgIcon
        width={'0.5rem'}
        height={'0.5rem'}
        color={theme.palette.info.main}
        icon={'dot'}
        variant={'filled'}
      />
    </Box>
  )
}

export default CellHOC(ListItemCellNotificationActions, styles)
