/* eslint-disable no-param-reassign */
/* global React */
import { useCallback, useEffect, useRef, useState } from 'react'
import { Box, Grid, Icon, InputAdornment, InputBase, useTheme } from '@mui/material'
import { TreeItem, TreeView } from '@mui/x-tree-view'
import { isObj, PlatformEvent } from 'lib/util'
import { getMimeType } from 'lib/util/attachment'
import { useMemoRef, useStyles } from '@platform/react/hook'
import { cellStyles } from '@platform/react/hoc/list/cell'
import { SvgFileIcon } from 'ui/Element/Icon/Svg'
import ScrollGrid from 'ui/Component/Grid/ScrollGrid'
import ErrorBoundary from 'ui/Error'

const commonLabelStyes = {
  fontSize: '0.75rem!important', // 12px
  lineHeight: '1rem!important',
  ...cellStyles.row,
}

const labelStyles = () => ({
  folder: {
    ...commonLabelStyes,
    fontWeight: '700!important',
  },
  file: {
    ...commonLabelStyes,
    fontWeight: '500!important',
  },
})

const styles = (theme, { rtl }) => ({
  root: {
    height: '100%',
    [theme.breakpoints.down('md')]: {
      height: '30%',
    },
    // width: '100%',
    // '& .MuiTreeItem-root': {
    //   overflow: 'inherit',
    // },
  },
  treeContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
  },
  content: {
    display: 'flex',
    justifyContent: 'stretch',
    paddingRight: 12,
  },
  node: {
    '& .MuiTreeItem-label': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    '& .MuiTreeItem-content': {
      flexDirection: rtl ? 'row-reverse' : 'row',
      marginBottom: '0.12rem',
      height: '2.25rem', // 36px
      color: theme.palette.black.main,
      paddingRight: theme.spacing(0.5),
      '&:hover': {
        borderRadius: '6.25rem', // 100px
      },
      '&.Mui-focused': {
        backgroundColor: 'transparent',
      },
      '&.Mui-selected': {
        borderRadius: '6.25rem', // 100px
        backgroundColor: `${theme.palette.white.main}!important`,
      },
      ...cellStyles.root,
    },
    '& .MuiTreeItem-group': {
      marginLeft: 0,
    },
  },
  file: {
    // marginLeft: '-15px', // Align with parent text
  },
  directory: {},
  fileWithIcon: {},
  directoryWithIcon: {},
  searchIcon: {
    marginLeft: '10px',
    marginRight: '10px',
    width: '24px',
    height: '24px',
    color: theme.palette.black.main,
  },
  searchField: {
    flex: 1,
    ...theme.typography['14/medium'],
    marginBottom: '1.5rem',
    borderRadius: '6.25rem',
    '& .MuiInputBase-input': {
      padding: [[0, '1rem']],
    },
    backgroundColor: theme.palette.white.main,
  },
  ...theme.custom.fileTree,
})

/**
 * Determines whether the current node's {@param type} is a folder.
 *
 * @param {String} type the type of the current node.
 * @return {boolean}
 */
const isDirectory = type => type === 'Directory' || type === 'Root'

/**
 * Helper function to get the icon for the corresponding file type
 * @param {string} fileType
 * @param {Object} types
 * @returns {string}
 */
const getIcon = (fileType, types) => Object.keys(types).find(id => types[id].includes(fileType))

/**
 * Render all nodes in the tree recursively.
 *
 * @param {Object} node         the current node to render
 * @param {Number} index        the index of the current node
 * @param {Object} classes      styles to apply to the node
 * @param {Object} labelClasses classes to be applied to the labels
 * @param {Object} options      additional options
 * @param {Number} depth        hierarchical level of the items in the tree
 * @returns {JSX.Element}
 * @private
 */
const _children = (node, index, { classes, labelClasses, ...options }, depth) => {
  const { directoriesOnly, events } = options
  const { onNodeType } = events

  // The root node doesn't have a name. Let's use the provided label.
  !node.name && (node.name = options.label)

  // If we have more than one root folders, the root (for this component) may not have a type
  !node?.type && (node.type = node?.$children ? 'Directory' : 'File')

  // Set the index of the node
  !node?.index && (node.index = index)

  // The ids throughout the tree have to be unique, so let's name it 'nodeName-0', 'nodeName-1', ...
  const key = `${
    isObj(node.name)
      ? node.name.props.children
      : node.name
  }-${node?.parent?.index || 0}-${index.toString()}`

  // We need to reference the concatenated id from different places. So let's replace it.
  !node.id && (node.id = key)

  const nodeType = onNodeType(node.type)

  // Whether the node has child folders
  const isCollapsible = node?.$children?.length ? node?.$children?.some(child => child.type === 'Directory') : false
  const isFile = nodeType === 'File' || nodeType === 'Url'

  const nodeAttachment = node.type === 'File'
    ? node?.attachment?.value || null
    : null

  // The file type of the current node's attachment.
  const fileType = node?.realName?.split?.('.')?.[1]
      || node?.url?.split?.('.')?.[1]
      || (nodeAttachment && getMimeType(nodeAttachment))
      || null

  const folderIcon = <SvgFileIcon icon={'folder'} />
  const fileIcon = fileType
    ? <SvgFileIcon icon={getIcon(fileType, options.types)} />
    : null

  // Filter the children in case we only want to display directories
  const filteredChildren = children => (directoriesOnly
    ? children.filter(x => isDirectory(x.type))
    : children)

  return !isDirectory(nodeType) && directoriesOnly ? null : (
    <TreeItem
      key={node.id}
      nodeId={node.id}
      {...options.fileIcons && node.id !== 'root' && {
        icon: (isFile && fileIcon)
            || (!isFile && options.directoryIcons && folderIcon),
      }}
      label={node.name || options.label || ''}
      title={node.name || options.label}
      classes={{ label: isCollapsible ? labelClasses.folder : labelClasses.file }}
      className={`${classes.node} ${
        /**
         * This gives us 4 possible classNames to style:
         * 1 - directory
         * 2 - file
         * 3 - directoryWithIcon (if the directory has an icon)
         * 4 - fileWithIcon (if the file has an icon)
         */
        classes[
          `${(nodeType || 'directory').toLowerCase()}${
            options.fileIcons ? 'WithIcon' : ''
          }`
        ]
      }`}
      ContentProps={{
        sx: {
          paddingLeft: depth,
        },
      }}
    >
      {Array.isArray(node.$children)
        ? filteredChildren(node.$children).map((child, i) => _children(child, i, { classes, labelClasses, ...options }, depth + 1))
        : null}
    </TreeItem>
  )
}

/**
 * Tree view component meant to render a tree structure.
 *
 * Can be used on its own if {@param events.onClick} and {@param events.onOpen}
 * event handlers are present. If the latter is not, it expects {@param node}
 * from a parent component (e.g. {@link FileExplorer}).
 *
 * @param {MutableRefObject} forwardedRef               parent ref
 * @param {Gaia.AppModule.EventHandler[]} parentEvents  events from parent component
 * @param {Gaia.AppModule.EventHandler[]} events        events for the component
 * @param {Function} parentEvents.onClick               handler for clicking on an item
 * @param {Function} [parentEvents.onOpen]              optional onOpen handler
 * @param {Function} events.onTreeSearch                handler for searching in the tree
 * @param {Object} nodes                                tree of nodes to render
 * @param {String[]} expandedNodes                      list of expanded node ids
 * @param {Object} selected                             currently selected node
 * @param {String} className                            styles to apply
 * @param {String} label                                label to use if a node doesn't have a name
 * @param {Object} props                                additional props
 * @param {boolean} props.fileIcons                     whether to use icons for file nodes
 * @param {boolean} props.directoryIcons                whether to use icons for directory nodes
 * @param {boolean} props.rtl                           whether to display icons on the right
 * @param {boolean} props.directoriesOnly               whether to only display directory nodes
 * @param {string} props.collapseIcon                   icon to use for expanding
 * @param {string} props.expandIcon                     icon to use for collapsing
 * @param {string} props.searchIcon                     search icon to use
 * @param {string} props.searchLabel                    search label to use
 * @param {boolean} props.disableSearch                 whether to disable the search
 * @param {boolean} props.skipSingleNode                whether to skip a node with a single child when clicked
 *                                                      on and directly open that child
 * @returns {JSX.Element}
 * @constructor
 */
const FileTree = ({ forwardedRef, parentEvents, events, nodes, selected, className, label, ...props }) => {
  const { expanded: expandedParentNodes, setExpanded: setExpandedParentNodes, fromSearch, setFromSearch } = props

  const [tree, setTree] = useState({})
  const [expandedTreeNodes, setExpandedTreeNodes] = useState([])

  const expanded = expandedParentNodes || expandedTreeNodes
  const setExpanded = setExpandedParentNodes || setExpandedTreeNodes

  const {
    spacing = 0,
    space = 0,
    gap = 0,
    directoriesOnly = false,
    disableSearch = false,
    collapseIcon: collapse = 'expand_more',
    expandIcon: expand = 'chevron_right',
    searchIcon: search = 'search',
    searchLabel = 'Search',
    skipSingleNode = false,
  } = props

  const classes = useStyles(styles, { rtl: props.rtl })()
  const labelClasses = useStyles(labelStyles)()
  const theme = useTheme()

  const treeEvents = {
    ...events,
    ...parentEvents,
  }

  const collapseIcon = <Icon>{collapse}</Icon>
  const expandIcon = <Icon>{expand}</Icon>

  const { onTreeSearch } = treeEvents
  const options = {
    classes,
    labelClasses,
    theme,
    label,
    collapseIcon,
    expandIcon,
    directoriesOnly,
    events: treeEvents,
    types: props.types,
    fileIcons: props.fileIcons,
    directoryIcons: props.directoryIcons,
  }

  /**
   * Traverses the tree up, beginning from {@param node}, and recursively
   * returns all nodes that are not expanded.
   * @param node
   * @param collapsedParents
   * @returns {*|*[]}
   */
  const getCollapsedParents = (node, collapsedParents = []) => {
    const isExpanded = node?.id
      ? expanded.some(expandedNode => expandedNode === node?.id)
      : false

    if (!isExpanded) {
      node?.id && collapsedParents.push(node.id)
    }

    const isParentExpanded = expanded.some(expandedNode => expandedNode === node?.parent?.id)
    if (!isParentExpanded && node?.parent) {
      return getCollapsedParents(node.parent, collapsedParents)
    }

    return collapsedParents
  }

  // Handle expanding a node
  const handleOpen = (node) => {
    node && node?.id && setExpanded(
      (prev) => {
        const isExpanded = expanded.some(expandedNode => expandedNode === node.id)
        // In case the node to be expanded is nested deeply, we need to check whether all its
        // parents are expanded as well, and if not, do so.
        const collapsedParents = getCollapsedParents(node)
        const uniqueNodes = [...new Set([...prev, ...collapsedParents, node.id])]
        return isExpanded ? prev : uniqueNodes
      },
    )
  }

  /**
   * Traverses the tree down, starting at {@param node} and returns all children that are
   * expanded.
   * @param {Object} node               the node to start with
   * @param {Object[]} expandedChildren list of expanded nodes
   * @returns {*[]}
   */
  const getExpandedChildren = (node, expandedChildren = []) => {
    const isExpanded = expanded.some(expandedNode => expandedNode === node.id)
    // Only if the node has a parent (e.g. not the root node) should we add it to the list
    // of expanded children. We never want to collapse the root node
    isExpanded && node?.parent && expandedChildren.push(node.id)

    node?.$children && node.$children.forEach(child => getExpandedChildren(child, expandedChildren))

    return expandedChildren
  }

  // Handle collapsing a node
  const handleClose = (node) => {
    setExpanded((prev) => {
      const expandedChildren = getExpandedChildren(node)
      return prev.filter(prevNode => !expandedChildren.includes(prevNode))
    })
  }

  // Handle clicking on a node in the tree view.
  const handleToggle = (node) => {
    const nodeType = node?.type

    // Adding file nodes to the expanded array will bold their label. We don't want that.
    if (nodeType === 'Directory') {
      expanded.some(expandedNode => expandedNode === node?.id)
        ? handleClose(node)
        : handleOpen(node)
    }

    if (nodeType !== 'Directory') {
      handleOpen(node.parent)
    }
  }

  // Handle selecting a node
  const handleNodeSelect = useCallback(async (e, id) => {
    const clickEvent = new PlatformEvent('click', { id, skipSingleNode })
    // Normally, only the {@code selected} node is expanded and only any other node
    // can be collapsed by clicking on the icon. However, if we want to collapse the
    // currently selected node, we need to force it, because it's normally forbidden.
    //
    // The {@code handleToggle} handler only ever gets called if {@code selected}
    // changes. But if we want to collapse the already selected node (by clicking on
    // it), {@code selected} never changes and thus the handler never gets called.
    // This is why we need this handler to cover this special behaviour.
    if (id === selected?.id) {
      setExpanded(prevState => (prevState.includes(id)
        ? prevState.filter(x => x !== id)
        : [...prevState, id]))
    }
    await treeEvents.onClick(clickEvent)
  }, [selected])

  // Set the {@code tree} to the incoming {@param nodes} if they change.
  useEffect(() => { nodes?.$children && setTree(nodes) }, [nodes])

  // Set {@code tree} if onOpen event handler is present.
  useEffect(() => {
    (async () => {
      const newTree = await treeEvents?.onOpen?.(null)
      newTree && setTree(newTree)
    })()
  }, [])

  // {@code selected} will change if we click on a node.
  useEffect(() => {
    if (selected && !fromSearch) {
      if (selected?.type === 'File' && selected?.parent && directoriesOnly) {
        handleToggle(selected.parent)
        return
      }

      handleToggle(selected)
    }
    fromSearch && setFromSearch(false)
  }, [selected?.id])

  const searchIcon = <Icon>{search}</Icon>

  const handleFilter = (e) => {
    const value = e.target.value.trim()

    if (!value) {
      setExpanded(() => [])

      return
    }

    const shouldExpand = [tree.id]
    onTreeSearch({ node: tree, value, shouldExpand, directoriesOnly })
    setExpanded(() => shouldExpand)
  }

  return (
    <ErrorBoundary>
      <Grid
        item
        container
        xs={props.xs}
        sm={props.sm}
        md={props.md}
        lg={props.lg}
        xl={props.xl}
        style={{
          padding: spacing
            ? theme.spacing(spacing)
            : theme.spacing(space, gap),
        }}
        className={`${classes.root} ${classes.treeContainer} ${className}`}
      >
        {disableSearch ? null : (
          <InputBase
            fullWidth={true}
            className={classes.searchField}
            placeholder={searchLabel}
            inputProps={{ 'aria-label': props.searchLabel, style: { height: '2.25rem' } }}
            onKeyUp={handleFilter}
            onKeyDown={(e) => {
              e.key === 'Enter' && e.preventDefault()
            }}
            endAdornment={
              <InputAdornment position={'end'}>
                <Box className={classes.searchIcon}>
                  {searchIcon}
                </Box>
              </InputAdornment>
            }
          />
        )}
        <ScrollGrid
          item
          className={classes.root}
          // Applying 'display: flex' to the ScrollGrid's contentClass
          // so that ellipsis of labels work
          // FIXME: Doesn't work
          classes={{ content: classes.content }}
        >
          <TreeView
            expanded={expanded}
            selected={selected?.id || ''}
            onNodeSelect={handleNodeSelect}
            defaultCollapseIcon={collapseIcon}
            defaultExpandIcon={expandIcon}
            style={{ flexGrow: 1 }}
          >
            {_children(tree, 'root', options, 1)}
          </TreeView>
        </ScrollGrid>
      </Grid>
    </ErrorBoundary>

  )
}

export default useMemoRef(FileTree, props => [props.nodes, props.selected, props.className])
