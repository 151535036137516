import { Badge, Box, Icon, lighten, Stack, Typography } from '@mui/material'
import CellHOC from '@platform/react/hoc/list/cell'
import { getThemeColor } from 'lib/util'

/**
 * Custom styles for the component
 *
 * @return {{
 * root: {alignItems: string, display: string, justifyContent: string, height: string}
 * }}
 */
const styles = () => ({
  root: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
})

/**
 * Intended to display an icon for the request
 *
 * @param {Object} classes  styling for the cell
 * @param {Object} props    props for the cell
 *
 * @return {JSX.Element}
 * @constructor
 */
export const ListItemCellRequestIcon = (classes, props = {}) => {
  const { type: typeKey, status, unreadMessages, events } = props
  const [type] = events?.getTicketType?.(typeKey) || []
  const typeIcon = type?.icon

  // Mapping status to class
  let statusColor = 'primary'
  if (status >= 60 && status < 70) statusColor = 'warning'
  if (status >= 80) statusColor = 'text'
  const statusColorType = status >= 80 ? 'secondary' : 'main'

  return (
    <Stack
      spacing={2}
      direction={'row'}
      sx={{ alignSelf: 'center' }}
    >
      <Badge
        color={'signal'} // background-color
        overlap={'circular'}
        sx={{ color: 'white' }}
        badgeContent={unreadMessages}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Typography
          key={typeKey}
          component={'div'}
        >
          <Box
            component={'span'}
            sx={theme => ({
              width: 40,
              height: 40,
              display: 'flex',
              borderRadius: '50%',
              alignItems: 'center',
              justifyContent: 'center',
              color: `${statusColor}.${statusColorType}`,
              bgcolor: lighten(theme.palette[statusColor][statusColorType], 0.9),
            })}
          >
            <Icon
              sx={{
                ...type?.color && { color: theme => getThemeColor(theme, type.color) },
              }}
            >
              {typeIcon}
            </Icon>
          </Box>
        </Typography>
      </Badge>
    </Stack>
  )
}

export default CellHOC(ListItemCellRequestIcon, styles)
