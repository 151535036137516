/* global React */
import { Grid, Skeleton, Fade } from '@mui/material'
import { useMemoRef } from '@platform/react/hook'

export const style = {
  maxWidth: '95%',
  maxHeight: '95%',
  backgroundColor: 'rgba(87, 87, 87, 0.11)',
}

/**
 PlaceholderListItemCellIcon

 To be displayed while a list's cell element, which would contain an icon, is being loaded.
 *
 * @param {number} size                 placeholder's grid width
 * @param {string|number} [width=40]    icon's height and width
 * @param {string|number} [iconSize=40] icon's height and width
 * @returns {null|JSX.Element}
 * @constructor
 */
const PlaceholderListItemCellIcon = useMemoRef(({ size, width = 40, iconSize = width, fullWidth }) => (
  <Fade
    in={true}
    timeout={{
      enter: 1500,
      exit: 1500,
    }}
  >
    <Grid
      item
      xs={size}
    >
      <Skeleton
        variant={'circular'}
        width={iconSize}
        height={iconSize}
        style={{
          ...style,
          ...fullWidth && {
            width: '100%',
            maxWidth: '100%',
          },
        }}
      />
    </Grid>
  </Fade>
), [])

export default PlaceholderListItemCellIcon
