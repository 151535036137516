/* eslint-disable no-unused-vars */
import { Badge, Box, useTheme } from '@mui/material'
import {
  Business,
  Factory,
  Handshake,
  Help,
  HomeRepairService,
  MapsHomeWork,
  // Uncommenting this prevents tree-shaking from working and adds all icons to the bundle
  // SvgIconComponent,
} from '@mui/icons-material'
import CellHOC from '@platform/react/hoc/list/cell'
import SvgIcon, { SvgRoundedIcon } from 'ui/Element/Icon/Svg'

/**
 * Styling for the icon.
 *
 * @param {Object} theme
 * @return {{
 * root: {alignItems: string, color, display: string, width: string},
 * icon: {alignItems: string, display: string}
 * }}
 */
const styles = theme => ({
  root: {}, // we need this overwrite 'overflow: hidden' from CellHOC
  badge: {
    padding: 0,
    width: '1.25rem', // 20px
    height: '1.25rem', // 20px
    backgroundColor: theme.palette.primary.main,
  },
})

/**
 * Intended to display an icon for the cart.
 *
 * @param {Object} classes  styling for the cell
 * @param {Object} props    props for the cell
 *
 * @returns {JSX.Element}
 */
export const ListItemCellCartIcon = (classes, props = {}) => {
  const theme = useTheme()
  const { status, cartIcon, purchaseRequestIcon } = props

  const icon = (
    <SvgRoundedIcon
      {...purchaseRequestIcon}
      color={theme.palette.text.secondary}
      background={theme.palette.background.content}
    />
  )
  const badge = (
    <Badge
      classes={{ badge: classes.badge }}
      badgeContent={
        <SvgIcon
          {...cartIcon}
          width={'0.625rem'} // 10px
          height={'0.625rem'} // 10px
          color={theme.palette.common.white}
        />
      }
    >
      {icon}
    </Badge>
  )

  return (
    <Box sx={{ position: 'relative' }}>
      {status === 50 ? badge : icon}
    </Box>
  )
}

export default CellHOC(ListItemCellCartIcon, styles)
