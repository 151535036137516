/* global React */
// eslint-disable-next-line import/no-extraneous-dependencies
import { Paper, Icon, Typography } from '@mui/material'
import { useStyles, useMemoRef } from '@platform/react/hook'
import { styles } from 'ui/Element/Icon/FabLike'

/**
 * FAB-styled Icon
 *
 * @param forwardedRef
 * @param icon
 * @param variant
 * @param background
 * @param color
 * @param className
 * @returns {JSX.Element}
 * @constructor
 */
const FabIcon = ({
  forwardedRef,
  icon,
  variant,
  background,
  color,
  className,
}) => {
  const classes = useStyles(styles)()
  return (
    <Paper
      className={`${classes.root} ${className}`}
      sx={{ bgcolor: background || 'primary.main' }}
      elevation={0}
      component={'span'}
      disabled={true}
      ref={forwardedRef}
    >
      <Typography
        variant={variant || 'h4'}
        className={classes.iconContainer}
      >
        <Icon
          sx={{ color: color || 'text.inverted' }}
          fontSize={'inherit'}
        >
          {icon}
        </Icon>
      </Typography>
    </Paper>
  )
}

export default useMemoRef(FabIcon, [])
