/* eslint-disable no-nested-ternary */
import { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { useAttachmentAPI, useMemoRef, useStyles } from '@platform/react/hook'
import { Avatar as MUIAvatar, Box, Grid, IconButton, lighten, Tooltip, Typography, useTheme } from '@mui/material'
import { RestartAlt, ZoomIn, ZoomOut } from '@mui/icons-material'
import { PlatformEvent } from 'lib/util'

const styles = (theme, { scaleFactor, translateDistance }) => ({
  title: {
    fontSize: '16px',
    fontWeight: 700,
    color: theme.palette.common.black,
  },
  area: {
    backgroundColor: 'red',
  },
  buttonContainer: {
    display: 'flex',
    padding: theme.spacing(1),
    justifyContent: 'flex-end',
    [theme.breakpoints.up('md')]: {
      position: 'absolute',
      top: theme.spacing(1),
      right: theme.spacing(1),
    },
    '& :not(:last-child)': {
      marginRight: '5px',
    },
  },
  button: {
    backgroundColor: `${theme.palette.common.white}!important`,
  },
  imgContainer: {
    // box
    height: '100%',
    width: 'fit-content',
    display: 'flex',
    justifyContent: 'space-around',
    transform: `scale(${scaleFactor}) translate(${translateDistance.x}px, ${translateDistance.y}px)`,
    position: 'relative',
    // image
    '& img': {
      // cropping image border
      clipPath: 'inset(2px 2px 2px 2px)',
      maxWidth: '100%',
      maxHeight: '100%',
    },
  },
  hotspot: {
    fontSize: '12px',
    fontWeight: 500,
    cursor: 'pointer',
    color: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
    backgroundColor: lighten(theme.palette.primary.main, 0.9),
    '&:hover': {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
      boxShadow: `0px 0px 0px 10px ${lighten(theme.palette.primary.main, 0.9)}`,
    },
  },
  selectedHotspot: {
    fontSize: '12px',
    fontWeight: 500,
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      boxShadow: `0px 0px 0px 10px ${lighten(theme.palette.primary.main, 0.9)}`,
    },
  },
  tooltip: {
    backgroundColor: theme.palette.common.white,
    borderRadius: '8px',
    boxShadow: '0px 0px 8px 0px rgba(0, 0, 0, 0.12)',
    color: theme.palette.common.black,
    '& p:last-child': {
      color: theme.palette.text.secondary,
    },
  },
})

/**
 * Helper function to get the description of the {@param node} currently being hovered
 *
 * @param {Object} node         the node to get the description of
 * @param {string} nodePos      the position of the node
 * @returns {unknown[]|string}
 * @private
 */
const _getHoveredNodeDescription = (node, nodePos, labels) => {
  const children = node?.$children?.length ? node.$children : node.parent?.$children
  const hoveredNode = children.find(x => x.pos === nodePos)

  const description = hoveredNode
    ? [hoveredNode?.name, ...hoveredNode?.description || [], hoveredNode?.orderNumber]
    : [labels.errorLabel, labels.noPartLabel]

  return description.map((x, i) => (
    <Typography
      key={i}
      variant={i === 0 ? 'subtitle2' : 'body2'}
    >
      {x}
    </Typography>
  )) || '-'
}

/**
 * Helper function to get the is of all nodes that are being referenced by the {@param pos} of
 * {@param selectedNode}
 *
 * @param {Object} selectedNode the node currently being selected
 * @param {string} pos          the position of the selected node
 * @returns {any[]}
 * @private
 */
const _getNodeIdsFromPos = (selectedNode, pos) => {
  const children = selectedNode?.$children?.length
    ? selectedNode?.$children
    : selectedNode.parent?.$children

  return children
    .filter(x => x.pos === pos)
    .map(x => x.id)
}

/**
 * Creates clickable hotspots for every node on the image.
 *
 * Is also responsible for calculating the correct coordinates for each hotspot given the image
 * width/height in relation to the container.
 *
 * The server gives us 4 coords for each hotspot (x1, y1, x2, y2). Ideally, we'd want to transform
 * them into percentages to use absolute positioning with left, top, right, bottom attributes.
 * However, unfortunately, the coords DO NOT represent a square (same with/height), but rather a
 * rectangle that precisely fit the number/label on the image.
 *
 * If it's a one-digit number (e.g. "2"), the height will be more than the width.
 * If it's a two-digit number (e.g. "10"), the height will rougly be equal to the width.
 * If it's a three-digit (or more) number (e.g. "123"), the height will be less than the width.
 *
 * Therefore, we can't use left, top, right, bottom (because we want a square in any case).
 * Instead, we need to calculate the center coordinate for each hotspot with the given coordinates
 * and substract half of the hotspots desired width and height. Then we can use top, left with
 * absolute positioning.
 *
 * @param {Object} selectedNode       the node currently selected
 * @param {Object[]} areas            the areas of the current node
 * @param {Object} events             events for the node
 * @param {number} scaleFactor        the current scaling factor
 * @param {boolean} isMoving          whether the node is currently being moved
 * @param {Object} translateDistance  the current translation distance of the node
 * @param {Object} options            additional options
 * @returns {*}
 * @private
 */
const _hotspots = ({
  selectedNode,
  areas,
  events,
  scaleFactor,
  isMoving,
  translateDistance,
  ...options
}) => areas.map((area, index) => {
  const { onClick } = events
  const { classes, radius, dimensions, labels } = options

  const [a1, b1, a2, b2] = area.coords
    .split(',')
    .map(x => parseInt(x, 10))

  // const centerX = a1 + ((a2 - a1) / 2)
  const centerY = b1 + ((b2 - b1) / 2)

  // const xAnchor = ((centerX / dimensions.width) * 100).toFixed(2)
  const yAnchor = ((centerY / dimensions.height) * 100).toFixed(2)

  const y1 = ((a1 / dimensions.width) * 100).toFixed(2)
  const width = (((a2 - a1) / dimensions.width) * 100).toFixed(2)

  const targetNodeIds = _getNodeIdsFromPos(selectedNode, area.pos)

  const avatar = <MUIAvatar
    key={index}
    className={targetNodeIds.includes(selectedNode.id) ? classes.selectedHotspot : classes.hotspot}
    variant={'rounded'}
    onClick={(e) => {
      const platformEvent = new PlatformEvent(e, {
        id: targetNodeIds.length > 1
          ? targetNodeIds
          : targetNodeIds[0],
      })
      targetNodeIds.length && onClick?.(platformEvent)
    }}
    sx={{
      position: 'absolute',
      top: `calc(${yAnchor}% - ${radius}px)`,
      left: `calc(${y1}%)`,
      minWidth: '1.5rem',
      width: `calc(${width}%)!important`,
      height: radius * 2,
    }}
  >
    {area.pos}
  </MUIAvatar>

  return !isMoving ? (
    <Tooltip
      key={index}
      leaveDelay={200}
      classes={{ tooltip: classes.tooltip }}
      title={_getHoveredNodeDescription(selectedNode, area.pos, labels)}
    >
      {avatar}
    </Tooltip>
  ) : avatar
})

/**
 * Component to display a movable image of the current node with hotspots attached to it that can
 * be clicked or hovered. Also makes the image zoomable with the mouse or with display control
 * buttons. To be used together with {@link Hotspot}.
 *
 * @param {Object} node             the node currently being displayed
 * @param {number} radius           the radius of the hotspots
 * @param {Object} events           events for the node
 * @param {Object} [parentClasses]  additional styling to overwrite the default one
 * @param {number} maxScaleFactor   the maximum scaling factor
 * @param {Object} props            additional props for the node
 * @returns {null|JSX.Element}
 * @constructor
 */
const Exploded = ({ node, radius, events, classes: parentClasses, maxScaleFactor = 4, ...props }) => {
  const theme = useTheme()
  const { onNodeType, onClick } = events
  const { spacing = 0, space = 0, gap = 0 } = props

  const nodeType = onNodeType(node?.type)
  const displayedNode = (nodeType === 'Directory' && node)
      || (nodeType === 'File' && node.parent)

  const explodedDrawing = displayedNode?.explodedDrawing || {}

  const currentAttachment = explodedDrawing?.attachment?.value?.name
    ? { ...explodedDrawing.attachment, key: explodedDrawing.value.attachmentId }
    : { value: { name: '' } }

  const { state, onLoad } = useAttachmentAPI({
    attachment: currentAttachment,
    skipPlaceholder: true,
  })

  const { src } = state

  const initialImageState = {
    dimensions: {},
    areas: [],
    scaleFactor: 1,
    translateDistance: { x: 0, y: 0 },
  }
  const [imageState, setImageState] = useState(initialImageState)
  const [isMoving, setIsMoving] = useState(false)

  const imageRef = useRef(null)
  const dragRef = useRef(null)

  const classes = useStyles(styles, {
    scaleFactor: imageState.scaleFactor,
    translateDistance: imageState.translateDistance,
  })()

  const options = { classes,
    radius,
    areas: imageState.areas,
    dimensions: imageState.dimensions,
    scaleFactor: imageState.scaleFactor,
    translateDistance: imageState.translateDistance,
    labels: {
      noPartLabel: props?.noPartLabel,
      errorLabel: props?.errorLabel,
    },
    theme,
    isMoving,
    events: {
      onClick,
    } }

  const calculateImageAreas = (event) => {
    const initialLoad = event?.type === 'load'
    const ressource = initialLoad ? event.target : imageRef.current

    const imageDimensions = {
      height: ressource.naturalHeight,
      width: ressource.naturalWidth,
    }

    setImageState({
      ...imageState,
      dimensions: imageDimensions,
      areas: explodedDrawing.value.area.map(area => ({
        ...area,
        top: imageDimensions.top,
        left: imageDimensions.left,
      })),
    })
  }

  // Resetting scaling/movement once we change the node
  useEffect(() => {
    setImageState(initialImageState)
  }, [src])

  const zoomHandler = (e) => {
    e.preventDefault()

    setImageState(prevState => ({
      ...prevState,
      scaleFactor: Math.min(
        Math.max(1, prevState.scaleFactor + Math.sign(-e.deltaY) * 0.1),
        maxScaleFactor,
      ),
    }))
  }

  const dragHandler = (e) => {
    const isDragging = e.clientX > 0 && e.clientY > 0
    if (isDragging) {
      const deltaX = e.clientX - dragRef.current.x + (dragRef?.current?.deltaX || 0)
      const deltaY = e.clientY - dragRef.current.y + (dragRef?.current?.deltaY || 0)

      dragRef.current = {
        ...dragRef.current,
        offsetX: deltaX,
        offsetY: deltaY,
      }

      setImageState(prevState => ({
        ...prevState,
        translateDistance: { x: deltaX, y: deltaY },
      }))
    }
  }

  const dragStartHandler = (e) => {
    setIsMoving(true)
    dragRef.current = { ...dragRef.current, x: e.clientX, y: e.clientY }
  }

  const dragEndHandler = (e) => {
    setIsMoving(false)
    dragRef.current = {
      deltaX: dragRef?.current?.offsetX,
      deltaY: dragRef?.current?.offsetY,
      x: e.clientX,
      y: e.clientY,
    }
  }

  const adjustScaleFactor = (scale = null) => () => {
    if (!scale) dragRef.current = { x: 0, y: 0 }

    setImageState(prevState => ({
      ...prevState,
      scaleFactor: !scale
        ? 1
        : Math.min(Math.max(1, prevState.scaleFactor + scale), maxScaleFactor),
      ...!scale && { translateDistance: { x: 0, y: 0 } },
    }))
  }

  useLayoutEffect(() => {
    imageRef?.current?.addEventListener?.('wheel', zoomHandler)
    imageRef?.current?.addEventListener?.('dragstart', dragStartHandler)
    imageRef?.current?.addEventListener?.('dragend', dragEndHandler)

    // NOTE: FF does not populate clientX, clientY coordinates for the drag event
    // https://bugzilla.mozilla.org/show_bug.cgi?id=505521 (OPEN FOR 15 YEARS!!)
    // Therefore, we need to use dragOver event in this case
    // https://stackoverflow.com/a/71934795
    navigator.userAgent.indexOf('Firefox') !== -1
      ? window.addEventListener('dragover', dragHandler)
      : imageRef?.current?.addEventListener?.('drag', dragHandler)

    return () => {
      imageRef?.current?.removeEventListener?.('wheel', zoomHandler)
      imageRef?.current?.removeEventListener?.('dragstart', dragStartHandler)
      imageRef?.current?.removeEventListener?.('dragend', dragEndHandler)

      navigator.userAgent.indexOf('Firefox') !== -1
        ? window.removeEventListener('dragover', dragHandler)
        : imageRef?.current?.removeEventListener?.('drag', dragHandler)
    }
  }, [imageRef?.current])

  return (
    <Grid
      item
      className={parentClasses.root}
      {...!props?.widthDelta && {
        xs: props.xs,
        sm: props.sm,
        md: props.md,
        lg: props.lg,
        xl: props.xl,
      }}
      style={{
        padding: spacing
          ? theme.spacing(spacing)
          : theme.spacing(space, gap),
      }}
    >
      <Grid
        item
        className={parentClasses.explodedDiagram}
      >
        {src && displayedNode && (
          <>
            <Box
              className={classes.imgContainer}
            >
              <img
                src={src}
                ref={imageRef}
                onLoad={(e) => {
                  onLoad()
                  calculateImageAreas(e)
                  dragRef.current = {}
                }}
                alt={displayedNode?.attachment?.value?.name}
              />
              {_hotspots({ selectedNode: node, ...options })}
            </Box>
            <Box
              className={classes.buttonContainer}
            >
              <IconButton
                color={'primary'}
                className={classes.button}
                onClick={adjustScaleFactor(-0.3)}>
                <ZoomOut />
              </IconButton>
              <IconButton
                color={'primary'}
                className={classes.button}
                onClick={adjustScaleFactor(0.3)}>
                <ZoomIn />
              </IconButton>
              <IconButton
                color={'primary'}
                className={classes.button}
                onClick={adjustScaleFactor(null)}
              >
                <RestartAlt />
              </IconButton>
            </Box>
          </>
        )}
      </Grid>
    </Grid>
  )
}

export default useMemoRef(Exploded, props => [props.node, props.classes])
