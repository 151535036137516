import { Grid, lighten } from '@mui/material'
import { withStyles } from '@mui/styles'
import { useMemoRef } from '@platform/react/hook'
import DecoratedText from 'ui/Element/Text/Decorated'
import FabLikeIcon from 'ui/Element/Icon/FabLike'
import { useContext } from 'react'
import ApplicationContext from '@platform/react/context/application'

/**
 * Styles for the component
 *
 * @param {Object} theme
 * @return {Object}
 */
const styles = theme => ({
  root: {
    padding: theme.spacing(1, 0),
    margin: theme.spacing(2, 0, 1),
  },
  iconContainer: {
    paddingLeft: 0,
  },
  icon: {
    display: 'flex',
    height: '40px',
    width: '40px',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    backgroundColor: lighten(theme.palette.primary.main, 0.9),
  },
  iconPaper: {
    backgroundColor: 'transparent',
  },
  ...theme.custom.organisationSegment,
})

/**
 * Organisation Segment element.
 *
 * Prints the name and address of an organisation within a single list item.
 *
 * @param {object} value      the data of the organisation to display
 * @param {object} [classes]  an object containing the element's css classes
 * @returns {JSX.Element}
 * @constructor
 */
const OrganisationSegment = ({ value, classes }) => {
  const { intl } = useContext(ApplicationContext)
  const [{ value: { name = '-', address = {} } }] = value
  const addressSummary = intl.address(address)

  return (
    <Grid
      item
      container
      className={classes.root}
      spacing={2}
      alignItems={'center'}
      xs={12}
    >
      <Grid
        item
        xs={'auto'}
        className={classes.iconContainer}
      >
        <FabLikeIcon
          icon={'location_on'}
          variant={'h5'}
          className={classes.icon}
        />
      </Grid>
      <Grid
        item
      >
        <DecoratedText
          color={'primary'}
          decorators={{ bold: true }}
          variant={'h6'}
        >
          {name}
        </DecoratedText>
        <DecoratedText
          noWrap={true}
          color={'textSecondary'}
        >
          {addressSummary}
        </DecoratedText>
      </Grid>
    </Grid>
  )
}

export default useMemoRef(withStyles(styles)(OrganisationSegment), [])
