/* global React */
import { createRef } from 'react'
import { createRoot } from 'react-dom/client'
import { flushSync } from 'react-dom'

const ref = createRef()

/**
 * Platform Web Native Entry Point
 *
 * It renders application into designated location in html.
 *
 * @example
 *  import AppRenderer from '@ui/AppRenderer'
 *  import Stub from '@ui/Stub'
 *
 *  await AppRenderer(
 *    <Stub />, {}, (...args) => console.log('return vals after successful render', ...args)
 *  )
 *
 * @param {React.ReactNode} ComponentToMount
 * @param {object} props
 * @param {function} [callback]
 * @return {Promise<*>}
 * @constructor
 */
const AppRenderer = async (ComponentToMount, props, callback) => {
  const container = document.querySelector('[js-app]')
  const root = createRoot(container)
  const callbackFn = () => (callback ? callback(ref) : ref)
  const component = <ComponentToMount {...props} ref={ref} callback={callbackFn}/>
  // A synchronous render of the component is necessary to timely obtain its ref through callbackFn
  flushSync(() => root.render(component))
}

export default AppRenderer
