import { useMemoRef } from 'platform/react/hook'
import Toolbar from 'platform/react/hoc/attachment/toolbar'

/**
 * Displays a simple text file in an iframe.
 *
 * @param {Object} attachment information about the attachment
 * @param {Object} props      additional props
 * @returns {JSX.Element}
 * @constructor
 */
const Text = ({ attachment, ...props }) => (
  // We can't fetch the attachment in JS due to CORS. But we can use an iframe.
  <iframe
    src={attachment?.value?.url}
    style={{
      height: '100%',
      width: '100%',
      border: 'none',
    }}>
  </iframe>
)

export default useMemoRef(Toolbar(Text), props => [props.attachment])
