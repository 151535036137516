/* eslint-disable arrow-body-style, object-curly-newline */
/* global React */
// eslint-disable-next-line import/no-extraneous-dependencies
import { FormControlLabel, Icon, Radio } from '@mui/material'
import useMemoRef from '@platform/react/hook/useMemoRef'
import { getThemeColor } from 'lib/util'

/**
 * Radio Element
 *
 * providing checked attribute to the element will throw a React warning in regard to
 * switching from uncontrolled to controlled component,
 * so we need to convert checked to defaultChecked
 *
 * controlled component - we need to propagate new (props) checked on each change.
 * uncontrolled component - we set defaults (props) and let component take care of itself,
 * until we force a new set of props.
 *
 * More info: https://fb.me/react-controlled-components
 *
 * @param {Object} forwardedRef     ref to the root element
 * @param {Object} events           events for the radio field
 * @param {String} label            label to be displayed
 * @param {Boolean} checked         whether or not the option is checked
 * @param {String} checkedIcon      icon to be displayed when checked
 * @param {String} icon             icon to tbe displayed when unchecked
 * @param {Object} props            additional props
 * @param {Object} props.icon       icon to tbe displayed by default
 * @param {String} props.iconColor  {@param props.icon}'s color
 *
 * @return {*}
 * @constructor
 */
const RadioField = ({ forwardedRef, events, label, checked, checkedIcon, ...props }) => {
  const { icon, iconColor, ...fieldProps } = props
  const icons = {}
  icon && (icons.icon = (
    <Icon sx={{
      ...iconColor && { color: theme => getThemeColor(theme, iconColor) },
    }}>
      {icon}
    </Icon>
  ))

  checkedIcon && (icons.checkedIcon = (
    <Icon sx={{
      ...iconColor && { color: theme => getThemeColor(theme, iconColor) },
    }}>
      {checkedIcon}
    </Icon>
  ))

  return (
    <FormControlLabel
      control={
        <Radio
          color={'primary'}
          ref={forwardedRef}
          defaultChecked={checked}
          {...fieldProps}
          {...events}
          {...icons}
        />
      }
      label={ label }
    />
  )
}

export default useMemoRef(RadioField, props => [
  props.disabled, props.checked,
])
