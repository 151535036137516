/* eslint-disable import/no-extraneous-dependencies,object-curly-newline */
import {
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react'
import { flushSync } from 'react-dom'
import { AppBar as MuiAppBar, Grid } from '@mui/material'
import { withStyles } from '@mui/styles'
import AppContext from 'platform/react/context/application'
import ActionTitle from 'ui/Component/Layout/AppBar/ActionTitle'
import GaiaToolbar from 'ui/Component/Layout/AppBar/Toolbar'
import SearchField from 'ui/Component/Layout/AppBar/SearchField'
import CreateButton from 'ui/Component/Layout/AppBar/CreateButton'
import ActionLinks from 'ui/Component/Layout/AppBar/ActionLinks'
import BackButton from 'ui/Component/Layout/AppBar/BackButton'
import ActionBreadcrumbs from 'ui/Component/Layout/AppBar/ActionBreadcrumbs'
import CloseButton from 'ui/Component/Layout/AppBar/CloseButton'

const GAIA_HASHCHANGE = 'gaia:hashchange'

/**
 * Returns an object that specifies the visual appearance of each part of the AppBar component.
 *
 * @param {Object} theme  the application's theme object
 * @returns {Object}      elements' style definitions
 */
const styles = theme => ({
  appBar: {
    width: 'auto',
    position: 'absolute',
    backgroundColor: 'transparent',
    borderBottom: `1px solid ${theme.palette.gray[900]}`,
    borderRadius: 0,
    left: 0,
  },
  actionBar: {
    height: theme.layout.actionBarHeight,
    textAlign: 'left',
    alignItems: 'center',
    flexWrap: 'nowrap',
    color: theme.palette.black.main,
    borderRadius: 0,
    paddingLeft: theme.layout.contentGap,
    paddingRight: theme.layout.contentGap,
  },
  actionBarFullscreenPadding: {
    paddingLeft: '2rem',
    paddingRight: '1rem',
  },
  content: {
    height: '100%',
    flexDirection: 'column',
    justifyContent: 'center',
    alignContent: 'space-between',
    flexWrap: 'nowrap',
    flexShrink: 1,
  },
  container: {
    justifyContent: 'flex-start',
    alignContent: 'center',
    flexWrap: 'nowrap',
  },
  actions: {
    height: '100%',
    justifyContent: 'flex-start',
    alignContent: 'center',
    alignItems: 'center',
    flexWrap: 'nowrap',
    flexShrink: 0,
  },
  searchAndCreate: {
    height: '100%',
    maxWidth: 312,
    justifyContent: 'flex-end',
    alignContent: 'center',
    alignItems: 'center',
    flexWrap: 'nowrap',
    flexShrink: 0,
  },
  toolbar: {
    backgroundColor: theme.palette.gray[960],
    minHeight: theme.layout.toolBarHeight,
    [theme.breakpoints.down('lg')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  toolbarAction: {
    color: theme.palette.black.main,
  },
  toolbarItems: {
    display: 'flex',
    flexDirection: 'row',
  },
  toolbarLinks: {
    display: 'flex',
    flexDirection: 'row',
    paddingTop: 0,
    paddingBottom: 0,
  },
  toolbarLinksItem: {
    color: theme.palette.appBar.text || theme.palette.primary.contrastText,
    padding: [[0, 8]],
    height: 34,
    borderRadius: '20%',
    '& > a': {
      borderRadius: '20%',
    },
  },
  toolbarLinksItemBadge: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.signal.main,
    right: -3,
    top: 5,
  },
  logo: {
    ...theme.mixins.toolbar,
    backgroundImage: theme.logo.url,
    backgroundSize: theme.logo.backgroundSize || '80%',
    imageRendering: 'high-quality',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    width: theme.layout.navigationDrawerWidth,
    height: theme.layout.actionBarHeight,
    flexShrink: 0,
  },
  navigationPadding: {
    [theme.breakpoints.up('md')]: {
      paddingLeft: theme.layout.navigationDrawerWidth,
    },
  },
  ...theme.custom.appBar,
})

/**
 * Filters all defined elements from {@param classes} and concatenates them as a string, separated
 * by whitespaces.
 *
 * @param {...any} classes a set of items to be concatenated
 * @return {string} all defined {@param classes} concatenated in a string
 */
const merge = (...classes) => classes.filter(item => !!item).join(' ')

/**
 * AppBar layout component
 *
 * Component intended to be displayed at the top of the application's content and to contain
 * essential navigation and contextual controls and basic information about the currently active
 * action.
 *
 * It keeps its navigation controls always synchronized with the navigation stack by listening to
 * the {@link GAIA_HASHCHANGE} event.
 *
 * @param {Object} props          component's properties
 * @param {Object} props.classes  component's styles definition
 */
const AppBar = forwardRef((props, ref) => {
  const { router, isLogged, eventBus } = useContext(AppContext)
  const [state, setState] = useState({})
  const [updateKey, setUpdateKey] = useState(null)
  const { classes } = props

  const update = useCallback(() => {
    flushSync(() => setUpdateKey({}))
  }, [])

  useEffect(() => {
    eventBus.add(GAIA_HASHCHANGE, update, { useCapture: true })
    return () => eventBus.remove(GAIA_HASHCHANGE, update, { useCapture: true })
  }, [])

  useImperativeHandle(ref, () => ({
    setState: newState => setState({
      appBar: true,
      ...newState,
    }),
  }))

  return !state.appBar ? null : (
    <MuiAppBar
      className={merge(
        classes.appBar,
        !state.fullScreen && isLogged && classes.navigationPadding,
      )}
      position={'fixed'}
      elevation={0}
    >
      {!state.fullScreen && (
        <GaiaToolbar
          classes={classes}
          login={state.login}
        />
      )}
      <Grid
        container
        className={merge(
          classes.actionBar,
          state.fullScreen && isLogged && classes.actionBarFullscreenPadding,
        )}
        columnSpacing={1}
      >
        {!state.fullScreen && !isLogged && (
          <Grid
            item
            className={classes.logo}
          />
        )}
        {isLogged && (
          <Grid
            item
            container
            className={classes.content}
            xs={12}
          >
            <ActionBreadcrumbs
              update={updateKey}
              hidden={state.fullScreen}
            />
            <Grid
              item
              container
              className={classes.container}
              columnSpacing={1}
            >
              <BackButton
                update={updateKey}
                hidden={state.fullScreen}
              />
              <ActionTitle title={state.title} />
            </Grid>
          </Grid>
        )}
        {(state.search || state.create) && (
          <Grid
            item
            container
            className={classes.searchAndCreate}
          >
            <SearchField
              {...state.search}
              reset={updateKey}
              hidden={!state.search}
            />
            <CreateButton
              {...state.create}
              hidden={!state.create}
            />
          </Grid>
        )}
        <ActionLinks
          actions={state.quickActions}
          hidden={!state.quickActions}
        />
        <CloseButton
          onClick={router.back}
          hidden={!state.fullScreen}
        />
      </Grid>
    </MuiAppBar>
  )
})

export default withStyles(styles)(AppBar)
