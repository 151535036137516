import { Typography } from '@mui/material'
import CellHOC from '@platform/react/hoc/list/cell'
import { def, getFirstItem } from 'lib/util'
import internal from 'model/organisation/collection/internal'

/**
 * Special username styles
 *
 * @param {Object} theme
 * @return {{verified: {color}, invited: {color}}}
 */
const styles = theme => ({
  root: {
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
  },
  verified: {
    color: theme.palette.info.main,
  },
  invited: {
    color: theme.palette.signal.main,
  },
  subRow: {
    gap: '5px',
    display: 'flex',
    alignItems: 'center',
  },
})

/**
 * Intended to display additional information about the person. Is only visible if the user
 * in question passes the acl check.
 *
 * @param {Object} classes  styling for the cell
 * @param {Object} props    props for the cell
 *
 * @returns {JSX.Element}
 */
export const ListItemCellPersonInfo = (classes, props = {}) => {
  const {
    getInvitationStatus = null,
    getInvitationRole = null,
    getUserRole = null,
  } = props?.events || {}

  const {
    acl,
    toBeValidated,
    toBeApprovedInfo,
    toBeValidatedInfo,
    invite: {
      role: inviteRole,
      status: invitationStatusCache,
    } = {},
    organisation: {
      key: parentId = null,
      refs: {
        parent: baseParent = null,
      } = {},
    } = {},
    invitedAs,
    count,
  } = props

  const userRoles = count?.value?.user?.person?.data?.roles
  const baseParentId = getFirstItem(baseParent)?.key || null

  const displayApproval = internal.some(x => x.key === parentId || x.key === baseParentId)
  const displayValidation = def(toBeValidated) && acl !== false

  const userRole = userRoles?.length ? getFirstItem(getUserRole?.(userRoles))?.value : null

  const invitationStatus = getFirstItem(getInvitationStatus?.(invitationStatusCache)) || {}
  const invitationRole = getFirstItem(getInvitationRole?.(inviteRole))?.value || {}
  const showInvitationStatus = invitationStatus?.showDescription

  const userInfo = (invitationRole && showInvitationStatus && <>{invitedAs} {invitationRole}</>)
    || (acl !== false && displayValidation && <>{toBeValidatedInfo}</>)
    || (acl !== false && displayApproval && <>{toBeApprovedInfo}</>)
    || (userRole && <>{userRole}</>)
    || '-'

  return <>
    <Typography
      variant={'body2'}
      className={classes.row}
      sx={{
        color: theme => (invitationRole && showInvitationStatus && theme.palette.primary.main)
            || ((displayValidation || displayApproval) && theme.palette.signal.main)
            || (userRole && theme.palette.primary.main)
            || 'inherit',
        fontWeight: 700,
      }}
    >
      {userInfo}
    </Typography>
  </>
}

export default CellHOC(ListItemCellPersonInfo, styles)
