/* eslint-disable object-curly-newline */
/* global React */
import { useEffect, useRef, useState } from 'react'
import { Grid, TextField } from '@mui/material'
import { withStyles } from '@mui/styles'
import PlatformEvent from 'lib/util/event'
import { useMemoRef } from '@platform/react/hook'
import DecoratedText from 'ui/Element/Text/Decorated'
import FabButton from 'ui/Element/Button/Fab'
import SimpleButton from 'ui/Element/Button/Simple'
import SelectField from 'ui/Element/Field/Select'
import GaiaImageCount from 'ui/Component/Attachment/Count'
import Mention from 'ui/Element/Field/Mention'

const styles = theme => ({
  root: {
    width: '100%',
    display: 'flex',
    backgroundColor: '#EEE',
    borderRadius: 4,
  },
  header: {
    padding: theme.spacing(1, 1, 0, 2.5),
  },
  deleteIcon: {
    color: theme.palette.text.secondary,
  },
  content: {
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1, 2, 2.5),
  },
  author: {
    minHeight: 56,
    display: 'flex',
    alignItems: 'center',
  },
  input: {
    margin: theme.spacing(1, 0, 2, 0),
    paddingRight: theme.spacing(2),
    '& fieldset': {
      border: 'none',
    },
    '& .MuiOutlinedInput-root': {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  type: {
    fontWeight: 500,
    textTransform: 'uppercase',
    border: 'none',
    fontSize: '.9rem',
    marginLeft: theme.spacing(-0.5),
    paddingLeft: theme.spacing(0.5),
    '&::after, &::before, &:hover::after, &:hover::before': {
      borderBottom: ['none', '!important'],
    },
    marginTop: [0, '!important'],
  },
  typeHelperText: {
    display: 'none',
  },
  button: {
    marginLeft: theme.spacing(-0.5),
  },
  footer: {
    padding: theme.spacing(1, 2, 0),
    borderTop: '1px solid #DDDDDD',
  },
  attachButton: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
  },
  attachIcon: {
    color: theme.palette.text.secondary,
  },
  attachCount: {
    color: theme.palette.text.secondary,
  },
  ...theme.custom.noteForm,
})

const AuthorLabel = ({ value, classes, xs }) => (
  <Grid
    item
    component={DecoratedText}
    color={'textSecondary'}
    decorators={{ bold: true }}
    className={classes.author}
    xs={xs}
  >
    {value}
  </Grid>
)

const DeleteButton = ({ onClick, classes, xs }) => (
  <Grid
    key={'delete'}
    id={'delete-button'}
    item
    xs={xs}
  >
    <FabButton
      events={{ onClick }}
      classes={{ icon: classes.deleteIcon }}
      icon={{ name: 'close', variant: 'filled' }}
    />
  </Grid>
)

const AttachButton = ({ onClick, classes, xs }) => (
  <Grid
    key={'attach'}
    item
    className={classes.attachButton}
    xs={xs}
  >
    <FabButton
      key={'button'}
      small={true}
      events={{ onClick }}
      classes={{ icon: classes.attachIcon }}
      icon={{ name: 'paperclip', variant: 'filled' }}
    />
    <GaiaImageCount
      key={'count'}
      group={'note'}
      autoHide={true}
      className={classes.attachCount}
      xs={'auto'}
    />
  </Grid>
)

/**
 * Note Form.
 *
 * Used as a form to add new notes.
 *
 * @param {object[]} selection            the available types of notes
 * @param {object} classes
 * @param {object} events                 an object containing event handlers
 * @param {function} events.onOpen        called at the beginning to alternatively obtain the note
 *                                        {@param selection}.
 * @param {function} events.onChange      called whenever the note's text field is manipulated
 * @param {function} events.onClick       called whenever the save button is clicked
 * @param {function} events.onAttach      called whenever the attach button is clicked
 * @param {function} events.onClose       called whenever the delete button is clicked
 * @param {object} props                  additional component's properties
 * @param {string} props.meLabel          translation of english 'me'
 * @param {string} props.placeholder      note's text field placeholder
 * @param {string} props.group            the name of the note's attachment group
 * @param {string} props.buttonText       the text of the save button
 * @param {string} props.buttonTitle      the title of the save button
 * @param {object[]} props.children       note's subelements
 *
 * @returns {JSX.Element}
 * @constructor
 */
const NoteForm = ({ selection, events, classes, children, ...props }) => {
  const { onOpen, onChange, onClick, onAttach, onClose, onType } = events
  const [spentTime, ...restChildren] = children
  const inputRef = useRef(null)
  const [text, setText] = useState('')
  const [mentions, setMentions] = useState([])
  const [empty, setEmpty] = useState(true)
  const types = onOpen ? onOpen(null)?.filter(item => item.selectable) : selection
  const [type, setType] = useState(types[0].key)
  const [timeTracking, setTimeTracking] = useState(false)

  const handleTextChange = (event) => {
    const { value, mentions: newMentions } = event.detail

    setText(value)
    setMentions(newMentions)
    setEmpty(!value?.length || !value.trim())
  }

  const handleClick = (event) => {
    const mentionedPersons = mentions.map(mention => mention.key)
    onClick?.(new PlatformEvent(event, { text, type, mentions: mentionedPersons }))
  }

  const handleTypeSelection = (event) => {
    const { value } = event.target
    setType(value)
  }

  const onCtrlEnter = handler => (event) => {
    !empty && event.ctrlKey && event.key === 'Enter' && handler(event)
  }

  useEffect(() => {
    type && setTimeTracking(
      onType?.(new PlatformEvent(new CustomEvent('onType'), { value: type })),
    )
  }, [type])

  return (
    <Grid
      className={classes.root}
      container
      item
    >
      <Grid
        className={classes.header}
        container
        item
        xs={12}
      >
        <AuthorLabel
          value={props.meLabel}
          classes={classes}
          xs={true}
        />
        <DeleteButton
          onClick={onClose}
          classes={classes}
          xs={'auto'}
        />
      </Grid>
      <Grid
        className={classes.content}
        container
        item
        columnSpacing={1}
        xs={12}
      >
        <Mention
          Component={TextField}
          item
          xs={12}
          value={text}
          autoFocus={true}
          multiline={true}
          inputRef={inputRef}
          className={classes.input}
          onChange={handleTextChange}
          placeholder={props.placeholder}
          onKeyPress={onCtrlEnter(handleClick)}
          trigger={children.filter(child => child.props?.mention)}
        />
        <Grid
          item
          container
          alignItems={'center'}
          sx={{
            flex: '1 1 50%',
          }}
        >
          <SelectField
            selection={types}
            events={{ onChange: handleTypeSelection }}
            helperText={null}
            FormHelperTextProps={{
              classes: {
                root: classes.typeHelperText,
              },
            }}
            InputProps={{
              classes: {
                root: classes.type,
                input: classes.type,
              },
            }}
          />
        </Grid>
        <Grid
          item
          md={12}
          lg={'auto'}
        >
          <SimpleButton
            value={props.buttonText}
            title={props.buttonTitle}
            events={{ onClick: handleClick }}
            variant={'text'}
            color={'secondary'}
            className={classes.button}
            disabled={empty}
          />
        </Grid>
      </Grid>
      <Grid
        className={classes.footer}
        container
        item
      >
        {timeTracking && spentTime}
        <AttachButton
          onClick={onAttach}
          classes={classes}
          xs={2}
        />
        {restChildren}
      </Grid>
    </Grid>
  )
}

export default useMemoRef(withStyles(styles)(NoteForm), props => [props.error])
