/* global G */
import { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { Page } from 'react-pdf'
import { repeat } from 'lib/util'
import { useMemoRef } from 'platform/react/hook'
import ApplicationContext from 'platform/react/context/application'
import ScrollGrid from 'ui/Component/Grid/ScrollGrid'
import { withStyles } from '@mui/styles'

/**
 * Checks whether {@param el1} is in the visible area of {@param el2}
 * @param el1
 * @param el2
 * @returns {boolean}
 */
const isVisible = (el1, el2) => {
  const rect1 = el1.getBoundingClientRect()
  const rect2 = el2.getBoundingClientRect()

  return (
    ((rect2.top <= rect1.top) && (rect1.top <= rect2.bottom))
    && ((rect2.top <= rect1.bottom) && (rect1.bottom <= rect2.bottom))
    && ((rect2.left <= rect1.left) && (rect1.left <= rect2.right))
    && ((rect2.left <= rect1.right) && (rect1.right <= rect2.right))
  )
}

const styles = theme => ({
  scroll: {
    height: '100%',
    flexShrink: 1,
  },
  scrollContent: {
    justifyContent: 'center',
    flexDirection: 'column',
    flexWrap: 'nowrap',
  },
  page: {
    display: 'flex',
    justifyContent: 'center',
    '& .react-pdf__Page__textContent': {
      left: 'auto!important',
      right: 'auto!important',
      '& > span > mark': {
        borderRadius: '0.5rem',
        backgroundColor: theme.palette.text.light,
        boxShadow: `0px 0px 10px 0px ${theme.palette.text.regular}`,
        '&.highlight': {
          backgroundColor: theme.palette.warning.main,
        },
      },
    },
    '& .react-pdf__Page__canvas': {
      border: `1px solid ${theme.palette.common.black}`,
    },
    '& .react-pdf__Page__annotations': {
      left: 'auto!important',
      right: 'auto!important',
    },
    '&:not(:last-child)': {
      paddingBottom: '0.5rem',
    },
  },
})

/**
 * Memoized wrapper around react-pdf's {@link Page} to better handle re-renderings of pages in our
 * specific case.
 *
 * @type {React.FC}
 */
const MemoizedPage = useMemoRef(({ forwardedRef, index, ...props }) => {
  const { highlightedSearchResult, pageRef, currentPage, ...restProps } = props

  const renderTextLayerSuccessHandler = useCallback(() => {
    if (highlightedSearchResult?.itemIndex) {
      const highlightedElement = document.getElementsByClassName('highlight')[0]
      const pageIndex = highlightedElement?.id || null

      if (pageIndex === (index + 1).toString()) {
        if (!isVisible(highlightedElement, pageRef?.current)) {
          const layer = highlightedElement.parentNode.parentNode
          layer.scrollIntoView({ inline: 'nearest', block: 'nearest' })
        }
      }
    }
    props.onRenderTextLayerSuccess?.()
  }, [highlightedSearchResult, pageRef, index])

  const inputRefHandler = useCallback((currentPageRef) => {
    if (currentPageRef && ((currentPage === index + 1) || (!currentPage && index === 0))) {
      currentPageRef.scrollIntoView()
    }
  }, [currentPage])

  return (
    <Page
      {...restProps}
      onRenderTextLayerSuccess={renderTextLayerSuccessHandler}
      inputRef={inputRefHandler}
    />
  )
}, props => [
  props.customTextRenderer,
  props.onRenderSuccess,
  props.currentPage,
  props.inputRef,
  props.width,
  props.scale,
])

/**
 * Simple Pdf Pages Component
 *
 * Shows a container with all pages of the current pdf.
 *
 * @param {Object} pagesProps                               props
 * @param {React.Ref} pagesProps.forwardedRef               ref to the dom node
 * @param {Object} pagesProps.classes                       styles from parent component
 * @param {number} pagesProps.currentPage                   the current page
 * @param {number} pagesProps.currentPageLoaded             whether the page at index
 *                                                          {@param currentPage} has been loaded
 * @param {boolean} pagesProps.lastPageLoaded               whether all pages have been loaded
 * @param {number} pagesProps.pages                         number of pages
 * @param {number} pagesProps.pageScale                     the current page scale
 * @param {Object} pagesProps.events                        events for the component
 * @param {Function} pagesProps.events.onSearch             handler when the search term changes
 * @param {Function} pagesProps.events.onCurrentPageLoaded  event triggered when the page at index
 *                                                          {@param currentPage} has been loaded
 * @param {Function} pagesProps.events.onLastPageLoaded     event triggered when all pages have been
 *                                                          loaded
 * @param {Object} pagesProps.props                         additional props
 * @param {Object} pagesProps.props.searchTerm              the current search term
 * @param {Object} pagesProps.props.highlightedSearchResult the currently highlighted search term
 * @returns {JSX.Element}
 * @constructor
 */
const Pages = ({
  forwardedRef,
  currentPage,
  currentPageLoaded,
  pages,
  pageScale,
  events,
  classes,
  lastPageLoaded,
  ...props
}) => {
  const { eventBus } = useContext(ApplicationContext)

  const { onSearch = null, onPageScale = null, onCurrentPageLoaded, onLastPageLoaded } = events || {}
  const { searchTerm, highlightedSearchResult } = props

  const pageRef = useRef(null)
  const loadedPagesRef = useRef(0)

  useEffect(() => {
    // If we have request an initial page, and it's not the first one
    // disable loader after all pages have been loaded
    if (Number.isInteger(currentPage)
        && currentPage !== 1
        && lastPageLoaded) {
      eventBus.dispatch(eventBus.type(G.LOAD, G.DONE))
    }

    // If we have not requested an initial page, or it's the first one
    // disable loader after first page has been loaded
    if (!currentPage || currentPage === 1) {
      eventBus.dispatch(eventBus.type(G.LOAD, G.DONE))
    }
  }, [lastPageLoaded])

  // Handler for ctrl + mwheel
  const zoomHandler = useCallback((e) => {
    if (e.ctrlKey) {
      e.stopPropagation()
      Math.sign(e.deltaY) === -1
        && onPageScale?.(prevState => prevState + 0.125)
      Math.sign(e.deltaY) === 1
        && onPageScale?.(prevState => (prevState > 0.25 ? prevState - 0.125 : 0.25))
    }
  }, [onPageScale])

  // Registering zoom event handler, no need for cleanup here
  useEffect(() => {
    if (pageRef.current) {
      pageRef.current.addEventListener?.('wheel', zoomHandler, { passive: true })
    }
  }, [pageRef.current])

  // Return the highlighted pattern with a mark, the current on in a different color
  // Look at {@link styles} to see how we style the marks
  const highlightPattern = (text, pattern, highlight) => text?.str.replace(new RegExp(pattern, 'i'), value => (
    text.pageIndex === highlight?.pageIndex && text.itemIndex === highlight?.itemIndex
      ? `<mark id="${text.pageIndex}" class="highlight">${value}</mark>`
      : `<mark>${value}</mark>`))

  /**
   * Comparison function that sorts items after {code itemIndex}
   * @param {Object} a   the first item
   * @param {Object} b  the second item
   * @returns {number}
   */
  const comparator = (a, b) => a.pageIndex - b.pageIndex || a.itemIndex - b.itemIndex

  // Handler for rendering text above the pdf, this is needed when searching for a term
  const textRenderer = useCallback(
    (textItem) => {
      if (searchTerm?.length && textItem?.str?.toLowerCase().includes(searchTerm?.toLowerCase())) {
        onSearch?.((prevResults) => {
          const alreadyFound = prevResults.some(
            result => result.pageIndex === textItem.pageIndex
              && result.itemIndex === textItem.itemIndex,
          )
          const newResults = !alreadyFound ? [...prevResults, textItem] : prevResults
          // We need to sort the search results by itemIndex, because the initial page we rendered
          // this pdf with might not have been page one, in that case the search result found on
          // that page will be the first in the list, but it's not the first in the pdf
          return newResults.sort(comparator)
        })
      }
      return highlightPattern(textItem, searchTerm, highlightedSearchResult)
    },
    [searchTerm, highlightedSearchResult],
  )

  return (
    <ScrollGrid
      ref={pageRef}
      container
      className={classes.scroll}
      classes={{ content: classes.scrollContent }}
    >
      {repeat(pages, i => (
        <MemoizedPage
          loading={null}
          key={`page-${i}`}
          index={i}
          width={props.width}
          pageNumber={i + 1}
          currentPage={currentPage}
          scale={pageScale || 1}
          pageRef={pageRef}
          renderTextLayer={true}
          className={classes.page}
          customTextRenderer={textRenderer}
          highlightedSearchResult={highlightedSearchResult}
          onRenderSuccess={() => {
            currentPage === i + 1 && !currentPageLoaded && onCurrentPageLoaded?.(true)
          }}
          onRenderTextLayerSuccess={() => {
            loadedPagesRef.current += 1
            loadedPagesRef.current === pages && onLastPageLoaded?.(true)
          }}
        />
      ))}
    </ScrollGrid>
  )
}

export default useMemoRef(withStyles(styles)(Pages), props => [
  props.classes,
  props.pages,
  props.currentPage,
  props.currentPageLoaded,
  props.lastPageLoaded,
  props.pageScale,
  props.showThumbnails,
  props.searchTerm,
  props.width,
  props.highlightedSearchResult,
])
