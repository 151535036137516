/* global React */
import {
  IconButton as MuiIconButton, ListItemSecondaryAction,
  ListItemText, ListItemIcon, Icon as MuiIcon,
} from '@mui/material'

const Icon = props => (
  <MuiIcon
    size={'small'}
    fontSize={'large'}
    color={'primary'}
    {...props}
  />
)

const IconButton = props => (
  <MuiIconButton
    size={'small'}
    fontSize={'small'}
    color={'primary'}
    className={'material-icons-outlined'}
    {...props}
  />
)

const AttachmentListTemplateLocal = (props) => {
  const [progress, setProgress] = React.useState(false)
  const { file } = props
  // const { name } = file || {}
  const [name, setName] = React.useState(file.name || '')

  // adds file and callback to passed event
  const addEventDetail = (e) => {
    e.detail = {
      file,
      fn: setName,
    }
    return e
  }

  const onPlay = (e) => {
    setProgress(true)
    props.onClick(addEventDetail(e))
  }

  return (
    <>
      <ListItemIcon>
        <Icon>attachment</Icon>
      </ListItemIcon>
      <ListItemText
        primaryTypographyProps={{
          fontSize: 'large',
          color: 'primary',
        }}
      >
        { name || 'really long file name' }
      </ListItemText>
      <ListItemSecondaryAction>
        {
          progress
            ? <IconButton>stop</IconButton>
            : <IconButton onClick={onPlay}>
              play_circle_outline
            </IconButton>
        }
        <IconButton onClick={() => setProgress(false)}>cancel</IconButton>
      </ListItemSecondaryAction>
    </>
  )
}

export default AttachmentListTemplateLocal
