/* eslint-disable object-curly-newline */
/* global React */
import { Icon, Link, ListItemIcon, ListItemText, MenuItem } from '@mui/material'

const AclMenuItem = React.forwardRef(({ child, options }, ref) => {
  const { icon, label, context, route: { module, action } } = child
  const { props, events: { onClick } } = options
  return (
    <MenuItem onClick={onClick} {...props} ref={ref}>
      <Link href={`/#/${context}/${module}/${action}`} underline="hover">
        { icon && <ListItemIcon><Icon>{icon}</Icon></ListItemIcon> }
        <ListItemText primary={label}/>
      </Link>
    </MenuItem>
  );
})

export default AclMenuItem
