import SvgIcon from 'ui/Element/Icon/Svg'
import CellHOC from '@platform/react/hoc/list/cell'

/**
 * Custom styles for the note text.
 *
 * @return {{cell: {lineHeight: number}}}
 */
const styles = () => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
})

/**
 * Displays the 'More' icon of the note.
 *
 * @param {Object} classes  styling for the cell
 * @param {Object} props    props for the cell
 *
 * @returns {JSX.Element}
 */
const ListItemCellNoteText = (classes, props = {}) => (
  <SvgIcon
    variant={'filled'}
    icon={'more'}
  />
)

export default CellHOC(ListItemCellNoteText, styles)
