/* eslint-disable object-curly-newline,arrow-body-style,import/no-extraneous-dependencies */
/* global React */
import { useTransition } from 'react'
import { Button, Grid } from '@mui/material'
import { withStyles } from '@mui/styles'
import { useMemoRef } from '@platform/react/hook'
import FabLike from '@ui/Element/Icon/FabLike'
import Preface from '@ui/Element/Text/Preface'

const styles = theme => ({
  root: {
    justifyContent: 'left',
    alignItems: 'center',
  },
  iconContainer: {
    width: 'auto',
  },
  icon: {
    width: 48,
    height: 48,
    color: theme.palette.primary.main,
  },
  prefaceContainer: {
    paddingLeft: 24,
  },
  prefaceHeading: {
    color: theme.palette.primary.main,
    textAlign: 'left',
  },
  ...theme.custom.iconPrefaceButton,
})

const IconPrefaceButton = ({ forwardedRef, events, icon, classes, ...props }) => {
  const [, startTransition] = useTransition()
  const handleClick = event => startTransition(() => events.onClick(event))

  return (
    <Grid
      item container
      direction={'row'}
      component={Button}
      elevation={0}
      ref={forwardedRef}
      onClick={handleClick}
      className={classes.root}
    >
      <Grid
        item
        container
        className={classes.iconContainer}
      >
        <FabLike icon={icon} />
      </Grid>
      <Grid
        item
        container
        className={classes.prefaceContainer}
        xs={10}
        sm={11}
      >
        <Preface
          heading={props.heading}
          text={props.text}
          variant={props.preface.variant}
          gutterBottom={props.preface.gutterBottom}
          classes={{ heading: classes.prefaceHeading }}
        />
      </Grid>
    </Grid>
  )
}

export default useMemoRef(withStyles(styles)(IconPrefaceButton), [])
