import { useCallback, useContext, useEffect, useState } from 'react'
import { Button, CircularProgress, Grid, Typography } from '@mui/material'
import { withStyles } from '@mui/styles'
import AppContext from 'platform/react/context/application'
import { useMemoRef, useNetInfo } from 'platform/react/hook'
import SvgIcon from 'ui/Element/Icon/Svg'

const buttonStyles = theme => ({
  minWidth: 0,
  minHeight: 20,
  padding: theme.spacing(1),
  alignItems: 'center',
  flexWrap: 'nowrap',
  lineHeight: 1,
})

/**
 * OfflineBox component's Styles.
 *
 * NOTE: So that the OfflineBox component can display its icons when the application is offline,
 * it must obtain them beforehand, causing the browser to cache them. To achieve that, we render
 * it hidden for a short period of time when starting the application with the {@code hidden} class
 * (because {@code display: none} actually prevents it from being rendered). If at some point we
 * find a way to package the SVG icons with the application, we should remove the hidden class,
 * the component's preloaded state and this comment.
 *
 * @param {Object} theme
 * @return {Object}
 */
const styles = theme => ({
  hidden: {
    position: 'absolute',
    width: 0,
    height: 0,
    overflow: 'hidden',
    zIndex: -1,
  },
  root: {
    position: 'absolute',
    top: 0,
    left: '50%',
    transform: 'translateX(-50%)',
    height: theme.layout.toolBarHeight,
    flexWrap: 'nowrap',
    padding: theme.spacing(0, 1.2),
    columnGap: theme.spacing(3),
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '0.8rem',
    backgroundColor: theme.palette.warning[50],
    color: [[theme.palette.warning[700]], '!important'],
    '& *': {
      color: [[theme.palette.warning[700]], '!important'],
    },
    zIndex: 1250,
  },
  reconnect: {
    ...buttonStyles(theme),
    display: 'flex',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    textDecoration: 'underline',
    transform: 'opacity 500ms',
    '&.Mui-disabled': {
      opacity: 0.6,
      textDecoration: 'none',
    },
  },
  learnMore: {
    ...buttonStyles(theme),
    padding: theme.spacing(0.5, 1),
  },
  icon: {
    backgroundColor: [[theme.palette.warning[700]], '!important'],
  },
})

/**
 * Displays a dialogue intended to inform the user about the way the application works in offline
 * mode.
 *
 * TODO: to be used when the offline functionality is implemented
 *
 * @param {Gaia.Adapter.EventBus} eventBus  application's event dispatcher/listener
 * @param {Gaia.Adapter.Intl} intl          translations adapter
 */
// const showLearnMoreDialog = (eventBus, intl) => {
//   eventBus.dispatch(eventBus.type(G.DATA, G.UNDO), {
//     width: 'sm',
//     title: intl._t(
//       'dialog.ping.title',
//       {
//         ns: 'custom',
//         _key: 'dialog.ping.title',
//         defaultValue: '',
//       },
//     ),
//     text: intl.markdown(
//       'dialog.ping.textMD',
//       {
//         ns: 'custom',
//         _key: 'dialog.ping.textMD',
//         defaultValue: '',
//         joinArrays: '\n',
//         md: true,
//       },
//     ),
//     children: {
//       cancel: {
//         key: 'ok',
//         value: intl._t(
//           'button.ok',
//           {
//             ns: 'common',
//             _key: 'button.ok',
//             defaultValue: 'Ok',
//           },
//         ),
//       },
//     },
//   })
// }

/**
 * Helper function used to create elements that translate text with the {@link Gaia.Adapter.Intl}.
 *
 * @param {Object} intl  the application's translation adapter
 * @returns {function({tKey: *, ns?: string, value: *}): *}
 */
const translator = intl => ({ tKey, ns = 'common', value }) => intl._t(tKey, {
  ns, _key: tKey, defaultValue: value,
})

/**
 * OfflineBox component
 *
 * Intended to be displayed whenever the application loses its internet connection.
 *
 * Offers a button to force an immediate reconnection attempt.
 *
 * @param {Object} props          component properties
 * @param {Object} props.classes  component's style classes
 * @returns {JSX.Element}
 * @constructor
 */
const OfflineBox = ({ classes }) => {
  const { intl } = useContext(AppContext)
  const [preloaded, setPreloaded] = useState(false)
  const [pending, setPending] = useState(false)
  const [online, forceCheck] = useNetInfo()

  const TranslatedLabel = translator(intl)

  /* TODO: to be used when the offline functionality is implemented */
  // const handleLearnMore = useCallback(() => showLearnMoreDialog(eventBus, intl), [])

  const handleReconnect = useCallback(() => {
    setPending(true)
    forceCheck()
    setTimeout(() => setPending(false), 3000)
  }, [])

  useEffect(() => {
    setTimeout(() => setPreloaded(true), 3000)
  }, [])

  return online && preloaded ? null : (
    <Grid
      item
      container
      className={preloaded ? classes.root : classes.hidden}
      columnGap={3}
      xs={'auto'}
    >
      <Grid
        item
        container
        alignItems={'center'}
        columnGap={1}
        xs={'auto'}
      >
        <SvgIcon
          className={classes.icon}
          size={'1.5rem'}
          variant={'filled'}
          icon={'offline'}
        />
        <Typography
          fontWeight={'bold'}
        >
          {pending ? (
            <TranslatedLabel
              tKey={'label.connecting'}
              value={'Connecting...'}
            />
          ) : (
            <TranslatedLabel
              tKey={'label.noInternetConnection'}
              value={'No internet connection'}
            />
          )}
        </Typography>
      </Grid>
      <Button
        disabled={pending}
        onClick={handleReconnect}
        className={classes.reconnect}
        size={'small'}
        endIcon={pending && (
          <CircularProgress
            size={'1rem'}
            color={'warning'}
          />
        )}
      >
        <TranslatedLabel
          tKey={'button.reconnect'}
          value={'Reconnect'}
        />
      </Button>
      {/* TODO: to be displayed when the offline functionality is implemented */}
      {/* <Button */}
      {/*   className={classes.learnMore} */}
      {/*   onClick={handleLearnMore} */}
      {/*   size={'small'} */}
      {/* > */}
      {/*   <SvgIcon */}
      {/*     size={'1.4rem'} */}
      {/*     className={classes.icon} */}
      {/*     variant={'outlined'} */}
      {/*     icon={'info'} */}
      {/*   /> */}
      {/* </Button> */}
    </Grid>
  )
}

export default useMemoRef(withStyles(styles)(OfflineBox))
