import { Typography } from '@mui/material'
import CellHOC from '@platform/react/hoc/list/cell'

/**
 * Displays the team of the user.
 *
 * @param {Object} classes  styling for the cell
 * @param {Object} props    props for the cell
 *
 * @return {*}
 * @constructor
 */
export const ListItemCellUserTeam = (classes, props = {}) => {
  const { team } = props

  return (
    <Typography
      color={'black'}
      variant={'body2'}
      className={classes.row}
      sx={{ fontWeight: 700 }}
    >
      {team?.value?.name || '-'}
    </Typography>
  )
}

export default CellHOC(ListItemCellUserTeam)
