import OptionHOC, { getOptionLabel } from '@platform/react/hoc/autocomplete/option'
import OverflowTooltip from 'ui/Element/Text/OverflowTooltip'
import { Box } from '@mui/material'

/**
 * Displays the name of timezone together with an infoText containing the offset.
 *
 * @param {Object} classes  styling for the cell
 * @param {Object} props    props for the cell
 *
 * @return {*}
 * @constructor
 */
const TimezoneOption = (classes, props = {}) => {
  const { option } = props
  const offset = option?.offset || ''

  return (
    <>
      <Box
        key={'item'}
        component={'div'}
        className={classes.option}
      >
        <OverflowTooltip>
          {getOptionLabel(option)}
        </OverflowTooltip>
      </Box>
      <Box className={classes.label}>
        {offset}
      </Box>
    </>
  )
}

export default OptionHOC(TimezoneOption)
