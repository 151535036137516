/* global React */
import { Grid, Skeleton, Fade } from '@mui/material'
import { isArr, repeat } from 'lib/util'
import { useMemoRef } from '@platform/react/hook'

export const style = {
  maxHeight: '100%',
  backgroundColor: 'rgba(87, 87, 87, 0.11)',
}

/**
 * PlaceholderListItemCellText
 *
 * To be displayed while a list's cell element, which would contain text, is being loaded.
 *
 * @param {number} size                                           placeholder's grid width
 * @param {number} [lines=2]                                      number of lines to display
 * @param {string|string[]|number|number[]} [width='95%']         width of each line
 * @param {string|string[]|number|number[]} [textLineWidth=width] width of each line
 * @param {string|string[]|number|number[]} [textLineHeight=20]   height of each line
 * @returns {null|JSX.Element|JSX.Element[]}
 * @constructor
 */
const PlaceholderListItemCellText = useMemoRef(({ size, width = '95%', textLines = 2, textLineWidth = width, textLineHeight = 20, fullWidth }) => (
  <Fade
    in={true}
    timeout={{
      enter: 1500,
      exit: 1500,
    }}
  >
    <Grid
      item
      xs={size}
    >
      {repeat(textLines, index => (
        <Grid
          key={index}
          item
          width={isArr(textLineWidth) ? textLineWidth[index] : textLineWidth}
          maxWidth={'95%'}
          rowGap={1}
          style={{
            maxWidth: '95%',
            ...fullWidth && {
              maxWidth: '100%',
              width: '100%',
            },
          }}
        >
          <Skeleton
            variant={'text'}
            height={isArr(textLineHeight) ? textLineHeight[index] : textLineHeight}
            style={style}
          />
        </Grid>
      ))}
    </Grid>
  </Fade>
), props => [props.textLines, props.textLineWidth])

export default PlaceholderListItemCellText
