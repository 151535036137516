import { Typography } from '@mui/material'
import { useContext } from 'react'
import ApplicationContext from '@platform/react/context/application'
import CellHOC from '@platform/react/hoc/list/cell'

/**
 * Returns the translated device's selectUnknown label.
 *
 * @returns {string}  the serialUnidentified label
 */
const addNewDeviceLabel = () => {
  const { intl } = useContext(ApplicationContext)
  return intl._t(
    'label.selectUnknown',
    {
      ns: 'device',
      _key: 'label.selectUnknown',
      defaultValue: 'Select unknown device',
    },
  )
}

/**
 * Returns the translated device's serialNumber label.
 *
 * @returns {string}  the serialNumber label
 */
const serialNumberLabel = () => {
  const { intl } = useContext(ApplicationContext)
  return intl._t(
    'label.serialNumber',
    {
      ns: 'device',
      _key: 'label.serialNumber',
      defaultValue: 'Serial number',
    },
  )
}

/**
 * Returns the ListItemCellDeviceNew element's content according to {@param props}.
 *
 * @param {object} props    element's properties
 * @param {object} classes  element's style classes
 *
 * @returns {JSX.Element}
 */
const ListItemCellDeviceNew = (classes, props = {}) => {
  const addNewDevice = addNewDeviceLabel()
  const serialNumber = serialNumberLabel()

  return (
    <>
      <Typography
        color={'primary'}
        component={'span'}
        title={addNewDevice}
        className={classes.row}
        sx={theme => ({
          color: theme.palette.primary.main,
          fontWeight: 'bold',
        })}
      >
        {addNewDevice}
      </Typography>
      <Typography
        component={'span'}
        variant={'caption'}
        title={`${serialNumber}: ${props.serial}`}
        sx={theme => ({ color: theme.palette.text.primary })}
      >
        {serialNumber}: <b>{props.serial}</b>
      </Typography>
    </>
  )
}

export default CellHOC(ListItemCellDeviceNew)
