/* global React */
import { useEffect, useRef, useState } from 'react'
import { Grid, Skeleton } from '@mui/material'
import { style } from 'ui/Element/Placeholder/List/Item/Cell/Block'

/**
 * Simple Template Placeholder
 *
 * Displays a (one) simple {@link Skeleton} box as a placeholder.
 *
 * @param {React.ReactNode[]} [children]      children to wrap the skeleton around. Useful because
 *                                            the skeleton will infer its height based on children
 * @param {object} [placeholderProps]         placeholder's properties
 * @param {number} [placeholderProps.height]  height of the placeholder
 * @param {number} [placeholderProps.width]   width of the placeholder
 * @param {object} [props]                    additional element's properties
 * @param {boolean} [props.isHidden=true}     whether the element should be hidden
 * @returns {null|JSX.Element}
 * @constructor
 */
const PlaceholderSimple = ({ children = [], placeholderProps, ...props }) => {
  const { isHidden = true, timeout = 2000 } = props
  const { height = 20, width = '100%', variant = 'rounded' } = placeholderProps || {}
  const [hidden, setHidden] = useState(isHidden)
  const timer = useRef(setTimeout(() => setHidden(false), timeout))

  useEffect(() => () => clearTimeout(timer.current), [])

  return hidden ? null : (
    <Grid
      item
      container
      height={'100%'}
      alignItems={'center'}
      justifyContent={'space-around'}
    >
      <Skeleton
        variant={variant}
        width={width}
        style={style}
        {...children.length === 0 && { height }}
      >
        {children}
      </Skeleton>
    </Grid>
  )
}

export default PlaceholderSimple
