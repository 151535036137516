import CellHOC from '@platform/react/hoc/list/cell'
import LabeledHOC from '@platform/react/hoc/list/labeled'
import { ListItemCellRequestDescription } from 'ui/Element/List/Item/Cell/Request/Description'

export default CellHOC(
  LabeledHOC(
    props => props.labels.description,
    ListItemCellRequestDescription,
  ),
)
