import { useMemoRef, useStyles } from '@platform/react/hook'
import { Box, Button, Divider, Grid, List, ListItem, Typography, useTheme } from '@mui/material'
import { useEffect, useMemo, useState } from 'react'
import OverflowTooltip from 'ui/Element/Text/OverflowTooltip'

const styles = theme => ({
  summary: {
    flexDirection: 'column',
    backgroundColor: theme.palette.background.content,
    borderRadius: '0.5rem',
    width: '100%',
    display: 'flex',
    padding: '1.5rem',
  },
  summaryTitle: {
    fontWeight: 500,
    paddingBottom: '1rem',
  },
  position: {
    overflow: 'hidden',
    marginRight: '2rem',
    display: 'flex',
    flexDirection: 'column',
  },
  list: {
    paddingTop: '1.5rem',
    paddingBottom: '1.5rem',
  },
  listItem: {
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 0,
    paddingBottom: '1rem',
    '&:last-child': {
      paddingBottom: 0,
    },
    justifyContent: 'space-between',
  },
  sum: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: '1.5rem',
  },
})

/**
 * Creates a list of articles.
 *
 * @param {Object} classes      styles to use
 * @param {Object[]} positions  articles to show
 * @returns {*}
 */
const positionList = ({ classes, positions }) => positions.map((position, i) => (
  <Grid
    item
    key={i}
    component={ListItem}
    className={classes.listItem}
    xs={12}
  >
    <Box className={classes.position}>
      <OverflowTooltip
        variant={'14/bold'}
        color={'textDark'}
      >
        {position.name}
      </OverflowTooltip>
      <OverflowTooltip
        variant={'12/medium'}
        color={'textPrimary'}
      >
        {position?.articleNumber || '-'}
      </OverflowTooltip>
    </Box>
    <Typography
      variant={'14/bold'}
      color={'textDark'}
    >
      {position.amount}
    </Typography>
  </Grid>
))

/**
 * CartSummary component
 *
 * @param {Object} cart             the currently active cart
 * @param {React.Ref} forwardedRef  forwarded ref
 * @param {Object} parentClasses    classes injected from the parent
 * @param {number} activeStep       the currently active step
 * @param {Object[]} steps          list of steps
 * @param {Object} events           events for the component
 * @param {Object} props            additional props
 * @returns {JSX.Element}
 * @constructor
 */
const CartSummary = ({ cart, forwardedRef, activeStep, steps, classes: parentClasses, events, ...props }) => {
  const { onClick = null } = events
  const { positionsLabel, nextLabel, titleLabels, showItems } = props
  const { spacing = 0, space = 0, gap = 0 } = props

  const theme = useTheme()
  const classes = { ...useStyles(styles)(), ...parentClasses }
  const [state, setState] = useState({ key: 0, canStep: true, showItems: false })

  const memoizedSteps = useMemo(() => steps, [])

  useEffect(() => {
    setState({
      key: memoizedSteps.find(step => step.index === activeStep)?.key || null,
      canStep: memoizedSteps ? activeStep < steps.length - 1 : false,
      showItems,
    })
  }, [activeStep])

  const cartPositions = cart?.value?.positions
  const totalPositionsAmount = cart?.value?.positions.reduce((acc, key) => acc + key.amount, 0)

  const stepLabels = titleLabels.steps
  const currentStepTitle = stepLabels[state.key]

  return (
    <Grid
      item
      xs={props.xs}
      sm={props.sm}
      md={props.md}
      lg={props.lg}
      xl={props.xl}
      style={{
        padding: spacing
          ? theme.spacing(spacing)
          : theme.spacing(space, gap),
      }}
    >
      {!cart ? null : (
        <Box className={classes.summary}>
          <Typography
            variant={'body2'}
            className={classes.summaryTitle}
          >
            {currentStepTitle}
          </Typography>
          <Divider/>
          {!cartPositions?.length || !state.showItems ? null : (
            <>
              <Grid
                item
                container
                xs={12}
                component={List}
                className={classes.list}
              >
                {positionList({ classes, positions: cartPositions })}
              </Grid>
              <Divider/>
            </>
          )}
          <Box className={classes.sum}>
            <Typography
              variant={'24/bold'}
              color={'textDark'}
            >
              {positionsLabel}
            </Typography>
            <Typography
              variant={'24/bold'}
              color={'textDark'}
            >
              {totalPositionsAmount}
            </Typography>
          </Box>
          {!state.canStep ? null : (
            <Button
              fullWidth
              sx={{ marginTop: '1.5rem' }}
              variant={'contained'}
              color={'primary'}
              onClick={onClick}
            >
              {nextLabel}
            </Button>
          )}
        </Box>
      )}
    </Grid>
  )
}

export default useMemoRef(CartSummary, false)
