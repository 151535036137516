import SimpleButton from 'ui/Element/Button/Simple'
import { PlatformEvent } from 'lib/util'

/**
 * Intended to display a button.
 *
 * @param {Object} props
 * @return {JSX.Element}
 * @constructor
 */
export const ListItemCellButton = ({ value, labels, events }) => (
  <SimpleButton
    events={{ onClick: (event) => {
      events.onSecondaryAction(new PlatformEvent(event, value))
    } }}
    value={labels.button}
  />
)

export default ListItemCellButton
