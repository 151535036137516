/* global React */
import { Dialog, DialogContent, DialogTitle, Grid, IconButton, Typography, useTheme } from '@mui/material'
import { useMemoRef, useStyles } from '@platform/react/hook'
import PlatformEvent from 'lib/util/event'
import { useState } from 'react'
import Fab from 'ui/Element/Button/Fab'
import Simple from 'ui/Element/Button/Simple'
import ErrorBoundary from 'ui/Error'

const styles = theme => ({
  root: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  descriptionBox: {
    [theme.breakpoints.up('md')]: {
      width: '37.5rem', // 600px
    },
  },
  dialogButton: {
    textTransform: 'uppercase',
  },
  closeButton: {
    position: 'absolute',
    top: '1rem',
    right: '1rem',
  },
  content: {
    '& > *': {
      overflow: 'hidden',
    },
    // This will be the list
    '& > :first-child > :last-child': {
      height: '25rem', // 400px
    },
  },
})

/**
 * @typedef {import('@mui/material').ModalProps} ModalProps
 */

/**
 * Modal showing a dialog to add items to the cart.
 *
 * @type {React.FunctionComponent}
 *
 * @param {Object} addBoxProps                        props for the modal
 * @param {boolean} addBoxProps.open                  whether the modal is open
 * @param {Object} addBoxProps.children               children to render
 * @param {ModalProps['onClose']} addBoxProps.onClose onClose handler
 * @param {Object} addBoxProps.props
 * @returns {React.JSX.Element}
 * @constructor
 */
const AddBox = ({ open, children, onClose, ...props }) => {
  const { labels, classes } = props

  const CloseButton = useMemoRef(Fab, [])

  return (
    <Dialog
      open={open}
      onClose={onClose}
      classes={{ paper: classes.descriptionBox }}
    >
      <DialogTitle>
        {labels.title}
      </DialogTitle>
      <CloseButton
        events={{ onClick: onClose }}
        classes={{ button: classes.closeButton }}
        icon={{ name: 'close', variant: 'filled' }}
      />
      <DialogContent className={classes.content}>
        {children}
      </DialogContent>
    </Dialog>
  )
}

/**
 * CartFooter component
 *
 * @type {React.FunctionComponent}
 *
 * @param {Object} footerProps          props
 * @param {Object} footerProps.classes  classes injected from the parent
 * @param {Object} footerProps.events   events for the component
 * @param {Object} footerProps.children events for the component
 * @param {Object} footerProps.props    additional props
 * @param {React.Ref<Object>} ref
 * @returns {JSX.Element}
 * @constructor
 */
const CartFooter = ({ classes: parentClasses, events, children, ...props }, ref) => {
  const theme = useTheme()
  const classes = { ...useStyles(styles)(), ...parentClasses }
  const [open, setOpen] = useState(false)

  const { onOpen = null } = events || {}

  const { spacing = 0, space = 0, gap = 0, gridProps, ...options } = props
  const { addToCartLabel, addToCartIcon, optionLabels, view } = options

  return (
    <ErrorBoundary>
      <AddBox
        open={open}
        view={view}
        children={children}
        optionLabels={optionLabels}
        labels={{ title: addToCartLabel }}
        // events={{ onClick: onAddPart }}
        classes={{
          content: classes.content,
          dialogButton: classes.dialogButton,
          descriptionBox: classes.descriptionBox,
          closeButton: classes.closeButton,
        }}
        onClose={ () => setOpen(false) }
      />
      <Grid
        item
        container
        ref={ref}
        className={classes.root}
        xs={props.xs}
        sm={props.sm}
        md={props.md}
        lg={props.lg}
        xl={props.xl}
        style={{
          padding: spacing
            ? theme.spacing(spacing)
            : theme.spacing(space, gap),
        }}
      >
        <Simple
          fullWidth={false}
          color={'primary'}
          size={'small'}
          variant={'contained'}
          startIcon={addToCartIcon.icon}
          startIconProps={{
            variant: addToCartIcon.variant,
            color: theme.palette.common.white,
          }}
          events={{ onClick: (e) => {
            onOpen?.(new PlatformEvent(e, {}))
            setOpen(true)
          } }}
          value={
            <Typography variant={'14/bold'}>
              {addToCartLabel}
            </Typography>
          }
        />
      </Grid>
    </ErrorBoundary>
  )
}

export default CartFooter
